
define('template!customerTemplatesPopupAddTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="container-fluid">\n  <div class="row selected-template-group">\n    <div class="col-sm-12" class="form-group-standardsizes">\n\n      <form class="form-horizontal new-template" role="form">\n        <div class="row">\n          <div class="form-group" data-field-name="standard-sizes">\n            <div class="col-sm-3">\n              <label class="control-label">' +
((__t = ( _.i18n("common.templateGroup.standardSizes") )) == null ? '' : __t) +
'</label>\n            </div>\n            <div class="col-sm-9">\n                <div class="select-standardsizes"></div>\n            </div>\n          </div>\n        </div>\n        <div class="row">\n          <div class="form-group geometry-field" data-field-name="geometry">\n            <div class="col-sm-3">\n              <label class="control-label">' +
((__t = ( _.i18n("common.templateGroup.geometry") )) == null ? '' : __t) +
'</label>\n            </div>\n            <div class="col-sm-9">\n                <div class="radio radio-primary col-xs-6">\n                  <label class="geometry-radio-b">\n                    <input type="radio" name="optionsRadios" id="optionsRadios1" value="RECT" data-id="0" checked="">\n                    ' +
((__t = ( _.i18n('geometry.rect') )) == null ? '' : __t) +
'\n                  </label>\n                </div>\n                <div class="radio radio-primary col-xs-6">\n                  <label class="geometry-radio-b">\n                    <input type="radio" name="optionsRadios" id="optionsRadios2" value="ROUND" data-id="1">\n                    ' +
((__t = ( _.i18n('geometry.round') )) == null ? '' : __t) +
'\n                  </label>\n                </div>\n            </div>\n          </div>\n        </div>\n      </form>\n    </div>\n  </div>\n\n  <div class="text-center create-template-btn-in-popup">\n    <button type="button" class="btn btn-primary js-save-template">' +
((__t = ( _.i18n("common.saveTemplate") )) == null ? '' : __t) +
'</button>\n  </div>\n</div>';

}
return __p
};});

