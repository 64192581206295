define('runCreateFromUploadView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!runCreateFromUploadTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'settings',
  'autocompleteView',
  'entities/runs'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  Settings,
  AutocompleteView
) {
  'use strict';

  /*var selectRunTemplateRegion = Marionette.Region.extend({
    el: '.js-select-run-template-region',
    allowMissingEl: true
  });*/

  var CreateFromUploadView = DialogFormView.extend({
    template: createEditLayoutTpl,

    events: {
      'click .js-cancel-popup': 'hide',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectKitProt: '.js-select-kitprot-region',
      selectLmbAnalyzer: '.js-select-lmbanalyzer-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'name', type: 'required'}
    ],

    fromTemplate: true,

    onShow: function() {
      $.material.init();
      this.lmbAnalyzerView = new AutocompleteView(
          this._getAutocompleteOptions('refLmbAnalyzer', 'code', 'code', 'v2/lmbanalyzers',
            'selectlmbanalyzer.placeholder', 'code', this.onLmbAnalyzerChange)
      );
      this.getRegion('selectLmbAnalyzer').show(this.lmbAnalyzerView);

      var kitProtView = new AutocompleteView(
          this._getAutocompleteOptions('refKitProt', 'code', 'code', 'v2/kitprots/mineAndShared',
              'selectkitprot.placeholder', 'code', this.onKitProtChange)
      );
      this.getRegion('selectKitProt').show(kitProtView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onLmbAnalyzerChange: function(fieldName, model) {
      this.lmbAnalyzer = model;
    },

    onKitProtChange: function(fieldName, model) {
        this.kitProt = model;
    },

    onConfirm: function() {
        this.trigger('popup-create:selectLmbAnalyser', this.lmbAnalyzer, this.kitProt);
        this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
    }
  });

  module.exports = CreateFromUploadView;
});
