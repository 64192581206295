/**
 * Created by OBL on 14/01/2016.
 */
define('adminAnaAssayController',[
    'module',
    'underscore',
    'app',
    'adminAnaAssayView',
    'bootbox',
    'anaAssayCreateEditLayoutView',
    'settings',
    'entities/anaassays'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    CreateEditLayoutView
    ) {
    'use strict';

    var SuperAdminAnaAssayListController = {
        showAnaAssayList: function(data) {
            this.data = data;
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'anaassay:delete', _.bind(this.onDelete, this));
            view.listenTo(view, 'anaassay:edit', _.bind(this.onEdit, this));
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('anaAssayListTab').show(view);
        },

        showAnaAssayAdd: function(assaySecId) {
            this.showAnaAssayAddEdit(App.request('anaassay:model', {
                refAssay: {
                    secId: assaySecId
                }
            }), true);
        },

        showAnaAssayAddEdit: function(model, newItem) {
            this.createEditView = new CreateEditLayoutView({
                anaAssayModel: model,
                newItem: newItem
            });
            this.createEditView.show({
                title: _.i18n('anaassay.new')
            });
        },

        showAnaAssayEdit: function(assaySecId, anaAssaySecId) {
            var that = this;
            var model = App.request('anaassay:model', {
                refKit: {
                    secId: assaySecId
                },
                secId: anaAssaySecId
            });
            model.fetch().done(function() {
                that.showAnaAssayAddEdit(model, false);
            });
        },

        onEdit: function(mbKitSecId, anaAssaySecId){
            App.navigate('admin/assays/' + mbKitSecId + '/anaassay/' + anaAssaySecId + '/edit', {trigger: true});
        },

        onGridLoaded: function() {
            var data = {
                url: App.request('anaassay:get-collection-url', this.data.model.get('secId')),
                rowCountUrl: App.request('anaassay:get-count-url', this.data.model.get('secId'))
            };

            this.view.displayGrid(data);
        },

        onDelete: function(kitSecId, secId){
            bootbox.confirm(_.i18n('anaassay.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('anaassay:delete', kitSecId, secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = SuperAdminAnaAssayListController;
});
