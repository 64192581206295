define('adminKitprTabs',[
  'module',
  'underscore'
], function(
  module,
  _
) {
  'use strict';

  module.exports = [
    {
      name: 'detail',
      title: _.i18n('kitprs.details.tabTitle'),
      isDefault: true
    }
  ];
});
