
define('template!customerContactsPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="billingcodes-add-new-container">\n\t<div class="container-fluid">\n\t\t<div class="saving-label"></div>\n\t\t<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'</div>\n\t\t<form class="form-horizontal col-xs-12" role="form">\n\t\t\t<div class="form-group" data-field-name="email">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control email js-form-input floating-label"\n\t\t\t\t\t\tdata-field-name="email"\n\t\t\t\t\t\tplaceholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\tvalue="' +
((__t = ( email )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="name">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control name js-form-input floating-label"\n\t\t\t\t\t\tdata-field-name="name"\n\t\t\t\t\t\tplaceholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\tvalue="' +
((__t = ( name )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="firstName">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control firstName js-form-input floating-label"\n\t\t\t\t\t\tdata-field-name="firstName"\n\t\t\t\t\t\tplaceholder="' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\tvalue="' +
((__t = ( firstName ? firstName : '' )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="lastName">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\tclass="form-control lastName js-form-input floating-label"\n\t\t\t\t\t\tdata-field-name="lastName"\n\t\t\t\t\t\tplaceholder="' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'"\n\t\t\t\t\t\tvalue="' +
((__t = ( lastName ? lastName : '' )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="addressL1">\n\t\t\t\t<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input type="text" class="form-control floating-label js-form-input" data-field-name="addressL1" placeholder="' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'" value="' +
((__t = ( addressL1 ? addressL1 : '' )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="addressL2">\n\t\t\t\t<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input type="text" class="form-control floating-label js-form-input" data-field-name="addressL2" placeholder="' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'" value="' +
((__t = ( addressL2 ? addressL2 : '' )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group">\n\t\t\t\t<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<div class="js-select-countries-region"></div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group">\n\t\t\t\t<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<div class="col-xs-6 p-l-0" data-field-name="zip">\n\t\t\t\t\t\t<input type="text" class="form-control floating-label js-form-input" data-field-name="zip" placeholder="' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'" value="' +
((__t = ( zip ? zip : '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-6 p-r-0" data-field-name="city">\n\t\t\t\t\t\t<input type="text" class="form-control floating-label js-form-input" data-field-name="city" placeholder="' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'" value="' +
((__t = ( city ? city : '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group">\n\t\t\t\t<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('customerContact.phone') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.fax') )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<div class="col-xs-6 p-l-0" data-field-name="tel">\n\t\t\t\t\t\t<input type="text" class="form-control floating-label js-form-input" data-field-name="tel" placeholder="phone number" \n\t\t\t\t\t\tvalue="' +
((__t = ( tel ? tel : '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="col-xs-6 p-r-0" data-field-name="fax">\n\t\t\t\t\t\t<input type="text" class="form-control floating-label js-form-input" data-field-name="fax" placeholder="' +
((__t = ( _.i18n('common.fax') )) == null ? '' : __t) +
'" value="' +
((__t = ( fax ? fax : '' )) == null ? '' : __t) +
'">\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\t\t      \n\t\t\t<div class="form-group" data-field-name="comment">\n\t\t\t\t<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('customerContact.comment') )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<textarea class="form-control floating-label js-form-input" rows="4" data-field-name="comment" placeholder="' +
((__t = ( _.i18n('customerContact.comment') )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t' +
((__t = ( comment ? comment : '' )) == null ? '' : __t) +
'\n\t\t\t\t\t</textarea>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</form>\n\t</div>\n</div>';

}
return __p
};});

