/**
 * Created by RKL on 15/09/2015.
 */
define('customerDatasetsTabController',[
  'module',
  'backbone',
  'underscore',
  'app',
  'bootbox',
  'customerDatasetsTabView',
  'customerDatasetsCollectionView',
  'entities/customers/dataset'
], function(
  module,
  Backbone,
  _,
  App,
  bootbox,
  LayoutView,
  CollectionView
) {
  'use strict';

  var CustomersDatasetsTabController = {
    showTab: function(data) {
      if (!data.tab) {
        data.tab = 'datasets';
      }

      this.data = data;
      this.region = 'customerDatasetsTab';
      this.objectType = 'text';
      this.endUserId = this.data.model.get('endUserId');
      this.fetchCollection();
    },

    fetchCollection: function() {
      var that = this; 
      
      this.collection = App.request('dataset:collection');
      this.collection.getCollection({
        type: this.objectType, 
        endUserId: this.endUserId
      }).done(function() {
        that.layoutView = new LayoutView();

        App.regions.getRegion('main')
          .currentView.getRegion('content')
          .currentView.getRegion('customerDatasetsTab').show(that.layoutView);

        that._showDataSetTable();
      });
    },

    _showDataSetTable: function() {
      var collectionView = new CollectionView({
        collection: this.collection,
        endUserId: this.endUserId
      });

      this.layoutView.getRegion('datasetRow').show(collectionView);
    }
  };

  module.exports = CustomersDatasetsTabController;
});
