
define('template!wellTemplateTableTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content m-r-0 m-l-0">\n\t<div class="well-table jq-grid-table-page hidden-print">\n\t\t<div class="">\n\t\t\t<label class="btn add-btn-text btn-sm edit-smpid-button">\n\t\t\t\t<i class="mdi-editor-mode-edit"></i>\n\t\t\t\t<span>' +
((__t = ( _.i18n("well.editSmpId") )) == null ? '' : __t) +
'</span>\n\t\t\t</label>\n\t\t\t<label class="btn add-btn-text btn-sm edit-refassay-button">\n\t\t\t\t<i class="mdi-editor-mode-edit"></i>\n\t\t\t\t<span>' +
((__t = ( _.i18n("well.editRefAssay") )) == null ? '' : __t) +
'</span>\n\t\t\t</label>\n\t\t\t<!--<label class="btn add-btn-text btn-sm edit-refkitl-button">\n\t\t\t\t<i class="mdi-editor-mode-edit"></i>\n\t\t\t\t<span>' +
((__t = ( _.i18n("well.editRefKitL") )) == null ? '' : __t) +
'</span>\n\t\t\t</label>-->\n\t\t</div>\n\t  <div class="loading-block js-loading-block">\n\t    <span class="loading-message js-loading-message label"></span>\n\t  </div>\t\t\t      \n\t  <table id="jq-grid-well" class="wellTable" role="grid"></table>\n\t</div>\n</div>';

}
return __p
};});

