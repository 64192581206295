define('entities/mbsmpmats',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var MbSmpMat = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/mbsmpmats/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      code: '',
      name: '',
      description: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function () {
      return Settings.url('compuzz', 'v2/mbsmpmats');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var MbSmpMatCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/mbsmpmats');
    },
    model: MbSmpMat
  });

  var API = {

    getMbSmpMatCollectionUrl: function() {
      new MbSmpMatCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/mbsmpmats', params);
    },

    getAllMbSmpMats: function(params) {
      var url = Settings.url('compuzz', 'v2/mbsmpmats', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getMbSmpMatCountUrl: function() {
      var params = '';
      return 'v2/mbsmpmats/rowCount' + params;
    },

    deleteMbSmpMat: function(secId){
      var url = Settings.url('compuzz', 'v2/mbsmpmats/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('mbsmpmat:get-collection-url', function() {
      return API.getMbSmpMatCollectionUrl();
  });

  App.reqres.setHandler('mbsmpmat:get-collection-to-page', function(params) {
      return API.getAllMbSmpMats(params);
  });

  App.reqres.setHandler('mbsmpmat:get-count-url', function() {
    return API.getMbSmpMatCountUrl();
  });

  App.reqres.setHandler('mbsmpmat:model', function(itemJSON) {
    return new MbSmpMat(itemJSON);
  });

  App.reqres.setHandler('mbsmpmat:delete', function(secId) {
    return API.deleteMbSmpMat(secId);
  });
});
