/**
 * Created by RKL on 13/08/2015.
 */
define('kitlDetailView',[
  'module',
  'kitlCreateEditLayoutView',
  'underscore',
  'marionette',
  'template!kitlDetailTabTpl',
  'settings',
  'moment'
], function (
  module,
  CreateEditLayoutView,
  _,
  Marionette,
  viewTpl,
  Settings,
  moment
) {
  'use strict';

  var KitlDetailView = Marionette.ItemView.extend({
    template: viewTpl,

    modelEvents: {
      change: 'render'
    },

    events: {
      'click .js-edit': '_onEdit'
    },

    serializeData: function () {
      var templateData = this.model.toJSON();

      templateData.expDt = moment(this.model.get('expDt')).format('DD-MM-YYYY');
      templateData.prodDt = moment(this.model.get('prodDt')).format('DD-MM-YYYY');
      templateData.currentCAccountSecId = Settings.get('currentCAccount').secId;
      return templateData;
    },

    _onEdit: function() {
      var createEditView = new CreateEditLayoutView({
        kitlModel: this.model
      });
      createEditView.show({
        title: _.i18n('kitl.edit')
      });
    }

  });

  module.exports = KitlDetailView;
});
