define('customerContactsPopupView',[
  'module',
  'underscore',
  'dialogFormView',
  'validate',
  'settings',
  'autocompleteView',
  'template!customerContactsPopupTpl'
], function(
  module,
  _,
  DialogFormView,
  validateUtils,
  Settings,
  AutocompleteView,
  addNewContactsTpl
) {
  'use strict';

  var AddNewContactsPopupView = DialogFormView.extend({
    template: addNewContactsTpl,

    ui: {
      input: '.js-form-input'
    },   

    events: {
      'change @ui.input': 'onInputChange',
      'focus @ui.input': 'onFocus'
    },

    regions: {
      selectCountries: '.js-select-countries-region'
    },

    onShow: function() {
      var countriesView = new AutocompleteView(
        this._getCountriesAutocompleteOptions()
      );
      this.getRegion('selectCountries').show(countriesView);
      $.material.init();
    },

    _getCountriesAutocompleteOptions: function() {
      return {
        data: Settings.get('countries'),
        valueKey: 'name',
        apiKey: 'id',
        placeholder: _.i18n('common.countryCode'),
        value: '',
        name: 'countryCode',
        callback: _.bind(this.onChangeCountry, this)
      };
    },

    onChangeCountry: function(fieldName, model) {
      var code = model ? model.get('code') : '';

      this.onChange(fieldName, code);
      this.triggerMethod('enable:cancel:confirm');
    }
  });

  module.exports = AddNewContactsPopupView;
});
