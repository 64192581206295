define('entities/welltemplates',[
  'app',
  'backbone',
  'settings'
], function(
  App,
  Backbone,
  Settings
) {
  'use strict';

  var WellTemplate = Backbone.Model.extend({
    fetch: function() {
      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      'code':  '',
      'smpid': '',
      'assay': '',
      'pos': '',
      'refKitL': {}
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (runSecId) {
      return Settings.url('compuzz', 'v2/runtemplates/' + runSecId + '/wells');
    },

    save: function() {
      this.url = this.postUrl(this.get('run'));
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var WellTemplateCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/runtemplates');
    },
    model: WellTemplate
  });

  var API = {

    getWellTemplateCollectionUrl: function(runSecId, row, column, assaySecId, smpid) {
      new WellTemplateCollection();
      var params = {};
      if (row) {
        params.row = row;
      }
      if (column) {
        params.column = column;
      }
      if (assaySecId) {
        params.assaySecId = assaySecId;
      }
      if (smpid) {
        params.smpid = smpid;
      }
      return Settings.url('compuzz', 'v2/runtemplates/' + runSecId + '/wells', params);
    },

    getWellTemplatesCountUrl: function(runSecId, row, column, assaySecId, smpid) {
      var params = '?';
      if (row) {
        params += 'row=' + row + '&';
      }
      if (column) {
        params += 'column=' + column + '&';
      }
      if (assaySecId) {
        params += 'assaySecId=' + assaySecId + '&';
      }
      if (smpid) {
        params += 'smpid=' + smpid + '&';
      }
      return 'v2/runtemplates/'+runSecId+'/wells/rowCount' + params;
    }
  };

  App.reqres.setHandler('welltemplate:get-collection-url', function(runSecId, row, column, assaySecId, smpid) {
      return API.getWellTemplateCollectionUrl(runSecId, row, column, assaySecId, smpid);
  });

  App.reqres.setHandler('welltemplate:get-count-url', function(runSecId, row, column, assaySecId, smpid) {
    return API.getWellTemplatesCountUrl(runSecId, row, column, assaySecId, smpid);
  });

  App.reqres.setHandler('welltemplate:model', function(runJSON) {
    return new WellTemplate(runJSON);
  });
});
