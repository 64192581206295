  /**
 * Created by RKL on 18/08/2015.
 */
define('customerProdTemplatesItemView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'template!customerProdTemplatesItemTpl',
  'entities/caccounts/templates'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  viewTpl
) {
  'use strict';

  var CustomersProducTemplatesTabItemView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      imageHolder: '.js-image-holder',
      imageHolderContainer: '.js-image-holder-container',
      remove: '.js-remove-icon',
      modify: '.js-modify-icon'
    },

    events: {
      'click @ui.remove': 'onRemove',
      'click @ui.modify': 'onModify',
      'click @ui.imageHolderContainer' : 'onZoom'
    },

    tagName: 'div',

    className: 'original-img-wrapper zoom-img editor-holder template-item',

    onRender: function() {
      var imageUrl = Settings.url('file', this.model.get('snapshotsUids'));

      this.ui.imageHolder.css('background-image', 'url(' + imageUrl + ')');
    },

    onRemove: function() {
      bootbox.confirm(
        _.i18n('warning.corporateTemplates.delete'),
        _.bind(function (result) {
          if (result) {
            App.trigger('producttemplate:destroy', this.model);
          }
        }, this)
      );
    },

    onModify: function() {
      App.trigger(
        'producttemplate:create-new',
        this.model.get('productCode'),
        this.model.get('purchaseOrderId')
      );
    },

    onZoom: function() {
      App.trigger(
        'producttemplate:zoom', 
        this.model.get('id')
      );
    }

  });

  module.exports = CustomersProducTemplatesTabItemView;
});
