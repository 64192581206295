define('kitprCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!kitprCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      expDt: '.date-expdt',
      prodDt: '.date-proddt'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectMbAModel: '.js-select-mbamodel-region',
      selectKitProt: '.js-select-kitprot-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'refMbAModel', type: 'required'},
      {name: 'refKitProt', type: 'required'}
    ],

    initialize: function(options) {
      this.model = options.kitprModel.clone();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();

      var mbAModelView = new AutocompleteView(
          this._getAutocompleteOptions('refMbAModel', 'code', 'code', 'v2/mbamodels',
              'selectmbamodel.placeholder', 'code')
      );
      this.getRegion('selectMbAModel').show(mbAModelView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.model.save();
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.options.kitprModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('kitprs:header:rerender', modelJSON);
      App.navigate('admin/kitprots/' + model.get('refKitProt').secId + '/kitpr', {trigger: true});
      this.hide();
    },

    hide: function() {
      var kitProtSecId = this.model.get('refKitProt')?this.model.get('refKitProt').secId:'';
      var secId = this.model.get('secId');
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'admin/kitprots/'+kitProtSecId+'/kitpr/new' ||
          App.getCurrentRoute() ===  'admin/kitprots/'+kitProtSecId+'/kitpr/'+secId+'/edit') {
        App.navigate('admin/kitprots/'+kitProtSecId+'/kitpr', {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
