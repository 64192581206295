/**
 * Created by RKL on 18/08/2015.
 */
define('customerTemplatesFiltersView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'template!customerTemplatesFiltersTpl',
  'entities/caccounts/templates'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  viewTpl
) {
  'use strict';

  var TemplatesFiltersView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      filtersSelector: '.filters-selector',
      filtersInput: '.filters-input',
      filterReset: '.filters-search-clear'
    },

    events: {
      'change @ui.filtersSelector': 'selectOption',
      'click @ui.filterReset': 'resetFilter',
      'keydown @ui.filtersInput': 'changeIcon',
      'focusout @ui.filtersInput': 'hideReset',
      'focusin @ui.filtersInput': 'showReset',
      'change @ui.filtersInput': 'setFilter'
    },

    initialize: function(options) {
      this.options = options;
      this.filters = {};
    },

    serializeData: function() {
      //template data
      return {
        filters: this.options.filters,
        allCount: this.options.count
      };
    },

    onRender: function() {
  
    },

    changeIcon: function(e) {
      var $target = $(e.currentTarget),
          value,
          $iconHolder = $target.parent().find('.filters-search-clear');

      this.startTyping = true;
      setTimeout(function(){
        value = $target.val();
        if (value.length > 0) {
          $iconHolder.find('.search-icon').hide();
          $iconHolder.find('.reset-icon').show();
        } else {
          $iconHolder.find('.search-icon').show();
          $iconHolder.find('.reset-icon').hide();
        }
      }, 1);
    },

    hideReset: function(e) {
      var $target = $(e.currentTarget),
          $iconHolder = $target.parent().find('.filters-search-clear');

      $iconHolder.find('.reset-icon').css('visibility', 'hidden');
    },

    showReset: function(e) {
      var $target = $(e.currentTarget),
          $iconHolder = $target.parent().find('.filters-search-clear');

      $iconHolder.find('.reset-icon').css('visibility', 'visible');
    },

    resetFilter: function(e) {
      var $target = $(e.currentTarget),
          $filter = $target.parent().find('.filters-input'),
          filter = $filter.data('field-name');

          if ($filter.val().length === 0) return;
          this.filters[filter] = undefined;
          $filter.val('').addClass('empty');

          if (!this.startTyping) $filter.change();

      this.changeIcon({currentTarget: $filter[0]});
    },

    setFilter: function(e) {
      var that = this,
          $target = $(e.currentTarget),
          filter = $target.data('field-name'),
          value = $target.val(); 

      setTimeout(function(){
        that.startTyping = false;
        value = $target.val();
        if (filter) {
          that.filters[filter] = value;
        }
        App.trigger('templates-filters:filtersChanged', that.filters);
      }, 200);
    },

    selectOption: function(e) {
      var $target = $(e.currentTarget),
          selected = $target.find('option:selected'),
          filter = $target.data('field-name'),
          value = selected.val(); 

      if (filter) {
        value = value === 'all' ? undefined : value;
        this.filters[filter] = value;
      }
      App.trigger('templates-filters:filtersChanged', this.filters);
    }

  });

  module.exports = TemplatesFiltersView;
});
