define('cAccountTabs',[
  'underscore',
  'rolesMixin'
], function (_, RolesMixin) {
  'use strict';

  var  cAccountTabs = {
    graphicalIdTabs :
      [{
          route: 'objectnames',
          name: 'objectnames',
          role : RolesMixin.DASHBOARD_ACCOUNT_GRAPHICALID_OBJECTNAMES,
          title: _.i18n('cAccountDetail.objectNames.tabTitle')
      }, {
          route: 'fonts',
          name: 'fonts',
          role : RolesMixin.DASHBOARD_ACCOUNT_GRAPHICALID_FONTS,
          title: _.i18n('font.custom')
      } ],
    myAccountTabs :
      [{
         route: 'profile',
         name: 'profile',
         role : RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_PROFILE,
         title: _.i18n('cAccountDetail.profile.tabTitle')
       }, {
         route: 'credits',
         name: 'credits',
         role : RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_CREDITS,
         title: _.i18n('cAccountDetail.credits.tabTitle')
       }, {
         route: 'administrator',
         name: 'administrator',
         role : RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_ADMINISTRATOR,
         title: _.i18n('cAccountDetail.administrator.tabTitle')
       }
      ]
  };

  return cAccountTabs;
});
