/**
 * Created by RKL on 18/08/2015.
 */
define('customerTemplatesGroupsItemView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'template!customerTemplatesGroupsItemTpl',
  'entities/caccounts/templates'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  viewTpl
) {
  'use strict';

  var GroupsItemView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      count: '.templates-count',
      templateGroupItem: '.template-group-item'
    },

    events: {
      'click @ui.templateGroupItem': 'showTemplateGroup'
    },

    modelEvents: {
      'change:count': 'displayCount'
    },

    onRender: function() {
      if (this.model.get('count') === undefined) {
        this.model.getCount();
      } else {
        this.displayCount(this.model.get('count'));
      }
    },

    displayCount: function() {
      var left,
        count = this.model.get('count');

      if (typeof this.ui.count === 'object') {
        this.ui.count.text(count);
        left = count < 10 ? 10 : count < 100 ? 7 : 3;
        this.ui.count.css('left', left + 'px');
      }
    },

    showTemplateGroup: function() {
      App.trigger('folder-groups:show-group', this.model);
    }
  });

  module.exports = GroupsItemView;
});
