define('caccountDetailsTabOrdersView',[
         'module',
         'marionette',
         'underscore',
         'app',
         'chartView'
       ], function (
  module,
  Marionette,
  _,
  App,
  ChartView
) {
  'use strict';

  var CAccountDetailsTabOrdersView =  ChartView.extend({
    serializeData: function() {
       this.data = {
        labels : [
          _.i18n('month.january'),
          _.i18n('month.february'),
          _.i18n('month.march'),
          _.i18n('month.april'),
          _.i18n('month.may'),
          _.i18n('month.june'),
          _.i18n('month.july'),
          _.i18n('month.august'),
          _.i18n('month.september'),
          _.i18n('month.october'),
          _.i18n('month.november'),
          _.i18n('month.december')
        ],
        datasets : [
          {
            fillColor : 'rgba(0, 190, 164, 0.64)',
            strokeColor : 'rgba(220,220,220,1)',
            pointColor : 'rgba(220,220,220,1)',
            pointStrokeColor : '#fff',
            data : [
              this.model.get('orders').january,
              this.model.get('orders').february,
              this.model.get('orders').march,
              this.model.get('orders').april,
              this.model.get('orders').may,
              this.model.get('orders').june,
              this.model.get('orders').july,
              this.model.get('orders').august,
              this.model.get('orders').september,
              this.model.get('orders').october,
              this.model.get('orders').november,
              this.model.get('orders').december
            ]
          }
        ]
      };
      return this.data;
    }
  });

  module.exports = CAccountDetailsTabOrdersView;
});
