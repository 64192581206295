define('customerDatasetsItemView',[
  'module',
  'marionette',
  'template!customerDatasetsItemTpl',
  'customerDatasetPopupView',
  'app',
  'underscore'
], function(
  module,
  Marionette,
  itemTpl,
  PopupView,
  App,
  _
) {
  'use strict';

  var DatasetView = Marionette.ItemView.extend({
    template: itemTpl,
    tagName: 'tr',
    className: 'show-on-hover',

    events: {
     'click @ui.actionBtn' : '_getDatasetModel'
    },

    ui: {
      actionBtn: '.js-cell-action'
    },

    serializeData: function() {
      return {data: this.model.toJSON()};
    },

    _getDatasetModel: function() {
      var objectName = this.model.get('objectName'),
        endUserId = this.options.endUserId;

      this.ui.actionBtn.addClass('disabled');
      this.objectNameCollection = App.request('objectname:get-collection');
      this.listenTo(this.objectNameCollection, 'models:saved', this.onConfirmPopup);
      this.listenTo(this.objectNameCollection, 'models:not-changed', this._onHidePopup);
      this.listenTo(this.objectNameCollection, 'fetched', this._onShowPopup);
      this.objectNameCollection.getCollection({
        endUserId: endUserId,
        secId: objectName.secId
      });
    },

    onConfirmPopup: function() {
      this.popupView.hide();
      this.trigger('saved');
    },

    _onShowPopup: function() {
      this.popupView = new PopupView({
        collection: this.objectNameCollection,
        objectName: this.model.get('objectName')
      });
      this._showEditObjectNames();
      this.ui.actionBtn.removeClass('disabled');
    },

    _showEditObjectNames: function() {
      var that = this;

      this.popupView.show({title: _.i18n('dataset.edit.popup.title'),
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default btn-flat',
            callback: function() {return true;}
          },
          success: {
            label: _.i18n('common.confirm'),
            className: 'btn-primary',
            callback: function() {
              that.popupView.collection.saveChangedModels(that.options.endUserId);
              return false;
            }
          }
        }
      });
    },

    _onHidePopup: function() {
      this.popupView.hide();
    }
  });

  module.exports = DatasetView;
});
