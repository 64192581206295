
define('template!customerTemplatesGroupsItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n\t<div>\n\t\t<div class="panel panel-default template-group-item ' +
((__t = ( active ? 'active' : '' )) == null ? '' : __t) +
'">\n\t\t\t<div class="icon-holder">\n\t\t\t\t<i class="mdi-file-folder icon">\n\t\t\t\t\t<span class="templates-count text-in-icon">' +
((__t = ( count ? count : 0 )) == null ? '' : __t) +
'</span>\n\t\t\t\t</i>\n\t\t\t\t\n\t\t\t</div>\n\t\t\t<div class="template-group-name">\n\t\t\t\t' +
((__t = ( name )) == null ? '' : __t) +
'\n\t\t\t</div>\n\t\t\t<div class="template-group-edit">\n\t\t\t\t<i class="mdi-editor-mode-edit icon js-edit-icon"></i>\n\t\t\t\t<i class="mdi-action-delete icon js-remove-icon"></i>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

