/**
 * Created by RKL on 07/09/2015.
 */
define('customersListView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'jqgridActionsButtonFormatterActions',
  'jqgridActionsButtonFormatterView',
  'app',
  'imageUtils',
  'settings',
  'jqgridView',
  'template!customersListTpl',
  'customerCreateEditLayoutView',
  'entities/customers/customers'
], function (
  module,
  Marionette,
  _,
  Backbone,
  availableActions,
  ActionButtonsView,
  App,
  imageUtils,
  Settings,
  JqGridView,
  viewTpl,
  CreateEditLayoutView
) {
  'use strict';

  var CustomersListView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'customersListFilters',

    regions: {
      actions: '.js-action-buttons-region'
    },

    onShow: function() {
      var actionsButtonView = this._getActionButtonsView();

      this.listenTo(actionsButtonView, 'action', this._onAction);

      this.getRegion('actions').show(actionsButtonView);
    },

    _getActionButtonsView: function() {
      var actions = ['import', 'export'],
        actionsCollection = new Backbone.Collection();
      _.each(actions, function(action) {
        actionsCollection.push(availableActions[action]);
      });

      return new ActionButtonsView({
        collection: actionsCollection,
        dontShowIcons: true
      });
    },

    _onAction: function(action) {
      switch ( action ) {
        case 'import':
          this.$('#endUser-excel-input' ).trigger('click');
          break;
        case 'export':
          window.open(Settings.url('compuzz', 'endusers.json/excel'), '_self');
          break;
      }
    },

    onDeleteEndUser: function(event){
        event.preventDefault();
        event.stopImmediatePropagation();
       this.trigger('customers:remove', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage15'),
        currentPage: 1
      };
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        importBtn: '.endUser-import',
        uploadEndUsers: '.js-upload-endUsers',
        exportBtn: '.endUser-export'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click .js-add-new': 'onAddNew',
        'change @ui.uploadEndUsers': 'onUploadEndUsers',
         'click .delete-user-link': 'onDeleteEndUser'
      }, this.gridEvents);
    },

    serializeData: function () {
      this.selectedWells = [];
    },

    gridOptions: function(data) {
        var that = this, defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.name'),
              name: 'name',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: true,
              index: 'name',
              width: 55
            }, {
              label: _.i18n('common.code'),
              name: 'code',
              formatter: this.defaultFormatter,
              classes: 'agents-link',
              search: true,
              index: 'code',
              width: 55
            },{
              label: _.i18n('common.addressL1'),
              name: 'addressL1',
              formatter: this.defaultFormatter,
              index: 'addressL1',
              search: true,
              width: 65
            }, {
              label: _.i18n('common.zip'),
              name: 'zip',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: true,
              index: 'zip',
              width: 35
            }, {
              label: _.i18n('common.city'),
              name: 'city',
              formatter: this.defaultFormatter,
              classes: 'agents-link',
              search: true,
              index: 'city',
              width: 50
            }, {
              label: _.i18n('common.countryCode'),
              name: 'countryCode',
              formatter: this.defaultFormatter,
              classes: 'agents-link',
              search: true,
              index: 'countryCode',
              width: 45
            }, {
                  label: _.i18n('common.email'),
                  name: 'email',
                  formatter: this.defaultFormatter,
                  classes: 'agents-link',
                  search: true,
                  index: 'email',
                  width: 55
              },{
                  label: _.i18n('common.companyNumber'),
                  name: 'companyNumber',
                  formatter: this.defaultFormatter,
                  classes: 'agents-link',
                  search: true,
                  index: 'companyNumber',
                  width: 50
              },
              {
                  label: ' ',
                  name: 'delete',
                  search: false,
                  classes: 'delete-user-link delete-link',
                  formatter: this.deleteIconFormatter,
                  width: 20
              }
          ],

            beforeSelectRow: function(rowid, event) {
                // check if order selection
                var grid = $(this);
                if (event.ctrlKey || event.altKey) {  // alt instead ctrl for mac os
                    that.manageCtrlKey(grid, rowid);
                } else if (event.shiftKey) {
                    that.manageShiftKey(grid, rowid);
                } else {
                    return true;
                }
                return false;
            },

          onSelectRow: function(rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              App.trigger('customers:show', rowid);
            }
          }
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    manageCtrlKey: function(grid, rowid) {
      var maxSelectedWellNumber = 100,
          $row = grid.find('#' + rowid);
      if ($row.hasClass('selected')) {
          $row.removeClass('selected');
          this.onRowMultiselect(rowid, false);
      } else {
          if (this.selectedWells.length === maxSelectedWellNumber) {
              this.showWarningSelectedNumberOutOfRange();
          } else {
              $row.addClass('selected');
              this.onRowMultiselect(rowid, true);
          }
      }
    },

    onRowMultiselect: function(rowid, select) {
      var index,
          grid = this.ui.jqGrid,
          gridList = grid.find('tr'),
          row = grid.find('#' + rowid),
          order = {
              rowid: rowid,
              page: this.pagination.currentPage,
              index: gridList.index(row)
          };
      if (select) {
          this.selectedWells.push(order);
      } else {
          index = this.selectedWells.indexOf(_.findWhere(this.selectedWells, {rowid: rowid}));
          this.selectedWells.splice(index, 1);
      }
    },

    onAddNew: function(e) {
      e.preventDefault();
      e.stopPropagation();
      var createEditView = new CreateEditLayoutView({
        customerModel: App.request('customer:model'),
        newItem: true,
        countries: this.options.countries
      });
      createEditView.show({
        title: _.i18n('customers.new')
      });
    }, 

    onUploadEndUsers: function(e) {
          var that = this,
              target = e.target,
              fileInfo;

          if (e && e.target && e.target.files && e.target.files[0]) {
              fileInfo = imageUtils.getFileInfo(target, 0);

              if (fileInfo) {
                  imageUtils.encodeInputFileToBase64(target, 0, _.bind(function(imgBytes) {
                      fileInfo.base64Value = imgBytes;
                      that.uploadExcel(fileInfo);
                  }, this));
              }
          }
    },

    uploadExcel: function(fileInfo) {
       this.ui.uploadEndUsers.val('');
       this.trigger('customers:import-excel', fileInfo);
    }
  });

  module.exports = CustomersListView;
});
