define('entities/kitl',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var Kitl = Backbone.Model.extend({
    fetch: function() {      
      this.url = Settings.url('compuzz', 'v2/kitls/' + this.get('refKit').secId);
      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      lotNb: '1',
      prodDt: '',
      expDt: '',
      st: 10
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (kitSecId) {
      return Settings.url('compuzz', 'v2/kits/' + kitSecId + '/kitls');
    },

    save: function() {
      this.url = this.postUrl(this.get('refKit').secId);
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var KitlCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/kits/');
    },
    model: Kitl
  });

  var API = {

    getKitlCollectionUrl: function(kitSecId) {
      new KitlCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/kits/' + kitSecId + '/kitls', params);
    },

    getAllKitl: function(kitSecId, params) {
      var url = Settings.url('compuzz', 'v2/kits' + kitSecId + '/kitls', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getKitlCountUrl: function(kitSecId) {
      var params = '';
      return 'v2/kits/'+kitSecId+'/kitls/rowCount' + params;
    },

    deleteKitl: function(kitSecId, secId){
      var url = Settings.url('compuzz', 'v2/kits/'+kitSecId+'/kitls/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('kitl:get-collection-url', function(kitSecId) {
      return API.getKitlCollectionUrl(kitSecId);
  });

  App.reqres.setHandler('kitl:get-collection-to-page', function(kitSecId, params) {
      return API.getAllKitl(kitSecId, params);
  });

  App.reqres.setHandler('kitl:get-count-url', function(kitSecId) {
    return API.getKitlCountUrl(kitSecId);
  });

  App.reqres.setHandler('kitl:model', function(itemJSON) {
    return new Kitl(itemJSON);
  });

  App.reqres.setHandler('kitl:delete', function(kitSecId, secId) {
    return API.deleteKitl(kitSecId, secId);
  });
});
