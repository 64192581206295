/**
 * Created by GSP on 10/02/2016.
 */
define('tawlkToBehavior',['module',
        'jquery',
        'marionette'],
function(module, $, Marionette){
  'use strict';

  var TawlkToBehavior = Marionette.Behavior.extend({

    defaults: {
      smartSupSelector: '#tawkchat-iframe-container',
      visible: true
    },

    initialize: function() {
      if(typeof this.options.smartSupSelector !== 'string') {
        throw 'invalid smartSupSelector it look like a string';
      }

    },

    onBeforeRender: function(){
      /*jshint camelcase: false */
      if (Tawk_API.hasOwnProperty('showWidget') && Tawk_API.hasOwnProperty('hideWidget')) {
        if(this.options.visible === true) {
          Tawk_API.showWidget();
        } else {
          Tawk_API.hideWidget();
        }
      }
    }

  });

  module.exports = TawlkToBehavior;
});
