
define('template!sampleDetailTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="well mbamodel-detail">\n  <div class="detailed-info-form-container">\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.sampleId') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( sampleId ? sampleId : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.orderId') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( orderId ? orderId : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.patId') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( patId ? patId : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.extractSt') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( extractSt ? (extractSt + ' - ' + _.i18n('sample.extractSt.' + extractSt))  : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.refLmbSmpMat') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( refLmbSmpMat ? refLmbSmpMat.code : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.ordDt') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( ordDtString ? ordDtString : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.receptDt') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( receptDtString ? receptDtString : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.sampleDt') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( sampleDtString ? sampleDtString : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.datehh') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( datehhString ? datehhString : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( description ? description : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="pull-right p-r-15">\n        <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

