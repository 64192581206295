define('wellsGraphView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'moment',
  'mobioChartView'
], function (
  module,
  Marionette,
  _,
  App,
  moment,
  MobioChartView
) {
  'use strict';

  var RunGraphView =  MobioChartView.extend({

    channelToDisplay: null,

    serializeData: function() {

      this.data = {
        datasets : [
        ]
      };

      var curve1, curve2, curve3, curve4, curve5, curve6, algo1, algo2, algo3, algo4, algo5,
          algo6, legendContent = null;

      _.each(this.models, _.bind(function(model) {

          if (this.legend && this.channelToDisplay) {
              switch(this.legend) {
                  case 'hide': legendContent = ''; this.displayLegend = false;
                      break;
                  case 'well': legendContent = model.get('pos'); this.displayLegend = true;
                      break;
                  case 'smp': legendContent = (model.get('smpid') && model.get('smpid').length > 3) ?
                        model.get('smpid').substr(model.get('smpid').length - 3) :
                        model.get('smpid');
                        this.displayLegend = true;
                      break;
                  case 'ct': legendContent = model.getResFormated(this.channelToDisplay); this.displayLegend = true;
                      break;
              }
          }

          if (model.get('smpid')) {
              if (!this.amplGraph) {
                  curve1 = (!this.channelToDisplay || this.channelToDisplay === 1)?model.get('meltCurve1'):null;
                  curve2 = (!this.channelToDisplay || this.channelToDisplay === 2)?model.get('meltCurve2'):null;
                  curve3 = (!this.channelToDisplay || this.channelToDisplay === 3)?model.get('meltCurve3'):null;
                  curve4 = (!this.channelToDisplay || this.channelToDisplay === 4)?model.get('meltCurve4'):null;
                  curve5 = (!this.channelToDisplay || this.channelToDisplay === 5)?model.get('meltCurve5'):null;
                  curve6 = (!this.channelToDisplay || this.channelToDisplay === 6)?model.get('meltCurve6'):null;
                  algo1 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 1, 'MC-SMOO');
                  algo2 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 2, 'MC-SMOO');
                  algo3 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 3, 'MC-SMOO');
                  algo4 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 4, 'MC-SMOO');
                  algo5 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 5, 'MC-SMOO');
                  algo6 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 6, 'MC-SMOO');
              } else {
                  curve1 = (!this.channelToDisplay || this.channelToDisplay === 1)?model.get('amplCurve1'):null;
                  curve2 = (!this.channelToDisplay || this.channelToDisplay === 2)?model.get('amplCurve2'):null;
                  curve3 = (!this.channelToDisplay || this.channelToDisplay === 3)?model.get('amplCurve3'):null;
                  curve4 = (!this.channelToDisplay || this.channelToDisplay === 4)?model.get('amplCurve4'):null;
                  curve5 = (!this.channelToDisplay || this.channelToDisplay === 5)?model.get('amplCurve5'):null;
                  curve6 = (!this.channelToDisplay || this.channelToDisplay === 6)?model.get('amplCurve6'):null;
                  algo1 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 1, 'AC-SMOO');
                  algo2 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 2, 'AC-SMOO');
                  algo3 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 3, 'AC-SMOO');
                  algo4 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 4, 'AC-SMOO');
                  algo5 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 5, 'AC-SMOO');
                  algo6 = this.modelRun.getAlgoFromChannel(model.get('refAssay'), 6, 'AC-SMOO');
              }
              if ( !_.isUndefined(curve1) && !_.isEmpty(curve1)) {
                  this.data.datasets.push(this.addData(curve1, null, null, null,
                      legendContent!==null?legendContent:model.get('pos') + ' CH1', algo1));
              }
              if ( !_.isUndefined(curve2) && !_.isEmpty(curve2)) {
                  this.data.datasets.push(this.addData(curve2, null, null, null,
                      legendContent!==null?legendContent:model.get('pos') + ' CH2', algo2));
              }
              if ( !_.isUndefined(curve3) && !_.isEmpty(curve3)) {
                  this.data.datasets.push(this.addData(curve3, null, null, null,
                      legendContent!==null?legendContent:model.get('pos') + ' CH3', algo3));
              }
              if ( !_.isUndefined(curve4) && !_.isEmpty(curve4)) {
                  this.data.datasets.push(this.addData(curve4, null, null, null,
                      legendContent!==null?legendContent:model.get('pos') + ' CH4', algo4));
              }
              if ( !_.isUndefined(curve5) && !_.isEmpty(curve5)) {
                  this.data.datasets.push(this.addData(curve5, null, null, null,
                      legendContent!==null?legendContent:model.get('pos') + ' CH5', algo5));
              }
              if ( !_.isUndefined(curve6) && !_.isEmpty(curve6)) {
                  this.data.datasets.push(this.addData(curve6, null, null, null,
                      legendContent!==null?legendContent:model.get('pos') + ' CH6', algo6));
              }
          }
      }, this));

      if (this.data.datasets.length) {
        if (this.baseline !== null && this.baseline !== undefined) {
          this.data.datasets.push(this.addLine(this.baseline, 'baseline', 255, 0, 0));
        }
        if (this.threshold !== null && this.threshold !== undefined) {
          this.data.datasets.push(this.addLine(this.threshold, 'threshold', 0, 0, 255));
        }
      }

      this.generateLegend();
      return this.data;
    }
  });

  module.exports = RunGraphView;
});
