define('tabsBaseController',[
  'module'
], function(
  module
) {
  'use strict';

  function TabsBaseController(options) {
    var that = this;
    that.model = options.model;
    that.View = options.View;
    that.tabs = options.tabs;
    that._layoutView = undefined;

    that.showTabsLayout = function showTabsLayout(region, tabName, id) {
      var that = this,
        defer = $.Deferred();

      if(that.model.getCode && that.model.getName) {
        that.model.set(that.model.idAttribute, id);
      } else {
        throw 'Model does not have methods getCode and getName. Methods are required for filling in code ' +
          'and name properties which will be shown on the header';
      }

      if(this._layoutView && !this._layoutView.isDestroyed) {
        defer.resolve(this._layoutView.getRegion('tabs'), this._layoutView.model);
        this._layoutView.triggerMethod('show:active', tabName);

        return defer.promise();
      }

      that.model.fetch().done(function() {
        that.model.set({
          id: that.model.get(that.model.idAttribute),
          code: that.model.getCode(),
          name: that.model.getName()
        });
        that._layoutView = new that.View({
          model: that.model,
          tabs: that.tabs
        });
        region.show(that._layoutView);
        that._layoutView.triggerMethod('show:active', tabName);

        defer.resolve(that._layoutView.getRegion('tabs'), that._layoutView.model);
      });

      return defer.promise();
    };
  }

  module.exports = TabsBaseController;
});
