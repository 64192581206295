define('menuItems',[
  'underscore',
  'settings',
  'itemPagesNameMixin',
  'rolesMixin'
], function (_,
             Settings,
             ItemPagesNameMixin,
             RolesMixin) {
  'use strict';

  var SidebarMenuItems = [{
    //ACCOUNT
    title: _.i18n('menu.account'),
    itemPageName: ItemPagesNameMixin.ACCOUNT,
    link: function() {return '';},
    role: RolesMixin.DASHBOARD_ACCOUNT,
    icon: 'fa fa-user fa-lg',
    subMenu: [
      {
        title: _.i18n('menu.myAccount'),
        itemPageName: ItemPagesNameMixin.MY_ACCOUNT,
        link: function() {return 'caccount/' + (Settings.get('currentCAccount' ) ? Settings.get('currentCAccount' ).secId : '');},
        role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT,
        icon: 'fa fa-home fa-lg',
        subMenu: []
      }
    ]
  },
    //SAMPLES
    {
      title: _.i18n( 'menu.sampleManagement' ),
      itemPageName: ItemPagesNameMixin.SAMPLES,
      link: function () {
        return 'samples';
      },
      role: RolesMixin.DASHBOARD_SAMPLES,
      icon: 'fa fa-list-alt fa-lg',
      subMenu: [ ]
    },
    //EXTRACTRUNS
    {
      title: _.i18n( 'menu.extractRuns' ),
      itemPageName: ItemPagesNameMixin.EXTRACTRUNS,
      link: function () {
        return 'extractruns';
      },
      role: RolesMixin.DASHBOARD_EXTRACTRUNS,
      icon: 'fa fa-list-alt fa-lg',
      subMenu: []
    },
  //RUNS
  {
      title: _.i18n( 'menu.runs' ),
      itemPageName: ItemPagesNameMixin.RUNS,
      link: function () {
          return 'runs';
      },
      role: RolesMixin.DASHBOARD_RUNS,
      icon: 'fa fa-list-alt fa-lg',
      subMenu: []
  },

  //ADMIN
  {
      title: _.i18n('menu.admin'),
      itemPageName: ItemPagesNameMixin.ADMIN,
      link: function() {return 'admin';},
      role: RolesMixin.DASHBOARD_ADMIN,
      icon: 'fa fa-cog fa-lg',
      subMenu: [
          {
              title: _.i18n('menu.translations'),
              itemPageName: ItemPagesNameMixin.ADMIN_TRANSLATIONS,
              link: function() {return 'translations';},
              role: RolesMixin.DASHBOARD_ADMIN_TRANSLATIONS,
              icon: 'fa fa-language fa-lg',
              subMenu: []
          },
          {
              title: _.i18n('menu.timetracker'),
              itemPageName: ItemPagesNameMixin.ADMIN_TIMETRACKING,
              link: function() {return 'timetracker';},
              role: RolesMixin.DASHBOARD_TIMETRACKER,
              icon: 'fa fa-calendar fa-lg',
              subMenu: []
          },
          {
              title: _.i18n('menu.admin.kits'),
              itemPageName: ItemPagesNameMixin.ADMIN_KITS,
              link: function() { return 'admin/kits';},
              role: RolesMixin.DASHBOARD_ADMIN_KIT,
              icon: 'fa fa-male',
              subMenu: []
          },
          {
              title: _.i18n('menu.admin.kitprots'),
              itemPageName: ItemPagesNameMixin.ADMIN_KITPROTS,
              link: function() { return 'admin/kitprots';},
              role: RolesMixin.DASHBOARD_ADMIN_KITPROT,
              icon: 'fa fa-male',
              subMenu: []
          },
          {
              title: _.i18n('menu.admin.lmbanalyzers'),
              itemPageName: ItemPagesNameMixin.ADMIN_LMBANALYZERS,
              link: function() { return 'admin/lmbanalyzers';},
              role: RolesMixin.DASHBOARD_ADMIN_LMBANALYZER,
              icon: 'fa fa-male',
              subMenu: []
          },
          {
              title: _.i18n('menu.admin.lmbanas'),
              itemPageName: ItemPagesNameMixin.ADMIN_LMBANAS,
              link: function() { return 'admin/lmbanas';},
              role: RolesMixin.DASHBOARD_ADMIN_LMBANA,
              icon: 'fa fa-male',
              subMenu: []
          },
          {
              title: _.i18n('menu.admin.lmbsmpmats'),
              itemPageName: ItemPagesNameMixin.ADMIN_LMBSMPMATS,
              link: function() { return 'admin/lmbsmpmats';},
              role: RolesMixin.DASHBOARD_ADMIN_LMBSMPMAT,
              icon: 'fa fa-male',
              subMenu: []
          },
          {
              title: _.i18n('menu.admin.lis'),
              itemPageName: ItemPagesNameMixin.ADMIN_LIS,
              link: function() { return 'admin/lis';},
              role: RolesMixin.DASHBOARD_ADMIN_LIS,
              icon: 'fa fa-male',
              subMenu: []
          },
            {
              title: _.i18n('menu.runTemplates'),
              itemPageName: ItemPagesNameMixin.RUNTEMPLATES,
              link: function() {return 'runtemplates';},
              role: RolesMixin.DASHBOARD_RUNTEMPLATES,
              icon: 'fa fa-list-alt fa-lg',
              subMenu: []
            },
          {
              title: _.i18n('menu.admin.settings'),
              itemPageName: ItemPagesNameMixin.ADMIN_SETTING,
              link: function() {return 'admin/settings';},
              role: RolesMixin.DASHBOARD_ADMIN_SETTING,
              icon: 'fa fa-list-alt fa-lg',
              subMenu: []
          }
      ]
  },
  //SUPER ADMIN
  {
      title: _.i18n('menu.superAdmin'),
      itemPageName: ItemPagesNameMixin.SUPERADMIN,
      link: function() {return 'superAdmin';},
      role: RolesMixin.DASHBOARD_SUPERADMIN_MBANA,
      icon: 'fa fa-cogs fa-lg',
      subMenu: [
          {
              title: _.i18n('superAdmin.overview.tabTitle'),
              itemPageName: ItemPagesNameMixin.SUPERADMIN_OVERVIEW,
              link:  function() {return 'superAdmin/overview';},
              role: RolesMixin.DASHBOARD_SUPERADMIN,
              icon: 'fa fa-globe fa-lg',
              subMenu: []
          },
          {
              title: _.i18n('menu.superadmin.caccounts'),
              itemPageName: ItemPagesNameMixin.SUPERADMIN_CACCOUNTS,
              link: function() { return 'superAdmin/caccounts';},
              role: RolesMixin.DASHBOARD_SUPERADMIN,
              icon: 'fa fa-male',
              subMenu: []
          },
          {
              title: _.i18n('menu.superadmin.mbanas'),
              itemPageName: ItemPagesNameMixin.SUPERADMIN_MBANAS,
              link: function() { return 'superAdmin/mbanas';},
              role: RolesMixin.DASHBOARD_SUPERADMIN_MBANA,
              icon: 'fa fa-male',
              subMenu: []
          },
          {
              title: _.i18n('menu.superadmin.mbanaresgrs'),
              itemPageName: ItemPagesNameMixin.SUPERADMIN_MBANARESGRS,
              link: function() { return 'superAdmin/mbanaresgrs';},
              role: RolesMixin.DASHBOARD_SUPERADMIN_MBANARESGR,
              icon: 'fa fa-male',
              subMenu: []
          },
          {
              title: _.i18n('menu.superadmin.mbamodels'),
              itemPageName: ItemPagesNameMixin.SUPERADMIN_MBAMODELS,
              link: function() { return 'superAdmin/mbamodels';},
              role: RolesMixin.DASHBOARD_SUPERADMIN_MBAMODEL,
              icon: 'fa fa-male',
              subMenu: []
          },
          {
              title: _.i18n('menu.superadmin.mbsmpmats'),
              itemPageName: ItemPagesNameMixin.SUPERADMIN_MBSMPMATS,
              link: function() { return 'superAdmin/mbsmpmats';},
              role: RolesMixin.DASHBOARD_SUPERADMIN_MBSMPMAT,
              icon: 'fa fa-male',
              subMenu: []
          }
      ]
  }
  ];
  return SidebarMenuItems;
});
