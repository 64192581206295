define('adminKitprDetailsView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'template!adminKitprDetailsTpl',
  'adminKitprTabs',
  'rolesMixin',
  'backToListBehavior'
], function (
  module,
  Marionette,
  _,
  Backbone,
  App,
  Settings,
  imageUtils,
  viewTpl,
  kitprsTabs,
  RolesMixin,
  BackToListBehavior
) {
  'use strict';

  var KitprsDetailsView =  Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      tab: '.kitpr-tab',
      tabPane: '.tab-pane',
      logo: '.js-logo-holder',
      name: '.p-name',
      code: '.p-code'
    },

    regions: {
      kitprDetailTab: '#detail-tab'
    },

    behaviors: function() {
      var redirectUrl = 'admin/kitprots/'+this.options.model.get('refKitProt').secId+'/kitpr';
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: redirectUrl
        }
      };
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.kitprsTabs = kitprsTabs;
      templateData.compulogoCollection = this.model.get('compulogoCollection');
      return templateData;
    },

    onRender: function() {
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.kitpr-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onShow: function() {
      $.material.init();
    }
  });

  module.exports = KitprsDetailsView;
});
