/**
 * Created by OLD on 15/09/2015.
 */
define('caccountDetailsTabView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'settings',
  'chart',
  'template!caccountDetailsTabTpl',
  'rolesMixin'
], function (
  module,
  Marionette,
  App,
  _,
  Settings,
  Chart,
  viewTpl,
  RolesMixin
) {
  'use strict';

  var CAccountDetailsTabLayoutView =  Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      canvasOrders: '#orders-graph-region',
      canvasMonth: '#month-graph-region',
      canvasRequests: '#requests-graph-region'
    },

    serializeData: function() {
      var tplData = this.model.toJSON(),
        monthActivity;

      monthActivity = parseInt(tplData.month.done +
        tplData.month.inProgress + tplData.month.produced, 10);

      if (monthActivity !== 0) {
        tplData.newOrder = ((parseInt(tplData.month.done, 10) * 
          100) / monthActivity).toFixed(0);
        tplData.ordersInProgress = ((parseInt(tplData.month.inProgress, 10) * 
          100) / monthActivity).toFixed(0);
        tplData.produced = ((parseInt(tplData.month.produced, 10) * 
          100) / monthActivity).toFixed(0);
      } else {
        tplData.newOrder = 0;
        tplData.ordersInProgress = 0;
        tplData.produced = 0;
      }

      tplData.orderRole = Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS]?true:false;

      return tplData;
    }
  });

  module.exports = CAccountDetailsTabLayoutView;
});
