define( 'entities/caccounts/credits',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var Credit = Backbone.Model.extend({
      defaults: {
          quantity: 200
      }
  });
  
  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function(cAccountSecId) {
        var caccountIdParam = cAccountSecId ? '?cAccountSecId='+cAccountSecId : '';
        return 'credits.json/rowCount' + caccountIdParam;
    },

    getCreditsUrl: function(cAccountSecId) {
      var caccountIdParam = cAccountSecId ? '?cAccountSecId='+cAccountSecId : '';
      return Settings.url('compuzz', 'credits.json'+caccountIdParam);
    },

    getModel: function() {
        return new  Credit();
    },

    refundWithPaypal: function(data){
        var ca = Settings.get('currentCAccount'),
            origin = window.location.origin + window.location.pathname,
            urlStart = origin + '#caccount/'+ca.secId;

          var parm = {
              amount: data.get('quantity'),
              successURL: encodeURIComponent(urlStart + '/credits/payment/success'),
              cancelURL: encodeURIComponent(urlStart +'/credits/payment/cancel'),
              errorURL: encodeURIComponent(urlStart +'/credits/payment/error')
          };

          var defer = $.Deferred(),
              url = Settings.url('compuzz', 'credits.json/refund/paypal', parm);

          $.ajax({
              type: 'POST',
              dataType: 'text',
              contentType: false,
              url: url,
              success: function(resp) {
                  defer.resolve(resp);
              }
          });

          return defer.promise();
    },

    placePayment: function(data){
          var parms = {
              creditUid : data.creditId,
              payerID: data.payerId,
              paypalToken : data.paypalTokenId
          };

          var defer = $.Deferred(),
              url = Settings.url('compuzz', 'credits.json/place/paypal', parms);

          $.ajax({
              type: 'POST',
              dataType: 'text',
              contentType: false,
              url: url,
              success: function(resp) {
                  defer.resolve(resp);
              }
          });

          return defer.promise();
    },

    getCreditsBalance: function(cAccountSecId) {
      var caccountIdParam = cAccountSecId ? '?cAccountSecId='+cAccountSecId : '';
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'v2/stats/count/credits' + caccountIdParam);

      $.ajax({
        url: url,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    invoiceSample: function() {
          return Settings.url('compuzz', 'v2/invoices/sample');
    },

    addCredits: function(data,cAccountSecId) {

       var caccountIdParam = cAccountSecId ? '?cAccountSecId='+cAccountSecId : '';
       var parm = {
            amount: data.get('quantity')
       };

      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'credits.json/refund/manual' + caccountIdParam, parm);

      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'text',
        contentType: false,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('caccount-credits:row-count-url', function(secId) {
    return API.getRowCountUrl(secId);
  });

  App.reqres.setHandler('caccount-credits:refund-paypal', function(data){
    return API.refundWithPaypal(data);
  });

  App.reqres.setHandler('caccount-credits:place-paypal', function(data){
    return API.placePayment(data);
  });

  App.reqres.setHandler('caccount-credits:get-url', function(secId) {
    return API.getCreditsUrl(secId);
  });

  App.reqres.setHandler('caccount-credits:get-balance', function(secId) {
    return API.getCreditsBalance(secId);
  });

  App.reqres.setHandler('caccount-credits:get-model', function() {
        return API.getModel();
  });

  App.reqres.setHandler('caccount-credits:invoice-sample', function() {
        return API.invoiceSample();
  });

  App.reqres.setHandler('caccount-credits:add-credits', function(model, secId) {
      return API.addCredits(model, secId);
  });

  return ;
});
