/**
 * Created by RKL on 20/08/2015.
 */
define('customerTemplatesPopupAddEmptyView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'template!customerTemplatesPopupEditTpl',
  'dialogFormView',
  'autocompleteView',
  'template!customerTemplatesPopupAddTpl'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  viewTpl,
  DialogFormView,
  AutocompleteView,
  addTemplateTpl
) {
  'use strict';

  var CAccountTemplatesTabPopupViewAdd = DialogFormView.extend({
    template: addTemplateTpl,

    ui: {
      save: '.js-save-template',
      templateSizes: 'div[data-field-name=standard-sizes]',
      geometry: 'div[data-field-name=geometry]',
      geometryInput: '.geometry-radio-b',
      geometryCheckbox: '.geometry-radio-b>input'
    },

    events: {
      'click @ui.save': 'onSave',
      'change @ui.geometryCheckbox' : 'onChangeGeometry'
    },

    modelEvents: {
      sync: 'onModelSaved'
    },

    regions: {
      templateSizes: '.select-standardsizes'
    },

    serializeData: function() {
      return _.extend(this.model.toJSON(), {
        fromUpload: this.options.fromUpload !== undefined
      });
    },

    onShow: function() {
      var sizesView;

      sizesView = new AutocompleteView(
        this.getSizesAutocompleteOptions()
      );
      this.ui.geometryInput.find('input:checked').change();
      this.getRegion('templateSizes').show(sizesView);
    },

    onChangeGeometry: function(e) {
      var geoVal = $(e.currentTarget).val();

      this.model.set('geometry', geoVal);
      this.ui.geometry.removeClass('has-error');
    },

    getSizesAutocompleteOptions: function() {
      return {
        data: this.filterSizesAutocompleteOptions(),
        valueKey: 'searchValue',
        apiKey: 'secId',
        limit: this.options.standardSizes.length,
        callback: _.bind(this.onChangeStandardSizes, this),
        placeholder: _.i18n('common.templateGroup.standardSizes'),
        value: _.bind(function(){
           if(this.options.templateParent){
              var size =  _.find(this.options.standardSizes, _.bind(function(size){
                  return size.code === this.options.templateParent.get('standardSizeCode');
              }, this));

              if(size){
                 return size.code + ' - ' + size.nameTranslated;
              }
           }
           return null;
        }, this)()
      };



    },


    filterSizesAutocompleteOptions: function() {
      return _.map(this.options.standardSizes, function(item) {
        return {
          code: item.code,
          height: item.height,
          isAbsolute: item.isAbsolute,
          nameTranslated: item.nameTranslated,
          secId: item.secId,
          unit: item.unit,
          width: item.width,
          searchValue: item.code + ' - ' + item.nameTranslated
        };
      });
    },

    onChangeStandardSizes: function(fieldName, model) {
      this.onChange('refStandardSize', model);
    },

    onSave: function() {
      if(!this._validate()) {
        this.model.save();
      }
    },

    onModelSaved: function() {
      App.trigger('caccount-template:modify-template', this.model.get('secId'));
      this.destroy();
    },

    onDestroy: function() {
      this.hide();
    },

    _validate: function() {
      var hasError = false;
      
      if(!this.model.get('geometry')) {
        this._showGeometryError();
        hasError = true;
      }
      return hasError;
    },

    _showGeometryError: function() {
      this.ui.geometry.addClass('has-error');
    }
  });

  module.exports = CAccountTemplatesTabPopupViewAdd;
});
