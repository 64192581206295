/**
 * Created by BFR on 02/05/2017.
 */
define('adminLisAserieAnaController',[
    'module',
    'underscore',
    'app',
    'adminLisAserieAnaView',
    'bootbox',
    'lisAserieAddLisAnaView',
    'settings',
    'entities/lisanas'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    LisAserieAddLisAnaView
    ) {
    'use strict';

    var SuperAdminLisAnaListController = {
        showLisAnaList: function(data) {
            this.data = data;
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'lisana:delete', _.bind(this.onDelete, this));
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('lisAnaListTab').show(view);
        },

        showLisAnaAdd: function(lisAserieSecId) {
          if (this.data && this.data.model) {
            this.openLisAnaPopup(lisAserieSecId, this.data.model.get('refLis').secId);
          } else {
            this.model = App.request('lisaserie:model', {secId: lisAserieSecId});
            this.model.fetch().done(_.bind(function() {
              this.openLisAnaPopup(lisAserieSecId, this.model.get('refLis').secId);
            }, this));
          }
        },

        openLisAnaPopup:function(lisAserieSecId, lisSecId) {
          this.createEditView = new LisAserieAddLisAnaView({
            lisSecId: lisSecId,
            lisAserieSecId: lisAserieSecId,
            newItem: true
          });
          this.createEditView.show({
            title: _.i18n('lisaserieana.new')
          });
        },

        onGridLoaded: function() {
            var lisAserieSecId = this.data.model.get('secId');
            var data = {
                url: App.request('lisana:get-collection-lisaserie-url', lisAserieSecId),
                rowCountUrl: App.request('lisana:get-count-lisaserie-url', lisAserieSecId)
            };

            this.view.displayGrid(data);
        },

        onDelete: function(lisSecId, secId){
            bootbox.confirm(_.i18n('lisana.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('lisana:lisaserie-lisana-delete', lisSecId, secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = SuperAdminLisAnaListController;
});
