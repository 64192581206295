/**
 * Created by GSP on 4/08/2015.
 */
define('contentShowView',[
  'module',
  'marionette',
  'app',
  'template!contentLayoutTpl',
  'tawlkToBehavior'
], function(
  module,
  Marionette,
  App,
  layoutTpl,
  TawlkToBehavior
) {
  'use strict';

  var ContentLayoutView =  Marionette.LayoutView.extend({
    template: layoutTpl,

    regions: {
      header: '#header-region',
      sidebar: '#sidebar-region',
      content: '#content-region',
      zoom: '#gallery-zoom'
    },

    ui: {
      aside: '.aside-md'
    },

    behaviors: {
      SmartSupp: {
        behaviorClass: TawlkToBehavior,
        visible: true
      }
    },

    onSidebarShow: function() {
      if (this.ui.aside.hasClass('nav-off-screen')) {
        this.ui.aside
          .removeClass('nav-off-screen')
          .hide();
      } else {
        this.ui.aside
          .addClass('nav-off-screen')
          .show();
      }
    },

    onResizeSidebar: function() {
      if (this.ui.aside.hasClass('nav-xs')) {
        this.ui.aside.removeClass('nav-xs');
      } else {
        this.ui.aside.addClass('nav-xs');
      }
    },

    onResizeWindow: function () {
      var width = $(document.body).width();

      if (width > 768) {
        this.ui.aside
          .removeClass('nav-off-screen')
          .show();
      }
      if (width < 767) {
        this.ui.aside.removeClass('nav-xs');
      }
    }
  });

  module.exports = ContentLayoutView;
});
