define('customerTemplatesSelectionMix',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings'
], function (
  module,
  Marionette,
  _,
  App,
  Settings
) {
  'use strict';

  var TemplateSelectionItemMix = {

    prepareSelection: function() {
      var selectedTemplatesCollection = Settings.get('selectedTemplatesCollection'),
          selected;

      if (selectedTemplatesCollection) {
        selected = selectedTemplatesCollection.where({secId: this.model.get('secId')});
        if (selected.length > 0) {
          this.$el.addClass('selected');
        }
      }
      this.isVariation = !this.options.showVariationsButton;
    },

    onSelect: function(e) {
      if(e.ctrlKey){
        this.onMultiselect(e);
      }
      else{
        var $target = $(e.target);
        if ($target.parents('.template-item-editor').length === 0) {
          this.trigger('templates:unselect', this.model);
        }
      }
    },

    onMultiselect: function(e) {
      var $template = this.$el,
          selected = $template.hasClass('selected') ? true : false,
          shifted = e.shiftKey;

      if (shifted) {
        $template.addClass('selected');
        App.trigger('template:selectionChanged', false, this.model, this.isVariation);
        this.trigger('templates:unselect', this.model, shifted);
      } else {
        if (selected) {
          $template.removeClass('selected');
        } else {
          $template.addClass('selected');
        }
        App.trigger('template:selectionChanged', selected, this.model, this.isVariation);
      }
      e.preventDefault();
      e.stopPropagation();
    },

    onUnselectChildren: function(model) {
      var $template = this.$el,
          selected = $template.hasClass('selected') ? true : false;
      if (model !== this.model) {
        if (selected) {
          $template.removeClass('selected');
          App.trigger('template:selectionChanged', selected, this.model, this.isVariation);
        }
      } else {
        if (selected) {
          $template.removeClass('selected');
        } else {
          $template.addClass('selected');
        }
        App.trigger('template:selectionChanged', selected, this.model, this.isVariation);
      }
    },

    onSelectChildren: function() {
      var $template = this.$el,
          selected = $template.hasClass('selected') ? true : false;
        if (!selected) {
          $template.addClass('selected');
          App.trigger('template:selectionChanged', selected, this.model, this.isVariation);
        }
    }

  };

  module.exports = TemplateSelectionItemMix;
});
