define('entities/lisaseries',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var Lis = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/lisaseries/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      code: '',
      name: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (lisSecId) {
      return Settings.url('compuzz', 'v2/lis/' + lisSecId + '/lisaseries');
    },

    save: function() {
      this.url = this.postUrl(this.get('refLis').secId);
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var LisCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/lis/');
    },
    model: Lis
  });

  var API = {

    getLisCollectionUrl: function(lisSecId) {
      new LisCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/lis/' + lisSecId + '/lisaseries', params);
    },

    getAllLis: function(lisSecId, params) {
      var url = Settings.url('compuzz', 'v2/lis' + lisSecId + '/lisaseries', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getLisCountUrl: function(lisSecId) {
      var params = '';
      return 'v2/lis/'+lisSecId+'/lisaseries/rowCount' + params;
    },

    deleteLis: function(lisSecId, secId){
      var url = Settings.url('compuzz', 'v2/lis/'+lisSecId+'/lisaseries/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('lisaserie:get-collection-url', function(lisSecId) {
      return API.getLisCollectionUrl(lisSecId);
  });

  App.reqres.setHandler('lisaserie:get-collection-to-page', function(lisSecId, params) {
      return API.getAllLis(lisSecId, params);
  });

  App.reqres.setHandler('lisaserie:get-count-url', function(lisSecId) {
    return API.getLisCountUrl(lisSecId);
  });

  App.reqres.setHandler('lisaserie:model', function(itemJSON) {
    return new Lis(itemJSON);
  });

  App.reqres.setHandler('lisaserie:delete', function(lisSecId, secId) {
    return API.deleteLis(lisSecId, secId);
  });
});
