define( 'entities/tickets',[
  'app',
  'jquery',
  'backbone',
  'settings',
  'underscore'
], function(
  App,
  $,
  Backbone,
  Settings,
  _
) {
  'use strict';

  /**
   * Model
   */
  var Ticket = Backbone.Model.extend( {
    url: function() {
      return Settings.url('compuzz', 'tickets', {type: ''});
    }
  });

  /**
   * Collection
   */
  var TicketsCollection = Backbone.Collection.extend( {
    url: function() {
      return Settings.url('compuzz', 'tickets', {type: ''});
    },
    model: Ticket
  });

  /**
   * REST API
   */
  var API = {
    getTicketsEntities: function() {
      var tickets = new TicketsCollection({}),
        defer = $.Deferred();

      tickets.url = Settings.url( 'compuzz', 'tickets', {type: ''});
      tickets.fetch( {
        success: function(data) {
          defer.resolve(data);
        }
      } );

      return defer.promise();
    },

    getTicketsCollection: function() {
      var tickets = new TicketsCollection({});

      tickets.url = Settings.url('compuzz', 'tickets', {type: ''});

      return tickets;
    },

    getRowCount: function(urlParams) {
      var params = {},
        defer = $.Deferred(),
        name,
        status,
        dateFrom,
        dateTo;

      if (urlParams && urlParams.filter) {
        dateFrom = urlParams.filter.dateFrom ? '&dateFrom=' + 
          urlParams.filter.dateFrom : '';
        dateTo = urlParams.filter.dateTo ? '&dateTo=' + 
          urlParams.filter.dateTo : '';
        name = urlParams.filter.name ? '&name=' + name : '';
        status = urlParams.filter.status && status !== _.i18n('common.all') ? '&status=' + 
          status : '';

        if (dateFrom) { params.dateFrom = dateFrom; }
        if (dateTo) { params.dateTo = dateTo; }
        if (name) { params.name = name; }
        if (status) { params.status = status; }
      }
      params.type = '';
      $.ajax({
        url: Settings.url('compuzz', 'tickets/rowCount', params),
        success: function(count) {
          defer.resolve(count);
        }
      });

      return defer.promise();
    },

    getTicketsUrl: function(options) {
      options = options || {};
      _.defaults(options, {type: ''});
      return Settings.url('compuzz', 'tickets', { type: options.type });
    },

    getRowCountUrl: function(options) {
       options = options || {};
      _.defaults(options, {type: ''});
      var params = options.type ? '?type=' + options.type : ''; 
      return 'tickets/rowCount' + params;
    },

    markAsRead: function(id) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'tickets/ticket/' + id + '/read');

      $.ajax({
        type: 'PUT',
        url: url,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    closeTicket: function(secId) {
      var tickets = new Ticket(),
        defer = $.Deferred();

      tickets.url = Settings.url('compuzz', 'tickets/' + secId + '/close');
      tickets.fetch( {
        success: function(data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getTicketsModel: function(ticketId) {
      var tickets = new Ticket();

      tickets.url = tickets.url(ticketId);

      return tickets;
    },

    getTicketsData: function(secId) {
      var tickets = new Ticket(),
        defer = $.Deferred();

      tickets.url = Settings.url('compuzz', 'tickets/' + secId);
      tickets.fetch({
        success: function(data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    postNewTicket: function(formData, type) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url( 'compuzz', 'tickets/'+ type),
        type: 'POST',
        data : JSON.stringify(formData),
        contentType: 'application/json',
        complete: function(resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    },

    updateTariffScale: function(ticketSecID, tariffScale) {
      var defer = $.Deferred(),
        url = 'tickets/' + ticketSecID + '/updatetariffscale';

      $.ajax({
        url: Settings.url('compuzz', url, {tariffScale: tariffScale}),
        type: 'PUT',
        contentType: 'applization/json',
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    updateUrgency: function(secId, checked) {
      var defer = $.Deferred(),
        url = 'tickets/' + secId + '/updateurgency';
      $.ajax({
        url: Settings.url('compuzz', url, {urgent: checked}),
        type: 'PUT',
        contentType: 'application/json',
        complete: function() {
          defer.resolve();
        }
      });
      return defer.promise();
    },

    changeType: function(secId, type) {
          var defer = $.Deferred(),
              url = 'tickets/' + secId + '/changeType';

          $.ajax({
              url: Settings.url('compuzz', url, {toType: type.get('value')}),
              type: 'PUT',
              contentType: 'application/json',
              complete: function() {
                  defer.resolve();
              }
          });
          return defer.promise();
     },

    changeState: function(ticketID, state) {
      var defer = $.Deferred(),
        url = 'tickets/' + ticketID + '/updatestate';
      $.ajax({
        url: Settings.url('compuzz', url, {state: state}),
        type: 'PUT',
        contentType: 'application/json',
        complete: function() {
          defer.resolve();
        }
      });
      return defer.promise();
    },

     changeSubject: function(subject, secId) {
      var defer = $.Deferred(),
        url = 'tickets/' + secId + '/updatename';

      $.ajax({
        url: Settings.url('compuzz', url, {name: subject}),
        type: 'PUT',
        contentType: 'application/json',
        complete: function() {
          defer.resolve();
        }
      });
      return defer.promise();
    },

    createJira: function(secId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz', 'tickets/' + secId + '/createinjira'),
        type: 'POST',
        complete: function(resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('tickets:entities', function() {
    return API.getTicketsEntities();
  });

  App.reqres.setHandler('tickets:entity', function(ticketId) {
    return API.getTicketsModel(ticketId);
  });

  App.reqres.setHandler('tickets:row-count', function(urlParams) {
    return API.getRowCount(urlParams);
  });

  App.reqres.setHandler('tickets:row-count-url', function(options) {
    return API.getRowCountUrl(options);
  });

  App.reqres.setHandler('tickets:get-collection-url', function(options) {
    return API.getTicketsUrl(options);
  });

  App.reqres.setHandler('tickets:get-collection', function() {
    return API.getTicketsCollection();
  });
  App.reqres.setHandler('tickets:get-data', function(secId) {
    return API.getTicketsData(secId);
  });
  App.reqres.setHandler('tickets:close', function(secId) {
    return API.closeTicket(secId);
  });
  App.reqres.setHandler('tickets:mark-read', function(id) {
    return API.markAsRead(id);
  });
  App.reqres.setHandler('tickets:send-new', function(formData, type) {
    return API.postNewTicket(formData, type);
  });
  App.reqres.setHandler('ticket:update:urgency', function(secId, checked) {
    return API.updateUrgency(secId, checked);
  });
  App.reqres.setHandler('tickets:update:tariffScale', function(ticketSecID, tariffScale) {
    return API.updateTariffScale(ticketSecID, tariffScale);
  });
  App.reqres.setHandler('tickets:change-state', function(ticketID, state) {
    return API.changeState(ticketID, state);
  });
  App.reqres.setHandler('tickets:change-subject', function(subject, secId) {
    return API.changeSubject(subject, secId);
  });
  App.reqres.setHandler('tickets:change-type', function(secId, type) {
        return API.changeType(secId, type);
  });
  App.reqres.setHandler('tickets:create-jira', function(secId) {
    return API.createJira(secId);
  });
});
