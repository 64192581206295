define('customerContactsDetailsData',[
	'underscore'
], function (_) {
  'use strict';

  var customersContactsDetailTabs = [
    {
      route: 'info',
      name: 'info',
      title: _.i18n('customerContact.tabTitle.info')
    }
  ];

  return customersContactsDetailTabs;
});
