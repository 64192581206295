define('entities/kits',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var Kit = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/kits/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      code: '',
      nbTests: 0,
      description: '',
      assay: '',
      nbTargets: 0,
      ordRefName: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function () {
      return Settings.url('compuzz', 'v2/kits');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    },

    copyShared: function() {
      var url = Settings.url('compuzz', 'v2/kits/shared/' + this.get('secId') + '/copy', {}),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(item) {
          defer.resolve(item);
        }
      });

      return defer.promise();
    },

    shareShared: function() {
      var url = Settings.url('compuzz', 'v2/kits/shared/' + this.get('secId') + '/share', {}),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(item) {
          defer.resolve(item);
        }
      });

      return defer.promise();
    }
  });

  var KitCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/kits');
    },
    model: Kit
  });

  var API = {

    getKitCollectionUrl: function() {
      new KitCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/kits', params);
    },

    getAllKits: function(params) {
      var url = Settings.url('compuzz', 'v2/kits', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getKitCountUrl: function() {
      var params = '';
      return 'v2/kits/rowCount' + params;
    },

    deleteKit: function(secId){
      var url = Settings.url('compuzz', 'v2/kits/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('kit:get-collection-url', function() {
      return API.getKitCollectionUrl();
  });

  App.reqres.setHandler('kit:get-collection-to-page', function(params) {
      return API.getAllKits(params);
  });

  App.reqres.setHandler('kit:get-count-url', function() {
    return API.getKitCountUrl();
  });

  App.reqres.setHandler('kit:model', function(itemJSON) {
    return new Kit(itemJSON);
  });

  App.reqres.setHandler('kit:delete', function(secId) {
    return API.deleteKit(secId);
  });
});
