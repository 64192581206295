/**
 * Created by BFR on 02/05/2017.
 */
define('adminLisAnaController',[
  'module',
  'underscore',
  'app',
  'adminLisAnaView',
  'bootbox',
  'lisAnaCreateEditLayoutView',
  'settings',
  'entities/lisanas'
], function(
  module,
  _,
  App,
  View,
  bootbox,
  CreateEditLayoutView
) {
  'use strict';

  var SuperAdminLisAnaListController = {
    showLisAnaList: function(data) {
      this.data = data;
      var view = new View({data: data});
      this.view = view;
      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      view.listenTo(view, 'lisana:delete', _.bind(this.onDelete, this));
      view.listenTo(view, 'lisana:edit', _.bind(this.onEdit, this));
      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('lisAnaListTab').show(view);
    },

    showLisAnaAdd: function(lisSecId) {
      this.showLisAnaAddEdit(App.request('lisana:model', {
        refLis: {
          secId: lisSecId
        }
      }), true);
    },

    showLisAnaAddEdit: function(model, newItem) {
      this.createEditView = new CreateEditLayoutView({
        lisAnaModel: model,
        newItem: newItem
      });
      this.createEditView.show({
        title: _.i18n('lisana.new')
      });
    },

    onEdit: function(lisSecId, lisAnaSecId){
      App.navigate('admin/lis/' + lisSecId + '/lisana/' + lisAnaSecId + '/edit', {trigger: true});
    },

    showLisAnaEdit: function(lisSecId, lisAnaSecId) {
      var that = this;
      var model = App.request('lisana:model', {
        refLis: {
          secId: lisSecId
        },
        secId: lisAnaSecId
      });
      model.fetch().done(function() {
        that.showLisAnaAddEdit(model, false);
      });
    },

    onGridLoaded: function() {
      var lisSecId = this.data.model.get('secId');
      var data = {
        url: App.request('lisana:get-collection-url', lisSecId),
        rowCountUrl: App.request('lisana:get-count-url', lisSecId)
      };

      this.view.displayGrid(data);
    },

    onDelete: function(lisSecId, secId){
      bootbox.confirm(_.i18n('lisana.confirmDelete'), _.bind(function(result) {
        if(result){
          App.request('lisana:delete', lisSecId, secId)
            .done(_.bind(function(){
              this.view.updatePagination('reloadGrid');
            }, this));
        }
      }, this));
    }
  };

  module.exports = SuperAdminLisAnaListController;
});
