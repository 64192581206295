/**
 * Created by RKL on 07/09/2015.
 */
define('superAdminMbSmpMatDetailsController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'superAdminMbSmpMatDetailsView',
  'emptyView',
  'mbSmpMatDetailTabController',
  'superAdminMbSmpMatDetailsController',
  'rolesMixin',
  'settings',
  'superAdminMbSmpMatsTabs',
  'entities/mbsmpmats'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  mbSmpMatDetailTabController
) {
  'use strict';

  var MbSmpMatsDetailsController = {
    fetchMbSmpMat: function(data) {
      var that = this,
          emptyView = new EmptyView(),
          contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.secId) {
        this.showMbSmpMat(data);
      } else {
        this.model = App.request('mbsmpmat:model', {secId: data.secId});
        this.model.fetch().done(function() {
          if (contentRegion) {
            that.showMbSmpMat(data);
          }
        });
      }
    },

    showMbSmpMat: function(data) {
      var view = new View({model: this.model, data: data});

      this.view = view;
      data.model = this.model;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      this.displayTabs(data);

      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
      view.listenTo(App, 'mbsmpmat:header:rerender', _.bind(this.onHeaderChange, this));
    },

    onHeaderChange: function (mbsmpmat) {
      this.view.onHeaderChange(mbsmpmat);
    },

    displayTabs: function(data) {
      mbSmpMatDetailTabController.showTab(data);
      data.model = this.model;
      this.tab = data.tab;
    },

    onTabClick: function(tab) {
      if (this.data.tab !== tab || this.data.secId) {
        this.data.tab = tab;
        this.data.secId = null;

        this.displayTabs(this.data);
        App.navigate('superAdmin/mbsmpmats/' + this.model.get('secId') + '/' + tab.name);
      }
    }
  };

  module.exports = MbSmpMatsDetailsController;
});
