
define('template!headerWellTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="img-responsive">\n    <div class="row m-b-0">\n        <div class="col-xs-1">\n            ' +
((__t = ( pos )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-2">\n            ';
 if (error) { ;
__p += '\n            <div class="mdi-alert-error well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: red;"></div>\n            ';
 } else if (warning) { ;
__p += '\n            <div class="mdi-alert-warning well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: orange;"></div>\n            ';
 } else if (ok) { ;
__p += '\n            <div class="mdi-action-done well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: green;"></div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="col-xs-3">\n            ' +
((__t = ( smpid )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-5">\n            ' +
((__t = ( refKitL != null ? refKitL.lotNb : '' )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    <hr class="well-separator m-t-0"/>\n    ';
 _.each(results, function(result) { ;
__p += '\n    <div class="row m-b-0">\n        <label class="col-xs-1">' +
((__t = ( result.target )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2">\n            <div class="' +
((__t = ( result.resCodeErr === 'E' ? 'well-square-icon-black' : 'well-square-icon-green')) == null ? '' : __t) +
' ' +
((__t = ( result.resCodeErr === 'W' ? 'well-square-icon-orange' : '')) == null ? '' : __t) +
' p-t-0 p-l-0"\n                 data-toggle="tooltip" data-placement="right" title="' +
((__t = ( result.comment )) == null ? '' : __t) +
'">\n                ';
 if (result.resColor) { ;
__p += '\n                <div class="well-circle-icon p-t-1" style="position:absolute; background: ' +
((__t = ( result.resColor )) == null ? '' : __t) +
';"/>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        <div class="col-xs-3">\n            <label class="row">' +
((__t = ( result.resTxt )) == null ? '' : __t) +
'</label>\n        </div>\n        <label class="col-xs-5">' +
((__t = ( result.resFormated )) == null ? '' : __t) +
'</label>\n    </div>\n    ';
 }); ;
__p += '\n</div>';

}
return __p
};});

