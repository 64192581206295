
define('template!runAssaysTargetsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="tab-content content">\n  <div class="well run-detail">\n    <div class="row">\n      <div class="col-xs-' +
((__t = ( displayBT?'5':'2' )) == null ? '' : __t) +
'">\n        <div class="row">\n          <div class="col-xs-' +
((__t = ( displayBT?'3':'6' )) == null ? '' : __t) +
'">\n            <label>' +
((__t = ( _.i18n('well.assay') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-' +
((__t = ( displayBT?'3':'6' )) == null ? '' : __t) +
'">\n            <label>' +
((__t = ( _.i18n('well.target') )) == null ? '' : __t) +
'</label>\n          </div>\n          ';
 if (displayBT) { ;
__p += '\n            <div class="col-xs-3">\n              <label>' +
((__t = ( _.i18n('well.baseline') )) == null ? '' : __t) +
'</label>\n            </div>\n            <div class="col-xs-3">\n              <label>' +
((__t = ( _.i18n('well.threshold') )) == null ? '' : __t) +
'</label>\n            </div>\n          ';
 } ;
__p += '\n        </div>\n      </div>\n      <div class="col-xs-' +
((__t = ( displayBT?'2':'3' )) == null ? '' : __t) +
'">\n        <div class="row">\n          <div class="col-xs-12">\n            <label>' +
((__t = ( _.i18n('well.all') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.tot') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.ok') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.warn') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.err') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details\'>\n              <label class="clickable">' +
((__t = ( (data.wellCount + data.wellCCount) > 0 ? (data.wellCount + data.wellCCount) : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"errorInd":"O","res1CodeErr":"O","res2CodeErr":"O","res3CodeErr":"O","res4CodeErr":"O","res5CodeErr":"O","res6CodeErr":"O"}\'>\n              <label class="clickable" style="color:green;">' +
((__t = ( (data.wellCountOk + data.wellCCountOk) > 0 ? (data.wellCountOk + data.wellCCountOk) : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"errorInd":"W","res1CodeErr":"W","res2CodeErr":"W","res3CodeErr":"W","res4CodeErr":"W","res5CodeErr":"W","res6CodeErr":"W"}\'>\n              <label class="clickable" style="color:orange;">' +
((__t = ( (data.wellCountWarn + data.wellCCountWarn) > 0 ? (data.wellCountWarn + data.wellCCountWarn) : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"errorInd":"E","res1CodeErr":"E","res2CodeErr":"E","res3CodeErr":"E","res4CodeErr":"E","res5CodeErr":"E","res6CodeErr":"E"}\'>\n              <label class="clickable" style="color:red;">' +
((__t = ( (data.wellCountErr + data.wellCCountErr) > 0 ? (data.wellCountErr + data.wellCCountErr) : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n        </div>\n      </div>\n      <div class="col-xs-' +
((__t = ( displayBT?'2':'3' )) == null ? '' : __t) +
'">\n        <div class="row">\n          <div class="col-xs-12">\n            <label>' +
((__t = ( _.i18n('well.sample') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.tot') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.ok') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.warn') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.err') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"smptype":"U"}\'>\n              <label class="clickable">' +
((__t = ( data.wellCount > 0 ? data.wellCount : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"errorInd":"O","smptype":"U","res1CodeErr":"O","res2CodeErr":"O","res3CodeErr":"O","res4CodeErr":"O","res5CodeErr":"O","res6CodeErr":"O"}\'>\n              <label class="clickable" style="color:green;">' +
((__t = ( data.wellCountOk > 0 ? data.wellCountOk : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"errorInd":"W","smptype":"U","res1CodeErr":"W","res2CodeErr":"W","res3CodeErr":"W","res4CodeErr":"W","res5CodeErr":"W","res6CodeErr":"W"}\'>\n              <label class="clickable" style="color:orange;">' +
((__t = ( data.wellCountWarn > 0 ? data.wellCountWarn : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"errorInd":"E","smptype":"U","res1CodeErr":"E","res2CodeErr":"E","res3CodeErr":"E","res4CodeErr":"E","res5CodeErr":"E","res6CodeErr":"E"}\'>\n              <label class="clickable" style="color:red;">' +
((__t = ( data.wellCountErr > 0 ? data.wellCountErr : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n        </div>\n      </div>\n      <div class="col-xs-' +
((__t = ( displayBT?'2':'3' )) == null ? '' : __t) +
'">\n        <div class="row">\n          <div class="col-xs-12">\n            <label>' +
((__t = ( _.i18n('well.qc') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.tot') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.ok') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.warn') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <label>' +
((__t = ( _.i18n('well.err') )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"smptype":"C"}\'>\n              <label class="clickable">' +
((__t = ( data.wellCCount > 0 ? data.wellCCount : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"errorInd":"O","smptype":"C"}\'>\n              <label class="clickable" style="color:green;">' +
((__t = ( data.wellCCountOk > 0 ? data.wellCCountOk : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"errorInd":"W","smptype":"C"}\'>\n              <label class="clickable" style="color:orange;">' +
((__t = ( data.wellCCountWarn > 0 ? data.wellCCountWarn : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"errorInd":"E","smptype":"C"}\'>\n              <label class="clickable" style="color:red;">' +
((__t = ( data.wellCCountErr > 0 ? data.wellCCountErr : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n        </div>\n      </div>\n    </div>\n\n\n    <hr class="m-t-5 m-b-5"/>\n    ';
 _.each(data.assays, function(assay) { var printAssay = true; ;
__p += '\n    ';
 _.each(assay.kitps, function(kitp) { ;
__p += '\n    <div class="row">\n      <div class="col-xs-' +
((__t = ( displayBT?'5':'2' )) == null ? '' : __t) +
'">\n        <div class="row">\n          <div class="col-xs-' +
((__t = ( displayBT?'3':'6' )) == null ? '' : __t) +
'">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'"}\'>\n              <label class="clickable">' +
((__t = ( printAssay?assay.code:'' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-' +
((__t = ( displayBT?'3':'6' )) == null ? '' : __t) +
'">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'"}\'>\n              <label class="clickable">' +
((__t = ( kitp.channelNb )) == null ? '' : __t) +
' ' +
((__t = ( kitp.refMbAna.code )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          ';
 if (displayBT) { ;
__p += '\n            <div class="col-xs-2">\n              <label class="run-at-baseline" data-runat-secid="' +
((__t = ( kitp.runAtSecId )) == null ? '' : __t) +
'">' +
((__t = ( kitp.baseline )) == null ? '' : __t) +
'</label>\n            </div>\n            <div class="col-xs-2">\n              <label class="run-at-threshold" data-runat-secid="' +
((__t = ( kitp.runAtSecId )) == null ? '' : __t) +
'">' +
((__t = ( kitp.threshold )) == null ? '' : __t) +
'</label>\n            </div>\n          ';
 } ;
__p += '\n          <div class="col-xs-2">\n            ';
 if (kitp.runAtSecId) { ;
__p += '\n              <span class="mdi-content-create edit-run-at clickable" data-runat-secid="' +
((__t = ( kitp.runAtSecId )) == null ? '' : __t) +
'"></span>\n            ';
 } ;
__p += '\n          </div>\n        </div>\n      </div>\n      <div class="col-xs-' +
((__t = ( displayBT?'2':'3' )) == null ? '' : __t) +
'">\n        <div class="row">\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'"}\'>\n              <label class="clickable">' +
((__t = ( (kitp.wellCount + kitp.wellCCount) > 0 ? (kitp.wellCount + kitp.wellCCount) : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "errorInd":"O", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'","res' +
((__t = ( kitp.channelNb )) == null ? '' : __t) +
'CodeErr":"O"}\'>\n              <label class="clickable" style="color:green;">' +
((__t = ( (kitp.wellCountOk + kitp.wellCCountOk) > 0 ? (kitp.wellCountOk + kitp.wellCCountOk) : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "errorInd":"W", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'","res' +
((__t = ( kitp.channelNb )) == null ? '' : __t) +
'CodeErr":"W"}\'>\n              <label class="clickable" style="color:orange;">' +
((__t = ( (kitp.wellCountWarn + kitp.wellCCountWarn) > 0 ? (kitp.wellCountWarn + kitp.wellCCountWarn) : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "errorInd":"E", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'","res' +
((__t = ( kitp.channelNb )) == null ? '' : __t) +
'CodeErr":"E"}\'>\n              <label class="clickable" style="color:red;">' +
((__t = ( (kitp.wellCountErr + kitp.wellCCountErr) > 0 ? (kitp.wellCountErr + kitp.wellCCountErr) : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n        </div>\n      </div>\n      <div class="col-xs-' +
((__t = ( displayBT?'2':'3' )) == null ? '' : __t) +
'">\n        <div class="row">\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'","smptype":"U"}\'>\n              <label class="clickable">' +
((__t = ( kitp.wellCount > 0 ? kitp.wellCount : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "errorInd":"O", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'","smptype":"U","res' +
((__t = ( kitp.channelNb )) == null ? '' : __t) +
'CodeErr":"O"}\'>\n              <label class="clickable" style="color:green;">' +
((__t = ( kitp.wellCountOk > 0 ? kitp.wellCountOk : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "errorInd":"W", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'","smptype":"U","res' +
((__t = ( kitp.channelNb )) == null ? '' : __t) +
'CodeErr":"W"}\'>\n              <label class="clickable" style="color:orange;">' +
((__t = ( kitp.wellCountWarn > 0 ? kitp.wellCountWarn : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "errorInd":"E", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'","smptype":"U","res' +
((__t = ( kitp.channelNb )) == null ? '' : __t) +
'CodeErr":"E"}\'>\n              <label class="clickable" style="color:red;">' +
((__t = ( kitp.wellCountErr > 0 ? kitp.wellCountErr : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n        </div>\n      </div>\n      <div class="col-xs-' +
((__t = ( displayBT?'2':'3' )) == null ? '' : __t) +
'">\n        <div class="row">\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'","smptype":"C"}\'>\n              <label class="clickable">' +
((__t = ( kitp.wellCCount > 0 ? kitp.wellCCount : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "errorInd":"O", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'","smptype":"C"}\'>\n              <label class="clickable" style="color:green;">' +
((__t = ( kitp.wellCCountOk > 0 ? kitp.wellCCountOk : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "errorInd":"W", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'","smptype":"C"}\'>\n              <label class="clickable" style="color:orange;">' +
((__t = ( kitp.wellCCountWarn > 0 ? kitp.wellCCountWarn : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n          <div class="col-xs-3">\n            <a href=\'#runs/' +
((__t = ( run.secId )) == null ? '' : __t) +
'/details/filters/{"mbAnaSecId":"' +
((__t = ( kitp.refMbAna.secId )) == null ? '' : __t) +
'", "assaySecId":"' +
((__t = ( assay.secId )) == null ? '' : __t) +
'", "errorInd":"E", "targetSecId": "' +
((__t = ( kitp.secId )) == null ? '' : __t) +
'","smptype":"C"}\'>\n              <label class="clickable" style="color:red;">' +
((__t = ( kitp.wellCCountErr > 0 ? kitp.wellCCountErr : '-' )) == null ? '' : __t) +
'</label>\n            </a>\n          </div>\n        </div>\n      </div>\n    </div>\n    ';
 printAssay = false; }); ;
__p += '\n    ';
 }); ;
__p += '\n  </div>\n</div>';

}
return __p
};});

