/**
 * Created by GSP on 19/10/2015.
 */
define('dialogMixin',['module',
        'jquery',
        'underscore',
        'bootbox'], function(module, $, _, Bootbox){

  'use strict';

  var DialogMixin = {

    show: function (options) {
      this.box = Bootbox.dialog({
        message: this.render().el,
        title: options.title,
        className: options.className,
        buttons: options.buttons
      });
      this.triggerMethod('show');

      this.box.on('hidden.bs.modal', _.bind(function() {
        this.box.off();
        this.hide();
      }, this));
      this.box.on('shown.bs.modal', _.bind(function() {
        this.triggerMethod('shown');
      }, this));
    },

    setTitle: function(title) {
      if (this.box) {
        $(this.box ).find('.modal-title').text(title);
      }
    },

    hide: function () {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
    }

  };

  module.exports = DialogMixin;
});
