define('entities/lmbanas',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var LmbAna = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/lmbanas/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      code: '',
      shortname: '',
      rptname: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function () {
      return Settings.url('compuzz', 'v2/lmbanas');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var LmbAnaCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/lmbanas');
    },
    model: LmbAna
  });

  var API = {

    getLmbAnaCollectionUrl: function() {
      new LmbAnaCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/lmbanas', params);
    },

    getAllLmbAnas: function(params) {
      var url = Settings.url('compuzz', 'v2/lmbanas', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getLmbAnaCountUrl: function() {
      var params = '';
      return 'v2/lmbanas/rowCount' + params;
    },

    deleteLmbAna: function(secId){
      var url = Settings.url('compuzz', 'v2/lmbanas/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('lmbana:get-collection-url', function() {
      return API.getLmbAnaCollectionUrl();
  });

  App.reqres.setHandler('lmbana:get-collection-to-page', function(params) {
      return API.getAllLmbAnas(params);
  });

  App.reqres.setHandler('lmbana:get-count-url', function() {
    return API.getLmbAnaCountUrl();
  });

  App.reqres.setHandler('lmbana:model', function(itemJSON) {
    return new LmbAna(itemJSON);
  });

  App.reqres.setHandler('lmbana:delete', function(secId) {
    return API.deleteLmbAna(secId);
  });
});
