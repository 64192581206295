define('adminKitProtTabs',[
  'module',
  'underscore'
], function(
  module,
  _
) {
  'use strict';

  module.exports = [
    {
      name: 'detail',
      title: _.i18n('kitprots.details.tabTitle')
    },
    {
        name: 'kitpr',
        title: _.i18n('kitprots.kitpr.tabTitle')
    }
  ];
});
