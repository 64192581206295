
define('template!caccountAdminsAddTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="my-vector-popup-container">\n    <form class="form-horizontal">\n      <fieldset>\n        \n       <!-- First name  -->\n        <div class="form-group" data-field-name="firstName">\n          <div class="col-xs-12">\n            <input type="text" class="form-control floating-label js-popup-input" data-field-name="firstName" placeholder="' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( firstName )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <!-- Last name  -->\n        <div class="form-group" data-field-name="lastName">\n          <div class="col-xs-12">\n            <input type="text" class="form-control floating-label js-popup-input" data-field-name="lastName" placeholder="' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( lastName )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <!-- Email  -->\n        <div class="form-group" data-field-name="email">\n          <div class="col-xs-12">\n            <input type="email" class="form-control floating-label js-popup-input" data-field-name="email" placeholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'" value="' +
((__t = ( email )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n      </fieldset>\n    </form>\n  </div>\n</div>';

}
return __p
};});

