define('sampleCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!sampleCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'settings',
  'moment',
  'autocompleteView',
  'entities/samples'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  Settings,
  moment,
  AutocompleteView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      ordDt: '.date-ordDt',
      receptDt: '.date-receptDt',
      sampleDt: '.date-sampleDt',
      datehh: '.date-datehh'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectLmbSmpMat: '.js-select-lmbsmpmat-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'sampleId', type: 'required'}
    ],

    fromTemplate: true,

    initialize: function(options) {
      this.model = options.sampleModel.clone();
      this.fromTemplate = this.options.newItem;
      if (this.options.newItem) {
        if (Settings.get('sampleOrdDt')) {
          this.model.set('ordDt', parseInt(Settings.get('sampleOrdDt'), 10));
        }
        if (Settings.get('sampleReceptDt')) {
          this.model.set('receptDt', parseInt(Settings.get('sampleReceptDt'), 10));
        }
        if (Settings.get('sampleDt')) {
          this.model.set('sampleDt', parseInt(Settings.get('sampleDt'), 10));
        }
        if (Settings.get('sampleDateHh')) {
          this.model.set('datehh', parseInt(Settings.get('sampleDateHh'), 10));
        }
      }
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.ordDt = moment(this.model.get('ordDt')).format('DD-MM-YYYY');
      templateData.receptDt = moment(this.model.get('receptDt')).format('DD-MM-YYYY');
      templateData.sampleDt = moment(this.model.get('sampleDt')).format('DD-MM-YYYY');
      templateData.datehh = moment(this.model.get('datehh')).format('DD-MM-YYYY');
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();

      var lmbSmpMatView = new AutocompleteView(
          this._getAutocompleteOptions('refLmbSmpMat', 'code', 'code', 'v2/lmbsmpmats', 'selectlmbsmpmat.placeholder', 'code')
      );
      this.getRegion('selectLmbSmpMat').show(lmbSmpMatView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onRender: function() {
      this.initDatePicker(this.ui.ordDt, 'ordDt');
      this.initDatePicker(this.ui.receptDt, 'receptDt');
      this.initDatePicker(this.ui.sampleDt, 'sampleDt');
      this.initDatePicker(this.ui.datehh, 'datehh');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.validateTests();
      }
    },

    validateTests: function() {
      App.request('sample:checkTests', this.model.get('tests')).done(_.bind(function() {
        this.model.save();
      }, this)).fail(_.bind(function(response) {
        bootbox.alert(_.i18n('sample.failTests') + ' : ' + response.responseText);
      }, this));
    },

    onSave: function(model) {
      if (this.options.newItem) {
        Settings.set('sampleOrdDt', model.get('ordDt'));
        Settings.set('sampleReceptDt', model.get('receptDt'));
        Settings.set('sampleDt', model.get('sampleDt'));
        Settings.set('sampleDateHh', model.get('datehh'));
      }
      var modelJSON = model.toJSON();
      this.options.sampleModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('sample:header:rerender', modelJSON);
      App.navigate('samples/' + model.get('secId'), {trigger: true});
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'samples/new') {
        App.navigate('samples', {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
