/**
 * Created by OLD on 25/11/2015.
 */
define('wellResultView',[
  'module',  
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'jqgridView',
  'template!wellResultTpl',
  'colorUtils',
  'dateUtils',
  'entities/wells'
], function(
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  JqGridView,
  viewTpl,
  ColorUtils,
  dateConverter
) {
  'use strict';

  var WellTableView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'resultsListFilters',

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqgRow: 'tr.jqgrow',
        sortWellRadio: '.sort-well-radio'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'change @ui.sortWellRadio': 'onSortWellRadioChange',
        'click .print-results': 'print'
      }, this.gridEvents);
    },

    onShow: function() {
      $.material.init();
    },

    print: function() {
      var $print = $('#jq-grid-list')
        .clone()
        .addClass('print')
        .prependTo('#print-region');

      $('#print-region').find('.js-kitl-table-details-for-printing-container').remove();
      $('.js-kitl-table-details-for-printing-container').clone().prependTo('#print-region');
      $('.ui-jqgrid-htable>thead').clone().prependTo($print);
      $('html,body').css('overflow', 'visible');
      $('#main-region').hide();
      //window.print() stops JS execution
      window.print();

      //Remove div once printed
      $print.remove();
      $('html,body').css('overflow', 'hidden');
      $('#main-region').show();
    },

    serializeData: function() {
      var templateData = {};
      templateData.sortOn = this.sortOn;
      templateData.creatDate = dateConverter.toDateFormatString(this.model.get('creatDate'));
      templateData.model = this.model.toJSON();
      return templateData;
    },

    onSortWellRadioChange: function() {
      if(this.ui.sortWellRadio){
        _.each(this.ui.sortWellRadio, _.bind(function(radio) {
          if (radio.checked) {
            switch(radio.value) {
              case 'sortRow':
                this.trigger('well-table:sort-row');
                break;
              case 'sortColumn':
                this.trigger('well-table:sort-column');
                break;
            }
          }
        }, this));
      }
    },

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: 9999,
        currentPage: 1
      };
    },

    gridOptions: function(data) {
       var defaultsOptions, options, 
        _colModel = [];

        _colModel.push({
            label: '',
            name: 'num',
            key: true,
            hidden: true
        });

        _colModel.push({
            label: _.i18n('well.pos'),
            name: 'pos',
            classes: 'well-list-pos-label',
            formatter: this.defaultFormatter,
            search: true,
            sortable: true,
            index: 'pos',
            searchoptions: {
              sopt: ['cn']
            },
            width: 60
        });

        _colModel.push({
            label: _.i18n('well.smpid'),
            name: 'smpId',
            classes: 'well-list-label',
            formatter: this.defaultFormatter,
            search: true,
            sortable: true,
            index: 'smpId',
            searchoptions: {
              sopt: ['cn']
            },
            width: 120
        });

        _colModel.push({
          label: _.i18n('well.refAssay'),
          name: 'refAssay.code',
          classes: 'well-list-label',
          formatter: this.defaultFormatter,
          search: true,
          sortable: true,
          index: 'refAssay.code',
          searchoptions: {
            sopt: ['cn']
          }
        });

        _colModel.push({
          label: _.i18n('well.target'),
          name: 'refKitP.refMbAna.code',
          classes: 'well-list-label',
          formatter: this.defaultFormatter,
          search: true,
          sortable: true,
          index: 'refKitP.refMbAna.code',
          searchoptions: {
            sopt: ['cn']
          }
        });

        _colModel.push({
          label: _.i18n('well.res1Code'),
          name: 'resCode',
          classes: 'well-list-label',
          formatter: _.bind(this.defaultFormatter, this),
          search: true,
          sortable: true,
          index: 'resCode',
          searchoptions: {
            sopt: ['cn']
          },
          width: 60
        });

        _colModel.push({
          label: _.i18n('well.res1Code'),
          name: 'resColor',
          classes: 'well-list-label',
          formatter: _.bind(this.squareIconFormatter, this),
          search: false,
          sortable: true,
          index: 'resColor',
          width: 60
        });

        _colModel.push({
          label: _.i18n('well.resText'),
          name: 'resText',
          classes: 'well-list-label',
          formatter: _.bind(this.defaultFormatter, this),
          search: true,
          sortable: true,
          index: 'resText',
          searchoptions: {
            sopt: ['cn']
          }
        });

        _colModel.push({
          label: _.i18n('well.res1Num'),
          name: 'resNum1',
          classes: 'well-list-label',
          formatter: _.bind(this.defaultFormatter, this),
          search: true,
          sortable: true,
          index: 'resNum1',
          searchoptions: {
            sopt: ['cn']
          },
          width: 60
        });

        _colModel.push({
          label: _.i18n('well.res2Num'),
          name: 'resNum2',
          classes: 'well-list-label',
          formatter: _.bind(this.defaultFormatter, this),
          search: true,
          sortable: true,
          index: 'resNum2',
          searchoptions: {
            sopt: ['cn']
          },
          width: 60
        });

        _colModel.push({
          label: _.i18n('well.res3Num'),
          name: 'resNum3',
          classes: 'well-list-label',
          formatter: _.bind(this.defaultFormatter, this),
          search: true,
          sortable: true,
          index: 'resNum3',
          searchoptions: {
            sopt: ['cn']
          },
          width: 60
        });

        _colModel.push({
            label: _.i18n('well.resCodeErr'),
            name: 'resCodeErr',
            classes: 'well-list-label',
            formatter: _.bind(this.defaultFormatter, this),
            search: false,
            sortable: true,
            index: 'resCodeErr',
            width: 60
        });

        _colModel.push({
            label: _.i18n('well.resComment'),
            name: 'resComment',
            classes: 'well-list-label',
            formatter: _.bind(this.defaultFormatter, this),
            search: false,
            sortable: true,
            index: 'resComment'
        });

      defaultsOptions = this.gridInitOptions(data);
      options = {
          colModel: _colModel,
          sortname: 'smpId', //this.sortOn?this.sortOn:'row'
          loadonce: true,
          onSelectRow: _.bind(function(rowId, bool, e) {
            if (e.type === 'click') {
              this.trigger('well-result:opengraphpopup', this.codeTableData[rowId-1]);
            }
          }, this)
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    displayTargetFormater: function(cellValue) {
      var targetName = this.model.getTargetName(cellValue, this.channelToDisplay);
      return '<span class="cell-default">' + targetName + '</span>';
    },

    squareIconFormatter: function(cellValue, call, object) {
      if (object.resColor !== null) {
        var color = ColorUtils.decimalToHexa(object.resColor);

        return '<div class="row m-b-0">' +
          '<div class="col-xs-1">' +
          '<div class="' + (object.resCodeErr === 'E' ? 'well-square-icon-black' : 'well-square-icon-green') +
          ' '+ (object.resCodeErr === 'W' ? 'well-square-icon-orange' : '') +'">' +
          '<div class="well-circle-icon p-t-1" style="position:absolute; background-color: '+color+'!important;"/>' +
          '</div>' +
          '</div>' +
          '</div>';
      } else {
        return '';
      }
    },

    onLoadComplete: function(data) {
      this.codeTableData = data;
      if(data && data.length) {
        this.ui.jqGrid.setSelection(data[0].secId).click();
      }
    },

    onPreselectRow: function(rowId) {
      if (this.codeTableData) {
        this.ui.jqgRow.removeClass('btn-secondary');
        this.ui.jqGrid.find('tr#' + rowId).addClass('btn-secondary');
        this.trigger('well-table:loaded', rowId);
      } else {
        return;
      }
    },

    onCheckRowCount: function(rowCount) {
      if (rowCount === 0) {
        this.trigger('well:check-rowcount');
      }
    }
  });

  module.exports = WellTableView;
});
