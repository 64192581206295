
define('template!customerCommonPopupFromTemplateLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="saving-label"></div>\n<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'</div>\n<div class="js-filters-region row"></div>\n<div class="templates-variations-header">\n\t<div class="variations-label">\n\t\t<label class="control-label">' +
((__t = ( _.i18n('template.popup.templateVariations') )) == null ? '' : __t) +
'</label>\n\t</div>\n\t<div class="back-button">\n\t\t<a class="btn-raised" href="javascript:void(0);">\n\t\t\t<i class="mdi-content-reply icon"></i>' +
((__t = ( _.i18n('template.backToTemplates') )) == null ? '' : __t) +
'</a>\n\t</div>\n</div>\n<div class="js-templates-region caccount-details-container from-template-region row"></div>\n<div class="js-templates-variants-region caccount-details-container from-template-variants-region row"></div>';

}
return __p
};});

