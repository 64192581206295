/**
 * Created by RKL on 18/08/2015.
 */
define('customerTemplatesCollectionView',[
  'module',
  'app',
  'customerTemplatesItemView',
  'customerTemplatesNewItemView',
  'template!elementsCollectionTpl',
  'marionette',
  'stateMessages',
  'underscore'
], function (
  module,
  App,
  ItemView,
  NewItemView,
  templatesTpl,
  Marionette,
  StateMessages,
  _
) {
  'use strict';

  var CollectionView = Marionette.CompositeView.extend({
    template: templatesTpl,

    getChildView: function(item) {
      if (item.get('secId') !== null) {
        return ItemView;
      } else {
        return NewItemView;
      }
    },
    childViewContainer: '.elements-container',

    ui: {
      scrollContainer: '.scroll-container',
      scrollBlock: '.lazy-load-block'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    onShow: function() {
      this.ui.scrollContainer.on('scroll', _.bind(this.onScroll, this));
      this.trigger('showView');
      this.on('childview:templates:unselect', _.bind(this.unselectChildren, this));

      App.off('copy-paste:unselectAll:fromCategory');
      App.on('copy-paste:unselectAll:fromCategory', _.bind(this.unselectAll, this));
    },

    unselectAll: function() {
        this.children.each(_.bind(function (childView) {
          childView.triggerMethod('unselectChildren', null);
        }, this));
    },

    unselectChildren: function(view, model, shifted) {
      if (shifted) {
        this.shiftedMultiselection(model);
      } else {
        this.children.each(_.bind(function (childView) {
          childView.triggerMethod('unselectChildren', model);
        }, this));
      }
    },

    shiftedMultiselection: function(model) {
      var selectionIndex = this.collection.indexOf(model),
          viewModel,
          index,
          selected,
          firstIndex,
          lastIndex,
          flag;
        // find first - last selections items
        this.children.each(_.bind(function (childView) {
          viewModel = childView.model;
          index = this.collection.indexOf(viewModel);
          selected = childView.$el.hasClass('selected');

          if (viewModel.get('isFakeAddTemplate')) return;
          if (selected) {
            if (index <= selectionIndex && !firstIndex) {
              firstIndex = index;
            } 
            if (index >= selectionIndex && !flag) {
              if (firstIndex === selectionIndex) {
                lastIndex = index;
              } else {
                lastIndex = index;
                flag = true;
              }
            }
          }
        }, this));
        // select needed
        this.children.each(_.bind(function (childView) {
          selected = childView.$el.hasClass('selected');
          viewModel = childView.model;
          index = this.collection.indexOf(viewModel);

          if (viewModel.get('isFakeAddTemplate')) return;
          if (selected) {
            if (index < firstIndex) {
              childView.triggerMethod('unselectChildren', null);
            }
            if (index > lastIndex) {
              childView.triggerMethod('unselectChildren', null);
            }
          } else {
            if (index > firstIndex && index < lastIndex) {
              childView.triggerMethod('selectChildren');
            }
          }
        }, this));
    }, 

    onScroll: function() {
      this.trigger('onScroll');
    },

    childViewOptions: function() {
      return {
        customerSecId: this.options.customerSecId,
        groupModel: this.options.groupModel,
        showFromProduct: this.options.showFromProduct,
        subdomain: this.options.subdomain,
        showVariationsButton: true
      };
    }
  });

  module.exports = CollectionView;
});
