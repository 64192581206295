define('entities/kitpr',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var Kitpr = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/kitprots/anytoken/kitprs/' + this.get('secId'));
      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      lotNb: '1',
      prodDt: '',
      expDt: '',
      st: 10
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (kitProtSecId) {
      return Settings.url('compuzz', 'v2/kitprots/' + kitProtSecId + '/kitprs');
    },

    save: function() {
      this.url = this.postUrl(this.get('refKitProt').secId);
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var KitprCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/kitprots/');
    },
    model: Kitpr
  });

  var API = {

    getKitprCollectionUrl: function(kitProtSecId) {
      new KitprCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/kitprots/' + kitProtSecId + '/kitprs', params);
    },

    getAllKitpr: function(kitProtSecId, params) {
      var url = Settings.url('compuzz', 'v2/kitprots' + kitProtSecId + '/kitprs', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getKitprCountUrl: function(kitProtSecId) {
      var params = '';
      return 'v2/kitprots/'+kitProtSecId+'/kitprs/rowCount' + params;
    },

    deleteKitpr: function(kitProtSecId, secId){
      var url = Settings.url('compuzz', 'v2/kitprots/'+kitProtSecId+'/kitprs/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('kitpr:get-collection-url', function(kitProtSecId) {
      return API.getKitprCollectionUrl(kitProtSecId);
  });

  App.reqres.setHandler('kitpr:get-collection-to-page', function(kitProtSecId, params) {
      return API.getAllKitpr(kitProtSecId, params);
  });

  App.reqres.setHandler('kitpr:get-count-url', function(kitProtSecId) {
    return API.getKitprCountUrl(kitProtSecId);
  });

  App.reqres.setHandler('kitpr:model', function(itemJSON) {
    return new Kitpr(itemJSON);
  });

  App.reqres.setHandler('kitpr:delete', function(kitProtSecId, secId) {
    return API.deleteKitpr(kitProtSecId, secId);
  });
});
