define('imageUploadBehavior',[
  'module',
  'marionette',
  'imageUtils',
  'imageSelectionPopupView',
  'app',
  'underscore',
  'entities/images'
], function(
  module,
  Marionette,
  imageUtils,
  ImageSelectionPopupView,
  App,
  _
) {
  'use strict';

  var ImageUploadBehavior = Marionette.Behavior.extend({
    ui: {
      changeLogo: '.js-change-logo'
    },

    events: {
      'click .js-logo-holder': '_onClickLogoHolder',
      'change @ui.changeLogo': '_onChangeLogo'
    },

    triggers: {
      cancel: 'image:upload:cancel'
    },

    fileExt: 'png',

    _onClickLogoHolder: function() {
      this.ui.changeLogo.trigger('click');
    },

    _onChangeLogo: function() {
      this.triggerMethod('alert');
      var target = this.ui.changeLogo[0],
        fileInfo = imageUtils.getFileInfo(target, 0);
      this.imageInfo = App.request('images:model');
      this.listenTo(this.imageInfo, 'sync', this._showImageOrPopup);
      this.listenTo(this.imageInfo, 'request', this._showUploadLabel);

      if (fileInfo) {
        this.imageInfo.getImageInfo(fileInfo.formData, fileInfo.fileLastModified);
      }
    },

    _showUploadLabel: function() {
      this.view.triggerMethod('uploading');
    },

    _showImageOrPopup: function() {
      this.view.triggerMethod('hide:label');
      switch(this.imageInfo.get('hasWhite')) {
        case 'out':
          this._showImageSelectionPopup('out');
          break;
        case 'all':
          this._showImageSelectionPopup('all');
          break;
        case 'error':
        case 'none':
          this._showLogo();
          break;
        default:
          throw 'hasWhite has wrong value: ' + this.imageInfo.get('hasWhite');
      }
    },

    _showImageSelectionPopup: function(hasWhite) {
      var imageSelectionView = new ImageSelectionPopupView({
        hasWhite: hasWhite,
        imageUid: this.imageInfo.get('fileUid')
      });
      imageSelectionView.show({title: _.i18n('logotypeSelection.title')});

      this.listenTo(imageSelectionView, 'confirm', this._triggerConfirm);
      this.listenTo(imageSelectionView, 'cancel', this._triggerCancel);
    },

    _showLogo: function() {
      var that = this,
        target = this.ui.changeLogo[0],
        fileInfo = imageUtils.getFileInfo(target, 0);
      imageUtils.encodeInputFileToBase64(target, 0, function(imageBase64) {
          that._triggerConfirm(imageBase64, fileInfo.fileExt);
        }
      );
    },

    _triggerConfirm: function(imageBase64, fileExt) {
      this.view.triggerMethod('image:upload:confirm', imageBase64, fileExt);
    },

    _triggerCancel: function() {
      this.view.triggerMethod('image:upload:cancel');
    }
  });

  module.exports = ImageUploadBehavior;
});
