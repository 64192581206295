define('adminKitlView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!adminKitlListTpl'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl
    ) {
    'use strict';

    var KitlListView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'kitlListFilters',

        triggers: {},

        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteKitl: '.delete-kitl-link'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click @ui.deleteKitl': 'onDeleteKitl'
            }, this.gridEvents);
        },

        serializeData: function() {
            var templateData = {
                type: 'kitl',
                data: this.options.data
            };
            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: Settings.get('perPage15'),
                currentPage: 1
            };
        },

        onDeleteKitl: function(event){
            this.trigger('kitl:delete', this.options.data.model.get('secId'), $(event.currentTarget).find('span').attr('data-row-id'));
        },

        gridOptions: function(data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('kitl.refKit'),
                        name: 'refKit.code',
                        classes: 'kitl-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'refKit.code'
                    }, {
                        label: _.i18n('kitl.lotNb'),
                        name: 'lotNb',
                        classes: 'kitl-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'lotNb'
                    }, {
                        label: _.i18n('kitl.prodDt'),
                        name: 'prodDt',
                        classes: 'kitl-link',
                        formatter:  this.dateFormatter,
                        search: false,
                        index: 'prodDt'
                    }, {
                        label: _.i18n('kitl.expDt'),
                        name: 'expDt',
                        classes: 'kitl-link',
                        formatter:  this.dateFormatter,
                        search: false,
                        index: 'expDt'
                    }, {
                        label: _.i18n('kitl.st'),
                        name: 'st',
                        classes: 'kitl-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'st'
                    }
                ],

                onSelectRow: function(rowId, bool, e) {                    
                    if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
                        if (e.type === 'click') {
                            App.navigate('admin/kitl' + '/' + rowId + '/detail', {trigger: true});
                        }
                    }
                }
            };

            if (!this.options.data.model.get('refKitSecId')) {
                options.colModel.push({
                    label: ' ',
                    name: 'delete',
                    search: false,
                    classes: 'delete-kitl-link delete-link',
                    formatter: this.deleteIconFormatter,
                    width: 20
                });
            }

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        }
    });

    module.exports = KitlListView;
});
