/**
 * Created by Matteo on 11/07/2015.
 */
define('caccountShowView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'imageUtils',
  'template!caccountViewTpl',
  'cAccountTabs',
  'rolesMixin'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  Utils,
  viewTpl,
  cAccountTabs,
  RolesMixin
) {
  'use strict';

  var CaccountDetailsView =  Marionette.LayoutView.extend({
    template: viewTpl,

    triggers: {
      'click @ui.viewMore': 'onViewMore'
    },

    ui: {
      tab: '.caccount-tab',
      tabPane: '.tab-pane',
      logo: '.js-logo-holder',
      name: '.p-name',
      code: '.p-code',
      phone: '.p-phone',
      email: '.p-email',
      switch_: '.js-switch-caccount'
    },

    events: {
      'click @ui.tab': 'onTabClick',
      'click @ui.switch_': 'onBtnSwitchClick'
    },

    regions: {
      caccountProfileTab: '#profile-tab',
      caccountDetailsTab: '#detail-tab',
      caccountAdministratorsTab: '#administrator-tab',
      caccountCreditsTab: '#credits-tab',
      caccountAttachmentsTab: '#attachments-tab',
      caccountShareTab: '#share-tab'
    },

    serializeData: function() {
      var templateData = this.model.toJSON(),
        data = this.options.data,
        cAccountRoles = Settings.get('cAccountRoles'),
        check = data.type === 'caccounts' || data.type === 'subcaccounts',
        shareTab;

      templateData.cAccountTabs = [];
      if(this.options.data.id === 'new') {
        var profileTab = _.findWhere( cAccountTabs.myAccountTabs, {role:  RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_PROFILE});
        templateData.cAccountTabs.push(profileTab);
        return templateData;
      }
      if (data.type === 'caccount' ||  data.type === 'subcaccounts') {
        if (cAccountRoles) {
          _.each( cAccountTabs.myAccountTabs, function (tab) {
            if (cAccountRoles[tab.role] === true) {
              templateData.cAccountTabs.push(tab);
            }
          });
        } else {
          templateData.cAccountTabs =  cAccountTabs.myAccountTabs;
        }
      } else if (check) {
        if (cAccountRoles && cAccountRoles.accountsharecreator) {
          shareTab = _.findWhere( cAccountTabs.myAccountTabs, {name: data.tab.name});
          templateData.cAccountTabs.push(shareTab);
        }
      }

      templateData.switchBtn = (this.model.id !== Settings.get('currentCAccount').secId);
      return templateData;
    },

    onRender: function() {
      this._setLogo(this.model.get('logo'), this.model.get('code'));

      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.caccount-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onTabClick: function(e) {
      var $target = $(e.currentTarget),
        tab = {
          route: $target.data('route'),
          role: $target.data('role'),
          name: $target.data('name')
        };
      this.ui.tab.removeClass('active');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

      this.triggerMethod('click:tab', tab);
    },

    onBtnSwitchClick: function() {
      App.trigger('switchCaccount', this.model.toJSON());
    },

    onHeaderChange: function(caccount) {
      this.ui.name.text(caccount.name);
      this.ui.code.text(caccount.code);
      this.ui.phone.text(caccount.phone);
      this.ui.email.text(caccount.contactEmail);
      this._setLogo(caccount.logo, caccount.code);
    },

    _setLogo: function(logo, code) {
      var logoUrl = Utils.getLogo(logo, code, {
        action: 'res',
        params: 't'
      });

      if(logoUrl) {
        this.ui.logo.css('background-image', 'url(' + logoUrl + ')');
      } else {
        this.ui.logo.addClass('default-upload-image');
      }
    }

  });

  module.exports = CaccountDetailsView;
});
