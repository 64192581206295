/**
 * Created by OBL on 14/01/2016.
 */
define('smpResListTabController',[
    'module',
    'underscore',
    'app',
    'smpResListTabView',
    'bootbox',
    'smpResCreateEditLayoutView',
    'settings',
    'entities/smpres'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    CreateEditLayoutView
    ) {
    'use strict';

    var SmpResListController = {
        showSmpResList: function(data) {
            this.data = data;
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'smpres:delete', _.bind(this.onDelete, this));
            view.listenTo(view, 'smpres:edit', _.bind(this.onEdit, this));
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('smpResListTab').show(view);
        },

        showSmpResAdd: function(sampleSecId) {
            this.createEditView = new CreateEditLayoutView({
                smpresModel: App.request('smpres:model', {
                    refSample: {
                        secId: sampleSecId
                    }
                }),
                newItem: true
            });
            this.createEditView.show({
                title: _.i18n('smpres.new')
            });
        },

        showSmpResEdit: function(sampleSecId, smpResSecId) {
            var model = App.request('smpres:model', {
                refSample: {
                    secId: sampleSecId
                },
                secId: smpResSecId
            });
            model.fetch().done(function() {
                this.createEditView = new CreateEditLayoutView({
                    smpresModel: model,
                    newItem: false
                });
                this.createEditView.show({
                    title: _.i18n('smpres.edit')
                });
            });
        },

        onGridLoaded: function() {
            var data = {
                url: App.request('smpres:get-collection-url', this.data.model.get('secId')),
                rowCountUrl: App.request('smpres:get-count-url', this.data.model.get('secId'))
            };

            this.view.displayGrid(data);
        },

        onEdit: function(sampleSecId, smpResSecId){
            App.navigate('samples/' + sampleSecId + '/smpres/' + smpResSecId + '/edit', {trigger: true});
        },

        onDelete: function(sampleSecId, secId){
            bootbox.confirm(_.i18n('smpres.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('smpres:delete', sampleSecId, secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = SmpResListController;
});
