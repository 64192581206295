define('entities/anaassays',[
  'app',
  'backbone',
  'settings',
  'jquery'
], function(
  App,
  Backbone,
  Settings,
  $
) {
  'use strict';

  var AnaAssay = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/assays/undefined/anaassays/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      nbRepetition: 0,
      comment: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (assaySecId) {
      return Settings.url('compuzz', 'v2/assays/' + assaySecId + '/anaassays');
    },

    save: function() {
      this.url = this.postUrl(this.get('refAssay').secId);
      return Backbone.Model.prototype.save.call(this);
    }

  });

  var AnaAssayCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/assays/');
    },
    model: AnaAssay
  });

  var API = {

    getAnaAssayCollectionUrl: function(assaySecId) {
      new AnaAssayCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/assays/' + assaySecId + '/anaassays', params);
    },

    getAnaAssayCountUrl: function(assaySecId) {
      var params = '';
      return 'v2/assays/'+assaySecId+'/anaassays/rowCount' + params;
    },

    getLmbAnaAnaAssayCollectionUrl: function(lmbAnaSecId) {
      new AnaAssayCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/lmbanas/' + lmbAnaSecId + '/anaassays', params);
    },

    getLmbAnaAnaAssayCountUrl: function(lmbAnaSecId) {
      var params = '';
      return 'v2/lmbanas/'+lmbAnaSecId+'/anaassays/rowCount' + params;
    },

    deleteAnaAssay: function(assaySecId, secId){
      var url = Settings.url('compuzz', 'v2/assays/'+assaySecId+'/anaassays/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('anaassay:get-collection-url', function(assaySecId) {
      return API.getAnaAssayCollectionUrl(assaySecId);
  });

  App.reqres.setHandler('anaassay:get-count-url', function(assaySecId) {
    return API.getAnaAssayCountUrl(assaySecId);
  });

  App.reqres.setHandler('anaassay:get-lmbana-collection-url', function(assaySecId) {
    return API.getLmbAnaAnaAssayCollectionUrl(assaySecId);
  });

  App.reqres.setHandler('anaassay:get-lmbana-count-url', function(assaySecId) {
    return API.getLmbAnaAnaAssayCountUrl(assaySecId);
  });

  App.reqres.setHandler('anaassay:model', function(itemJSON) {
    return new AnaAssay(itemJSON);
  });

  App.reqres.setHandler('anaassay:delete', function(assaySecId, secId) {
    return API.deleteAnaAssay(assaySecId, secId);
  });
});
