define('adminKitpDetailsView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'template!adminKitpDetailsTpl',
  'adminKitpTabs',
  'rolesMixin',
  'backToListBehavior'
], function (
  module,
  Marionette,
  _,
  Backbone,
  App,
  Settings,
  imageUtils,
  viewTpl,
  kitpsTabs,
  RolesMixin,
  BackToListBehavior
) {
  'use strict';

  var KitpsDetailsView =  Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      tab: '.kitp-tab',
      tabPane: '.tab-pane',
      logo: '.js-logo-holder',
      name: '.p-name',
      code: '.p-code'
    },

    events: {
        'click @ui.tab': 'onTabClick'
    },

    regions: {
      kitpDetailTab: '#detail-tab',
      settingListTab: '#setting-tab'
    },

    behaviors: function() {
      var redirectUrl = 'admin/assays/'+this.options.model.get('refAssay').secId+'/kitp';
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: redirectUrl
        }
      };
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.kitpsTabs = kitpsTabs;
      templateData.compulogoCollection = this.model.get('compulogoCollection');
      return templateData;
    },

    onRender: function() {
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.kitp-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onShow: function() {
      $.material.init();
    },

    onTabClick: function(e) {
        var $target = $(e.currentTarget),
            tab = {
                route: $target.data('route'),
                role: $target.data('role'),
                name: $target.data('name')
            };

        this.ui.tab.removeClass('active');
        this.ui.tabPane.removeClass('active');
        $target.addClass('active');
        this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

        this.triggerMethod('click:tab', tab);
    }

  });

  module.exports = KitpsDetailsView;
});
