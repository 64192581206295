
define('template!caccountAttachsNewItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="main text-center">\n  <div class="image-holder js-image-holder pgallery_item">\n    <input type="file" class="filestyle js-upload-attachment hidden" multiple="multiple" id="upload-attachment" accept="image/*,application/pdf,application/ai,application/eps"/>\n    <div class="bootstrap-filestyle">\n      <label for="upload-attachment" class="">\n        <i class="mdi-action-perm-media icon"></i>\n        <i class="mdi-content-add abs-icon-addon"></i>\n      </label>\n    </div>\n  </div>\n</div>';

}
return __p
};});

