
define('template!wellEditSmpIdTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      ';
 _.each(models, function(model, index) { ;
__p += '\n      ';
 var modelJson = model.toJSON(); ;
__p += '\n      <div class="form-group">\n        <label class="col-xs-2 control-label">' +
((__t = ( modelJson.pos )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 form-control-wrapper">\n          <select class="form-control js-info-input row" data-field-name="smptype" value="" data-secid="' +
((__t = ( modelJson.secId )) == null ? '' : __t) +
'">\n            <option value="" ';
  if (!modelJson.smptype) { ;
__p += ' selected ';
 } ;
__p += ' ></option>\n            <option value="E" ';
  if (modelJson.smptype === 'E') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.e') )) == null ? '' : __t) +
'</option>\n            <option value="C" ';
  if (modelJson.smptype === 'C') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.c') )) == null ? '' : __t) +
'</option>\n            <option value="U" ';
  if (modelJson.smptype === 'U') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.u') )) == null ? '' : __t) +
'</option>\n          </select>\n          <div class="floating-label">' +
((__t = ( _.i18n('well.smptype') )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="col-xs-8 div-input-smpid" style="' +
((__t = ( modelJson.smptype === 'C'?'display: none;':'' )) == null ? '' : __t) +
'" data-secid="' +
((__t = ( modelJson.secId )) == null ? '' : __t) +
'">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="smpid"\n                 data-secid="' +
((__t = ( modelJson.secId )) == null ? '' : __t) +
'" maxlength="13"\n                 placeholder="' +
((__t = ( _.i18n('well.smpid') )) == null ? '' : __t) +
'" value="' +
((__t = ( modelJson.smpid )) == null ? '' : __t) +
'">\n        </div>\n        <div class="col-xs-8 div-cmb-smpid" style="' +
((__t = ( modelJson.smptype !== 'C'?'display: none;':'' )) == null ? '' : __t) +
'" data-secid="' +
((__t = ( modelJson.secId )) == null ? '' : __t) +
'">\n          <div class="form-group" data-field-name="smpid">\n            <div class="col-xs-12">\n              <div class="cmb-smpid cmb-smpid-' +
((__t = ( modelJson.secId )) == null ? '' : __t) +
'" data-secid="' +
((__t = ( modelJson.secId )) == null ? '' : __t) +
'"></div>\n            </div>\n          </div>\n        </div>\n      </div>\n      ';
 }); ;
__p += '\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

