define('entities/runats',[
  'app',
  'backbone',
  'settings'
], function(
  App,
  Backbone,
  Settings
) {
  'use strict';

  var RunAt = Backbone.Model.extend({
    fetch: function() {
      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      'baseline':  0,
      'threshold': 0,
      'override': false
    },
    idAttribute: 'secId',

    postUrl: function (runSecId) {
      return Settings.url('compuzz', 'v2/runs/' + runSecId + '/runats');
    },

    save: function() {
      this.url = this.postUrl('undefined');
      return Backbone.Model.prototype.save.call(this);
    }
  });

  App.reqres.setHandler('runat:model', function(runJSON) {
    return new RunAt(runJSON);
  });
});
