
define('template!customerTemplatesPopupVariationsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="progress variant-upload-progress">\n  <div class="indeterminate" style="width: 70%"></div>\n</div>\n<div class="js-action-buttons-region orders-action-button variant-copy-paste f-r"></div>\n<div class="js-templates-region caccount-details-container variation-popup row"></div>\n<div class="variant-blinder">\n\t\n</div>';

}
return __p
};});

