/**
 * Created by RKL on 13/08/2015.
 */
define('runAssaysTargetsTabView',[
  'module',
  'app',
  'underscore',
  'marionette',
  'template!runAssaysTargetsTabTpl',
  'createEditRunAtLayoutView',
  'entities/wells',
  'entities/runats'
], function (
  module,
  App,
  _,
  Marionette,
  viewTpl,
  CreateEditRunAtLayoutView
) {
  'use strict';

  var RunAssaysTargetsView = Marionette.ItemView.extend({
    template: viewTpl,

    events: {
      'click .edit-run-at': 'onEditRunAt'
    },

    onEditRunAt: function(e) {
      var $target = $(e.currentTarget),
          runAtSecId = $target.data('runat-secid'),
          modelRunAt = _.findWhere(this.model.get('runAts'), { secId: runAtSecId });

      this.createEditRunAtView = new CreateEditRunAtLayoutView({
        model: App.request('runat:model', modelRunAt),
        modelRun: this.model,
        newItem: false
      });
      this.createEditRunAtView.show({
        title: _.i18n('runat.edit')
      });
    },

    refreshRunAt: function(runAt) {
      this.$el.find('.run-at-baseline[data-runat-secid="'+runAt.secId+'"]').text(runAt.baseline);
      this.$el.find('.run-at-threshold[data-runat-secid="'+runAt.secId+'"]').text(runAt.threshold);
    },

    serializeData: function () {
      var templateData = {};
      this.data = templateData.data = this.model.getAssaysTargets();
      templateData.run = this.model.toJSON();
      templateData.displayBT = this.model.get('refKitProt')?this.model.get('refKitProt').resGraph !== 'M':true;
      return templateData;
    }
  });

  module.exports = RunAssaysTargetsView;
});
