/**
 * Created by BFR on 02/05/2017.
 */
define('adminLisSmpMatController',[
    'module',
    'underscore',
    'app',
    'adminLisSmpMatView',
    'bootbox',
    'lisSmpMatCreateEditLayoutView',
    'settings',
    'entities/lissmpmats'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    CreateEditLayoutView
    ) {
    'use strict';

    var SuperAdminLisSmpMatListController = {
        showLisSmpMatList: function(data) {
            this.data = data;
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'lissmpmat:delete', _.bind(this.onDelete, this));
            view.listenTo(view, 'lissmpmat:edit', _.bind(this.onEdit, this));
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('lisSmpMatListTab').show(view);
        },

        showLisSmpMatAdd: function(lisSecId) {
            this.showLisSmpMatAddEdit(App.request('lissmpmat:model', {
                refLis: {
                    secId: lisSecId
                }
            }), true);
        },

        showLisSmpMatAddEdit: function(model, newItem) {
            this.createEditView = new CreateEditLayoutView({
                lisSmpMatModel: model,
                newItem: newItem
            });
            this.createEditView.show({
                title: _.i18n('lissmpmat.new')
            });
        },

        showLisSmpMatEdit: function(lisSecId, lisSmpMatSecId) {
            var that = this;
            var model = App.request('lissmpmat:model', {
                refLis: {
                    secId: lisSecId
                },
                secId: lisSmpMatSecId
            });
            model.fetch().done(function() {
                that.showLisSmpMatAddEdit(model, false);
            });
        },

        onEdit: function(lisSecId, lisSmpMatSecId){
            App.navigate('admin/liss/' + lisSecId + '/lissmpmat/' + lisSmpMatSecId + '/edit', {trigger: true});
        },

        onGridLoaded: function() {
            var lisSecId = this.data.model.get('refLisSecId')?
                                this.data.model.get('refLisSecId'):this.data.model.get('secId');
            var data = {
                url: App.request('lissmpmat:get-collection-url', lisSecId),
                rowCountUrl: App.request('lissmpmat:get-count-url', lisSecId)
            };

            this.view.displayGrid(data);
        },

        onDelete: function(lisSecId, secId){
            bootbox.confirm(_.i18n('lissmpmat.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('lissmpmat:delete', lisSecId, secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = SuperAdminLisSmpMatListController;
});
