
define('template!headerPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="user-information-container">\n\t<div class="container-fluid">\n\t\t<div class="row">\n\t\t\t<div class="loading-block js-loading-block">\n\t\t\t\t<span class="loading-message js-loading-message label"></span>\n\t\t\t</div>\n\t\t</div>\n\t\t<form class="form-horizontal col-xs-12" role="form">\n\t\t\t<div class="form-group">\n\t\t\t\t<label class="col-xs-3 control-label">' +
((__t = ( _.i18n("common.avatar") )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="avatar-change-container col-xs-9">\n\t\t\t\t\t<div class="logo-user-edit-block">\n\t\t\t\t\t\t<div class="user-avatar-img" style="';
 if (fileUrl) { ;
__p += 'background-image: url(' +
((__t = ( fileUrl )) == null ? '' : __t) +
');';
 } ;
__p += '"></div>\n\t\t\t\t\t\t<label for="uploadUserAvatar" class="upload-title"><i class="mdi-file-file-upload"></i></label>\n\t\t\t\t\t\t<input id="uploadUserAvatar" data-key="file" multiple type="file" name="filename" class="hidden upload-user-avatar" accept="application/pdf, application/postscript, image/*"/>\n\t\t\t\t\t\t<a class="change-password js-change-password pull-right">' +
((__t = ( _.i18n("login.changePassword") )) == null ? '' : __t) +
'</a>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="logonId">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("common.username") )) == null ? '' : __t) +
'</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\ttype="logonId"\n\t\t\t\t\t\tclass="form-control logonId js-form-input"\n\t\t\t\t\t\tname="logonId"\n\t\t\t\t\t\tdata-field-name="logonId"\n\t\t\t\t\t\tplaceholder="' +
((__t = ( logonId )) == null ? '' : __t) +
'"\n\t\t\t\t\t\tvalue="' +
((__t = ( logonId )) == null ? '' : __t) +
'"\n\t\t\t\t\t\tdisabled>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="firstName">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("login.firstName") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\ttype="logonId"\n\t\t\t\t\t\tclass="form-control firstName js-form-input"\n\t\t\t\t\t\tname="firstName"\n\t\t\t\t\t\tdata-field-name="firstName"\n\t\t\t\t\t\tplaceholder="' +
((__t = ( firstName )) == null ? '' : __t) +
'"\n\t\t\t\t\t\tvalue="' +
((__t = ( firstName )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="form-group" data-field-name="lastName">\n\t\t\t\t<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("login.lastName") )) == null ? '' : __t) +
':</label>\n\t\t\t\t<div class="col-xs-9">\n\t\t\t\t\t<input\n\t\t\t\t\t\ttype="logonId"\n\t\t\t\t\t\tclass="form-control lastName js-form-input"\n\t\t\t\t\t\tname="lastName"\n\t\t\t\t\t\tdata-field-name="lastName"\n\t\t\t\t\t\tplaceholder="' +
((__t = ( lastName )) == null ? '' : __t) +
'"\n\t\t\t\t\t\tvalue="' +
((__t = ( lastName )) == null ? '' : __t) +
'">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</form>\n\t</div>\n\n\t<!-- SAVE / CANCEL -->\n\t<div class="buttons-container">\n\t\t<div class="form-group pull-right p-r-20">\n\t\t\t<a class="btn btn btn-s-md-save btn-primary">' +
((__t = ( _.i18n("common.save") )) == null ? '' : __t) +
'</a>\n\t\t\t<a class="btn btn btn-s-md-cancel btn-primary" data-dismiss="modal">' +
((__t = ( _.i18n("common.cancel") )) == null ? '' : __t) +
'</a>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

