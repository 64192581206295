
define('template!runTemplateDetailsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content info-page">\n    <div class="container-fluid">\n        <div class="col-md-5 left-area col-sm-12 well hidden-print">\n            <div class="well-table">\n\n            </div>\n        </div>\n        <div class="col-md-1 hidden-print" />\n        <div class="row col-md-6 col-sm-12">\n            <div id="right-graph" class="right-area col-sm-12 well well-left-border">\n                <div id="header-region"></div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

