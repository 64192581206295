/**
 * Created by bfr on 30/01/2017.
 */
define('runsMain',[
    'module',
    'logger',
    'runsMainRouter'
], function(
    module,
    Logger
) {
    'use strict';

    var Runs = {
        start: function() {
            Logger.log(module, 'starting RunsModule');
        },

        stop: function() {
            Logger.log(module, 'stopping RunsModule');
        }
    };

    module.exports = Runs;
});
