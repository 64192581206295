define('entities/mbanares',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var MbAnaRes = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/mbanares/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      code: '',
      txt: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (mbAnaResGrSecId) {
      return Settings.url('compuzz', 'v2/mbanaresgrs/' + mbAnaResGrSecId + '/mbanares');
    },

    save: function() {
      this.url = this.postUrl(this.get('mbAnaResGrSecId'));
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var MbAnaResCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/mbanaresgrs/');
    },
    model: MbAnaRes
  });

  var API = {

    getMbAnaResCollectionUrl: function(mbAnaResGrSecId) {
      new MbAnaResCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/mbanaresgrs/' + mbAnaResGrSecId + '/mbanares', params);
    },

    getAllMbAnaRes: function(mbAnaResGrSecId, params) {
      var url = Settings.url('compuzz', 'v2/mbanaresgrs' + mbAnaResGrSecId + '/mbanares', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getMbAnaResCountUrl: function(mbAnaResGrSecId) {
      var params = '';
      return 'v2/mbanaresgrs/'+mbAnaResGrSecId+'/mbanares/rowCount' + params;
    },

    deleteMbAnaRes: function(mbAnaResGrSecId, secId){
      var url = Settings.url('compuzz', 'v2/mbanaresgrs/'+mbAnaResGrSecId+'/mbanares/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('mbanares:get-collection-url', function(mbAnaResGrSecId) {
      return API.getMbAnaResCollectionUrl(mbAnaResGrSecId);
  });

  App.reqres.setHandler('mbanares:get-collection-to-page', function(mbAnaResGrSecId, params) {
      return API.getAllMbAnaRes(mbAnaResGrSecId, params);
  });

  App.reqres.setHandler('mbanares:get-count-url', function(mbAnaResGrSecId) {
    return API.getMbAnaResCountUrl(mbAnaResGrSecId);
  });

  App.reqres.setHandler('mbanares:model', function(itemJSON) {
    return new MbAnaRes(itemJSON);
  });

  App.reqres.setHandler('mbanares:delete', function(mbAnaResGrSecId, secId) {
    return API.deleteMbAnaRes(mbAnaResGrSecId, secId);
  });
});
