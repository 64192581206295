/**
 * Created by RKL on 13/08/2015.
 */
define('mbSmpMatDetailView',[
  'module',
  'mbSmpMatCreateEditLayoutView',
  'underscore',
  'marionette',
  'template!mbSmpMatDetailTabTpl',
  'colorUtils'
], function (
  module,
  CreateEditLayoutView,
  _,
  Marionette,
  viewTpl,
  ColorUtils
) {
  'use strict';

  var MbSmpMatDetailView = Marionette.ItemView.extend({
    template: viewTpl,

    modelEvents: {
      change: 'render'
    },

    events: {
      'click .js-edit': '_onEdit'
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.viewLess = this.model.get('secId');
      templateData.colorHexa = ColorUtils
          .decimalToHexa(this.model.get('matColor'))
          .split('#')[1];
      return templateData;
    },

    _onEdit: function() {
      var createEditView = new CreateEditLayoutView({
        mbsmpmatModel: this.model
      });
      createEditView.show({
        title: _.i18n('customers.edit')
      });
    }

  });

  module.exports = MbSmpMatDetailView;
});
