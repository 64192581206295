/**
 * Created by RKL on 10/09/2015.
 */
define('dialogFormView',[
  'module',
  'underscore',
  'dialogView',
  'formView',
  'dialogMixin',
  'settings',
  'moment'
], function(
  module,
  _,
  DialogView,
  FormView,
  DialogMixin,
  Settings,
  moment
) {
  'use strict';

  var DialogFormView = FormView.extend(
    _.extend({
      _getAutocompleteOptions: function(modelProperty, valueProperty, queryProperty, urlPath, placeHolderLabel,
                                        valueKey, callback) {
        var object = this.model?this.model.get(modelProperty):null;
        if (typeof object === 'string') {
          object = {};
          object[valueProperty] = this.model.get(modelProperty);
        }
        return {
          type: 'remote',
          remote: Settings.url('compuzz', urlPath),
          valueKey: valueKey,
          apiKey: 'secId',
          placeholder: _.i18n(placeHolderLabel),
          value: object?object[valueProperty]:null,
          name: modelProperty,
          onPressKey: true,
          lazyLoad: true,
          keys: {
            query: queryProperty,
            limit: 'pageSize'
          },
          callback: _.bind(callback?callback:this.onChangeAutoComplete, this)
        };
      },

      onChangeAutoComplete: function(fieldName, model) {
        if (model) {
          model.unset('autocompleteValue', {silent: true});
        }
        this.onChange(fieldName, model);
        this.triggerMethod('enable:cancel:confirm');
      },

      initDatePicker: function(element, fieldName) {
        var that = this, format = 'dd-mm-yy';
        element.datepicker({
          autoSize: true,
          dateFormat: format,
          dayNamesMin: moment.weekdaysShort(),
          firstDay: 1,
          changeYear: true,
          prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
          nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>',
          beforeShow: function() {
            $('#ui-datepicker-div')
                .removeClass('tt-datepicker');
            $('#ui-datepicker-div')
                .addClass('tt-datepicker');
          },
          onSelect: function() {
            that.model.set(fieldName, parseInt(moment(element.val(), 'DD-MM-YYYY').format('x'), 10));
          }
        });
      }
    }, DialogMixin)
  );

  module.exports = DialogFormView;
});
