/**
 * Created by GSP on 15/02/2016.
 */
define('dropdownItemView',['module',
        'jquery',
        'underscore',
        'marionette',
        'template!dropdownItemTpl'],
  function(module,
           $,
           _,
           Marionette,
           dropDownItemTpl) {
  'use strict';
  var DropDownItem = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'dropdown__item',
    template: dropDownItemTpl,

    events: {
      'click .dropdown__item-link' : 'onItemSelected'
    },

    serializeData: function() {
       return {
         label : this.model.get(this.options.modelDisplayProperty)
       };
    },

    onItemSelected: function(e) {
      e.preventDefault();
      $( e.currentTarget ).parents('.dropdown__list' )
        .find('.dropdown__item-link--active' )
        .removeClass('dropdown__item-link--active');
      $( e.currentTarget ).addClass('dropdown__item-link--active');
      this.trigger('item:selected');
    }

  });

  return DropDownItem;
});
