/**
 * Created by OLD on 30/10/2015.
 */
define('adminMain',[
  'module',
  'logger',
  'adminMainRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var Admin = {
    start: function() {
      Logger.log(module, 'starting adminModule');
    },

    stop: function() {
      Logger.log(module, 'stopping adminModule');
    }
  };

  module.exports = Admin;
});
