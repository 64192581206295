/**
 * Created by RKL on 18/08/2015.
 */
define('groupsItemView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'template!groupsTabTpl',
  'entities/caccounts/templates'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  viewTpl
) {
  'use strict';

  var GroupsItemView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      count: '.templates-count',
      templateGroupItem: '.template-group-item',
      edit: '.js-edit-icon',
      remove: '.js-remove-icon'
    },

    events: {
      'click @ui.templateGroupItem': 'showTemplateGroup',
      'click @ui.edit': 'onEdit',
      'click @ui.remove': 'onRemove'
    },

    serializeData: function() {
      var tplData = this.model.toJSON();

      tplData.active = this.model.get('secId') === this.options.data.secId;
      tplData.count = this.model.count ? this.model.count : 0;

      return tplData;
    },

    onRender: function() {
      var data = this.options.data,
        left, count;

      if (this.model.count === undefined) {
        App.trigger(
          'folder-groups:get-count',
          this.model.get('secId'),
          _.bind(this.displayCount, this)
        );
      } else {
        count = this.model.count;
        left = count < 10 ? 10 : count < 100 ? 7 : 3;
        this.ui.count.css('left', left + 'px');
      }

      if (data.type === 'customer') {
        if (data.tab === 'designelements' || data.tab === 'datasets') {
          if (this.model.get('refCAccountJson')) {
            this.ui.templateGroupItem.addClass('has-edits');
          }
        }
      }
    },

    displayCount: function(count) {
      var left;

      if (typeof this.ui.count === 'object') {
        this.model.count = count;
        this.ui.count.text(count);
        left = count < 10 ? 10 : count < 100 ? 7 : 3;
        this.ui.count.css('left', left + 'px');
      }
    },

    showTemplateGroup: function() {
      App.trigger('folder-groups:show-group', this.model);
    },

    onEdit: function(e) {
      e.stopPropagation();
      App.trigger('folder-groups:edit-group', this.model, this);
    },

    onRemove: function(e) {
      e.stopPropagation();
      App.trigger('folder-groups:remove-group', this.model);
    }

  });

  module.exports = GroupsItemView;
});
