/**
 * Created by RKL on 07/09/2015.
 */
define('sampleDetailsView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'template!sampleDetailsTpl',
  'samplesTabs',
  'rolesMixin',
  'backToListBehavior'
], function (
  module,
  Marionette,
  _,
  Backbone,
  App,
  Settings,
  imageUtils,
  viewTpl,
  samplesTabs,
  RolesMixin,
  BackToListBehavior
) {
  'use strict';

  var SamplesDetailsView =  Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      tab: '.sample-tab',
      tabPane: '.tab-pane',
      sampleId: '.p-sampleId',
      orderId: '.p-orderId'
    },

    events: {
      'click @ui.tab': 'onTabClick',
      'change @ui.collectionToggle': 'onCollectionToggle'
    },

    regions: {
      sampleDetailTab: '#detail-tab',
      smpResListTab: '#smpres-tab'
    },

    behaviors: {
      BackToList: {
        behaviorClass: BackToListBehavior,
        redirectUrl: 'samples'
      }
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.samplesTabs = samplesTabs;
      templateData.compulogoCollection = this.model.get('compulogoCollection');
      return templateData;
    },

    onRender: function() {
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.sample-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onShow: function() {
      $.material.init();
    },

    onTabClick: function(e) {
      var $target = $(e.currentTarget),
        tab = {
          route: $target.data('route'),
          role: $target.data('role'),
          name: $target.data('name')
        };

      this.ui.tab.removeClass('active');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

      this.triggerMethod('click:tab', tab);
    },

    onHeaderChange: function(sample) {
      this.ui.sampleId.text(sample.sampleId);
      this.ui.orderId.text(sample.orderId);
    }
  });

  module.exports = SamplesDetailsView;
});
