
define('template!wellResultTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page">\n    <div class="row">\n      <label class="print-results pull-right" style="height: 30px;"><i class="mdi-action-print clickable hidden-print"></i></label>\n    </div>\n    <div class="js-kitl-table-details-for-printing-container">\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( model.name ? model.name : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('runList.runModDate') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( creatDate ? creatDate : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('runList.refRespUser') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( model.refCreatUser.email ? model.refCreatUser.email : '-'  )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>      \n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('run.refKitProt') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( model.refKitProt ? model.refKitProt.code : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('run.refMbAModel') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( model.refMbAModel ? model.refMbAModel.code : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('run.refLmbAnalyzer') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( model.refLmbAnalyzer ? model.refLmbAnalyzer.code : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('run.plateId') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( model.plateId ? model.plateId : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( model.comment ? model.comment : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>      \n    </div>\n    <div class="kitl-table">\n      <!--<div class="radio radio-primary hidden-print">\n        <label>\n          <input type="radio" class="sort-well-radio sort-well-radio-row" name="sort-well-radio" value="sortRow" ' +
((__t = ( sortOn!=='column'?'checked':'' )) == null ? '' : __t) +
' />\n          ' +
((__t = ( _.i18n('well.sortby.row') )) == null ? '' : __t) +
'\n        </label>\n      </div>\n      <div class="radio radio-primary hidden-print">\n        <label>\n          <input type="radio" class="sort-well-radio sort-well-radio-column" name="sort-well-radio" value="sortColumn" ' +
((__t = ( sortOn==='column'?'checked':'' )) == null ? '' : __t) +
' />\n          ' +
((__t = ( _.i18n('well.sortby.column') )) == null ? '' : __t) +
'\n        </label>\n      </div>-->\n      <table id="jq-grid-list" class="resultsTable" role="grid"></table>\n    </div>\n  </div>\n</div>';

}
return __p
};});

