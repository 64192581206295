/**
 * Created by RKL on 19/08/2015.
 */
define('customerProdTemplatesPopupView',[
  'module',
  'app',
  'underscore',
  'settings',
  'template!customerProdTemplatesPopupAddNewTpl',
  'template!customerProdTemplatesProductTpl',
  'autocompleteView',
  'dialogFormView'
], function (
  module,
  App,
  _,
  Settings,
  viewTpl,
  productTemplate,
  AutocompleteView,
  DialogFormView
) {
  'use strict';

  var CustomersProducTemplatesTabPopupViewAddNew = DialogFormView.extend({
    template: viewTpl,

    ui: {
      fromEmpty: '.js-from-empty',
      fromUpload: '.js-from-upload'
    },

    events: {
      'change @ui.fromUpload': 'onUploadImage',
      'click @ui.fromEmpty': 'onFromEmpty'
    },

    regions: {
      productsAutocomplete: '.select-product'
    },

    onShow: function() {
      var productsView;

      App.regions.addRegions(this.regions);

      
      productsView = new AutocompleteView(
        this.getAutocompleteOptions()
      );

      App.regions.getRegion('productsAutocomplete').show(productsView);
    },

    getAutocompleteOptions: function() {
      var params = {
          first: 0,
          pageSize: 10 
        },
        url = Settings.url('compuzz', 'products.json/advancedSearch', params);

      return {
        childTemplate: productTemplate,
        type: 'remote',
        remote: url,
        valueKey: 'code',
        apiKey: 'id',
        placeholder: 'Select Product',
        value: null,
        name: 'product',
        keys: {
          apiKey: 'apiKey',
          query: 'globalSearchField',
          limit: 'pageSize'
        },
        callback: _.bind(this.onSelectProduct, this)
      };
    },

    onSelectProduct: function(field, model) {
      this.hide();
      if (model) {
        App.trigger('producttemplate:create-new', model.get('code'));
      }
    }
  });

  module.exports = CustomersProducTemplatesTabPopupViewAddNew;
});
