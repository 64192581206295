define('customerCommonPopupFromTemplateView',[
  'module',
  'marionette',
  'template!customerCommonPopupFromTemplateLayoutTpl',
  'template!customerCommonPopupFromTemplateFilterTpl',
  'template!customerCommonPopupFromTemplateItemTpl',
  'app',
  'dialogView',
  'autocompleteView',
  'customerTemplatesMetaInfoView',
  'underscore',
  'customerTemplatesTabGeometryData',
  'backbone',
  'emptyView',
  'noItemsView',
  'savingBehavior',
  'entities/caccounts/templates'
], function(
  module,
  Marionette,
  fromTemplateLayoutTpl,
  fromTemplateFiltersTpl,
  fromTemplateItemTpl,
  App,
  DialogView,
  AutocompleteView,
  MetaInfoView,
  _,
  geometryData,
  Backbone,
  EmptyView,
  NoItemsView,
  SavingBehavior
) {
  'use strict';

  var FromTemplatePopupFiltersView = Marionette.LayoutView.extend({
    template: fromTemplateFiltersTpl,

    regions: {
      templateGroups: '.js-template-groups-region',
      standardSizes: '.js-standard-sizes-region'
    },

    ui: {
      geometryCheckbox: '.geometry-radio-b>input'
    },

    events: {
      'click @ui.geometryCheckbox': '_onChangeGeometry'
    },

    onShow: function() {
      var standardSizes = this.options.standardSizes || this.options.groupModel.get('standardSizes');
      if(!this.options.isGroupHidden) {
        this.autocompleteView = new AutocompleteView(
          this._getTplGroupsAutocompleteOptions()
        );
        this.getRegion('templateGroups').show(this.autocompleteView);
      }

      this._showStandardSizesAutocomplete(standardSizes);
    },

    onShown: function() {
      if (this.autocompleteView) {
        this.autocompleteView.triggerMethod('shown');
      }
    },


    serializeData: function() {
      return {
        isGroupHidden: this.options.isGroupHidden
      };
    },

    _showStandardSizesAutocomplete: function(standardSizes) {
      this.getRegion('standardSizes').show(new AutocompleteView(
        this._getStdSizesAutocompleteOptions(standardSizes)
      ));
    },

    _getTplGroupsAutocompleteOptions: function() {
      return {
        data: this.options.groupModel.collection.toJSON(),
        valueKey: 'name',
        apiKey: 'secId',
        callback: _.bind(this._onChangeTemplateGroup, this),
        value: this.options.groupModel.get('name'),
        placeholder: _.i18n('common.templateGroup'),
        inputType: 'adminDropdown'
      };
    },

    _onChangeTemplateGroup: function(a, model) {
      if(model) {
        this.model.set('templateGroupSecId', model.get('secId'));
        this._showStandardSizesAutocomplete(model.get('standardSizes'));
      }

    },

    _getStdSizesAutocompleteOptions: function(standardSizes) {
      return {
        data: this._filterSizesAutocompleteOptions(standardSizes),
        valueKey: 'searchValue',
        apiKey: 'secId',
        callback: _.bind(this._onChangeStdSizes, this),
        value:  _.bind(function(){
            if(this.options.templateParent){
                var size =  _.find(standardSizes, _.bind(function(size){
                    return size.code === this.options.templateParent.get('standardSizeCode');
                }, this));

                if(size){
                    return size.code + ' - ' + size.nameTranslated;
                }
            }
            return null;
        }, this)(),
        placeholder: _.i18n('common.templateGroup.standardSizes')
      };
    },

    _filterSizesAutocompleteOptions: function(standardSizes) {
      return _.map(standardSizes, function(item) {
        return {
          code: item.code,
          height: item.height,
          isAbsolute: item.isAbsolute,
          nameTranslated: item.nameTranslated,
          secId: item.secId,
          unit: item.unit,
          width: item.width,
          searchValue: item.code + ' - ' + item.nameTranslated
        };
      });
    },

    _onChangeStdSizes: function(a, model) {
      if (model) {
        this.model.set('standardSizeSecId', model.get('secId'));
      } else {
        this.model.set('standardSizeSecId', null);
      }
    },

    _onChangeGeometry: function(e) {
      var geoVal = $(e.currentTarget).val();

      this.model.set('geometry', geoVal);
    }
  });

  var LoaderView =  Marionette.ItemView.extend({
    template: _.template('Loading...'),

    tagName: 'li',

    className: 'loader display-inline-block empty-page-loader m-0'
  });

  var FromTemplatePopupTemplateView = Marionette.ItemView.extend({
    template: fromTemplateItemTpl,

    tagName: 'li',

    className: 'template-item from-template display-inline-block li-style-none clickable p-t-10 p-r-10',

    events: {
      click: '_onClick'
    },

    ui: {
      metaInfoContainer: '.template-meta-info-container'
    },

    serializeData: function() {
      return {
        templateImageUrl: this.model.getImageUrl(200, 150)
      };
    },

    onRender: function() {
      new MetaInfoView({
        el: this.ui.metaInfoContainer,
        model: this.model
      }).render();
    },

    _onClick: function() {
      var variationsNumber = this.model.get('variationsNumber');
      if (variationsNumber === 0) {
        this.trigger('save:template');
      } else {
        this.showTemplateVariations();
      }
    },

    showTemplateVariations: function() {
      var secId = this.model.get('secId'),
          newTemplate = this.model.clone();
          
      App.request('caccount:template-variations', secId).done(_.bind(function (variations) {
        newTemplate.set('variationsNumber', 0);
        newTemplate.set('refParent', {secId: secId});
        variations.add(newTemplate, {at: 0});
        this._parent.trigger('display:variations', variations);
      }, this));
    }

  });

  var FromTemplatePopupTemplateCollectionView = Marionette.CollectionView.extend({
    getChildView: function(item) {
      if(!item.get('secId')) {
        return LoaderView;
      }
      return FromTemplatePopupTemplateView;
    },

    emptyView: NoItemsView,

    tagName: 'ul',

    className: 'from-template-item-list',

    events: {
      'scroll': '_onScroll'
    },

    allCollection: undefined,
    isSaving: false,

    loadingViewModel: undefined,

    initialize: function(options) {
      this.allCollection = this.collection.clone();
      this.collection.reset(this.collection.slice(0, 6));
      this.loadingViewModel = this.collection.add({});

      this._onLazyLoadThrottled = _.throttle(_.bind(this._onLazyLoad, this), 300);
      this.listenTo(options.newModel, 'sync', this._onSaved);
    },

    onAttach: function() {
      setTimeout(_.bind(this._onLazyLoad, this), 50);
    },

    onChildviewSaveTemplate: function (childView) {
      if (!this.isSaving) {
        this.isSaving = true;
        this.options.newModel.createFromTemplate(
            {
              templateSecId: childView.model.get('secId'),
              templateGroupSecId: this.options.templateGroupSecId,
              endUserId: this.options.endUserId,
              refParent: this.options.refParent,
              fromVariations: this.options.fromVariations
            });
      }
    },

    _onSaved: function(fromVariations) {
      this.isSaving = false;
      if (this.options.fromVariations && fromVariations) {
        App.trigger('save:from:template', this.options.newModel);
      } else if (!fromVariations) {
        App.trigger('save:from:template', this.options.newModel);
      }
    },

    _onLazyLoad: function() {
      var lastIndex = this.collection.length - 1;
      if(this._checkLoaderIsVisible()) {
        this.collection.add(this.allCollection.at(lastIndex), {at: lastIndex});
        setTimeout(_.bind(this._onLazyLoad, this), 50);
      }
      if(this.allCollection.length < this.collection.length) {
        this.collection.pop();
        this.$el.off('scroll');
      }
    },

    _onLazyLoadThrottled: undefined,

    _checkLoaderIsVisible: function() {
      var scrollContainer = this.$el,
        height = scrollContainer.height() || 75,
        scrollBlock, top;
      if(!this.children.findByModel(this.loadingViewModel)) {
        return;
      }

      scrollBlock = this.children.findByModel(this.loadingViewModel).$el;
      top = scrollBlock.offset().top - scrollContainer.offset().top;

      if (top < height) {
        return true;
      }
    },

    _onScroll: function() {
      this._onLazyLoadThrottled();
    }
  });

  var FromTemplatePopupLayoutView = DialogView.extend({
    template: fromTemplateLayoutTpl,

    regions: {
      filters: '.js-filters-region',
      templates: '.js-templates-region',
      variations: '.js-templates-variants-region'
    },

    ui: {
      variationsHeader: '.templates-variations-header'
    },

    events: {
      'click @ui.variationsHeader a': '_hideVariations'
    },

    templatesCollection: undefined,

    filterModel: undefined,

    className: 'upload-from-template-popup',

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    initialize: function(options) {
      this.filterModel = this._createFilterModel();
      this.templatesCollection = options.templatesCollection;

      this.listenTo(this.options.newModel, 'sync', this.hide);
      this.listenTo(this.templatesCollection, 'request', this._showLoadingTemplates);
      this.listenTo(this.templatesCollection, 'sync', this._showTemplates);
      this.listenTo(this.filterModel, 'change', this._fetchCollection);
      this.listenTo(this.options.newModel, 'request', this._savingNewTemplate);
    },

    onShow: function() {
      this.$el.closest('.modal-content').width('700px');
      this.fromTemplatePopupFiltersView = new FromTemplatePopupFiltersView({
        model: this.filterModel,
        standardSizes: this.options.standardSizes,
        groupModel: this.options.groupModel,
        isGroupHidden: this.options.isGroupHidden,
        templateParent: this.options.templateParent
      });
      this.getRegion('filters').show(this.fromTemplatePopupFiltersView);
      this._fetchCollection();
    },

    onShown: function() {
      this.fromTemplatePopupFiltersView.triggerMethod('shown');
    },

    _savingNewTemplate: function() {
      this.triggerMethod('saving');
    },

    _createFilterModel: function() {
      return new Backbone.Model({
        standardSizeSecId: null,
        templateGroupSecId: this.options.groupModel.get('secId'),
        geometry: geometryData[0].send,
        endUserId: this.options.groupModel.get('endUserId')
      });
    },

    _showLoadingTemplates: function() {
      this.getRegion('templates').show(new EmptyView());
    },

    _showTemplates: function() {
      this.getRegion('templates').show(new FromTemplatePopupTemplateCollectionView({
        collection: this.templatesCollection,
        templateGroupSecId: this.options.groupModel.get('secId'),
        endUserId: this.options.groupModel.get('endUserId'),
        newModel: this.options.newModel,
        refParent: this.options.refParent
      }));
      this.listenTo(this.getRegion('templates').currentView, 'display:variations', _.bind(this._displayVariations, this));
    },

    _displayVariations: function(variations) {
      this.ui.variationsHeader.show();
      this.getRegion('templates').$el.hide();
      this.getRegion('variations').$el.show();
      this.getRegion('variations').show(new FromTemplatePopupTemplateCollectionView({
        collection: variations,
        templateGroupSecId: this.options.groupModel.get('secId'),
        endUserId: this.options.groupModel.get('endUserId'),
        newModel: this.options.newModel,
        refParent: this.options.refParent,
        fromVariations: true
      }));
      this.variationsDisplayed = true;
    },
    _hideVariations: function(){
      this.variationsDisplayed = false;
      this.ui.variationsHeader.hide();
      this.getRegion('variations').destroy().$el.hide();
      this.getRegion('templates').$el.show();
    },

    _fetchCollection: function() {
      var params = this.filterModel.toJSON();
      if (this.variationsDisplayed) {
        this._hideVariations();
      }
      this.templatesCollection.fetchByStdSizeGeometryTplGroupSecId(params);
    }
  });

  module.exports = FromTemplatePopupLayoutView;
});
