/**
 * Created by RKL on 07/09/2015.
 */
define('adminAssayDetailsView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'template!adminAssayDetailsTpl',
  'adminAssayTabs',
  'rolesMixin',
  'backToListBehavior'
], function (
  module,
  Marionette,
  _,
  Backbone,
  App,
  Settings,
  imageUtils,
  viewTpl,
  assaysTabs,
  RolesMixin,
  BackToListBehavior
) {
  'use strict';

  var AssaysDetailsView =  Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      tab: '.assay-tab',
      tabPane: '.tab-pane',
      logo: '.js-logo-holder',
      name: '.p-name',
      code: '.p-code'
    },

    events: {
      'click @ui.tab': 'onTabClick',
      'change @ui.collectionToggle': 'onCollectionToggle'
    },

    regions: {
      assayDetailTab: '#detail-tab',
      kitpListTab: '#kitp-tab',
      anaAssayListTab: '#anaassay-tab'
    },

    behaviors: function() {
      var redirectUrl = 'admin/kits/'+this.options.model.get('refKit').secId+'/assay';
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: redirectUrl
        }
      };
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.assaysTabs = assaysTabs;
      templateData.compulogoCollection = this.model.get('compulogoCollection');
      return templateData;
    },

    onRender: function() {
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.assay-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onShow: function() {
      $.material.init();
    },

    onTabClick: function(e) {
      var $target = $(e.currentTarget),
        tab = {
          route: $target.data('route'),
          role: $target.data('role'),
          name: $target.data('name')
        };

      this.ui.tab.removeClass('active');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

      this.triggerMethod('click:tab', tab);
    },

    onHeaderChange: function(assay) {
      this.ui.name.text(assay.name);
      this.ui.code.text(assay.code);
    }
  });

  module.exports = AssaysDetailsView;
});
