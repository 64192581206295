
define('template!kitCQctCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitcqct.kitp') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-kitp-region"></div>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="targetType">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitcqct.targetType') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="targetType" value="">\n            <option value="RES1" ';
  if (targetType === 'RES1') { ;
__p += ' selected ';
 } ;
__p += ' >RES1</option>\n            <option value="RES2" ';
  if (targetType === 'RES2') { ;
__p += ' selected ';
 } ;
__p += ' >RES2</option>\n            <option value="RES3" ';
  if (targetType === 'RES3') { ;
__p += ' selected ';
 } ;
__p += ' >RES3</option>\n            <option value="RESCODE" ';
  if (targetType === 'RESCODE') { ;
__p += ' selected ';
 } ;
__p += ' >RESCODE</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="algoCtrlType">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitcqct.algoCtrlType') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="algoCtrlType" value="">\n            <option value="EXACT_RES" ';
  if (algoCtrlType === 'EXACT_RES') { ;
__p += ' selected ';
 } ;
__p += ' >EXACT_RES</option>\n            <option value="ABSOLUTE_VAL" ';
  if (algoCtrlType === 'ABSOLUTE_VAL') { ;
__p += ' selected ';
 } ;
__p += ' >ABSOLUTE_VAL</option>\n            <option value="STD_DEV" ';
  if (algoCtrlType === 'STD_DEV') { ;
__p += ' selected ';
 } ;
__p += ' >STD_DEV</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitcqct.qcTargetValCode') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-mbanares-region"></div>\n        </div>\n      </div>\n      <!--<div class="form-group" data-field-name="qcTargetValCode">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitcqct.qcTargetValCode') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="qcTargetValCode"\n                 placeholder="' +
((__t = ( _.i18n('kitcqct.qcTargetValCode') )) == null ? '' : __t) +
'" value="' +
((__t = ( qcTargetValCode )) == null ? '' : __t) +
'">\n        </div>\n      </div>-->\n      <div class="form-group" data-field-name="qcTargetValNum">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitcqct.qcTargetValNum') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="qcTargetValNum"\n                 placeholder="' +
((__t = ( _.i18n('kitcqct.qcTargetValNum') )) == null ? '' : __t) +
'" value="' +
((__t = ( qcTargetValNum )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="valueWarn">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitcqct.valueWarn') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="valueWarn"\n                 placeholder="' +
((__t = ( _.i18n('kitcqct.valueWarn') )) == null ? '' : __t) +
'" value="' +
((__t = ( valueWarn )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="valueErr">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitcqct.valueErr') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="valueErr"\n                 placeholder="' +
((__t = ( _.i18n('kitcqct.valueErr') )) == null ? '' : __t) +
'" value="' +
((__t = ( valueErr )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

