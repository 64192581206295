define('wellTemplateEditRefAssayView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!wellEditRefAssayTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'settings',
  'jquery'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView,
  Settings,
  $
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    models: [],

    ui: {
      input: '.js-info-input'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectAssay: '.js-select-assay-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
    ],

    initialize: function(options) {
      this.models = [];
      _.each(options.models, _.bind(function(model) {
        this.models.push(model.clone());
      }, this));
    },

    serializeData: function() {
      var templateData = {};
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();
      var url = 'v2/assays';

      if (this.runModel && this.runModel.get('refKitProt')) {
        url += '?kitProtSecId=' + this.runModel.get('refKitProt').secId;
      }

      var assayView = new AutocompleteView(
        this._getAutocompleteOptions('refAssay', 'code', 'code', url,
          'selectassay.placeholder', 'code', this.onChangeAssay)
      );
      this.getRegion('selectAssay').show(assayView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeAssay: function(fieldName, model) {
      _.each(this.models, function(wellModel) {
        if (model) {
          model.unset('autocompleteValue', {silent: true});
          wellModel.set(fieldName, model.toJSON());
        } else {
          wellModel.set(fieldName, null);
        }
      });
      this.triggerMethod('enable:cancel:confirm');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        var list = [];
        _.each(this.models, function(wellModel) {
          var modelToSave = wellModel.clone();
          modelToSave.set('amplCurve1', null);
          modelToSave.set('amplCurve2', null);
          modelToSave.set('amplCurve3', null);
          modelToSave.set('amplCurve4', null);
          modelToSave.set('amplCurve5', null);
          modelToSave.set('amplCurve6', null);
          modelToSave.set('meltCurve1', null);
          modelToSave.set('meltCurve2', null);
          modelToSave.set('meltCurve3', null);
          modelToSave.set('meltCurve4', null);
          modelToSave.set('meltCurve5', null);
          modelToSave.set('meltCurve6', null);
          list.push(modelToSave.save());
        });

        $.when(list).done(_.bind(function (){
          this.triggerMethod('saved');
          this.hide();
          App.trigger('well:mass:edit', this.models);
        }, this));
      }
    },

    onSave: function() {

    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.triggerMethod('hide:label');
      }
    }
  });

  module.exports = CreateEditLayoutView;
});
