/**
 * Created by RKL on 07/09/2015.
 */
define('superAdminMbAModelDetailsController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'superAdminMbAModelDetailsView',
  'emptyView',
  'mbAModelDetailTabController',
  'rolesMixin',
  'settings',
  'superAdminMbAModelsTabs',
  'entities/mbamodels'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  mbAModelDetailTabController
) {
  'use strict';

  var MbAModelsDetailsController = {
    fetchMbAModel: function(data) {
      var that = this,
          emptyView = new EmptyView(),
          contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.secId) {
        this.showMbAModel(data);
      } else {
        this.model = App.request('mbamodel:model', {secId: data.secId});
        this.model.fetch().done(function() {
          if (contentRegion) {
            that.showMbAModel(data);
          }
        });
      }
    },

    showMbAModel: function(data) {
      var view = new View({model: this.model, data: data});

      this.view = view;
      data.model = this.model;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      this.displayTabs(data);

      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
      view.listenTo(App, 'mbamodel:header:rerender', _.bind(this.onHeaderChange, this));
    },

    onHeaderChange: function (mbamodel) {
      this.view.onHeaderChange(mbamodel);
    },

    displayTabs: function(data) {
      mbAModelDetailTabController.showTab(data);
      data.model = this.model;
      this.tab = data.tab;
    },

    onTabClick: function(tab) {
      if (this.data.tab !== tab || this.data.secId) {
        this.data.tab = tab;
        this.data.secId = null;

        this.displayTabs(this.data);
        App.navigate('superAdmin/mbamodels/' + this.model.get('secId') + '/' + tab.name);
      }
    }
  };

  module.exports = MbAModelsDetailsController;
});
