/**
 * Created by Matteo on 11/07/2015.
 */
define('caccountGraphicalIdController',[
    'module',
    'underscore',
    'app',
    'caccountGraphicalIdView',
    'emptyView',
    'caccountObjectNamesTabController',
    'caccountFontsTabController',
    'caccountAttachsTabController',
    'rolesMixin',
    'settings',
    'cAccountTabs',
    'entities/caccounts'
], function(
    module,
    _,
    App,
    View,
    EmptyView,
    caccountObjectNamesTabController,
    caccountFontsTabController,
    caccountAttachmentsTabController,
    RolesMixin,
    Settings,CAccountTabs
    ) {
    'use strict';

    var CaccountGraphicalIdController = {
        fetchCaccount: function(data) {
            var that = this,
                emptyView = new EmptyView(),
                contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

            contentRegion.show(emptyView);

            this.data = data;
            if (this.model && this.model.get('secId') === data.cAccountId) {
                data.model = this.model;
                this.showCAccount(data);
            } else {
                this.model = App.request('caccount:entity', data.cAccountId);
                data.model = this.model;
                if (data.cAccountId === 'new') {
                    this.showCAccount(data);
                } else {
                    this.model.fetch().done(function () {
                        if (contentRegion) {
                            that.showCAccount(data);
                        }
                    });
                }
            }
        },

        showCAccount: function(data) {
            var view = new View({model: this.model, data: data});

            this.view = view;
            App.regions.getRegion('main').currentView.getRegion('content').show(view);
            this.displayTabs(data);

            view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));

            //App 'on' because view instanse destroys when switch to 'my account'
            App.off('caccount:header:rerender')
                .on('caccount:header:rerender', _.bind(this.onHeaderChange, this));
        },

        onHeaderChange: function () {
            this.model.fetch();
        },

        displayTabs: function(data) {
            var controllers = {}, roles = Settings.get('cAccountRoles');

            controllers[RolesMixin.DASHBOARD_ACCOUNT_GRAPHICALID_OBJECTNAMES] = caccountObjectNamesTabController;
            controllers[RolesMixin.DASHBOARD_ACCOUNT_GRAPHICALID_FONTS] = caccountFontsTabController;

            data.model = this.model;
            if (data.id === 'new') {
                controllers.profile.showTab(data);
                return;
            }
            if (data.type === 'caccount') {
                if (data.tab) {
                    controllers[data.tab.role].showTab(data);
                } else if (roles) {
                    _.some(CAccountTabs.graphicalIdTabs, function (c, index) {
                        data.tab = CAccountTabs.graphicalIdTabs[index];
                        if (roles[data.tab.role] === true) {
                            controllers[data.tab.role].showTab(data);
                            return true;
                        }
                    });
                }
            }

            this.tab = data.tab;
        },

        onTabClick: function(tab) {
            if (this.data.tab !== tab || this.data.secId) {
                this.data.tab = tab;
                this.data.secId = null;
                this.displayTabs(this.data);
                App.navigate('graphicalIdentity/' + this.data.cAccountId + '/' + tab.route);
            }
        }
    };

    module.exports = CaccountGraphicalIdController;
});
