define('entities/kitcqct',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var KitCQct = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/kitcs/' + this.get('refKitC').secId + '/kitcqcts/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      targetType: 'RES1',
      algoCtrlType: 'EXACT_RES',
      qcTargetValCode: '',
      qcTargetValNum: '',
      valueWarn: '',
      valueErr: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (kitCSecId) {
      return Settings.url('compuzz', 'v2/kitcs/' + kitCSecId + '/kitcqcts');
    },

    save: function() {
      this.url = this.postUrl(this.get('refKitC').secId);
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var KitCQctCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/kitcs/');
    },
    model: KitCQct
  });

  var API = {

    getKitCQctCollectionUrl: function(kitCSecId) {
      new KitCQctCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/kitcs/' + kitCSecId + '/kitcqcts', params);
    },

    getAllKitCQct: function(kitCSecId, params) {
      var url = Settings.url('compuzz', 'v2/kitcs' + kitCSecId + '/kitcqcts', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getKitCQctCountUrl: function(kitCSecId) {
      var params = '';
      return 'v2/kitcs/'+kitCSecId+'/kitcqcts/rowCount' + params;
    },

    deleteKitCQct: function(kitCSecId, secId){
      var url = Settings.url('compuzz', 'v2/kitcs/'+kitCSecId+'/kitcqcts/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('kitcqct:get-collection-url', function(kitCSecId) {
      return API.getKitCQctCollectionUrl(kitCSecId);
  });

  App.reqres.setHandler('kitcqct:get-collection-to-page', function(kitCSecId, params) {
      return API.getAllKitCQct(kitCSecId, params);
  });

  App.reqres.setHandler('kitcqct:get-count-url', function(kitCSecId) {
    return API.getKitCQctCountUrl(kitCSecId);
  });

  App.reqres.setHandler('kitcqct:model', function(itemJSON) {
    return new KitCQct(itemJSON);
  });

  App.reqres.setHandler('kitcqct:delete', function(kitCSecId, secId) {
    return API.deleteKitCQct(kitCSecId, secId);
  });
});
