/**
 * Created by RKL on 18/08/2015.
 */
define('customerTemplatesGroupsCollectionView',[
  'module',
  'marionette',
  'app',
  'settings',
  'customerTemplatesGroupsItemView',
  'customerTemplatesGroupsNewItemView'
], function (
  module,
  Marionette,
  App,
  Settings,
  ItemView,
  NewItemView
) {
  'use strict';

  var GroupsCollectionView = Marionette.CollectionView.extend({
    getChildView: function(item) {
      if (item.get('id') !== 0) {
        return ItemView;
      } else {
        return NewItemView;
      }
    }
  });

  module.exports = GroupsCollectionView;
});
