
define('template!caccountViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n\t<div class="with-header">\n\t\t<div class="caccount-details-header">\n\t\t\t<div class="caccount-info-container row">\n\t\t\t\t<div class="logo-container col-left col-lg-2">\n\t\t\t\t<!-- Logo -->\n\t\t\t\t\t<div class="original-img-wrapper zoom-img">\n\t\t\t\t\t\t<div class="main text-center">\n\t\t\t\t\t\t\t<div class="logo-holder js-logo-holder"></div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-left name-holder js-name-holder m-t-5 col-lg-2">\n\t\t\t\t\t<p class="p-name">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n\t\t\t\t\t<p class="p-code">' +
((__t = ( code )) == null ? '' : __t) +
'</p>\n\t\t\t\t</div>\n\n\t\t\t\t';
 if (phone) { ;
__p += '\n\t\t\t\t<div class="vertical-divider col-lg-1"></div>\t\t\t\t\n\t\t\t\t\t<div class="col-left js-phone-holder m-t-10 col-lg-2">\n\t\t\t\t\t\t<i class="mdi-notification-phone-in-talk icon holder-i"></i>\n\t\t\t\t\t\t<span class="p-phone">' +
((__t = ( phone )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t</div>\n\t\t\t\t';
 } ;
__p += '\n\n\t\t\t\t';
 if (contactEmail) { ;
__p += '\n\t\t\t\t<div class="vertical-divider col-lg-1"></div>\n\t\t\t\t<div class="col-left js-email-holder m-t-10 col-lg-2">\n\t\t\t\t\t<i class="mdi-content-mail icon holder-i"></i>\n\t\t\t\t\t<span class="p-email">' +
((__t = ( contactEmail )) == null ? '' : __t) +
'</span>\n\t\t\t\t</div>\n\t\t\t\t';
 } ;
__p += '\n\n\t\t\t\t';
 if (switchBtn) { ;
__p += '\n\t\t\t\t\t<div class="form-group switch-button pull-right">\n\t\t\t\t\t\t<a class="btn btn-primary js-switch-caccount">' +
((__t = ( _.i18n('common.switch') )) == null ? '' : __t) +
'</a>\n\t\t\t\t\t</div>\n\t\t\t\t';
 } ;
__p += '\n\t\t\t</div>\n\t\t\t<div class="caccount-tabs-container">\n\t\t\t\t<div class="navbar navbar-default navbar-md row ">\n\t\t\t\t\t<div class="navbar-header">\n\t\t\t\t\t\t<a class="btn btn-link navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">\n\t\t\t\t\t\t\t<span class="icon-bar"></span>\n\t\t\t\t\t\t\t<span class="icon-bar"></span>\n\t\t\t\t\t\t\t<span class="icon-bar"></span>\n\t\t\t\t\t\t</a>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="p-l-0 navbar-collapse collapse">\n\t\t\t\t\t\t<ul class="nav navbar-nav" id="sub-caccount-tabs">\n\t\t\t\t\t\t\t';
 _.each(cAccountTabs, function(cAccountTab, index) { ;
__p += '\n\t\t\t\t\t\t\t\t<li class=" js-tab-title caccount-tab';
 if (index === 0) { ;
__p += ' active';
 } ;
__p += '" data-role="' +
((__t = ( cAccountTab.role )) == null ? '' : __t) +
'" data-name="' +
((__t = ( cAccountTab.name )) == null ? '' : __t) +
'" data-route="' +
((__t = ( cAccountTab.route )) == null ? '' : __t) +
'">\n                  <a href="#' +
((__t = ( cAccountTab.route )) == null ? '' : __t) +
'-tab" data-toggle="tab">' +
((__t = ( cAccountTab.title )) == null ? '' : __t) +
'</a>\n                </li>\n\t\t\t\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class="caccount-details-container">\n\t\t<div class="tab-content content">\n\t\t\t';
 _.each(cAccountTabs, function(cAccountTab, index) { ;
__p += '\n\t\t\t\t<div class="tab-pane';
 if (index === 0) { ;
__p += ' active';
 } ;
__p += '" data-role="' +
((__t = ( cAccountTab.role )) == null ? '' : __t) +
'" data-name="' +
((__t = ( cAccountTab.name )) == null ? '' : __t) +
'" data-route="' +
((__t = ( cAccountTab.route )) == null ? '' : __t) +
'" id="' +
((__t = ( cAccountTab.name )) == null ? '' : __t) +
'-tab"></div>\n\t\t\t';
 }); ;
__p += '\n\t\t</div>\n\t</div>\n</div>\n';

}
return __p
};});

