
define('template!kitpDetailTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="well kit-detail">\n  <div class="detailed-info-form-container">\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitp.refMbAModel') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( refMbAModel.code )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitp.refMbAna') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( refMbAna.code )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitp.channelNb') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( channelNb )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitp.algoresCh1') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( algoresCh1 )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitp.paramCh1') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( paramCh1 )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitp.algoresACurve') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( algoresACurve )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitp.paramACurve') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( paramACurve )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitp.algoresMCurve') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( algoresMCurve )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitp.paramMCurve') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( paramMCurve )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      ';
 if (refAssay.refKit.refCAccount.secId === currentCAccountSecId) { ;
__p += '\n        <div class="pull-right p-r-15">\n          <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n        </div>\n      ';
 } ;
__p += '\n    </div>\n  </div>\n</div>';

}
return __p
};});

