define('caccountCreditsAccountFormPopupView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
    'template!caccountCreditsAccountFormPopupTpl',
    'template!caccountCreditsAccountFormPopupTplFirst',
  'jquery',
  'dropdownCompositeView',
  'underscore'
], function(
  module,
  Marionette,Backbone,
  DialogFormView,
  accountFormTpl,
  accountFormTplFirst,
  $,
  DropdownCompositeView,
  _
) {
  'use strict';

  var AccountFormPopupView = DialogFormView.extend({
      getTemplate: function() {
          if (this.options.first) {
              return accountFormTplFirst;
          } else {
              return accountFormTpl;
          }
      },

    ui: {
        countriesBlock: '.js-countries',
        errorLabel: '.error-label',
        errorMsg: '.error-msg',
        vatInput: '.js-vat-input',
        vatArea: '#vat-area',
        subjectToVat : '#subject-to-vat',
        subjectToVatRadio : '.subject-to-vat-radio'
    },

    events: {
        'click .js-confirm': '_onConfirm',
        'focus input' : 'onFocus',
        'change input': 'onInputChange',
        'click .js-cancel': 'hide',
        'change @ui.subjectToVat': 'onChangeSubjectToVat',
        'change @ui.subjectToVatRadio': 'onChangeSubjectToVatRadio',
        'click #welcome__start': 'onBtnClickStartRequest'
    },

    regions: function(){
        if (this.options.first) {
            return {};
        }
        else{
            return {
                selectCountries: '.js-select-countries-region'
            };
        }
    },

    onShow: function() {
        var countriesView = new DropdownCompositeView(
            this._getCountriesAutocompleteOptions()
        );
        if (!this.options.first) {
            this.getRegion('selectCountries').show(countriesView);
            this.listenTo(countriesView, 'focus', this._removeCountriesError);
        }
        $('.welcome__container' ).hide();
        $.material.init();
    },

    onRender: function(){
        var subjectToVat = this.model.get('subjectToVat') || false;
        if(!this.options.first){
            this.ui.vatArea.toggleClass('hidden',!subjectToVat);
            this.ui.subjectToVat.prop('checked', subjectToVat);
        }
    },


    fieldsToValidate: function(){
        var  fields = [];
        if (!this.options.first) {
            fields = [
                {name: 'name', type: 'required'},
                {name: 'city', type: 'required'},
                {name: 'address', type: 'required'},
                {name: 'zip', type: 'required'},
                {name: 'contactEmail', type: 'required'}
            ];
        }

        var subjectToVat = this.model.get('subjectToVat') || false;
        if(subjectToVat){
            fields.push({name: 'vatNumber', type: 'required'});
        }
        return fields;
    },

    modelEvents: {
      sync: '_onSaved',
      error: '_onError'
    },

    _onConfirm: function() {
      if( this.validate()) {
        this.model.update();
      }
    },

    onChangeSubjectToVat: function() {
          this.model.set('subjectToVat',this.ui.subjectToVat.is(':checked'));
          this.ui.vatArea.toggleClass('hidden',!(this.ui.subjectToVat.is(':checked')));
    },

      onChangeSubjectToVatRadio: function() {
          if(this.ui.subjectToVatRadio){
              _.each(this.ui.subjectToVatRadio, _.bind(function(radio) {
                  if(radio.checked){
                      var value = (radio.value === 'true');
                      this.model.set('subjectToVat',value);
                      this.ui.vatArea.toggleClass('hidden',!value);
                  }
              }, this));
          }

      },

    validate: function() {
        if (!this.options.first && !this.model.get('country')) {
            this.ui.countriesBlock.addClass('invalid-countries').find('input').addClass('invalid');
            DialogFormView.prototype.validate.call(this, this.fieldsToValidate());
            return false;
        } else {
            return DialogFormView.prototype.validate.call(this, this.fieldsToValidate());
        }
    },

    _removeCountriesError: function() {
      this.ui.countriesBlock.removeClass('has-error').find('input').removeClass('invalid');
    },

    _getCountriesAutocompleteOptions: function() {
      var countryCode, country;

      countryCode = this.model.get('country');
      country = _.findWhere(this.options.countries, {code: countryCode});

        this.options.countries.sort(function(a, b){
            return a.name.localeCompare(b.name);
        });

        return {
            modelProperty: 'code',
            modelDisplayProperty: 'name',
            modelSelectValue: country ? country.code : null,
            emptyIfNull: true,
            collection: new Backbone.Collection(this.options.countries),
            onItemSelected: _.bind(this._onChangeCountry, this)
        };
    },

    _onChangeCountry: function(selectedModel) {
      var code = selectedModel ? selectedModel.get('code') : '';
      this.ui.countriesBlock.removeClass('invalid-countries');
      this.onChange('country', code);
    },

    _onSaved: function(model) {
      this.trigger('saved', model);
      if(this.ui.errorMsg.empty){
        this.ui.errorMsg.empty();
      }
      $('.vat__container' ).hide();
      this.setTitle( _.i18n('welcome.firstRequest.title'));
      $('.welcome__container' ).fadeIn('slow');
    },

    _onError: function(model, response) {
          this.trigger('error', response);
    },

    onBtnClickStartRequest: function() {
      this.trigger('firstRequest:start');
    },

    onSaveError: function(error){
        switch(error.code){
            case 'VAT_INCORRECT':
                this.ui.vatInput.closest('div[data-field-name=vatNumber').addClass('has-error');
            break;
        }
        this.ui.errorLabel.fadeIn();
        this.ui.errorMsg.empty();
        if(error.responseJSON && error.responseJSON.label){
            this.ui.errorMsg.append('<span class="error">' + error.responseJSON.label + '</span><br/>');
        }
        else if(error.label){
            this.ui.errorMsg.append('<span class="error">' + error.label + '</span><br/>');
        }
        else{
            this.ui.errorMsg.append('<span class="error">' + _.i18n('common.serverError') + '</span><br/>');
        }

    }
  });

  module.exports = AccountFormPopupView;
});
