define('mbAnaResCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!mbAnaResCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'colorpicker',
  'colorUtils'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  colorpicker,
  ColorUtils
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      colorBtn: '.colorpick'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm',
      'click @ui.colorBtn' : 'onShowColorpicker'
    },

    regions: {
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'code', type: 'required'},
      {name: 'txt', type: 'required'}
    ],

    initialize: function(options) {
      this.model = options.mbanaresModel.clone();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      templateData.colorHexa = ColorUtils
          .decimalToHexa(this.model.get('color'))
          .split('#')[1];
      return templateData;
    },

    onShow: function() {
      $.material.init();
      this.triggerMethod('enable:cancel:confirm');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.model.save();
      }
    },

    onShowColorpicker: function(event) {
      var that = this,
          target = $(event.currentTarget),
          newColor;

      target.colorpicker();
      target.colorpicker('show');
      target.on('changeColor.colorpicker', function(event){
        newColor = event.color.toRGB();
        that.model.set('color', ColorUtils.rgbToDecimal(newColor.r, newColor.g, newColor.b));
      });
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.options.mbanaresModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('mbanares:header:rerender', modelJSON);
      App.navigate('superAdmin/mbanaresgrs/' + model.get('mbAnaResGrSecId') + '/mbanares', {trigger: true});
      this.hide();
    },

    hide: function() {
      var mbAnaResGrSecId = this.model.get('mbAnaResGrSecId');
      var secId = this.model.get('secId');
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'superAdmin/mbanaresgrs/new' ||
          App.getCurrentRoute() ===  'superAdmin/mbanaresgrs/'+mbAnaResGrSecId+'/mbanares/'+secId+'/edit') {
        App.navigate('superAdmin/mbanaresgrs/'+mbAnaResGrSecId+'/mbanares', {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
