define('runDetailsTabView',[
    'module',
    'marionette',
    'template!runDetailsTabTpl'
], function(
    module,
    Marionette,
    detailsTabTpl
) {
    'use strict';

    var DetailsTabView = Marionette.LayoutView.extend({
        template: detailsTabTpl,

        ui: {
            tabs1: '.tabs',
            firstTabMelt: '.first-tab-melt',
            firstTabAmpl: '.first-tab-ampl',
            tabMelt: '.tab-melt',
            tabAmpl: '.tab-ampl',
            firstTabMeltContent: '.first-tab-melt-content',
            firstTabAmplContent: '.first-tab-ampl-content',
            tabMeltContent: '.tab-melt-content',
            tabAmplContent: '.tab-ampl-content'
        },

        events: {
        },

        regions: {
            wellTable: '.well-table',
            runGraph: '#run-graph-region',
            runAmplGraph: '#run-ampl-graph-region',
            runGraph1: '#run-graph1-region',
            runAmplGraph1: '#run-ampl-graph1-region',
            runGraph2: '#run-graph2-region',
            runAmplGraph2: '#run-ampl-graph2-region',
            headerWell: '#header-region'
        },

        activeMelt: function() {
            this.ui.tabMelt.removeClass('active');
            this.ui.tabMeltContent.removeClass('active');
            this.ui.firstTabMelt.addClass('active');
            this.ui.firstTabMeltContent.addClass('active');
        },

        activeAmpl: function() {
            this.ui.tabAmpl.removeClass('active');
            this.ui.tabAmplContent.removeClass('active');
            this.ui.firstTabAmpl.addClass('active');
            this.ui.firstTabAmplContent.addClass('active');
        },

        showMelt: function() {
            this.ui.tabMelt.show();
        },

        showAmpl: function() {
            this.ui.tabAmpl.show();
        }
    });

    module.exports = DetailsTabView;
});
