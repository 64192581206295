
define('template!wellTableTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content m-0-i">\n\t<div class="well-table jq-grid-table-page hidden-print">\n\t\t<div>\n          <div class="row m-b-0 m-l-10">\n            <label class="btn add-btn-text btn-sm edit-smpid-button ' +
((__t = ( !editEnable?'':'hide' )) == null ? '' : __t) +
'">\n              <i class="mdi-editor-mode-edit"></i>\n              <span>' +
((__t = ( _.i18n("well.editSmpId") )) == null ? '' : __t) +
'</span>\n            </label>\n            <label class="btn add-btn-text btn-sm edit-refassay-button ' +
((__t = ( !editEnable?'':'hide' )) == null ? '' : __t) +
'">\n              <i class="mdi-editor-mode-edit"></i>\n              <span>' +
((__t = ( _.i18n("well.editRefAssay") )) == null ? '' : __t) +
'</span>\n            </label>\n            <!--<label class="btn add-btn-text btn-sm edit-refkitl-button ' +
((__t = ( !editEnable?'':'hide' )) == null ? '' : __t) +
'">\n              <i class="mdi-editor-mode-edit"></i>\n              <span>' +
((__t = ( _.i18n("well.editRefKitL") )) == null ? '' : __t) +
'</span>\n            </label>-->\n            <label class="btn add-btn-text btn-sm display-graph-button ' +
((__t = ( editEnable?'':'hide' )) == null ? '' : __t) +
'">\n              <i class="mdi-action-done"></i>\n              <span>' +
((__t = ( _.i18n("well.displayGraph") )) == null ? '' : __t) +
'</span>\n            </label>\n              <div class="col-xs-6 p-l-0">\n                  <div class="row m-b-0">\n                      <div class="radio radio-primary">\n                          <label>\n                              <input type="radio" class="sort-well-radio sort-well-radio-row" name="sort-well-radio" value="sortRow" ' +
((__t = ( sortOn!=='column'?'checked':'' )) == null ? '' : __t) +
' />\n                              ' +
((__t = ( _.i18n('well.sortby.row') )) == null ? '' : __t) +
'\n                          </label>\n                      </div>\n                      <div class="radio radio-primary">\n                          <label>\n                              <input type="radio" class="sort-well-radio sort-well-radio-column" name="sort-well-radio" value="sortColumn" ' +
((__t = ( sortOn==='column'?'checked':'' )) == null ? '' : __t) +
' />\n                              ' +
((__t = ( _.i18n('well.sortby.column') )) == null ? '' : __t) +
'\n                          </label>\n                      </div>\n                  </div>\n              </div>\n          </div>\n\n\t\t</div>\n\t  <div class="loading-block js-loading-block">\n\t    <span class="loading-message js-loading-message label"></span>\n\t  </div>\t\t\t      \n\t  <table id="jq-grid-well" class="wellTable" role="grid"></table>\n\t</div>\n</div>';

}
return __p
};});

