define('customerDatasetPopupView',[
  'module',
  'marionette',
  'formView',
  'template!customerDatasetsCollectionItemTpl',
  'template!customerDatasetsPopupTpl',
  'template!customerDatasetsPopupCreateNewModelTpl',
  'dialogFormView'
], function(
  module,
  Marionette,
  FormView,
  itemTpl,
  viewTpl,
  createModelTpl,
  DialogView
) {
  'use strict';
  var ObjectnamesView = FormView.extend({
    template: itemTpl,

    ui: {
      input: '.js-popup-input',
      deleteBtn: '.js-delete-btn'
    },   

    events: {
      'change @ui.input': 'onInputChange',
      'focus @ui.input': 'onFocus',
      'click @ui.deleteBtn' : 'onDelete'
    },

    serializeData: function() {
      return {
        first: this.options.childIndex === 0 ? true : false,
        model: this.model.toJSON(),
        isNew: this.model.isNew() ? true : false
      };
    },

    onInputChange: function(e) {
      var value = $(e.currentTarget).val();
      this.onChange(value);
    },

    onChange: function(value) {
      this.model.set({
        fileUid: value,
        value: value
      });
    },

    onDelete: function() {
      this.model.set({
        value: null,
        fileUid: null
      });
      this.$el.hide();
    }
  });
  var ObjectNamesCollectionView = Marionette.CollectionView.extend({
    childView: ObjectnamesView,
    childViewOptions: function(model, index) {
      return {
        childIndex: index
      };
    }
  });
  var DatasetEditPopupView = DialogView.extend({
    template: viewTpl,    
    createModelTpl: createModelTpl,

    regions: {
      objectnames: '.objectnames-container'
    },

    onShow: function() {
      this.getRegion('objectnames').show(new ObjectNamesCollectionView({collection: this.collection}));
    },

    serializeData: function() {
      return {objectName: this.options.objectName};
    },

    getTemplate: function() {
      if (this.collection.length !== 0) {
        return this.template;
      } else {
        this.collection.add(this.collection.getModel({
          objectName: this.options.objectName
        }));
        return this.createModelTpl;
      }
    }
  });

  module.exports = DatasetEditPopupView;
});
