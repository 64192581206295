/**
 * Created by RKL on 26/08/2015.
 */
define('orderprocessController',[
  'module',
  'backbone',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'orderprocessView',
  'entities/customers/customers',
  'entities/session'
], function(
  module,
  Backbone,
  _,
  App,
  Settings,
  bootbox,
  View
) {
  'use strict';

  var OrderProcessPluginController = {
    showPlugin: function(routeArgs) {
      var that = this,
        endUserRequest,
        sessionRequest,
        corpCountRequest,
        session;

      if (routeArgs.length >= 2) {
        if (Backbone.history.fragment.match('actions')) {
          if (routeArgs[0] === 'enduser') {
            this.productCode = routeArgs[1];
            this.endUserId = routeArgs[2];
            this.endUserSecId = routeArgs[3];
            this.returnToEnduserOrder = true;
          } else if(routeArgs[0] === 'endUserCompulogo'){
            this.productName =  routeArgs[1];
            this.productCode = routeArgs[2];
            this.endUserId = routeArgs[3];
            this.endUserSecId = routeArgs[4];
            this.logoId = routeArgs[5];
            this.optionId = routeArgs[6];
            this.returnToEnduserOrder = true;
            this.endUserCompulogo = true;
          } else {
            this.returnToEnduserOrder = false;
            this.endUserCompulogo = false;
          }
        } else {
          this.productCode = routeArgs[0];
          this.endUserId = routeArgs[1];
          this.returnToEnduserOrder = false;
        }


        session = {
          clientCode:this.endUserId,
          userCode:this.endUserId,
          ttc:false,
          languageCode:'en',
          locale:'en_US'
        };

        if (routeArgs.length >= 3 && routeArgs[2] === 'template') {
          session.userRoles = 'expert';
          session.returnUrl = location.origin + location.pathname +
            '#customers/' + this.endUserId +
            '/producttemplates/' + this.productCode;

          this.useFlex = true;
          if (routeArgs.length === 4 && routeArgs[3]) {
            this.poi = routeArgs[3];
          } else {
            this.poi = null;
          }
          endUserRequest = App.request('customers:entity', this.endUserId);
          endUserRequest.fetch().done(function (endUser) {
            sessionRequest = App.request('session:create-session', session);
            session.clientCode = endUser.code;
            session.userCode = endUser.code;
            $.when(sessionRequest).done(function (resp) {
              that.sessionId = resp.sessionId;
              that.onSessionCreated();
            });
          });
        } else {
          this.useFlex = false;
          if (routeArgs.length >= 3 && routeArgs[2] &&
              !this.returnToEnduserOrder) {
            this.poi = routeArgs[2];
            this.editOrder = true;
            session.returnUrl = location.origin + location.pathname +
              '#orders/' + this.poi;
            that.createSession(session);
          } else {
            this.editOrder = false;
            this.poi = null;
  
            session.returnUrl = location.origin + location.pathname +
              '#orders';

            corpCountRequest = App.request(
              'customer:count-templates',
              this.endUserId,
              this.productCode
            );

            $.when(corpCountRequest).then(function (result) {
              if (result > 0) {
                that.hasCorporateTemplate = true;
              } else  {
                that.hasCorporateTemplate = false;
              }
              that.createSession(session);
            }).fail(function () {
              that.hasCorporateTemplate = false;
              that.createSession(session);
            });
          }
        }
      }
    },

    createSession: function(session) {
      var that = this,
        sessionRequest;

      session.webServiceUrl = 'random';
      sessionRequest = App.request('session:create-session', session);
      $.when(sessionRequest).done(function (result) {
        that.sessionId = result.sessionId;
        that.onSessionCreated();
      });
    },

    onSessionCreated: function () {
      var params = {
          lang: Settings.get('lang')
        },
        url;

      if (this.useFlex) {
        // if we're editing or creating a new order
        params.oloSessionId = this.sessionId;
        if (this.poi) {
          params.purchaseOrderId = this.poi;
        } else {
          params.productCode = this.productCode;
        }

        url = Settings.url('compuzzOrderProcess', 'orderProcess.jsp', params);
        this.continueRender(url);
      } else if (this.optionId) {
        params.options = this.optionId;
        url = Settings.url('compuzzHtml',
          'oloSessionId/' + this.sessionId +
          '/productCode/' + this.productCode,
          params
        );
        this.continueRender(url);
      } else if (this.endUserCompulogo) {
        url = Settings.url('compuzzHtml',
          'oloSessionId/' + this.sessionId +
          '/productCode' + '/'+ this.productCode +
          '/endUser/' + this.endUserSecId +
          '/endUserCompulogo/' + this.logoId,
          params
        );
        this.continueRender(url);
      } else if (this.editOrder) {
        url = Settings.url('compuzzHtml',
          'oloSessionId/' + this.sessionId +
          '/productCode' + '/'+ this.productCode +
          '/purchaseOrderId/' + this.poi,
          params
        );
        this.continueRender(url);
      } else if (this.hasCorporateTemplate) {
        params = _.extend(params, {
          oloSessionId: this.sessionId,
          productCode: this.productCode,
          loadCorporateTemplate: true
        });

        url = Settings.url('compuzzOrderProcess', 'orderProcess.jsp', params);
        this.continueRender(url);
      } else {
        bootbox.dialog({
          title: _.i18n('expertChoice.title'),
          message: _.i18n('expertChoice.msg'),
          buttons: {
            success: {
              label: _.i18n('expertChoice.flex') ,
              className: 'btn-success',
              callback:_.bind(function() {
                params = _.extend(params, {
                  oloSessionId: this.sessionId,
                  productCode: this.productCode
                });

                url = Settings.url(
                  'compuzzOrderProcess',
                  'orderProcess.jsp',
                  params
                );
                this.continueRender(url);
              }, this)
            },
            main: {
              label: _.i18n('expertChoice.html'),
              className: 'btn-primary',
              callback: _.bind(function() {
                url = Settings.url('compuzzHtml',
                  'oloSessionId/' + this.sessionId +
                  '/productCode/' + this.productCode,
                  params
                );
                this.continueRender(url);
              }, this)
            }
          }
        });
      }
    },

    continueRender : function(url) {
      var view = new View({
        url: url,
        productCode: this.productCode || '',
        productName: this.productName || '',
        poi: this.poi || ''
      });
      
      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      $(document).on('orderSaved', _.bind(this.orderSaved, this));
    },

  
    orderSaved: function (e) {
      if (this.returnToEnduserOrder) {
        App.navigate('#customers/' + this.endUserSecId + '/' +
          'producttemplates', {trigger: true});
      } else {
        App.navigate('#orders/' + e.purchaseOrderId,  {trigger: true});
      }
    }
  };

  module.exports = OrderProcessPluginController;
});
