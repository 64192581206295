/**
 * Created by RKL on 07/09/2015.
 */
define('adminKitcDetailsController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'adminKitcDetailsView',
  'emptyView',
  'kitcDetailTabController',
  'adminKitCQctController',
  'rolesMixin',
  'settings',
  'entities/kitc'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  kitcDetailTabController,
  adminKitCQctController
) {
  'use strict';

  var KitcsDetailsController = {
    fetchKitc: function(data) {
      var that = this,
          emptyView = new EmptyView(),
          contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.secId) {
        this.showKitc(data);
      } else {
        this.model = App.request('kitc:model', {secId: data.secId});
        this.model.fetch().done(function() {
          if (contentRegion) {
            that.showKitc(data);
          }
        });
      }
    },

    showKitc: function(data) {
      var view = new View({model: this.model, data: data});

      this.view = view;
      data.model = this.model;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      this.displayTabs(data);

      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
      view.listenTo(App, 'kitc:header:rerender', _.bind(this.onHeaderChange, this));
    },

    onHeaderChange: function (kitc) {
      this.view.onHeaderChange(kitc);
    },

    displayTabs: function(data) {
      for (var region in this.view.regions) {
        if (App.regions.getRegion('main') && App.regions.getRegion('main').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content') &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region)) {
          var currentViewRegion = App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region).currentView;
          if(currentViewRegion) {
            currentViewRegion.destroy();
          }
        }
      }

      if (data.tab) {
        switch (data.tab.name) {
          case 'kitcqct':
            adminKitCQctController.showKitCQctList(data);
            break;
          default:
            kitcDetailTabController.showTab(data);
            break;
        }
      } else {
        kitcDetailTabController.showTab(data);
      }

      data.model = this.model;
      this.tab = data.tab;
    },

    onTabClick: function(tab) {
      if (this.data.tab !== tab || this.data.secId) {
        this.data.tab = tab;
        this.data.secId = null;

        this.displayTabs(this.data);
        App.navigate('admin/kitcs/' + this.model.get('secId') + '/' + tab.name);
      }
    }
  };

  module.exports = KitcsDetailsController;
});
