define('wellTemplateView',[
    'module',
    'marionette',
    'template!wellTemplateTpl'
], function(
    module,
    Marionette,
    wellTpl
) {
    'use strict';

    var WellTemplateView = Marionette.ItemView.extend({
        template: wellTpl,

        ui: {
            elementRight: '.elementRight'
        },

        events: {
            'click': 'onItemClick'
        },

        onItemClick:function() {
            this.trigger('welltemplate:filteredDetailItem', this.model.pos);
        },

        serializeData: function() {
            return {
                well: this.model
            };
        }
    });

    module.exports = WellTemplateView;
});
