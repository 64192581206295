/**
 * Created by RKL on 13/08/2015.
 */
define('kitcDetailView',[
  'module',
  'kitcCreateEditLayoutView',
  'underscore',
  'marionette',
  'template!kitcDetailTabTpl',
  'settings'
], function (
  module,
  CreateEditLayoutView,
  _,
  Marionette,
  viewTpl,
  Settings
) {
  'use strict';

  var KitcDetailView = Marionette.ItemView.extend({
    template: viewTpl,

    modelEvents: {
      change: 'render'
    },

    events: {
      'click .js-edit': '_onEdit'
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.viewLess = this.model.get('secId');
      templateData.currentCAccountSecId = Settings.get('currentCAccount').secId;
      return templateData;
    },

    _onEdit: function() {
      var createEditView = new CreateEditLayoutView({
        kitcModel: this.model
      });
      createEditView.show({
        title: _.i18n('kitc.edit')
      });
    }

  });

  module.exports = KitcDetailView;
});
