
define('template!adminAnaAssayListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content">\n    <div class="well jq-grid-table-page">\n        <div class="anaassay-table">\n            ';
 if (data.model.get('refKit').refCAccount.secId === currentCAccountSecId) { ;
__p += '\n            <div class="form-group add-new-block">\n                <a href="#admin/assays/' +
((__t = ( data.model.get('secId') )) == null ? '' : __t) +
'/anaassay/new" class="btn new-anaassay-button add-btn-text">\n                    <i class="mdi-content-add"></i>\n                    <span>' +
((__t = ( _.i18n('anaassay.new') )) == null ? '' : __t) +
'</span>\n                </a>\n            </div>\n            ';
 } ;
__p += '\n            <table id="jq-grid-list" class="anaassayTable" role="grid"></table>\n            <div id="jq-grid-pager-list"></div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

