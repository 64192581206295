
define('template!customerTemplatesMetaInfoTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="template-meta-info">\n  <div class="top-info-container">\n    <div class="template-variations-number">\n      <i class="mdi-file-folder icon">\n        <span class="badge primary-bg counter-m " data-count="' +
((__t = ( metaInfo.variationsNumber )) == null ? '' : __t) +
'">' +
((__t = ( metaInfo.variationsNumber )) == null ? '' : __t) +
'</span>\n      </i>\n    </div>\n    <div class="template-image-raster pull-left">\n      <i class="mdi-image-panorama icon ';
 if(!metaInfo.hasRasterImages) { ;
__p += 'no-raster';
 } ;
__p += '"></i>\n    </div>\n    <div class="template-name pull-left p-t-5 ellipsis w-60-pr">\n      <span class="span-ellipsis">' +
((__t = ( metaInfo.name )) == null ? '' : __t) +
'</span>\n    </div>\n  </div>\n  <div class="template-colors-container">\n    ';
 if(metaInfo.colors.length > 0) { ;
__p += '\n      <div class="template-colors">\n        ';
 _.each(metaInfo.colors, function(color) { ;
__p += '\n          <div class="template-color-item tooltipped-color"  title="' +
((__t = ( color.name )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="right" style="background-color:' +
((__t = ( color.hexColor )) == null ? '' : __t) +
'">\n          </div>\n        ';
 }) ;
__p += '\n      </div>\n      <!-- <div class="color-section-ellipsis" style="display: block;"><b>...</b></div> -->\n    ';
 } ;
__p += '\n    <div class="stndard-size-code">\n      <span>' +
((__t = ( metaInfo.standardSizeCode )) == null ? '' : __t) +
'</span>\n    </div>\n  </div>\n</div>';

}
return __p
};});

