define( 'entities/caccounts/fonts',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function(id) {
      id = id ? id + '/' : '';

      return 'fonts.json/' + id + 'custom/rowCount';
    },

    getFontsUrl: function(id) {
      id = id ? id + '/' : '';

      return Settings.url('compuzz', 'fonts.json/' + id + 'custom');
    },

    uploadFont: function(formdata, id) {
      var defer = $.Deferred(),
        params, url;

      if (id) {
        params = {endUserSecId: id};
        url = Settings.url('compuzz', 'fonts.json/upload', params);
      } else {
        url = Settings.url('compuzz', 'fonts.json/upload');
      }

      $.ajax({
        type: 'POST',
        url: url,
        data: formdata,
        processData: false,
        contentType: false,
        success: function(resp) {
          defer.resolve(resp);
        },
        error: function(resp) {
          defer.resolve(resp, 'error');
        }
      });

      return defer.promise();
    },

    deleteFont: function(fontId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'fonts.json/' + fontId);

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('fonts-tab:row-count-url', function(id) {
    return API.getRowCountUrl(id);
  });

  App.reqres.setHandler('fonts-tab:get-url', function(id) {
    return API.getFontsUrl(id);
  });

  App.reqres.setHandler('fonts-tab:add-font', function(formdata, id) {
    return API.uploadFont(formdata, id);
  });

  App.reqres.setHandler('fonts-tab:delete-font', function(fontId) {
    return API.deleteFont(fontId);
  });
});
