/**
 * Created by RKL on 19/08/2015.
 */
define('customerLogosImagesAddPopupView',[
  'module',
  'underscore',
  'imageUtils',
  'imageUploadBehavior',
  'template!customerLogosImagesAddNewPopupTpl',
  'dialogView'
], function (
  module,
  _,
  imageUtils,
  ImageUploadBehavior,
  viewTpl,
  DialogView
) {
  'use strict';

  var CustomersLogosImagesTabPopupViewAddNew = DialogView.extend({
    template: viewTpl,

    ui: {
      fromEmpty: '.js-from-empty',
      fromUpload: '.js-from-upload',
      fromExisting: '.js-from-existing',
      fromTpl: '.js-from-template'
    },

    events: {
      'click @ui.fromEmpty': 'onFromEmpty',
      'click @ui.fromExisting': 'onFromExisting',
      'click @ui.fromTpl': 'onFromTemplate'
    },

    behaviors: {
      ImageUpload: {
        behaviorClass: ImageUploadBehavior
      }
    },


    serializeData: function() {
      var fromTemplates = false;

      if (this.options.data.groupModel.get('templateGroupSecId')) {
        fromTemplates = true;
      }

      return { fromTemplates: fromTemplates };
    },

    onImageUploadConfirm: function(base64Value, fileExt) {
      this.options.callback('FromUpload', {base64Value: base64Value, fileExt:fileExt});
    },

    onFromEmpty: function() {
      this.options.callback('FromEmpty');
    },

    onFromExisting: function() {
      this.options.callback('FromExisting');
    },

    onFromTemplate: function(e) {
      if (!$(e.currentTarget).hasClass('disabled')) {
        this.options.callback('FromTemplates');
      }
    }
  });

  module.exports = CustomersLogosImagesTabPopupViewAddNew;
});
