define('adminLmbAnaTabs',[
  'module',
  'underscore'
], function(
  module,
  _
) {
  'use strict';

  module.exports = [
    {
      name: 'detail',
      title: _.i18n('lmbanas.details.tabTitle')
    },
    {
      name: 'restrans',
      title: _.i18n('lmbanas.restrans.tabTitle')
    },
    {
      name: 'anaassay',
      title: _.i18n('lmbanas.anaassay.tabTitle')
    }
  ];
});
