
define('template!customerProdTemplatesProductTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row dropdown-single-item">\n\t<div class="col-sm-2">\n\t\t<div class="thumbnail">\n\t\t\t<a>\n\t\t\t\t<img class=\'search-dropdown-image\' src="' +
((__t = ( url )) == null ? '' : __t) +
'" alt="thumb"/>\n\t\t\t</a>\n\t\t</div>\n\t</div>\n\t<div class="col-sm-3 search-item-name">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n\t<div class="col-sm-7 search-item-name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

