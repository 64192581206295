/**
 * Created by Matteo on 11/07/2015.
 */
define('headerShowView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'imageUtils',
  'autocompleteView',
  'template!headerViewTpl',
  'template!headerCaccountAutocompleteTpl'
], function(
  module,
  Marionette,
  _,
  App,
  Settings,
  imageUtils,
  AutocompleteView,
  viewTpl,
  caccountAutocompleteItemTpl
) {
  'use strict';

  var HeaderView =  Marionette.LayoutView.extend({
    template: viewTpl,

    triggers: {
      'click @ui.logout' : 'onLogout',
      'click @ui.sidebar' : 'onShowSidebar',
      'click @ui.editLogin' : 'onEdit'
    },

    ui: {
      logout : '.logout',
      sidebar: '.show-hide-sidebar',
      user: '.show-hide-user',
      navbar: '.nav-user',
      avatar: '.avatar-dropdown',
      logo: '.avatar-in-header',
      userName: '.js-user-name',
      caccountName: '.js-caccount-name',
      caccountLogo: '.js-caccount-logo',
      caret: '.brand-title .caret',
      dropdown: '.caccount-hover-dropdown',
      availableCAccount: '.available-caccount',
      searchCaccount: '.search-caccount',
      editLogin: '.edit-ac',
      accountSwitch: '.js-account-switch',
      accountSwitchDropdownContainer: '.caccounts-dropdown-container',
      accountSwitchDropdown: '.js-account-switch-dropdown',
      languageItem: '.language-item'
    },

    events: {
      'click @ui.availableCAccount': 'onSwitchCaccount',
      'click @ui.accountSwitch': 'onShowDropdown',
      'click @ui.accountSwitchDropdownContainer': 'onAccountContainerClick',
      'click @ui.languageItem': 'changeLanguage'
    },

    regions: {
      caccountAutocomplete: '.js-caccounts-autocomplete'
    },

    _caccountAutocompleteView: undefined,

    changeLanguage: function(event){
          var lang = this.$el.find(event.currentTarget).children().attr('lang');
          this.setCurrentLanguage(lang);
          this.trigger('changeLanguage',lang);
    },

    setCurrentLanguage: function(language){
        var $selected = this.$el.find('.language-head');
        $selected.attr('lang', language);
        $selected.text(language);
    },

    getAccountsAutocompleteOptions: function() {
      return {
        type: 'remote',
        alwaysOpen: true,
        remote: Settings.url('compuzz', 'sessions.json/token/byuser/caccounts'),
        valueKey: 'code',
        apiKey: 'secId',
        placeholder: _.i18n('selectaccount.placeholder'),
        value: null,
        name: 'accounts',
        onPressKey: true,
        callback: _.bind(this.onSwitchCaccount, this),
        childTemplate: caccountAutocompleteItemTpl,
        childViewSerializeData: this._autocompleteViewSerializeData,
        lazyLoad: true,
        keys: {
          query: 'caccountCode',
          limit: 'pageSize'
        }
      };
    },


    onRender: function () {
      this.displayCurrentUserPidInfo();
      this.trigger('getAvailableCaccounts');
      this.setCurrentLanguage(Settings.get('lang'));
    },

    onShow: function () {
      $.material.init();
    },

    onShowMenu: function(target) {
      if (this.ui.navbar.hasClass('open') || target === 'hide') {
        this.ui.navbar.removeClass('open');
      } else {
        this.ui.navbar.addClass('open');
      }
    },

    checkAvailableCaccounts: function (count) {
      if (count > 1) {
        this.ui.caret.show();
        this.renderCaccountsDropdown();
        this.displayDropdown();
      } else {
        this.ui.caret.hide();
        this.displayCurrentCaccountInfo();
      }
    },

    renderCaccountsDropdown: function () {
      this._caccountAutocompleteView = new AutocompleteView(this.getAccountsAutocompleteOptions());

      this.getRegion('caccountAutocomplete').show(this._caccountAutocompleteView);
      this._caccountAutocompleteView.$('.ac-suggestions.dropdown-menu').removeClass('dropdown-menu ac-suggestions').
        addClass('caccount-hover-dropdown');
      this.displayCurrentCaccountInfo(Settings.get('currentCAccount'));
    },

    displayCurrentCaccountInfo: function(account) {
      var currentCAccount = account || Settings.get('currentCAccount'),
        refMainCAccountJson = currentCAccount.refMainCAccountJson,
        logo, brandLogo;
      
      if (currentCAccount) {
        this.currentCAccount = currentCAccount;
        this.ui.caccountName.text(currentCAccount.code);
        if (refMainCAccountJson ) {
          brandLogo  = imageUtils.getLogo(
            refMainCAccountJson.logo,
            refMainCAccountJson.code, {
              action: 'res',
              params: 't'
            }
          );
          this.$('.compuzz_general_logo' ).attr('src', brandLogo );
        } else {
          this.$('.compuzz_general_logo' ).attr('src', Settings.url('base', Settings.get('compuzzLogo')) );
        }
        logo = imageUtils.getLogo(
          currentCAccount.logo,
          currentCAccount.code, {
            action: 'res',
            params: 't'
          }
        );
        this.ui.caccountLogo.attr('src', logo);
      }
    },

    displayCurrentUserPidInfo: function() {
      var currentUser = Settings.get('currentUser'),
        fileUrl;

      if (currentUser) {
        fileUrl = imageUtils.getLogo(
          currentUser.logo,
          currentUser.firstName + ' ' + currentUser.lastName, {
            action: 'res',
            params: 't'
          }, 'circle'
        );
        this.ui.userName.text(currentUser.logonId);
        this.ui.logo.attr('style', 
          'background-image: url(' + fileUrl + ')');
      } else {
        this.ui.userName.text('Please Log in!');
      }
    },

    onShowDropdown: function() {
      var that = this;

      setTimeout(function() {
        that._caccountAutocompleteView.ui.autocomplete.focus();
      }, 10);
    },

    onAccountContainerClick: function(e) {
      e.stopImmediatePropagation();
    },

    displayDropdown: function() {
      this.$('.navbar-brand').removeClass('disabled');
    },

    onSwitchCaccount: function(fieldName, cAccount) {
      App.trigger('switchCaccount', cAccount.toJSON());
    },

    _autocompleteViewSerializeData: function() {
      var templateData = this.model.toJSON();

      templateData.url = imageUtils.getLogo(this.model.get('logo'), this.model.get('code'), {
        action: 'res',
        params: 't'
      });

      return templateData;
    }
  });

  module.exports = HeaderView;
});
