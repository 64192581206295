
define('template!customerLogosImagesAddNewPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n\t<form>\n\t\t<div class="container-fluid add-new-template-container">\n\t\t\t<div class="row form-group text-center">\n\t\t\t\t<div class="col-sm-6 p-l-5 p-r-5">\n\t\t\t\t\t<button type="button" class="btn btn-primary js-from-empty">' +
((__t = ( _.i18n("common.fromEmpty") )) == null ? '' : __t) +
'</button>\n\t\t\t\t</div>\n\n\t\t\t\t<div class="col-sm-6 p-l-5 p-r-5">\n\t\t\t\t\t<input type="file" class="filestyle js-change-logo js-from-upload hidden" multiple="multiple" id="aprofile-image-input" accept="image/*,application/pdf,application/ai,application/eps"/>\n\t\t\t\t\t<div class="bootstrap-filestyle">\n\t\t\t\t\t\t<label for="aprofile-image-input" class="btn btn-primary">\n\t\t\t\t\t\t\t<span>' +
((__t = ( _.i18n("common.fromUpload") )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class="col-sm-6 p-l-5 p-r-5">\n\t\t\t\t\t<button type="button" class="btn btn-primary js-from-existing">' +
((__t = ( _.i18n("common.fromExisting") )) == null ? '' : __t) +
'</button>\n\t\t\t\t</div>\n\n\t\t\t\t<div class="col-sm-6 p-l-5 p-r-5">\n\t\t\t\t\t<button type="button" class="btn btn-primary js-from-template">' +
((__t = ( _.i18n("common.fromTemplates") )) == null ? '' : __t) +
'</button>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</form>\n</div>';

}
return __p
};});

