
define('template!headerWellTemplateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12">\n        <div class="col-xs-1">\n            ' +
((__t = ( pos )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-2">\n            ' +
((__t = ( smpid )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-1">\n            ' +
((__t = ( refKitL != null ? refKitL.lotNb : '' )) == null ? '' : __t) +
'\n        </div>\n    </div>\n</div>';

}
return __p
};});

