/**
 * Created by RKL on 07/09/2015.
 */
define('adminKitlDetailsController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'adminKitlDetailsView',
  'emptyView',
  'kitlDetailTabController',
  'rolesMixin',
  'settings',
  'entities/kitl'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  kitlDetailTabController
) {
  'use strict';

  var KitlsDetailsController = {
    fetchKitl: function(data) {
      var that = this,
          emptyView = new EmptyView(),
          contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.secId) {
        this.showKitl(data);
      } else {
        this.model = App.request('kitl:model', {refKit: {secId: data.secId}});
        this.model.fetch().done(function() {
          if (contentRegion) {
            that.showKitl(data);
          }
        });
      }
    },

    showKitl: function(data) {
      var view = new View({model: this.model, data: data});
      this.view = view;
      data.model = this.model;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);
      this.displayTabs(data);
    },

    displayTabs: function(data) {
      for (var region in this.view.regions) {
        if (App.regions.getRegion('main') && App.regions.getRegion('main').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content') &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region)) {
          var currentViewRegion = App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region).currentView;
          if(currentViewRegion) {
            currentViewRegion.destroy();
          }
        }
      }

      if (data.tab) {
        switch (data.tab.name) {          
          default:
            kitlDetailTabController.showTab(data);
            break;
        }
      } else {
        kitlDetailTabController.showTab(data);
      }

      data.model = this.model;
      this.tab = data.tab;
    }    
  };

  module.exports = KitlsDetailsController;
});
