
define('template!customerTemplatesGroupsLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="hbox folders-container">\n\t<aside class="groups-column-container">\n\t\t<div id="groups-column" class="groups-column"></div>\n\t</aside>\n\t<section class="elements-column-container">\n\t\t<div id="filters-column" class="filters-column vbox filters-max-height"></div>\n\t\t<div id="elements-column" class="elements-column vbox"></div>\n\t</section>\n</div>';

}
return __p
};});

