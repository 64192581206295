/**
 * Created by RKL on 13/08/2015.
 */
define('lmbAnaDetailTabController',[
  'module',
  'underscore',
  'backbone',
  'app',
  'lmbAnaDetailView',
  'settings'
], function(
  module,
  _,
  Backbone,
  App,
  View
) {
  'use strict';

  var LmbAnaDetailTabController = {
    showTab: function(data) {
      if (!data.tab) {
        data.tab = 'detail';
      }
      this.showLmbAnaInfo(data);
    },

    showLmbAnaInfo: function(data) {
      var that = this;
      this.model = data.model;
      this.tab = data.tab;
      this.data = data;
      that.createView();
    },

    createView: function() {
      var view;
      view = new View({
        model: this.model
      });
      view.tempModel = new Backbone.Model(this.model.toJSON());
      this.view = view;

      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('lmbAnaDetailTab').show(view);
    }
  };

  module.exports = LmbAnaDetailTabController;
});
