
define('template!assayDetailTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="well kit-detail">\n  <div class="detailed-info-form-container">\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( code ? code : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( description ? description : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      ';
 if (refKit.refCAccount.secId === currentCAccountSecId) { ;
__p += '\n      <div class="pull-right p-r-15">\n        <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n      </div>\n      ';
 } ;
__p += '\n    </div>\n  </div>\n</div>';

}
return __p
};});

