/**
 * Created by OLD on 30/10/2015.
 */
define('sampleMainRouter',[
  'underscore',
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin',
  'samplesTabs',
  'sampleDetailsController'
], function(
    _,
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin,
  SamplesTabs,
  SampleDetailsController
) {
  'use strict';

  var SampleRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'samples/new': 'showSamplesDetailsNew',
      'samples(/:secId)(/:tab)' : 'showSamplesDetails',
      'samples/:secId/:tab/new' : 'showSamplesTabNew'
    }
  });

  var API = {
    checkContent: function() {
      var defer = $.Deferred();

      require([
        'contentShowController'
      ], function(ContentShowController) {
        ContentShowController.showContent().done(function() {
          defer.resolve();
        });
      });
      return defer.promise();
    },

    showSamplesDetailsNew: function() {
      this.showSamplesDetails(null, null, true);
    },

    showSamplesDetails: function(secId, tab, newItem) {
      this.checkContent().done(function() {
        require([
          'samplesController'
        ], function(SamplesController) {
          if (SamplesController.createEditView) {
            SamplesController.createEditView.hide(true);
            SamplesController.createEditView = null;
          }

          if (secId) {
            SampleDetailsController.fetchSample({
              secId: secId,
              tab: _.findWhere(SamplesTabs, {name: tab})
            });
          } else {
            SamplesController.showSampleList({
              type: 'samples'
            });

            if (newItem) {
              SamplesController.showNewSample();
            }
          }

          App.execute('set:active:nav:item', ItemPagesNameMixin.SAMPLES);
        });
      });
    },

    showSamplesTabNew: function(secId, tab) {
      this.checkContent().done(function() {
        require([
          'smpResListTabController'
        ], function(SmpResController) {

          if (SmpResController.createEditView) {
            SmpResController.createEditView.hide(true);
          }

          if (secId) {
            SampleDetailsController.fetchSample({
              secId: secId,
              tab: _.findWhere(SamplesTabs, {name: tab})
            });
          }

          SmpResController.showSmpResAdd(secId);


          App.execute('set:active:nav:item', ItemPagesNameMixin.SAMPLES);
        });
      });
    }
  };

  App.on('sample:show', function() {
    App.navigate('sample');
  });

  App.on('before:start', function() {
    new SampleRouter({
      controller: API
    });
  });

  module.exports = SampleRouter;
});
