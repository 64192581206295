define('adminKitpView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!adminKitpListTpl'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl
    ) {
    'use strict';

    var KitpListView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'kitpListFilters',

        triggers: {
        },

        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteKitp: '.delete-kitp-link',
                editKitp: '.edit-kitp-link'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click @ui.deleteKitp': 'onDeleteKitp',
                'click @ui.editKitp': 'onEditKitp'
            }, this.gridEvents);
        },

        serializeData: function() {
            var templateData = {
                type: 'kitp',
                data: this.options.data,
                currentCAccountSecId: Settings.get('currentCAccount').secId
            };
            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: Settings.get('perPage15'),
                currentPage: 1
            };
        },

        onDeleteKitp: function(event){
            this.trigger('kitp:delete', this.options.data.model.get('secId'), $(event.currentTarget).find('span').attr('data-row-id'));
        },

        onEditKitp: function(event) {
            this.trigger('kitp:edit', this.options.data.model.get('secId'), $(event.currentTarget).find('span').attr('data-row-id'));
        },

        gridOptions: function(data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('kitp.refMbAna'),
                        name: 'refMbAna.code',
                        classes: 'kitp-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'refMbAna.code',
                        width: 60
                    }, {
                        label: _.i18n('kitp.refMbAna.name'),
                        name: 'refMbAna.name',
                        classes: 'kitp-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'refMbAna.name',
                        width: 80
                    }, {
                        label: _.i18n('kitp.refMbAModel'),
                        name: 'refMbAModel.code',
                        classes: 'kitp-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'refMbAModel.code',
                        width: 70
                    }, {
                        label: _.i18n('kitp.channelNb'),
                        name: 'channelNb',
                        classes: 'kitp-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'channelNb',
                        width: 40
                    }, {
                        label: _.i18n('kitp.algoresCh1.short'),
                        name: 'algoresCh1',
                        classes: 'kitp-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'algoresCh1',
                        width: 70
                    }, {
                        label: _.i18n('kitp.paramCh1.short'),
                        name: 'paramCh1',
                        classes: 'kitp-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'paramCh1'
                    }
                ],

                onSelectRow: function(rowId, bool, e) {
                    if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
                        if (e.type === 'click') {
                            App.navigate('admin/kitp/' + rowId, {trigger: true});
                        }
                    }
                }
            };

            if (Settings.get('currentCAccount').secId === this.options.data.model.get('refKit').refCAccount.secId) {
                options.colModel.push({
                    label: ' ',
                    name: 'delete',
                    search: false,
                    classes: 'delete-kitp-link delete-link',
                    formatter: this.deleteIconFormatter,
                    width: 20
                });
            }

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        }
    });

    module.exports = KitpListView;
});
