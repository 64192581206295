define('entities/assay',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
    App,
    Backbone,
    Settings,
    $,
    _
) {
  'use strict';

  var Assay = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/assays/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      code: '',
      nbTargets: 1,
      description: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (kitSecId) {
      return Settings.url('compuzz', 'v2/kits/' + kitSecId + '/assays');
    },

    save: function() {
      this.url = this.postUrl(this.get('refKit').secId);
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var AssayCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/kits/');
    },
    model: Assay
  });

  var API = {

    getAssayCollectionUrl: function(kitSecId) {
      new AssayCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/kits/' + kitSecId + '/assays', params);
    },

    getAllAssay: function(kitSecId, params) {
      var url = Settings.url('compuzz', 'v2/kits' + kitSecId + '/assays', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getAssayCountUrl: function(kitSecId) {
      var params = '';
      return 'v2/kits/'+kitSecId+'/assays/rowCount' + params;
    },

    deleteAssay: function(kitSecId, secId){
      var url = Settings.url('compuzz', 'v2/kits/'+kitSecId+'/assays/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('assay:get-collection-url', function(kitSecId) {
    return API.getAssayCollectionUrl(kitSecId);
  });

  App.reqres.setHandler('assay:get-collection-to-page', function(kitSecId, params) {
    return API.getAllAssay(kitSecId, params);
  });

  App.reqres.setHandler('assay:get-count-url', function(kitSecId) {
    return API.getAssayCountUrl(kitSecId);
  });

  App.reqres.setHandler('assay:model', function(itemJSON) {
    return new Assay(itemJSON);
  });

  App.reqres.setHandler('assay:delete', function(kitSecId, secId) {
    return API.deleteAssay(kitSecId, secId);
  });
});
