/**
 * Created by OBL on 14/01/2016.
 */
define('adminKitprController',[
    'module',
    'underscore',
    'app',
    'adminKitprView',
    'bootbox',
    'kitprCreateEditLayoutView',
    'settings',
    'entities/kitpr'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    CreateEditLayoutView
    ) {
    'use strict';

    var SuperAdminKitprListController = {
        showKitprList: function(data) {
            this.data = data;
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'kitpr:delete', _.bind(this.onDelete, this));
            view.listenTo(view, 'kitpr:edit', _.bind(this.onEdit, this));
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('kitprListTab').show(view);
        },

        showKitprAdd: function(kitProtSecId) {
            this.showKitprAddEdit(App.request('kitpr:model', {
                refKitProt: {
                    secId: kitProtSecId
                }
            }), true);
        },

        showKitprAddEdit: function(model, newItem) {
            this.createEditView = new CreateEditLayoutView({
                kitprModel: model,
                newItem: newItem
            });
            this.createEditView.show({
                title: _.i18n('kitpr.new')
            });
        },

        showKitprEdit: function(kitProtSecId, kitprSecId) {
            var that = this;
            var model = App.request('kitpr:model', {
                refKitProt: {
                    secId: kitProtSecId
                },
                secId: kitprSecId
            });
            model.fetch().done(function() {
                that.showKitprAddEdit(model, false);
            });
        },

        onEdit: function(mbKitSecId, kitprSecId){
            App.navigate('admin/kitprots/' + mbKitSecId + '/kitpr/' + kitprSecId + '/edit', {trigger: true});
        },

        onGridLoaded: function() {
            var kitProtSecId = this.data.model.get('secId');
            var data = {
                url: App.request('kitpr:get-collection-url', kitProtSecId),
                rowCountUrl: App.request('kitpr:get-count-url', kitProtSecId)
            };

            this.view.displayGrid(data);
        },

        onDelete: function(kitSecId, secId){
            bootbox.confirm(_.i18n('kitpr.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('kitpr:delete', kitSecId, secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = SuperAdminKitprListController;
});
