define( 'entities/customers/dataset',[
  'app',
  'jquery',
  'backbone',
  'settings',
  'underscore'
], function(
  App,
  $,
  Backbone,
  Settings,
  _
) {
  'use strict';

  /**
   * Model
   */
  var DatasetModel = Backbone.Model.extend({
    defaults: {
      objectName: {
        name: '',
        type: '',
        secId: ''
      },
      fileUid: null,
      value: null,
      secId: null
    },

    parse: function(model) {
      if (model.hasOwnProperty('latestDsv')) {
        if (model.latestDsv) {
          return model.latestDsv;
        } else {
          model.latestDsv = {
            objectName: {
              name: model.name,
              type: model.type,
              secId: model.secId
            },
            fileUid: null,
            value: null,
            secId: null
          };
          return model.latestDsv;
        }
      } else {
        return model;
      }   
    },

    idAttribute: 'secId'
  });

  var ObjectNameModel = Backbone.Model.extend({
    defaults: {
      value: '',
      objectName: {
        secId: '',
        name: '',
        type: 'text',
        code: '',
        endUserId: '',
        refObjectNameCategoryJson: {
          secId: '',
          code: '',
          name: '',
          visibleInTemplateDefinition: false,
          refCAccount: {
            secId: '',
            code: '',
            name: '',
            language: '',
            logo: '',
            address: '',
            zip: '',
            city: '',
            country: '',
            phone: '',
            returnUrl: '',
            exportUrl: '',
            serverUrl: '',
            company: '',
            htmlServerUrl: '',
            vatNumber: '',
            displayPublicPictos: false,
            displayPublicPictures: false,
            contactEmail: '',
            mailFrom: '',
            mailExpert: '',
            encodedValue: '',
            extension: '',
            refMainCAccountJson: {},
            colorSettings: {
              generalColor: '',
              secondColor: '',
              thirdColor: ''
            },
            subDomain: '',
            updateCompositionUrl: '',
            manufacturer: false,
            retailer: false,
            activated: false
          }
        },
        refCAccountJson: {
          secId: '',
          code: '',
          name: '',
          language: '',
          logo: '',
          address: '',
          zip: '',
          city: '',
          country: '',
          phone: '',
          returnUrl: '',
          exportUrl: '',
          serverUrl: '',
          company: '',
          htmlServerUrl: '',
          vatNumber: '',
          displayPublicPictos: false,
          displayPublicPictures: false,
          contactEmail: '',
          mailFrom: '',
          mailExpert: '',
          encodedValue: '',
          extension: '',
          refMainCAccountJson: {},
          colorSettings: {
            generalColor: '',
            secondColor: '',
            thirdColor: ''
          },
          subDomain: '',
          updateCompositionUrl: '',
          manufacturer: false,
          retailer: false,
          activated: false
        },
        templateGroupSecId: '',
        latestDsv: {}
      },
      fileName: '',
      name: '',
      encodedValue: '',
      extension: '',
      imageType: '',
      hasWhite: '',
      fileUid: ''
    },

    saveModel: function(endUserId) {
      this.url = Settings.url('compuzz', 'v2/datasetvalues/' + endUserId);
      this.save();
    },

    deleteModel: function() {
      this.url = Settings.url('compuzz', 'v2/datasetvalues/' + this.get('secId'));
      this.destroy({wait: true});
    },

    idAttribute: 'secId'
  });
  /**
   * Collection
   */
  var Dataset = Backbone.Collection.extend({ 
    getCollection: function(params) {
      var defer = $.Deferred();

      this.url = Settings.url('compuzz', 'v2/objectnames', params);
      this.fetch({
        success: function() {
          defer.resolve();
        }
      });
      return defer.promise();
    },

    model: DatasetModel
  });

  var ObjectName = Backbone.Collection.extend({
    fetch: function() {
      var that = this;
      Backbone.Collection.prototype.fetch.call(this, {
        success: function() {
          that.trigger('fetched');
        }
      });
    },

    getCollection: function(params) {
      var defer = $.Deferred(),
        endUserId = params.endUserId,
        secId = params.secId;

      this.url = Settings.url('compuzz', 'v2/datasetvalues/' + endUserId + '/objectnames/' + secId);
      this.fetch({
        success: function() {
          defer.resolve();
        }
      });
      return defer.promise();
    },

    getModel: function(params) {
      var newModel = new ObjectNameModel();
      if (params) {
        newModel.get('objectName').secId = params.objectName.secId;
        newModel.get('objectName').name = params.objectName.name;
      }
      return newModel;
    },

    saveChangedModels: function(endUserId) {
      var that = this,
        changedCollection = this.filter(function(model) {
          that.listenTo(model, 'sync', that._onModelSaved);
          that.listenTo(model, 'destroy', that._onModelSaved);
          return model.hasChanged('value');
        });
      this.changedLength = changedCollection.length;
      this._saveChangedCollection(changedCollection, endUserId);
    },

    _saveChangedCollection: function(changedCollection, endUserId) {
      if (this.changedLength === 0) {
        this.trigger('models:not-changed');
        return;
      }
      _.each(changedCollection, function(model) {
        if (model.get('value')) {
          model.saveModel(endUserId);
        } else {
          model.deleteModel();
        }
      });
    },

    _onModelSaved: function(model) {
      this.changedLength--;
      if (!this.changedLength) {
        this.trigger('models:saved');
      }
      this.stopListening(model, 'sync');
      this.stopListening(model, 'destroy');
    },

    model: ObjectNameModel
  });

  /**
   * Request communications
   */
  App.reqres.setHandler('dataset:get-model', function() {
    return new DatasetModel();
  });
  App.reqres.setHandler('dataset:collection', function() {
    return new Dataset();
  });
  App.reqres.setHandler('objectname:get-collection', function() {
    return new ObjectName();
  });
});
