define('adminLisTabs',[
  'module',
  'underscore'
], function(
  module,
  _
) {
  'use strict';

  module.exports = [
    {
      name: 'detail',
      title: _.i18n('lis.detail.tabTitle')
    }, {
      name: 'lisana',
      title: _.i18n('lis.lisana.tabTitle')
    }, {
      name: 'lisaserie',
      title: _.i18n('lis.lisaserie.tabTitle')
    }, {
      name: 'lissmpmat',
      title: _.i18n('lis.lissmpmat.tabTitle')
    }
  ];
});
