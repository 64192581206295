define('adminSettingKitpView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!adminSettingKitpListTpl'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl
    ) {
    'use strict';

    var SettingListView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'settingListFilters',

        triggers: {
        },

        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteSetting: '.delete-setting-link',
                editSetting: '.edit-setting-link'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click @ui.deleteSetting': 'onDeleteSetting',
                'click @ui.editSetting': 'onEditSetting'
            }, this.gridEvents);
        },

        serializeData: function() {
            var templateData = {
                type: 'setting',
                data: this.options.data,
                currentCAccountSecId: Settings.get('currentCAccount').secId
            };
            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: Settings.get('perPage15'),
                currentPage: 1
            };
        },

        onDeleteSetting: function(event){
            this.trigger('setting:delete', $(event.currentTarget).find('span').attr('data-row-id'));
        },

        gridOptions: function(data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('setting.sGroup'),
                        name: 'sGroup',
                        classes: 'setting-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'sGroup',
                        width: 50
                    }, {
                        label: _.i18n('common.code'),
                        name: 'code',
                        classes: 'setting-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'code',
                        width: 50
                    }, {
                        label: _.i18n('setting.params'),
                        name: 'params',
                        classes: 'setting-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'params',
                        width: 50
                    }, {
                        label: _.i18n('common.description'),
                        name: 'description',
                        classes: 'setting-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'description'
                    }
                ],

                onSelectRow: _.bind(function(rowId, bool, e) {
                    if (Settings.get('currentCAccount').secId === this.options.data.model.get('refAssay').refKit.refCAccount.secId) {
                        if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
                            if (e.type === 'click') {
                                App.navigate('admin/settings/' + rowId + '/fromkitp', {trigger: true});
                            }
                        }
                    }
                }, this)
            };

            if (Settings.get('currentCAccount').secId === this.options.data.model.get('refAssay').refKit.refCAccount.secId) {
                options.colModel.push({
                    label: ' ',
                    name: 'delete',
                    search: false,
                    classes: 'delete-setting-link delete-link',
                    formatter: this.deleteIconFormatter,
                    width: 20
                });
            }

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        }
    });

    module.exports = SettingListView;
});
