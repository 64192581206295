
define('template!wellTemplateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<span class="col-xs-12 small-regular-compuzz p-l-2 p-r-0">' +
((__t = ( well.smpid )) == null ? '' : __t) +
'</span>\n<span class="col-xs-12 small-regular-compuzz p-l-2 p-r-0">' +
((__t = ( well.refAssay?well.refAssay.code:'' )) == null ? '' : __t) +
'</span>\n';

}
return __p
};});

