define('adminLmbAnasView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!adminLmbAnasListTpl'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl
    ) {
    'use strict';

    var LmbAnaListView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'lmbAnasListFilters',

        triggers: {
        },

        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteLmbAna: '.delete-lmbana-link'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click @ui.deleteLmbAna': 'onDeleteLmbAna'
            }, this.gridEvents);
        },

        serializeData: function() {
            var templateData = {type: 'lmbanas'};
            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: 15,
                currentPage: 1
            };
        },

        onDeleteLmbAna: function(event){
            this.trigger('lmbana:delete', $(event.currentTarget).find('span').attr('data-row-id'));
        },

        gridOptions: function(data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('common.code'),
                        name: 'code',
                        classes: 'lmbana-link',
                        formatter:  this.defaultFormatter,
                        search: true,
                        index: 'code'
                    }, {
                        label: _.i18n('lmbana.refMbAna'),
                        name: 'refMbAna.code',
                        classes: 'lmbana-link',
                        formatter:  this.defaultFormatter,
                        search: true,
                        index: 'mbAnaCode'
                    }, {
                        label: _.i18n('lmbana.shortname'),
                        name: 'shortname',
                        classes: 'lmbana-link',
                        formatter:  this.defaultFormatter,
                        search: true,
                        index: 'shortname'
                    }, {
                        label: _.i18n('lmbana.rptname'),
                        name: 'rptname',
                        classes: 'lmbana-link',
                        formatter:  this.defaultFormatter,
                        search: true,
                        index: 'rptname'
                    }, {
                      label: _.i18n('lmbana.refMbAna.name'),
                      name: 'refMbAna.name',
                      classes: 'lmbana-link',
                      formatter:  this.defaultFormatter,
                      search: true,
                      index: 'mbAnaName'
                    }, {
                        label: _.i18n('lmbana.infectionType'),
                        name: 'refMbAna.infectionType',
                        classes: 'lmbana-link',
                        formatter:  this.defaultFormatter,
                        search: true,
                        index: 'infectionType'
                      }, {
                        label: _.i18n('lmbana.typeTarget'),
                        name: 'refMbAna.typeTarget',
                        classes: 'lmbana-link',
                        formatter:  this.defaultFormatter,
                        search: true,
                        index: 'typeTarget'
                      }, {
                        label: _.i18n('lmbana.eicControl'),
                        name: 'refMbAna.eicControl',
                        classes: 'lmbana-link',
                        formatter:  this.booleanFormatter,
                        search: false,
                        index: 'refMbAna.eicControl'
                      }, {
                        label: ' ',
                        name: 'delete',
                        search: false,
                        classes: 'delete-lmbana-link delete-link',
                        formatter: this.deleteIconFormatter,
                        width: 20
                    }
                ],

                onSelectRow: function(rowId, bool, e) {
                    if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
                        if (e.type === 'click') {
                            var route = App.getCurrentRoute() ===  'admin/lmbanas' ?
                                App.getCurrentRoute() : 'admin/lmbanas/' + App.getCurrentRoute();
                            App.navigate(route + '/' + rowId, {trigger: true});
                        }
                    }
                }
            };

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        }
    });

    module.exports = LmbAnaListView;
});
