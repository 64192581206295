/**
 * Created by RKL on 17/09/2015.
 */
define('customerTemplatesPopupAttachView',[
  'module',
  'template!customerTemplatesPopupAttachTpl',
  'dialogView',
  'app',
  'bootbox',
  'underscore'
], function (
  module,
  viewTpl,
  DialogView,
  App,
  bootbox,
  _
) {
  'use strict';

  var CAccountTemplatesTabPopupViewAttahcments = DialogView.extend({
    template: viewTpl,

    ui: {
      attachment: '.js-attachment'
    },

    events: {
      'click @ui.attachment': 'onSelectAttachment'
    },

    onSelectAttachment: function (e) {
      var target = $(e.currentTarget),
        attachId = target.data('attachment-id'),
        name = target.data('attachment-name'),
        params = {
            attachId: attachId,
            refParent: this.options.refParent,
            importIntoPdfImage : false
        };

      //Caught by elements_controller
        if(name && name.toLowerCase().indexOf('.pdf') >= 0){
            bootbox.dialog({
                message: _.i18n('template.pdfShouldBeEditable.message'),
                title: _.i18n('template.keepOriginalPdf.title'),
                buttons: {
                    no: {
                        label: _.i18n('common.no'),
                        callback:   _.bind(function () {
                                params.importIntoPdfImage = true;
                                App.trigger('template:create-from-attach',
                                    params, _.bind(this.destroy, this));
                            }, this)
                    },
                    yes: {
                        label: _.i18n('common.yes'),
                        callback: _.bind(function () {
                            params.importIntoPdfImage = false;
                            App.trigger('template:create-from-attach',
                                params, _.bind(this.destroy, this));
                        }, this)
                    }
                }
            });
        }
        else{
            App.trigger('template:create-from-attach',
                params, _.bind(this.destroy, this));
            this.destroy();
        }
    },

    onDestroy: function() {
      this.hide();
    }


  });

  module.exports = CAccountTemplatesTabPopupViewAttahcments;
});
