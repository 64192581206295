define('adminKitsView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!adminKitsListTpl'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl
    ) {
    'use strict';

    var KitListView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'kitsListFilters',

        triggers: {
        },

        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteKit: '.delete-kit-link'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click @ui.deleteKit': 'onDeleteKit'
            }, this.gridEvents);
        },

        serializeData: function() {
            var templateData = {type: 'kits'};

            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: 9999,
                currentPage: 1
            };
        },

        onDeleteKit: function(event){
            this.trigger('kit:delete', $(event.currentTarget).find('span').attr('data-row-id'));
        },

        gridOptions: function(data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('kit.productCode'),
                        name: 'code',
                        classes: 'kit-link',
                        formatter:  this.defaultFormatter,
                        search: true,
                        index: 'code',
                        width: 40,
                        searchoptions: {
                          sopt: ['cn']
                        }
                    }, {
                        label: _.i18n('kit.ordRefName'),
                        name: 'ordRefName',
                        classes: 'kit-link',
                        formatter:  this.defaultFormatter,
                        search: true,
                        index: 'ordRefName',
                        width: 40,
                        searchoptions: {
                          sopt: ['cn']
                        }
                      }, {
                        label: _.i18n('common.supplier'),
                        name: 'refCAccount.code',
                        classes: 'kit-link',
                        formatter:  this.defaultFormatter,
                        search: true,
                        index: 'refCAccount.code',
                        width: 50,
                        searchoptions: {
                          sopt: ['cn']
                        }
                    }, {
                        label: _.i18n('kit.refKitProt'),
                        name: 'refKitProt.code',
                        classes: 'kit-link',
                        formatter:  this.defaultFormatter,
                        search: true,
                        index: 'refKitProt.code',
                        width: 50,
                        searchoptions: {
                          sopt: ['cn']
                        }
                      }, {
                        label: _.i18n('common.description'),
                        name: 'description',
                        classes: 'kit-link',
                        formatter:  this.defaultFormatter,
                        search: true,
                        index: 'description',
                        searchoptions: {
                          sopt: ['cn']
                        }
                    }, {
                        label: ' ',
                        name: 'delete',
                        search: false,
                        classes: 'delete-kit-link delete-link',
                        formatter: this.deleteIconFormatter,
                        width: 20
                    }
                ],

                onSelectRow: function(rowId, bool, e) {
                    if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
                        if (e.type === 'click') {
                            var route = App.getCurrentRoute() ===  'admin/kits' ?
                                App.getCurrentRoute() : 'admin/kits/' + App.getCurrentRoute();
                            App.navigate(route + '/' + rowId, {trigger: true});
                        }
                    }
                },

                loadonce: true
            };

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        }
    });

    module.exports = KitListView;
});
