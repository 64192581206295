/**
 * Created by RKL on 19/08/2015.
 */
define('customerTemplatesPopupAddNewView',[
  'module',
  'template!customerTemplatesPopupAddNewTpl',
  'dialogView',
  'bootbox',
  'app',
  'customerTemplatesPopupAddEmptyView',
  'customerTemplatesPopupAttachView',
  'underscore',
  'customerCommonPopupFromTemplateView',
  'logotoolUtils'
], function (
  module,
  viewTpl,
  DialogView,
  bootbox,
  App,
  AddEmptyView,
  FromAttachView,
  _,
  FromTemplateView,
  logotoolUtils
) {
  'use strict';

  var CAccountTemplatesTabPopupViewAddNew = DialogView.extend({
    template: viewTpl,

    ui: {
      fromEmpty: '.js-from-empty',
      fromUpload: '.js-from-upload',
      fromAttach: '.js-from-attach',
      fromTemplate: '.js-from-template'
    },

    events: {
      'change @ui.fromUpload': 'onUploadImage',
      'click @ui.fromEmpty': 'popupFromEmpty',
      'click @ui.fromAttach': 'popupFromAttach',
      'click @ui.fromTemplate': 'popupFromTemplate'
    },

    serializeData: function() {
      return {
        logotoolUrl: logotoolUtils.getLogotoolUrl({
          endUserSecId: this.options.customerSecId,
          createTemplate: true
        }),
        showFromProduct: this.options.showFromProduct
      };
    },

    onUploadImage: function() {
      var params = {
          formdata: new FormData()
        };

      if(!this.ui.fromUpload[0].files.length) {
        return;
      }

      if (this.options.refParent) {
        params.refParent = {secId: this.options.refParent.secId};
      }

      var hasPdf = false;

      _.each(this.ui.fromUpload[0].files, function(file) {
        params.formdata.append('file', file);
        params.formdata.append('filename', file.name);
        if(file.name && file.name.toLowerCase().indexOf('.pdf') >= 0) {
            hasPdf = true;
        }
      });

        if(hasPdf){
              bootbox.dialog({
                message: _.i18n('template.pdfShouldBeEditable.message'),
                title: _.i18n('template.keepOriginalPdf.title'),
                buttons: {
                  no: {
                    label: _.i18n('common.no'),
                    callback: function () {
                      App.trigger('template:create-from-upload', params, true);
                    }
                  },
                  yes: {
                    label: _.i18n('common.yes'),
                    callback: function () {
                      App.trigger('template:create-from-upload', params, false);
                    }
                  }
                }
              });
        }
        else{
            App.trigger('template:create-from-upload', params, false);
        }

      this.hide();
    },

    popupFromEmpty: function() {
      this.popupView = new AddEmptyView({
        model: this._createNewModel(),
        standardSizes: this.options.groupModel.get('standardSizes'),
        templateParent: this.options.templateParent
      });

      this.popupView.show({title: _.i18n('template.popup.addFromEmpty')});
      $.material.init();
      this.hide();
    },

    popupFromAttach: function() {
      var that = this,
        secId = this.options.customerSecId,
        attachCollection = App.request(
            'caccount:attachments',
            'customer',
            secId
          );

      attachCollection.fetch().done(_.bind(function () {
        that.popupView = new FromAttachView({
          collection: attachCollection,
          refParent: this.options.refParent
        });
        that.destroy();
        that.popupView.show({
          title: _.i18n('template.popup.addFromAttachment')
        });
        $.material.init();
      }, this));
    },

    popupFromTemplate: function() {
      var fromTemplateView = new FromTemplateView({
        standardSizes: this.options.groupModel.standardSizes,
        groupModel: this.options.groupModel,
        templatesCollection: App.request('caccount:templates-collection'),
        refParent: this.options.refParent,
        newModel: App.request('caccount:template-model'),
        templateParent: this.options.templateParent
      });
      fromTemplateView.show({
        title: _.i18n('template.popup.addFromTemplate')
      });
      $.material.init();
      this.hide();
    },

    onDestroy: function() {
      this.hide();
    },

    _createNewModel: function () {
      var newModel = App.request('caccount:template-model');
      newModel.set({
        refTemplateGroup: {secId: this.options.groupModel.get('secId')},
        endUserId: this.options.endUserId
      });

      if (this.options.refParent) {
        newModel.set('refParent', {secId: this.options.refParent.secId});
      }

      return newModel;
    }
  });

  module.exports = CAccountTemplatesTabPopupViewAddNew;
});
