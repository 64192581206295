/**
 * Created by RKL on 07/09/2015.
 */
define('smpResDetailsController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'smpResDetailsView',
  'emptyView',
  'smpResDetailTabController',
  'smpResDetailsController',
  'rolesMixin',
  'settings',
  'smpResTabs',
  'entities/smpres'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  smpResDetailTabController
) {
  'use strict';

  var SmpResDetailsController = {

    fetchSmpRes: function(data) {
      var that = this,
          emptyView = new EmptyView(),
          contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.secId) {
        this.showSmpRes(data);
      } else {
        this.model = App.request('smpres:model', {
          secId: data.secId,
          refSample: {
            secId: data.sampleSecId
          }
        });
        this.model.fetch().done(function() {
          if (contentRegion) {
            that.showSmpRes(data);
          }
        });
      }
    },

    showSmpRes: function(data) {
      var view = new View({model: this.model, data: data});

      this.view = view;
      data.model = this.model;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      this.displayTabs(data);

      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
      view.listenTo(App, 'smpres:header:rerender', _.bind(this.onHeaderChange, this));
    },

    onHeaderChange: function (smpres) {
      this.view.onHeaderChange(smpres);
    },

    displayTabs: function(data) {
      smpResDetailTabController.showTab(data);
      data.model = this.model;
      this.tab = data.tab;
    },

    onTabClick: function(tab) {
      if (this.data.tab !== tab || this.data.secId) {
        this.data.tab = tab;
        this.data.secId = null;

        this.displayTabs(this.data);
        App.navigate('superAdmin/smpress/' + this.model.get('secId') + '/' + tab.name);
      }
    }
  };

  module.exports = SmpResDetailsController;
});
