define('adminLisSmpMatView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!adminLisSmpMatListTpl'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl
    ) {
    'use strict';

    var LisSmpMatListView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'lisSmpMatListFilters',

        triggers: {
        },

        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteLisSmpMat: '.delete-lissmpmat-link'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click @ui.deleteLisSmpMat': 'onDeleteLisSmpMat'
            }, this.gridEvents);
        },

        serializeData: function() {
            var templateData = {
                type: 'lissmpmat',
                data: this.options.data
            };
            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: Settings.get('perPage15'),
                currentPage: 1
            };
        },

        onDeleteLisSmpMat: function(event){
            this.trigger('lissmpmat:delete', this.options.data.model.get('secId'), $(event.currentTarget).find('span').attr('data-row-id'));
        },

        gridOptions: function(data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('common.code'),
                        name: 'code',
                        classes: 'lissmpmat-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'code',
                        width: 30
                    }, {
                        label: _.i18n('lissmpmat.refLmbSmpMat'),
                        name: 'refLmbSmpMat.code',
                        classes: 'lissmpmat-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'refLmbSmpMat.code',
                        width: 30
                    }, {
                        label: _.i18n('lissmpmat.smpSfx'),
                        name: 'smpSfx',
                        classes: 'lissmpmat-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'smpSfx'
                    }, {
                        label: ' ',
                        name: 'delete',
                        search: false,
                        classes: 'delete-lissmpmat-link delete-link',
                        formatter: this.deleteIconFormatter,
                        width: 20
                    }
                ],

                onSelectRow: function(rowId, bool, e) {
                    if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
                        if (e.type === 'click') {
                            App.navigate('admin/lissmpmats/' + rowId, {trigger: true});
                        }
                    }
                }
            };

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        }
    });

    module.exports = LisSmpMatListView;
});
