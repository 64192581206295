/**
 * Created by OBL on 14/01/2016.
 */
define('adminSettingKitpController',[
    'module',
    'underscore',
    'app',
    'adminSettingKitpView',
    'bootbox',
    'settingCreateEditLayoutView',
    'settings',
    'entities/setting'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    CreateEditLayoutView
    ) {
    'use strict';

    var AdminSettingListController = {
        showSettingList: function(data) {
            this.data = data;
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'setting:delete', _.bind(this.onDelete, this));
            view.listenTo(view, 'setting:edit', _.bind(this.onEdit, this));
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('settingListTab').show(view);
        },

        showSettingAdd: function(kitpSecId) {
            this.showSettingAddEdit(App.request('setting:model', {
                refKitP: {
                    secId: kitpSecId
                }
            }), true);
        },

        showSettingAddEdit: function(model, newItem) {
            this.createEditView = new CreateEditLayoutView({
                settingModel: model,
                newItem: newItem
            });
            this.createEditView.show({
                title: _.i18n('setting.new')
            });
        },

        showSettingEdit: function(kitpSecId, settingSecId) {
            var that = this;
            var model = App.request('setting:model', {
                refKitP: {
                    secId: kitpSecId
                },
                secId: settingSecId
            });
            model.fetch().done(function() {
                that.showSettingAddEdit(model, false);
            });
        },

        onEdit: function(mbKitSecId, settingSecId){
            App.navigate('admin/assays/' + mbKitSecId + '/setting/' + settingSecId + '/edit', {trigger: true});
        },

        onGridLoaded: function() {
            var data = {
                url: App.request('setting:get-collection-url-kitp', this.data.model.get('secId')),
                rowCountUrl: App.request('setting:get-count-url-kitp', this.data.model.get('secId'))
            };

            this.view.displayGrid(data);
        },

        onDelete: function(kitSecId, secId){
            bootbox.confirm(_.i18n('setting.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('setting:delete', kitSecId, secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = AdminSettingListController;
});
