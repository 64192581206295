/**
 * Created by OLD on 30/10/2015.
 */
define('superAdminOverviewController',[
  'module',
  'logger',
  'underscore',
  'app',
  'moment',
  'backbone',
  'emptyView',
  'superAdminOverviewView',
  'superAdminAccountStatView',
  'superAdminCreditStatView',
  'superAdminCreditGraphView',
  'superAdminAccountGraphView',
  'superAdminTicketsGraphView',
  'superAdminPopupView',
  'entities/superadmin'
], function(
  module,
  Logger,
  _,
  App,
  moment,
  Backbone,
  EmptyView,
  View,
  AccountStatView,
  CreditStatView,
  CreditGraphView,
  AccountGrahView,
  TicketsGraphView,
  PopupView
) {
  'use strict';

  var SuperAdminController = {
    showSuperAdminTab: function() {
      var that = this,
        accountRowRequest = App.request('superadmin:account-rowcount'),
        creditRowRequest = App.request('superadmin:credits-rowcount'),
        accountsRequest = App.request('superadmin:stats-account'),
        creditRequest = App.request('superadmin:stats-credit'),
        creditGraphRequest = App.request('superadmin:stats-credit-graph', this.getDefaultFilters()),
        accountGraphRequest = App.request('superadmin:stats-account-graph', this.getDefaultFilters()),
        ticketsGraphRequest = App.request('superadmin:stats-tickets-graph', this.getDefaultFilters());

      this.view = new View();
      this.view.listenTo(this.view, 'superadmin:on-edit', _.bind(this.onEdit, this));
      App.regions.getRegion('main')
        .currentView.getRegion('content')
        .currentView.getRegion('saTabContentRegion').show(this.view);
      
      this.view.getRegion('accounsStats').show(new EmptyView());
      this.view.getRegion('creditsStats').show(new EmptyView());
      this.view.getRegion('creditsGraph').show(new EmptyView());
      this.view.getRegion('accountGraph').show(new EmptyView());
      this.view.getRegion('ticketsGraph').show(new EmptyView());
      $.when(accountRowRequest,
        accountsRequest)
          .done(function(accountRow,
            accountsStats
          ) {
        that.accountPagination = {
          first: 0,
          currentPage: 0,
          pageSize: 10,
          total: accountRow,
          totalPages: accountRow % 10 ? (accountRow/10 + 1).toFixed(0) : accountRow/10
        };
        that.onCreateAccountStatView(accountsStats);
      });
      $.when(creditRowRequest, creditRequest)
        .done(function(creditRow, creditStats) {
          that.creditPagination = {
            first: 0,
            currentPage: 0,
            pageSize: 10,
            total: creditRow,
            totalPages: creditRow % 10 ? (creditRow/10 + 1).toFixed(0) : creditRow/10
          };
          that.onCreateCreditStatView(creditStats);
      });
      $.when(creditGraphRequest)
        .done(function(creditGraph) { 
          that.onCreateCreditGraphView(creditGraph);
      });
      $.when(accountGraphRequest)
        .done(function(accountGraph) { 
          that.onCreateAccountGraphView(accountGraph);
      });
      $.when(ticketsGraphRequest)
        .done(function(ticketsGraph) { 
          that.onCreateTicketsGraphView(ticketsGraph);
      });
    },

    onCreateAccountStatView: function(model) {
      this.accountStatView = new AccountStatView({pagin: this.accountPagination});
      this.accountStatView.model = new Backbone.Model(model);

      this.accountStatView.listenTo(
        this.accountStatView, 
        'superadmin:update-account-table',
        _.bind(this.updateAccountStatView, this));
      var region = this.view.getRegion('accounsStats');
      if (region){
        region.show(this.accountStatView);
      }
    },

    onCreateCreditStatView: function(model) {
      this.creditStatView = new CreditStatView({pagin: this.creditPagination});
      this.creditStatView.model = new Backbone.Model(model);

      this.creditStatView.listenTo(
        this.creditStatView, 
        'superadmin:update-credit-table',
        _.bind(this.updateCreditStatView, this));
      var region = this.view.getRegion('creditsStats');
      if (region){
        region.show(this.creditStatView);
      }
    },

    onCreateCreditGraphView: function(model) {
      var filters = this.creditFilters ? this.creditFilters : this.getDefaultFilters();

      this.creditGraphView = new CreditGraphView({type: 'line', filters: filters});
      this.creditGraphView.model = new Backbone.Model(model);
      var region = this.view.getRegion('creditsGraph');
      if (region){
        region.show(this.creditGraphView);
      }
    },

    onCreateAccountGraphView: function(model) {
      var filters = this.accountFilters ? this.accountFilters : this.getDefaultFilters();

      this.accountGraphView = new AccountGrahView({type: 'line', filters: filters});
      this.accountGraphView.model = new Backbone.Model(model);
      var region = this.view.getRegion('accountGraph');
      if (region){
        region.show(this.accountGraphView);
      }
    },

    onCreateTicketsGraphView: function(model) {
      var filters = this.ticketFilters ? this.ticketFilters : this.getDefaultFilters();

      this.statusData = {
        'OPEN':     {data: [], color: 'rgba(0, 190, 164, 0.64)'},
        'CLOSE':    {data: [], color: 'rgba(226, 147, 239, 0.64)'},
        'PROCESS':  {data: [], color: 'rgba(0, 190, 32, 0.64)'},
        'REVIEW':   {data: [], color: 'rgba(0, 9, 190, 0.64)'},
        'PENDING':  {data: [], color: 'rgba(178, 190, 0, 0.64)'},
        'DONE':     {data: [], color: 'rgba(190, 0, 18, 0.64)'},
        'CANCELED': {data: [], color: 'rgba(240, 139, 14, 0.64)'}
      };

      this.ticketsGraphView = new TicketsGraphView({
        type: 'Bar',
        filters: filters,
        statusData: this.statusData
      });
      this.ticketsGraphView.model = new Backbone.Model(model);
      var region = this.view.getRegion('ticketsGraph');
      if (region){
        region.show(this.ticketsGraphView);
      }
    },

    updateAccountStatView: function(paging) {
      var that = this;

      this.accountPagination = paging;
      $.when(App.request('superadmin:stats-account', paging)).done(function(resp) {
        that.accountStatView.destroy();
        that.onCreateAccountStatView(resp);
      });
    },

    updateCreditStatView: function(paging) {
      var that = this;

      this.creditPagination = paging;
      $.when(App.request('superadmin:stats-credit', paging)).done(function(resp) {
        that.creditStatView.destroy();
        that.onCreateCreditStatView(resp);
      });
    },

    onEdit: function(target) {
      var popupTitle, filters;

      if (target.hasClass('credits-graph')) {
        popupTitle = _.i18n('popup.superadmin.creditsGraph');
        filters = this.creditFilters ? this.creditFilters : this.getDefaultFilters();
        filters.popupType = 'superadmin:stats-credit-graph';
      } else if (target.hasClass('account-graph')) {
        popupTitle = _.i18n('popup.superadmin.accountGraph');
        filters = this.accountFilters ? this.accountFilters : this.getDefaultFilters();
        filters.popupType = 'superadmin:stats-account-graph';
      } else if (target.hasClass('tickets-graph')) {
        popupTitle = _.i18n('popup.superadmin.ticketsGraph');
        filters = this.ticketFilters ? this.ticketFilters : this.getDefaultFilters();
        filters.popupType = 'superadmin:stats-tickets-graph';
      }
      this.popupView = new PopupView({
        filters: filters,
        statusData: this.statusData
      });
      this.popupView.listenTo(
        this.popupView, 
        'superadmin:change-date', 
        _.bind(this.onChangeData, this)
      );
      this.popupView.listenTo(
        this.popupView,
        'clear:filters',
        _.bind(this.onClearFilter, this)
      );
      this.popupView.show({
        title: popupTitle,
        className: 'superadmin-stat'
      });
    },

    onChangeData: function(filters) {
      var that = this;

      this.popupView.hide();
      $.when(App.request(filters.popupType, filters)).done(function(resp) {
        if (filters.popupType === 'superadmin:stats-credit-graph') {
          that.creditFilters = filters;
          that.creditGraphView.destroy();
          that.onCreateCreditGraphView(resp);
        } else if (filters.popupType === 'superadmin:stats-account-graph') {
          that.accountFilters = filters;
          that.accountGraphView.destroy();
          that.onCreateAccountGraphView(resp);
        } else if (filters.popupType === 'superadmin:stats-tickets-graph') {
          that.ticketFilters = filters;
          that.ticketsGraphView.destroy();
          that.onCreateTicketsGraphView(resp);
        }
      });
    },

    onClearFilter: function(filters) {
      switch(filters.popupType) {
        case 'superadmin:stats-credit-graph':
          this.creditFilters = this.getDefaultFilters();
          this.creditFilters.popupType = filters.popupType;
          this.popupView.options.filters = this.creditFilters;
          this.popupView.render();
          break;
        case 'superadmin:stats-account-graph':
          this.accountFilters = this.getDefaultFilters();
          this.accountFilters.popupType = filters.popupType;
          this.popupView.options.filters = this.accountFilters;
          this.popupView.render();
          break;
        case 'superadmin:stats-tickets-graph':
          this.ticketFilters = this.getDefaultFilters();
          this.ticketFilters.popupType = filters.popupType;
          this.popupView.options.filters = this.ticketFilters;
          this.popupView.render();
          break;
        default:
          throw 'Unsupported popupType: ' + filters.popupType;
      }
    },

    getDefaultFilters: function() {
      return {
          dateFrom: +moment().subtract(6, 'month').format('x'),
          dateTo: +moment().format('x'),
          type: 'Month',
          statusFilters: []
        };
    }
  };

  module.exports = SuperAdminController;
});

