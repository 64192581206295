/**
 * Created by RKL on 18/08/2015.
 */
define('customerLogosImagesNewItemView',[
  'module',
  'marionette',
  'backbone',
  'underscore',
  'app',
  'settings',
  'template!customerLogosImagesNewItemTpl',
  'entities/caccounts/attachments'
], function (
  module,
  Marionette,
  Backbone,
  _,
  App,
  Settings,
  viewTpl
) {
  'use strict';

  var CustomersLogosImagesTabNewItemView = Marionette.ItemView.extend({
    template: viewTpl,

    events: {
      'click': 'onAddNewTemplate'
    },

    tagName: 'div',

    className: 'original-img-wrapper zoom-img template-item template-new-item',

    onAddNewTemplate: function() {
      App.trigger('elements:add-item', this.model, this);
    }
  });

  module.exports = CustomersLogosImagesTabNewItemView;
});
