
define('template!customerDatasetsCollectionItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-control">\n\t<div class="input-group col-xs-12 show-on-hover">\n\t\t<input type="text" class="form-control js-popup-input" \n\t\tdata-field-name="' +
((__t = ( model.objectName.name )) == null ? '' : __t) +
'" \n\t\tdata-secid="' +
((__t = ( model.secId )) == null ? '' : __t) +
'"\n\t\tdata-objname-secid="' +
((__t = ( model.objectName.secId )) == null ? '' : __t) +
'"\n\t\tplaceholder=""\n\t\tvalue="' +
((__t = ( model.value )) == null ? '' : __t) +
'">\n\t\t';
 if (!first) { ;
__p += '\n\t\t<span class="input-group-btn clickable">\n\t\t\t<i class="mdi-action-delete js-delete-btn"></i>\n\t\t</span>\n\t\t';
 } ;
__p += '\n\t</div>\n</div>\n';

}
return __p
};});

