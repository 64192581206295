define('entities/common',[
  'app',
  'jquery',
  'backbone',
  'underscore',
  'settings'
], function(
  App,
  $,
  Backbone,
  _,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  /**
   * REST API
   */
  var API = {
    getRowCount: function(urlParams) {
      var params = {},
      defer = $.Deferred();

      _.each(urlParams.filters, function (item, key) {
        if (item) {
          params[key] = item;
        }
      });
       $.ajax({
        url: Settings.url('compuzz', urlParams.rowCountUrl, params),
        success: function(result) {
          defer.resolve(result);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('common:get-row-count', function(urlParams) {
    return API.getRowCount(urlParams);
  });
});
