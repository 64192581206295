/**
 * Created by BFR on 07/09/2015.
 */
define('adminSettingDetailsController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'adminSettingDetailsView',
  'emptyView',
  'settingDetailTabController',
  'rolesMixin',
  'settings',
  'adminSettingTabs',
  'entities/setting'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  settingDetailTabController
) {
  'use strict';

  var SettingsDetailsController = {
    fetchSetting: function(data) {
      var that = this,
          emptyView = new EmptyView(),
          contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.secId) {
        this.showSetting(data);
      } else {
        this.model = App.request('setting:model', {secId: data.secId});
        this.model.fetch().done(function() {
          if (contentRegion) {
            that.showSetting(data);
          }
        });
      }
    },

    showSetting: function(data) {
      var view = new View({model: this.model, data: data});

      this.view = view;
      data.model = this.model;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      this.displayTabs(data);

      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
      view.listenTo(App, 'setting:header:rerender', _.bind(this.onHeaderChange, this));
    },

    onHeaderChange: function (setting) {
      this.view.onHeaderChange(setting);
    },

    displayTabs: function(data) {
      for (var region in this.view.regions) {
        if (App.regions.getRegion('main') && App.regions.getRegion('main').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content') &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region)) {
          var currentViewRegion = App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region).currentView;
          if(currentViewRegion) {
            currentViewRegion.destroy();
          }
        }
      }

      settingDetailTabController.showTab(data);

      data.model = this.model;
      this.tab = data.tab;
    },

    onTabClick: function(tab) {
      if (this.data.tab !== tab || this.data.secId) {
        this.data.tab = tab;
        this.data.secId = null;

        this.displayTabs(this.data);
        App.navigate('admin/settings/' + this.model.get('secId') + '/' + tab.name);
      }
    }
  };

  module.exports = SettingsDetailsController;
});
