/**
 * Created by OLD on 25/11/2015.
 */
define('wellTemplateTableView',[
  'module',  
  'marionette',
  'underscore',
  'app',
  'settings',
  'jqgridView',
  'template!wellTemplateTableTpl'
], function(
  module,
  Marionette,
  _,
  App,
  Settings,
  JqGridView,
  viewTpl
) {
  'use strict';

  var WellTemplateTableView = JqGridView.extend({
    filterEnable: false,
    GRID_MIN_WIDTH: 300,
    template: viewTpl,

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-well',
        jqgRow: 'tr.jqgrow',
        editRefAssayBtn: '.edit-refassay-button',
        editRefKitLBtn: '.edit-refkitl-button',
        editSmpIdBtn: '.edit-smpid-button'
      }, this.gridUi);
    },

    events: {
      'click @ui.editRefAssayBtn': 'onEditRefAssayBtnClick',
      'click @ui.editRefKitLBtn': 'onEditRefKitLBtnClick',
      'click @ui.editSmpIdBtn': 'onEditSmpIdBtnClick'
    },

    onEditRefAssayBtnClick: function() {
      this.trigger('welltemplate-table:edit-ref-assay', this.ui.jqGrid.getRowData());
    },

    onEditRefKitLBtnClick: function() {
      this.trigger('welltemplate-table:edit-ref-kitl', this.ui.jqGrid.getRowData());
    },

    onEditSmpIdBtnClick: function() {
      this.trigger('welltemplate-table:edit-smpid', this.ui.jqGrid.getRowData());
    },

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: 100,
        currentPage: 1
      };
    },

    gridOptions: function(data) {
       var defaultsOptions, options, 
        _colModel = [{
          label: '',
          name: 'secId',
          key: true,
          hidden: true
        },
        {
          label: _.i18n('well.pos'),
          name: 'pos',
          classes: 'well-list-pos-label',
          formatter: this.defaultFormatter,
          search: false,
          index: 'pos',
          width: 80
        }, {
          label: _.i18n('well.smpid'),
          name: 'smpid',
          classes: 'well-list-label',
          formatter: this.defaultFormatter,
          search: false,
          index: 'smpid',
          width: 80
        }, {
          label: _.i18n('well.refAssay'),
          name: 'refAssay.code',
          classes: 'well-list-label',
          formatter: this.defaultFormatter,
          search: false,
          index: 'refAssay.code'
        }, {
            label: _.i18n('well.refKitL'),
            name: 'refKitL.lotNb',
            classes: 'well-list-label',
            formatter: this.defaultFormatter,
            search: false,
            index: 'refKitL.lotNb'
          }];
      
      defaultsOptions = this.gridInitOptions(data);
      options = {colModel: _colModel};
      options = _.defaults(options, defaultsOptions);

      return options;
    },

    onLoadComplete: function(data) {
      this.codeTableData = data;
      if(data && data.length) {
        this.ui.jqGrid.setSelection(data[0].secId).click();
      }
    },

    onPreselectRow: function(rowId) {
      if (this.codeTableData) {
        this.ui.jqgRow.removeClass('btn-secondary');
        this.ui.jqGrid.find('tr#' + rowId).addClass('btn-secondary');
        this.trigger('welltemplate-table:loaded', rowId);
      } else {
        return;
      }
    },

    onCheckRowCount: function(rowCount) {
      if (rowCount === 0) {
        this.trigger('welltemplate:check-rowcount');
      }
    }
  });

  module.exports = WellTemplateTableView;
});
