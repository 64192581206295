
define('template!customerTemplatesPopupAttachTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n\t<div class="from-attachment-item-list">\n\t\t';
 _.each(items, function (attachment) { ;
__p += '\n      <div class="display-inline-block p-t-10 p-r-10">\n        <div class="image-texture-and-background js-attachment clickable" data-attachment-name="' +
((__t = ( attachment.fileName )) == null ? '' : __t) +
'" data-attachment-id="' +
((__t = ( attachment.id )) == null ? '' : __t) +
'">\n          <img src="' +
((__t = ( attachment.fileLink )) == null ? '' : __t) +
'"/>\n        </div>\n      </div>\n\t\t';
 }); ;
__p += '\n\t</div>\n</div>';

}
return __p
};});

