/**
 * Created by OBL on 14/01/2016.
 */
define('superAdminMbAnaResGrsController',[
    'module',
    'underscore',
    'app',
    'superAdminMbAnaResGrsView',
    'bootbox',
    'mbAnaResGrCreateEditLayoutView',
    'settings',
    'entities/mbanaresgrs'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    CreateEditLayoutView
    ) {
    'use strict';

    var SuperAdminMbAnaResGrListController = {
        showMbAnaResGrList: function(data) {
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'mbanaresgr:delete', _.bind(this.onDelete, this));
            App.regions.getRegion('main').currentView.getRegion('content').show(view);
        },

        showMbAnaResGrAdd: function() {
            this.createEditView = new CreateEditLayoutView({
                mbanaresgrModel: App.request('mbanaresgr:model'),
                newItem: true
            });
            this.createEditView.show({
                title: _.i18n('mbanaresgr.new')
            });
        },

        onGridLoaded: function() {
            var data = {
                url: App.request('mbanaresgr:get-collection-url'),
                rowCountUrl: App.request('mbanaresgr:get-count-url')
            };

            this.view.displayGrid(data);
        },

        onDelete: function(secId){
            bootbox.confirm(_.i18n('mbanaresgr.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('mbanaresgr:delete', secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = SuperAdminMbAnaResGrListController;
});
