define('entities/smpres',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var SmpRes = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/smpres/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      resSt: '',
      resCode: '',
      resNum1: '',
      resNum2: '',
      resNum3: '',
      resCom: '',
      orderedSt: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (sampleSecId) {
      return Settings.url('compuzz', 'v2/samples/' + sampleSecId + '/smpres');
    },

    save: function() {
      this.url = this.postUrl(this.get('refSample').secId);
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var SmpResCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/samples/');
    },
    model: SmpRes
  });

  var API = {

    getSmpResCollectionUrl: function(sampleSecId) {
      new SmpResCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/samples/' + sampleSecId + '/smpres', params);
    },

    getAllSmpRes: function(sampleSecId, params) {
      var url = Settings.url('compuzz', 'v2/samples' + sampleSecId + '/smpres', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var samplet = [];
          _.each(items, function(item){
            samplet.push(item.secId);
          });
          defer.resolve(samplet);
        }
      });

      return defer.promise();
    },

    getSmpResCountUrl: function(sampleSecId) {
      var params = '';
      return 'v2/samples/'+sampleSecId+'/smpres/rowCount' + params;
    },

    deleteSmpRes: function(sampleSecId, secId){
      var url = Settings.url('compuzz', 'v2/samples/'+sampleSecId+'/smpres/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('smpres:get-collection-url', function(sampleSecId) {
      return API.getSmpResCollectionUrl(sampleSecId);
  });

  App.reqres.setHandler('smpres:get-collection-to-page', function(sampleSecId, params) {
      return API.getAllSmpRes(sampleSecId, params);
  });

  App.reqres.setHandler('smpres:get-count-url', function(sampleSecId) {
    return API.getSmpResCountUrl(sampleSecId);
  });

  App.reqres.setHandler('smpres:model', function(itemJSON) {
    return new SmpRes(itemJSON);
  });

  App.reqres.setHandler('smpres:delete', function(sampleSecId, secId) {
    return API.deleteSmpRes(sampleSecId, secId);
  });
});
