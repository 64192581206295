/**
 * Created by RKL on 28/08/2015.
 */
define('jqgridView',[
  'module',
  'jquery',
  'marionette',
  'underscore',
  'app',
  'settings',
  'jqgridFormatters',
  'dateUtils',
  'imageUtils',
  'colorUtils',
  'jqgrid',
  'entities/common'
], function (
  module,
  $,
  Marionette,
  _,
  App,
  Settings,
  jqGridFormatters,
  dateConverter,
  imageUtils,
  ColorUtils
) {
  'use strict';

  var JqGridView =  Marionette.LayoutView.extend({
    filterEnable: true,
    defaultFilters: {},
    GRID_MIN_WIDTH: 1000,

    gridUi: {
      jqGridInput : '.ui-search-input input',
      jqGridSelect: '.ui-search-input select',
      jqGridClear : '.ui-search-clear',
      gridCellWithoutImage: 'td[role=gridcell]:not(.image-cell, .without-tooltip)'
    },

    gridEvents: {
      'change @ui.jqGridSelect' : 'onGridSelectClick',
      'change  @ui.jqGridInput'  : 'onGridFilterChange',
      'click  @ui.jqGridClear'  : 'onGridFilterChange'
    },

    //Could be used in child views
    imagePopoverContainer: '',

    initialize: function() {
      this.selectDefault = _.i18n('common.all');
    },

    preselectRow: function(e, rowid) {
      this.hidePopover(rowid);
      this.triggerMethod('preselectRow', rowid);
    },

    hidePopover: function(rowid) {
      var $row = this.$('#' + rowid),
        $image = $row.find('.jq-picture');
      $image.popover('hide');
    },

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage'),
        currentPage: 1
      };
    },

    onRender : function() {
      this.filters = {};
      this.pagination = _.extend({}, this.pagination());

      if (this.getSelectValues) {
        this.getSelectValues();
      }

      if (Settings.jqGridLoaded) {
        this.onGridLoaded();
      } else {
        App.on('jqGrid:loaded', _.bind(this.onGridLoaded, this));
      }
      this.ui.jqGrid.bind('jqGridSelectRow', _.bind(this.preselectRow, this));
    },

    gridInitOptions: function(data) {
      var that = this,
        postData;

      this.sidx = this.sidx || '';
      this.sord = this.sord || 'asc';
      this.displayRowToolTip = data.displayRowToolTip || false;
      postData = Settings.getFromMemory(this.filtersName);
      if (postData) {
        this.sidx = postData.sidx;
        this.sord = postData.sord;
        this.pagination.currentPage = postData.page;
      }

      return {
        regional : Settings.get('lang'),
        url: data.url,
        mtype: 'GET',
        datatype: 'json',
        colModel: [],
        viewrecords: true,
        autowidth: true,
        height: '100%',
        rowNum: that.pagination.pageSize,
        page: that.pagination.currentPage,
        jsonReader: {
          page: function(obj) {
            if(obj.length === 0) {
              data.rowCount = 1;
              return 1;
            }
          },
          total: function() {
            return Math.ceil(data.rowCount/that.pagination.pageSize);
          },
          records: function() { return data.rowCount; }
        },
        sortname: this.sidx,
        sortorder: this.sord,
        postData: postData || {
          pageSize: that.pagination.pageSize,
          first: that.pagination.first
        },
        pager: that.ui.jqGridPager,

        onPaging: function(pgButton) {
          var jqGridPostData = that.ui.jqGrid.getPostData(),
            currentPage = that.pagination.currentPage,
            pageSize = that.pagination.pageSize,
            rowCount = that.pagination.rowCount;

          switch (pgButton) {
            case 'first':
              currentPage = 1;
              break;
            case 'next':
              currentPage++;
              break;
            case 'prev':
              currentPage--;
              break;
            case 'last':
              currentPage = Math.ceil(rowCount / pageSize);
              break;
          }

          that.pagination.currentPage = currentPage;
          jqGridPostData.first = ((currentPage - 1) * pageSize);
          that.ui.jqGrid.setPostData(jqGridPostData);
        },

        serializeGridData: function(postData) {
          var currentPage =  Math.ceil( that.pagination.rowCount/ that.pagination.pageSize );
          if (that.filterChange === true) {
            postData.first = 0;
            that.pagination.currentPage = 1;
            postData.page = 1;
            that.filterChange = false;
          } else if(that.pagination.currentPage > currentPage) {
            that.pagination.currentPage = currentPage ? currentPage : 1;
            postData.page = that.pagination.currentPage;
            postData.first = ((that.pagination.currentPage - 1) * that.pagination.pageSize);
          }

          if (that.sidx !== postData.sidx || that.sord !== postData.sord) {
            postData.first = 0;
            that.pagination.currentPage = 1;
            postData.page = 1;
          }

          that.sidx = postData.sidx;
          that.sord = postData.sord;

          Settings.setToMemory(that.filtersName, postData);

          return postData;
        },

        gridComplete: function() {
          that.onGridComplete();
          that.hidePopovers();
          that.attachPopover();
          if (that.displayRowToolTip === true) {
            that.attachTooltip();
          }
          $.material.init();
        },

        loadComplete: function(data) {
          that.triggerMethod('loadComplete', data);
        }
      };
    },

    onGridLoaded: function() {
      this.trigger('onGridLoaded');
    },

    displayGrid: function(data) {
      if (data) {
        var that = this,
          storedFilters = Settings.getFromMemory(this.filtersName),
          urlParams = {
            rowCountUrl: data.rowCountUrl,
            filters:  storedFilters  || this.defaultFilters
          };
        this.rowCountUrl = data.rowCountUrl;
        this.data = data;

        $.when(App.request('common:get-row-count', urlParams))
          .done(function(rowCount) {
            //display grid only if you have make a search or if you have records
            that.gridOptionsObj = that.gridOptions(data);
            if (rowCount || that.hasLastFilterUsed(storedFilters)) {
              that.onRowCountDone(data, rowCount);
            }
            that.triggerMethod('checkRowCount', rowCount);
          });
      }
    },

    onRowCountDone: function(data, rowCount) {
      var that = this;

      data.rowCount = rowCount;
      this.pagination.rowCount = rowCount;
      this.colModel = that.gridOptionsObj.colModel;
      this.ui.jqGrid.jqGrid(that.gridOptionsObj);

      // subscribe to resize events
      $(window).on('resize.onResizeSidebar', _.bind(this.onResizeSidebar, this));
      App.on('sidebar:resize', this.onResizeSidebar, this);

      // activate the toolbar searching
      this.ui.jqGrid.jqGrid('filterToolbar', {autosearch: false});

      this.$('.ui-jqgrid-sortable').click(function() {
        that.sorting = true;
      });
      _.each(Settings.getFromMemory(this.filtersName), _.bind(function(value, key) {
        this.$('input[name=' + key + ']').val(value);
      }, this));

      this.onResizeSidebar();
    },

    onResizeSidebar: function() {
      var content = this.$el.closest('.content');
      if (this.ui.jqGrid && this.$el.parent().hasClass('tab-pane active')) {
        var gridWidth = this.GRID_MIN_WIDTH > this.$el.find('.jq-grid-table-page').width() ?
                        this.GRID_MIN_WIDTH : this.$el.find('.jq-grid-table-page').width();

        if(gridWidth === this.GRID_MIN_WIDTH) {
          content.width(this.GRID_MIN_WIDTH + 50);
          content.parent().width(this.GRID_MIN_WIDTH + 90);
          $('#content-region').css('overflow-x', 'scroll');
        }
        this.ui.jqGrid.setGridWidth(gridWidth);
      } else {
        content.css('width', 'auto');
        content.parent().css('width', 'auto');
      }
    },

    updatePagination: function() {
      var that = this,
        urlParams = {
          rowCountUrl: this.rowCountUrl,
          filters: this.filters
        };
      if (that.ui.jqGrid[0].grid) {
        if (this.rowCountUrl) {
          $.when(App.request('common:get-row-count', urlParams))
            .done(function(rowCount) {
              that.pagination.rowCount = rowCount;
              that.ui.jqGrid.setGridParam({
                jsonReader: {
                  page: function() {
                    return that.pagination.currentPage;
                  },
                  total: function() {
                    return Math.ceil(rowCount/that.pagination.pageSize);
                  },
                  records: function() {
                    return rowCount;
                  }
                }
              });
              if (that.filterChange === true) {
                that.ui.jqGrid[0].triggerToolbar();
              } else {
                that.ui.jqGrid.trigger('reloadGrid', [{current: that.pagination.currentPage}]);
              }
            });
        } else {
          that.ui.jqGrid[0].triggerToolbar();
        }
      } else {
        this.displayGrid(this.data);
      }
    },

    onGridSelectClick: function(e) {
      var target = $(e.currentTarget),
        name = target.attr('name');

      if (!this.selectValue) {
        this.selectValue = {};
        this.selectValue[name] = this.selectDefault;
      }

      if (target.val() !== this.selectValue[name]) {
        this.selectValue[name] = target.val();
        this.onGridFilterChange(e);
      }
    },

    onGridFilterChange: function(e) {
      var that = this,
        filters = this.defaultFilters,
        value;

      this.triggerClear(e);

      _.each(this.colModel, function(column) {
        if (column.search) {
          if (column.stype === 'select') {
            value = that.$('select[name="' + column.index + '"]').val();
            filters[column.index] = value === that.selectDefault ? '' : value;
          } else {
            value = that.$('input[name="' + column.index + '"]').val();
            filters[column.index] = value;
          }
        }
      });

      this.filters = filters;
      this.filterChange = true;
      this.updatePagination();
    },

    hasLastFilterUsed: function(storedFilters){
      var colModel = this.gridOptionsObj.colModel;
      if (colModel && storedFilters) {
        var found = _.some(colModel, function(item){
          if (item.search === true) {
            var filterFound = _.some(storedFilters, function(value, key){
              if (item.name.toUpperCase() === key.toUpperCase()){
                return true;
              }
            });
            if (filterFound === true) {
              return true;
            }
          }
        });
        return found;
      }
    },

    preselectFirstRow: function() {
      var ids = this.ui.jqGrid.jqGrid('getDataIDs');
      if(ids && ids.length > 0)
        this.ui.jqGrid.jqGrid('setSelection', ids[0]);
    },

    defaultFormatter: function(cellValue, options) {
      if (cellValue === null || cellValue === undefined) { cellValue = ''; }

      return '<span class="cell-default" data-row-id="' +
             options.rowId + '">' + cellValue + '</span>';
    },

    colorFormatter: function(cellValue) {
      return '<div style="width:15px; height: 15px; background: '+ColorUtils.decimalToHexa(cellValue)+';" ></div>';
    },

    booleanFormatter: function(cellvalue) {
      var checked = cellvalue ? 'checked' : '',
          htmlEl = '<div class="form-group checkbox-group">' +
              '<div class="checkbox checkbox-primary"><label>' +
              '<input disabled="disabled" type="checkbox"' +
              ' class="js-ticket-urgent" ' + checked + ' ' +
              '></label></div></div>';
      return htmlEl;
    },

    deleteIconFormatter: function(cellValue, options) {
      return '<span class="mdi-action-delete cell-delete js-icon" ' +
             'data-row-id="' + options.rowId + '"></span>';
    },

    editIconFormatter: function(cellValue, options) {
      return '<span class="mdi-content-create cell-edit" ' +
             'data-row-id="' + options.rowId + '"></span>';
    },

    dateFormatter: function(cellValue) {
      var date = dateConverter.toDateFormatString(cellValue);

      return '<span class="cell-date">'+ date + '</span>';
    },

    lastCharFormater: function(cellValue) {
      if (cellValue && cellValue.length > 8) {
        cellValue = cellValue.substr(cellValue.length - 8);
      }
      return '<span class="cell-default">' + (cellValue || '') + '</span>';
    },

    localeDateFormatter: function(cellValue) {
      var date = dateConverter.toDateFormatString(cellValue);

      return '<span class="cell-date">'+ date + '</span>';
    },

    pictureUidFormatter:  function(cellValue, colModel, row) {
      var pay = row.itemKey ? row.itemKey.substring(0, 3) : null;

      if (pay === 'PAY') {
        return '<div class="jq-picture pay-pal-logo"></div>';
      } else {
        cellValue = _getImageCellValue(cellValue);
        return '<span><img data-link="' + cellValue + '"' +
               'class="jq-picture js-image-cell" src="' + cellValue + '"/></span>';
      }
    },

    hidePopovers: function() {
      $('.jqgrid-popover').remove();
    },

    attachPopover: function() {
      var that = this,
        images = this.$('.js-image-cell'),
        link;

      this.previousImages = images;
      _.each(images, function(image) {
        link = $(image).data('link');
        that.showImageZoomPopover(link, $(image));
      });
    },

    attachTooltip: function() {

      this.ui.gridCellWithoutImage.tooltip({
        track: true,
        content: function() {
          return _.i18n('common.click.here.for.detail');
        }
      } );
    },

    onGridComplete: function() {
      var that = this;
      this.bindUIElements();
      if (this.$('.jq-picture').length > 0) {
        this.$('.jq-picture').error(function(e) {
          var target = $(e.currentTarget),
            fileLink = that.checkFileName(target) || Settings.get('notFoundImage');
          target.attr('src', fileLink);
          target.popover('destroy');
        });
      }
      if (this.filterEnable && this.setDefaultFiltersState) {
        this.setDefaultFiltersState();
      }
    },

    setDefaultFiltersState: function() {
      var that = this, postData = Settings.getFromMemory(this.filtersName ),
          fillerValue;
      _.each(this.colModel, function(column) {
        if (column.search) {
          fillerValue = postData[column.index];
          if (column.stype === 'select' && fillerValue) {
            that.$('select[name="' + column.index + '"]').val(fillerValue);
          }
        }
      });
    },

    checkFileName: function(target) {
      var name = target.data('name'),
        nameAsArray,
        ext;
      if (!name) return;
      nameAsArray = name.split('.');
      if (nameAsArray.length > 1) {
        ext = nameAsArray[1].substr(0,4);
      } else {
        ext = name.substr(0,3);
      }
      return imageUtils.getPreview(ext);
    },

    showImageZoomPopover: function(fileLink, target) {
      var url = Settings.url('base', fileLink),
        content = '<div class="img-responsive">' +
                  '<img class="img-popover" src="' + url + '"/></div>';

      target.attr('data-content', content);
      target.popover({
        trigger: 'hover',
        placement: 'right',
        html: 'true',
        container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content')
      }).data('bs.popover').tip().addClass('jqgrid-popover');
    },

    onBeforeDestroy: function() {
      this.hidePopovers();
      $(window).off('resize.onResizeSidebar');
      if (this.ui.gridCellWithoutImage.length > 0 && this.displayRowToolTip === true) {
        this.ui.gridCellWithoutImage.tooltip('destroy');
      }
      $.jgrid.gridDestroy(this.ui.jqGrid.attr('id'));
      App.off('sidebar:resize', this.onResizeSidebar, this);
    },

    triggerClear: function(e) {
      var $target = $(e.currentTarget);
      if($target.is('input')) {
        if(!$target.val()) {
          $target.trigger('clear');
        }
      } else {
        $target.closest('tr').find('.ui-search-input input').trigger('clear');
      }
    },

    onDestroy: function() {
      jqGridFormatters.destroyActionsButtonViews();
      this.ui.jqGridInput.off('clear');
    },

    codeNameFormatter: function(cellvalue) {
      if (cellvalue) {
        return '<span>' + cellvalue.code + ' - ' + cellvalue.name + '</span>';
      } else {
        return '';
      }
    }
  });


  var _getImageCellValue = function(cellValue) {
    if (!cellValue) {
      return Settings.get('notFoundImage');
    } else {
      return Settings.url('file', cellValue, {size: 'thumbnail'});
    }
  };

  module.exports = JqGridView;
});
