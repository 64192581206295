define('runTabsLayoutController',[
    'module',
    'runTabsLayoutView',
    'app',
    'jquery',
    'underscore',
    'tabsBaseController',
    'runTabsData',
    'entities/runs'
], function(
    module,
    TabsLayoutView,
    App,
    $,
    _,
    TabBaseController,
    tabs
) {
    'use strict';

    var RunTabsLayoutController = _.extend(TabBaseController,{
        model: App.request('run:model'),
        View: TabsLayoutView,
        tabs: tabs,

        showTabsLayout: function (region, tabName, id) {
            var defer = $.Deferred();
            this.model = App.request('run:model');
            this.model.set(this.model.idAttribute, id);

            if(this._layoutView && !this._layoutView.isDestroyed) {
                defer.resolve(this._layoutView.getRegion('tabs'), this._layoutView.model);
                this._layoutView.triggerMethod('show:active', tabName);
                return defer.promise();
            }

            this.model.fetch().done(_.bind(function() {

                this._layoutView = new TabsLayoutView({
                    model: this.model,
                    tabs: this.tabs
                });
                region.show(this._layoutView);
                this._layoutView.triggerMethod('show:active', tabName);

                defer.resolve(this._layoutView.getRegion('tabs'), this._layoutView.model);
            }, this));
            return defer.promise();
        }
    });

    module.exports = RunTabsLayoutController;
});
