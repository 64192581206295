/**
 * Created by OLD on 20/08/2015.
 */
define('graphicalRequestAllInOnePopupView',[
  'module',
  'jquery',
  'underscore',
  'backbone',
  'settings',
  'photoswipe',
  'photoswipeUi',
  'template!graphicalRequestAllInOnePopupTpl',
  'template!graphicalRequestAllInOneWarningTpl',
  'dialogFormView',
  'oldBrowserFormView',
  'dropdownCompositeView',
  'bootbox',
  'imageUtils',
  'dropzone',
  'graphicalRequestsWizardMixins',
  'jqueryInputValidator'
], function(
  module,
  $,
  _,
  Backbone,
  Settings,
  PhotoSwipe,
  PhotoSwipeUI,
  viewTpl,
  viewWarningTpl,
  DialogFormView,
  OldBrowserFormView,
  DropdownCompositeView,
  bootbox,
  ImageUtils,
  DropZone,
  WizardMix
) {
  'use strict';

  var VectorisationPopupView = DialogFormView.extend({
    template: viewTpl,
    warningTemplate: viewWarningTpl,

    triggers: {
      'click @ui.imgNew' : 'gallery:init',
      'click @ui.fillUp' : 'onNavigateToCredit'
    },

    ui: {
      dropZone: 'div.upload-container-drag-drop',      
      buttonSendTo: '.send-to-d-b',
      dropArea: '.drag-and-drop-area',
      uploadBtn: '.dd-b-upload',
      uploadIcon: '.dd-icon',
      preview: '.preview-container',
      previewImg: '#previewImg',
      ticketNameBlock: '.ticket-name-new',
      ticketName: '.ticket-name-new input',
      ticketNameConfirmedBlock: '.ticket-name-new-confirmed',
      ticketNameConfirmed: '.ticket-name-new-confirmed input',
      ticketMessage: 'textarea.textarea-input',
      ticketOutputColorDetailBlock: '.outputcolor-container .textarea-container',
      ticketMessageConfirmed: 'textarea.textarea-input-confirmed',
      ticketOutputColorDetail: 'textarea.textarea-input-coloroutput',
      ticketOutputColorDetailConfirmed: 'textarea.textarea-input-outputcolor-confirmed',
      ticketUrgent: '.js-ticket-urgent',
      fillUp: '.fill-up-b',
      creditArea: '.check-credit-container',
      uploadArea: '.upload-container',
      ticketUrgency: 'input[type=checkbox]',
      ticketTariffScaleRadio: 'form.tariff-scale-radio-group',
      conversionChooser: '.conversion-type-chooser',
      uploadProgress: '.upload-progress',
      conversionTypeBlock: '.graphical-type-block'
    },

    regions: {
      dropdownOutputFileRegion: '.output-file-dropdown-region',
      dropdownConfirmOutputFileRegion: '.output-file-dropdown-region-confirm'
    },

    events: {      
      'click @ui.buttonSendTo'    : 'onSendPostRequest',
      'change @ui.ticketUrgency'  : 'onChangeUrgency',
      'cut @ui.ticketMessage'     : 'onChangeTextArea',
      'paste @ui.ticketMessage'   : 'onChangeTextArea',
      'drop @ui.ticketMessage'    : 'onChangeTextArea',
      'keydown @ui.ticketMessage' : 'onChangeTextArea',
      'cut @ui.ticketMessageConfirmed'     : 'onChangeTextArea',
      'paste @ui.ticketMessageConfirmed'   : 'onChangeTextArea',
      'drop @ui.ticketMessageConfirmed'    : 'onChangeTextArea',
      'keydown @ui.ticketMessageConfirmed' : 'onChangeTextArea',      
      'cut @ui.ticketOutputColorDetail'     : 'onChangeTextArea',
      'paste @ui.ticketOutputColorDetail'   : 'onChangeTextArea',
      'drop @ui.ticketOutputColorDetail'    : 'onChangeTextArea',
      'keydown @ui.ticketOutputColorDetail' : 'onChangeTextArea',
      'cut @ui.ticketOutputColorDetailConfirmed'     : 'onChangeTextArea',
      'paste @ui.ticketOutputColorDetailConfirmed'   : 'onChangeTextArea',
      'drop @ui.ticketOutputColorDetailConfirmed'    : 'onChangeTextArea',
      'keydown @ui.ticketOutputColorDetailConfirmed' : 'onChangeTextArea',      
      'click @ui.autocomplete'    : 'onClearValidation',
      'keypress @ui.autocomplete' : 'onClearValidation',
      'change @ui.conversionChooser' : 'conversionTypeChanged',
      'click @ui.conversionTypeBlock' : 'onSelectConversionBlock',
      'keydown @ui.ticketName'     : 'onChangeTicketName',
      'change @ui.ticketNameConfirmed' : 'onChangeTicketName'
    },

    //extend wizard mixins
    constructor: function() {
      _.extend(this, WizardMix);
      this.ui = _.extend({}, this.wizardUI, this.ui);
      this.events = _.extend({}, this.wizardEvents, this.events);
      DialogFormView.prototype.constructor.apply(this, arguments);
    },
    //....................

    initialize: function() {
      this.wizardInit(1);
    },

    defaults: {
      conversionTypes: {
        vector:  'vectorization',
        convert: 'conversion',
        remove:  'REMOVE_BACKGROUND',
        colorization: 'colorization'
      },
      vectorExtValue: '.pdf (PDF 1.4)'
    },

    checkAllowedKeys: function(e) {
      var target = $(e.target),
          code = e.keyCode;
      if (!target.hasClass('form-control') ||
        (target.is('input') && code === 13)) {
        e.preventDefault();
        e.stopPropagation();
      }
    },

    onShow: function() {
      $.material.init();
      this.$el.closest('.vectorizationAddNew').on('keydown', _.bind(function(e){
        this.checkAllowedKeys(e);
      } , this));
    },

    onSelectConversionBlock: function(e) {
      var $target = $(e.currentTarget),
          $chooser = $target.find('.conversion-type-chooser');

        $chooser.prop('checked', true).change();
    },

    conversionTypeChanged: function(e) {
      var target = $(e.currentTarget),
          conversionType = target.val();

      this.conversionType = conversionType;
      switch (this.conversionType) {
        case this.defaults.conversionTypes.vector:
          this.getRegion('dropdownOutputFileRegion' ).currentView.filterByProperty('isVectorFormat', true);
          this.getRegion('dropdownConfirmOutputFileRegion' ).currentView.filterByProperty('isVectorFormat', true);
          this.$('.outputcolor-container').hide();
          break;
        case this.defaults.conversionTypes.remove:
          this.getRegion('dropdownOutputFileRegion' ).currentView.filterByProperty('isVectorFormat', false);
          this.getRegion('dropdownConfirmOutputFileRegion' ).currentView.filterByProperty('isVectorFormat', false);
          this.$('.outputcolor-container').hide();
          break;
        case this.defaults.conversionTypes.colorization:
          this.getRegion('dropdownOutputFileRegion' ).currentView.filtersByProperties([            
            {key: 'isVectorFormat', value: false},
            {key: 'extension', value: '.psd (Photoshop Document)', actions: { exclude: true } }]);
          this.getRegion('dropdownConfirmOutputFileRegion' ).currentView.filtersByProperties([
            {key: 'isVectorFormat', value: false},
            {key: 'extension', value: '.psd (Photoshop Document)', actions: { exclude: true }}
          ]);
          this.$('.outputcolor-container').show();
          break;
        default:
          this.getRegion('dropdownOutputFileRegion' ).currentView.filterByProperty();
          this.getRegion('dropdownConfirmOutputFileRegion' ).currentView.filterByProperty();
          this.$('.outputcolor-container').hide();

      }
    },

    serializeData: function() {
      var templateData = {};

      //get stored to cookie file ext
      this.storedExtension = this.getUserExtension();

      templateData.balance = this.options.balance;
      templateData.subject = Settings.get('currentCAccount').name;
      templateData.needHelpEmail = Settings.get('supportEmail');
      templateData.conversionTypes = this.defaults.conversionTypes;
      templateData.choosenConversionType = this.conversionType;
        if (this.conversionType === this.defaults.conversionTypes.colorization) {
          this.filters = [
            {key: 'isVectorFormat', value: this.storedExtension.isVectorFormat},
            {key: 'extension', value: '.psd (Photoshop Document)', actions: { exclude: true }}
          ];
        } else {
          this.filters = [{key: 'isVectorFormat', value: this.storedExtension.isVectorFormat}];
        }
      return templateData;
    },

    onChangeTicketName: function(e, flag) {
      var that = this,
          $target = $(e.currentTarget),
          value;
      if (flag) return;

      that.validateWizardStep(2, 3);
      this.ui.ticketNameConfirmedBlock.removeClass('has-error');
      this.ui.ticketNameBlock.removeClass('has-error');

      setTimeout(function(){
        value = $target.val();
        if (!value.trim().length) {
          that.setLastAllowed(2);
          that.ui.ticketNameBlock.addClass('has-error');
        }
        if ($target[0] === that.ui.ticketNameConfirmed[0]) {
          that.ui.ticketName.val(value).trigger('change', true);
        } else {
          that.ui.ticketNameConfirmed.val(value).trigger('change', true);
          if ( that.ui.ticketOutputColorDetail.is(':visible')) {
            that.ui.ticketOutputColorDetail.trigger('keydown');
          }
        }
      }, 0);
    },

    customError: function(step, $itemsInError) {
      if (step === 3) {
        if (!$itemsInError.length) {
          this.ui.ticketNameBlock.addClass('has-error');
          this.ui.ticketName.focus();
          this.ui.ticketOutputColorDetailBlock.addClass('has-error');
        }
        $itemsInError.first().addClass('has-error');
        $itemsInError.first().find('.form-control').focus();
      }
    },

    onChangeTextArea: function(e, height) {
      var that = this,
          $target = $(e.currentTarget),
          value;

      if (height) {
        $target.height('auto');
        return;
      }
      setTimeout(function () {
        $target.height('auto');
        height = $target[0].scrollHeight + 'px';
        value = $target.val();
        if ($target[0] === that.ui.ticketOutputColorDetailConfirmed[0]) {
          that.ui.ticketOutputColorDetail.val(value).trigger('keydown', height);
        } else if ($target[0] === that.ui.ticketOutputColorDetail[0]) {
          that.validateWizardStep(2, 3);
          that.ui.ticketOutputColorDetailBlock.removeClass('has-error');
          if (!(value.trim()).length){
            that.setLastAllowed(2);
            that.ui.ticketOutputColorDetailBlock.addClass('has-error');
          }
          that.ui.ticketOutputColorDetailConfirmed.val(value).trigger('keydown', height);
        }

        if ($target[0] === that.ui.ticketMessageConfirmed[0]) {
          that.ui.ticketMessage.val(value).trigger('keydown', height);
        } else if ($target[0] === that.ui.ticketMessage[0]) {
          that.ui.ticketMessageConfirmed.val(value).trigger('keydown', height);
        }
      }, 0);
    },

    getTemplate: function() {
      if (this.options.balance <= 0) {
        return this.warningTemplate;
      } else if (this.options.balance < Settings.get('minBalance')) {
        this.balance = this.options.balance;
        return this.template;
      } else {
        return this.template;
      }
    },

    onRender: function() {
      var that = this,
        fileData = {},
        timerCount = 0,
        dropZoneFlag,
        timer;
      if(this.options.balance > 0) {
        if(!this._browserCompatible()) {
          new OldBrowserFormView({el: this.ui.uploadArea}).render();
          return;
        }

        this.ui.dropZone.dropzone({
          url: Settings.url('compuzz'),
          autoProcessQueue: false,
          uploadMultiple: false,
          maxFiles: 1,
          init: function () {
            this.on('addedfile', function(file) {
              dropZoneFlag = true;
              fileData.fileName = file.name;
              fileData.extension = file.type;
              that.ui.uploadIcon.hide();
              that.ui.uploadProgress.removeClass('hidden');
              timer = setInterval(function() {
                if(timerCount === 100) {
                  timerCount = 0;
                } else {
                  timerCount += 10;
                }
                that.ui.uploadProgress.children().first().css('width', timerCount + '%');
              }, 100);
              ImageUtils.encodeFileToBase64(
                file, function(base64) {
                  fileData.base64 = base64;
                  that.ui.previewImg.on('load', function() {
                    if (dropZoneFlag) {
                      dropZoneFlag = false;
                      clearInterval(timer);
                      that.ui.uploadProgress.addClass('hidden');
                      that.ui.dropArea.addClass('uploaded');
                      that.imgOriginalBase64 = base64;
                      that.fileName = file.name;
                      that.extension = file.type.split('/')[1];
                      that.ui.previewImg.off('load');
                      that.ui.preview.show();
                      that.validateWizardStep(1, 2);
                    }
                  }).on('error', function() {
                    if (dropZoneFlag) {
                      dropZoneFlag = false;
                      clearInterval(timer);
                      that.ui.uploadProgress.addClass('hidden');
                      that.ui.dropArea.addClass('uploaded');
                      that.imgOriginalBase64 =  fileData.base64 ;
                      that.fileName =  fileData.fileName;
                      that.extension = fileData.extension.split('/')[1];
                      that.ui.previewImg.off('load');
                      that.createPreviewFromName();
                      that.ui.preview.show();
                      that.validateWizardStep(1, 2);
                    }
                  }).attr('src', base64);
                });
            });
          },
          dictDefaultMessage: _.i18n('common.file.drag.drop')
        });

        // create ext dropdown for third step
        this.getRegion('dropdownOutputFileRegion').show(new DropdownCompositeView(
          {
            modelProperty: 'extension',
            modelSelectValue: this.storedExtension.extension,
            filters: this.filters,
            collection: this.options.outputFileCollection.clone(),
            onItemSelected: function(model) {
              that.setUserExtension(model);
              that.getRegion('dropdownConfirmOutputFileRegion').currentView.selectItemFromOutside(model);
            },
            onItemPreselected: function(model) {
              that.setUserExtension(model);
            }
          }));
        // create ext dropdown for confirm step
        this.getRegion('dropdownConfirmOutputFileRegion').show(new DropdownCompositeView(
          {
            modelProperty: 'extension',
            modelSelectValue: this.storedExtension.extension,
            filters: this.filters,
            collection: this.options.outputFileCollection.clone()
          }));
        this.getRegion('dropdownConfirmOutputFileRegion').$el.find('.dropdown__button').addClass('disabled');
      }
    },

    getUserExtension: function() {
      var value = Settings.get('userFileExtension'),
          defaultValue = {
            extension: this.defaults.vectorExtValue,
            isVectorFormat: true,
            conversionType: this.defaults.conversionTypes.vector
          },
          parsed;

      try{
        parsed = value ? JSON.parse(value) : defaultValue;
      } catch(e) {
        parsed = defaultValue;
      }
      this.conversionType = parsed.conversionType;
      return parsed;
    },

    setUserExtension: function(model) {
      var jModel = model.toJSON(),
          value ;

      jModel.conversionType = this.conversionType;
      value = JSON.stringify(jModel);
      this.storedExtension = jModel;
      Settings.set('userFileExtension', value);
    },    
    
    // when preview creation failed make preview from name
    //TODO: encapsulate that ino the utils class
    createPreviewFromName: function() {
      var name = this.fileName,
        arrayFromName = name.split('.'),
        src,
        ext;

      if (arrayFromName.length > 1) {
        ext = arrayFromName[arrayFromName.length-1].substr(0,4);
      } else {
        ext = name.substr(0,3);
      }
      src = ImageUtils.getPreview(ext);

      this.ui.previewImg.attr('src', src);
    },

    onDestroy: function() {
      //Clearing out left overs from dropzone
      $('input.dz-hidden-input').remove();
    },

    onChangeUrgency: function(e) {
      // for two checkboxes
      var $target = $(e.currentTarget);

      if( !$target.is(':checked') ) {
        this.ui.ticketUrgency.prop('checked', false);
        return;
      }
      bootbox.confirm(
        _.i18n('warning.updateTicketUrgency'),
        _.bind(function (result) {
          if (!result) {
            this.ui.ticketUrgency.prop('checked', false);
          } else {
            this.ui.ticketUrgency.prop('checked', true);
          }
        }, this)
      );
    },

    onSendPostRequest: function() {
      var ticketName = this.ui.ticketNameConfirmed.val();

      if (ticketName.trim().length) {
        this.trigger('popup:view:close',
          ticketName,
          this.conversionType
        );
      }
    },

    _browserCompatible: function() {
      var capableBrowser, regex, _i, _len, _ref;
      capableBrowser = true;
      if (window.File && window.FileReader && window.FileList && window.Blob && window.FormData && document.querySelector) {
        if (!('classList' in document.createElement('a'))) {
          capableBrowser = false;
        } else {
          _ref = [/opera.*Macintosh.*version\/12/i];
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            regex = _ref[_i];
            if (regex.test(navigator.userAgent)) {
              capableBrowser = false;
              continue;
            }
          }
        }
      } else {
        capableBrowser = false;
      }
      return capableBrowser;
    }
  });

  module.exports = VectorisationPopupView;
});
