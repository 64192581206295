define('customersContactsInfoTabController',[
  'module',
  'settings',
  'app',
  'underscore',
  'customersContactsInfoTabView',  
  'customerContactsPopupView'
], function(
  module,
  Settings,
  App,
  _,
  InfoTabView,
  PopupView
) {
  'use strict';

  var CustomersContactsInfoTab = {
    showInfoTab: function(region, contactModel) {
      this.region = region;
      this.infoView = new InfoTabView({
        model: contactModel
      });
      this.infoView.listenTo(this.infoView, '_onEditContactInfo', _.bind(this._onEditContactInfo, this));
      region.show(this.infoView);
    },

    _onEditContactInfo: function() {
      var that = this;
      
      this.editPopup = new PopupView({model: this.infoView.model.clone()});
      if  (!Settings.get('countries')) {        
        $.when(App.request('caccount:countries')).done(function(countries) {
          Settings.set('countries', countries.toJSON());
          that._onShowEditPopup();
        });
      } else {
        this._onShowEditPopup();
      }   
    },

    _onShowEditPopup: function() {
      var that = this;

      this.editPopup.show({title: _.i18n('customerContact.edit.popup.title'),
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default btn-flat',
            callback: function() {return true;}
          },
          success: {
            label: _.i18n('common.confirm'),
            className: 'btn-primary',
            callback: function() {
              that._onConfirmEditPopup();
              return false;
            }
          }
        }
      });
    },

    _onConfirmEditPopup: function() {
      if (!this._validate()) { return false; }
      this.editPopup.model.listenTo(this.editPopup.model, 'sync', _.bind(this._onSaveEventDone, this));
      this.editPopup.model._updateModel();
    },

    _validate: function() {
      var fieldsToValidate = [
        {name: 'email', type: 'email'},
        {name: 'name', type: 'required'}
      ];

      return this.editPopup.validate(fieldsToValidate);
    },


    _onSaveEventDone: function(model) {
      this.infoView.model.set({
        addressL1: model.get('addressL1'),
        addressL2: model.get('addressL2'),
        city: model.get('city'),
        comment: model.get('comment'),
        countryCode: model.get('countryCode'),
        fax: model.get('fax'),
        firstName: model.get('firstName'),
        lastName: model.get('lastName'),
        name: model.get('name'),
        tel: model.get('tel'),
        zip: model.get('zip'),
        email: model.get('email'),
        secId: model.get('secId')
      });
      this.editPopup.hide();
      this.infoView.render();
    }
  };

  module.exports = CustomersContactsInfoTab;
});
