define('runTemplateCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!runTemplateCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'settings',
  'autocompleteView'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  Settings,
  AutocompleteView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      inputName: '.run-input-name'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectLmbAnalyzer: '.js-select-lmbanalyzer-region',
      selectKitProt: '.js-select-kitprot-region',
      selectMbAModel: '.js-select-mbamodel-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'name', type: 'required'}
    ],

    initialize: function(options) {
      this.model = options.runTemplateModel.clone();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();

      this.lmbAnalyzerView = new AutocompleteView(
          this._getAutocompleteOptions('refLmbAnalyzer', 'code', 'code', 'v2/lmbanalyzers',
            'selectlmbanalyzer.placeholder', 'code')
      );
      this.getRegion('selectLmbAnalyzer').show(this.lmbAnalyzerView);

      var kitProtView = new AutocompleteView(
          this._getAutocompleteOptions('refKitProt', 'code', 'code', 'v2/kitprots',
              'selectkitprot.placeholder', 'code', this.onKitProtChange)
      );
      this.getRegion('selectKitProt').show(kitProtView);

      var mbAModelView = new AutocompleteView(
          this._getAutocompleteOptions('refMbAModel', 'code', 'code', 'v2/mbamodels',
              'selectkitmbamodel.placeholder', 'code', this.onMbAModelChange)
      );
      this.getRegion('selectMbAModel').show(mbAModelView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onKitProtChange: function(fieldName, model) {
      if (model) {
        model.unset('autocompleteValue', {silent: true});
        this.ui.inputName.val(model.get('runNamePattern'));
        this.ui.inputName.change();
      }
      this.onChange(fieldName, model);
    },

    onMbAModelChange: function(fieldName, model) {
      if (model) {
        model.unset('autocompleteValue', {silent: true});
        this.onChange(fieldName, model);
        if (this.lmbAnalyzerView) {
          this.lmbAnalyzerView.destroy();
        }
        this.lmbAnalyzerView = new AutocompleteView(
            this._getAutocompleteOptions('refLmbAnalyzer', 'code', 'code', 'v2/lmbanalyzers?mbAModelSecId=' + model.get('secId'),
                'selectlmbanalyzer.placeholder', 'code')
        );
        this.getRegion('selectLmbAnalyzer').show(this.lmbAnalyzerView);
      }
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.wells = this.model.get('wells');
        this.model.set('wells', null);
        this.model.save();
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.model.set('wells', this.wells);
      this.options.runTemplateModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('runtemplate:header:rerender', modelJSON);
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'runtemplates/new') {
        App.navigate('runtemplates', {trigger: true});
      } else {
        App.navigate('runtemplates/' + this.options.runTemplateModel.get('secId'), {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
