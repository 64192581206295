/**
 * Created by RKL on 13/08/2015. 
 */
define('caccountSAGraphicalRequestsTabController',[
  'module',
  'backbone',
  'underscore',
  'app',
  'caccountSAGraphicalRequestsTabView',
  'fileExtensionData'
], function(
  module,
  Backbone,
  _,
  App,
  View,
  FileExtensionData
) {
  'use strict';

  var CAccountsSAGraphicalRequestsTabController = {
    showTab: function(data) {
      var view = new View({outputFileCollection: new Backbone.Collection(FileExtensionData.extensions), cAccountSecId: data.secId});
      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('caccountGraphicalRequestsTab').show(view);
    }
  };

  module.exports = CAccountsSAGraphicalRequestsTabController;
});
