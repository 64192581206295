define('headerWellView',[
    'module',
    'marionette',
    'template!headerWellTpl',
    'wellCreateEditView',
    'underscore',
    'settings',
    'app',
    'envHandler',
    'backbone'
], function(
    module,
    Marionette,
    headerWellTpl,
    CreateEditLayoutView,
    _
) {
    'use strict';

    var HeaderWellView = Marionette.LayoutView.extend({
        template: headerWellTpl,

        ui: {
            btnEditWell: '.btn-edit-well'
        },

        events: {
            'click @ui.btnEditWell': 'onEditWell'
        },

        serializeData: function() {
            var templateData = this.model.toJSON();
            templateData.results = this.modelRun.getResultForWell(this.model);
            templateData.channelToDisplay = this.channelToDisplay;
            templateData = _.extend(templateData, this.model.getErrorObject());
            return templateData;
        },

        onEditWell: function() {
            this.createEditView = new CreateEditLayoutView({
                wellModel: this.model,
                runModel: this.modelRun,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.edit')
            });
        }
    });

    module.exports = HeaderWellView;
});
