/**
 * Created by RKL on 15/09/2015.
 */
define('customerProdTemplatesTabController',[
  'module',
  'backbone',
  'underscore',
  'app',
  'groupsController',
  'customerProdTemplatesElController',
  'customerProdTemplatesGroupView',
  'customerProdTemplatesPopupView',
  'entities/customers/product_templates'
], function(
  module,
  Backbone,
  _,
  App,
  GroupsController,
  ElementsController,
  GroupsView,
  PopupView
) {
  'use strict';

  var CustomersProducTemplatesTabController = _.defaults({

    showTab: function(data) {
      this.data = data;
      this.region = 'customerProductTemplatesTab';
      this.showLayoutView();

      this.subscribeToEvents();
    },

    subscribeToEvents: function() {
      var that = this;

      App.off('customer-template:show-templates');
      App.on('customer-template:show-templates', function(code) {
        App.navigate('customers/' + that.data.id +
          '/producttemplates/' + code);
        that.data.secId = code;
        that.showGroups();
      });

      App.off('producttemplate:create-new');
      App.on('producttemplate:create-new', function(code, poi) {
        poi = poi ? '/' + poi : '';
        App.navigate(
          'orderprocess/' + code + '/' + that.data.id + '/template' + poi,
          {trigger: true}
        );
        that.update = true;
        that.data.groupModel.count = undefined;
      });

      App.off('producttemplate:destroy');
      App.on('producttemplate:destroy', function(model) {
        model.url = model.deleteUrl(model.get('purchaseOrderId'));
        model.destroy().done(function () {
          that.data.onChangeCount();
        });
        that.update = true;
      });

      App.off('folder-groups:add-group');
      App.on('folder-groups:add-group', _.bind(this.onNewGroup, this));

      $(window).on('resize', _.bind(this.calcColumnHeight, this));

      this.data.onChangeCount = _.bind(this.onChangeCount, this);
    },

    onNewGroup: function() {
      this.popupView = new PopupView();

      this.popupView.show({title: _.i18n('popup.corporateTemplates.add')});
      $.material.init();
    },

    fetchGroups: function() {
      var that = this,
        request;

      if ((!this.collection || this.collection.length === 0) ||
          this.update || this.endUserId !== this.data.id) {
        this.update = false;
        this.endUserId = this.data.id;
        request = App.request(
          'customer:product-templates',
          this.data.model.get('secId')
        );

        $.when(request).done(function(collection) {
          that.collection = collection;
          that.data.groupsCollection = collection;
          that.showGroups();
        });
      } else {
        this.showGroups();
      }
    },

    showGroups: function(changeCount) {
      var view = new GroupsView({
          collection: this.collection,
          data: this.data
        }),
        secId;

      if (this.collection.length > 0) {
        if (this.data.secId) {
          secId = this.data.secId;
          this.data.groupModel = {};
        } else {
          secId = this.collection.models[0].get('0').get('productCode');
          this.data.secId = secId;
          this.data.groupModel = this.collection.models[0].get('0');
        }

        this.layoutView.getRegion('groupsColumn').show(view);
        if (!changeCount) {
          this.displayElements();
        }
        this.calcColumnHeight(true);
      }
    },

    displayElements: function() {
      ElementsController.displayElements(
        this.data,
        this.collection
      );
    }

  }, GroupsController);

  module.exports = CustomersProducTemplatesTabController;
});
