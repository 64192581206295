define('runTabsLayoutView',[
  'module',
  'tabsBaseView',
  'template!runTabsHeaderTpl',
  'autocompleteView',
  'jquery',
  'app',
  'envHandler',
  'backToListBehavior',
  'marionette'
], function(
  module,
  TabsBaseView,
  headerTpl,
  AutocompleteView,
  $,
  App,
  EnvironmentHandler,
  BackToListBehavior,
  Marionette
) {
  'use strict';

  var HeaderView = Marionette.LayoutView.extend({
    template: headerTpl
  });

  var RunTabsLayoutView = TabsBaseView.extend({

    behaviors: function() {
      this.backToListUrl = 'runs';
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: this.backToListUrl
        }
      };
    },

    onShowTabsBase: function() {
      this.getRegion('header').show(new HeaderView({
        model: this.model
      }));
    },

    onNavigate: function(tabName) {
      var currentRoute = App.getCurrentRoute(),
        routeSplitted = currentRoute.split('/'), newRoute = [];

      newRoute.push(routeSplitted[0]);
      newRoute.push(routeSplitted[1]);
      newRoute.push(tabName);
      App.navigate(newRoute.join('/'), {trigger: true});
    }
  });

  module.exports = RunTabsLayoutView;
});
