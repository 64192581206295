
define('template!runDetailTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="tab-content content">\n  <div class="well run-detail">\n    <div class="detailed-info-form-container">\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( name ? name : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('run.refKitProt') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( refKitProt ? refKitProt.code : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('run.refMbAModel') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( refMbAModel ? refMbAModel.code : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('run.refLmbAnalyzer') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( refLmbAnalyzer ? refLmbAnalyzer.code : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <!--<div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('run.status') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( status ? (status + ' - ' + _.i18n('run.status.' + status))  : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>-->\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('run.plateId') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( plateId ? plateId : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <!--<div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('run.plateColumnNb') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( plateColumnNb ? plateColumnNb : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('run.plateRowNb') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( plateRowNb ? plateRowNb : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>-->\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( comment ? comment : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

