/**
 * Created by OBL on 14/01/2016.
 */
define('adminKitCQctController',[
    'module',
    'underscore',
    'app',
    'adminKitCQctView',
    'bootbox',
    'kitCQctCreateEditLayoutView',
    'settings',
    'entities/kitcqct'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    CreateEditLayoutView
    ) {
    'use strict';

    var SuperAdminKitCQctListController = {
        showKitCQctList: function(data) {
            this.data = data;
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'kitcqct:delete', _.bind(this.onDelete, this));
            view.listenTo(view, 'kitcqct:edit', _.bind(this.onEdit, this));
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('kitCQctListTab').show(view);
        },

        showKitCQctAdd: function(mbKitCSecId) {
            this.showKitCQctAddEdit(App.request('kitcqct:model', {
                refKitC: {
                    secId: mbKitCSecId
                }
            }), true);
        },

        showKitCQctAddEdit: function(model, newItem) {
            this.createEditView = new CreateEditLayoutView({
                kitCQctModel: model,
                newItem: newItem
            });
            this.createEditView.show({
                title: _.i18n('kitcqct.new')
            });
        },

        showKitCQctEdit: function(mbKitCSecId, kitCQctSecId) {
            var that = this;
            var model = App.request('kitcqct:model', {
                refKitC: {
                    secId: mbKitCSecId
                },
                secId: kitCQctSecId
            });
            model.fetch().done(function() {
                that.showKitCQctAddEdit(model, false);
            });
        },

        onEdit: function(mbKitCSecId, kitCQctSecId){
            App.navigate('admin/kitcs/' + mbKitCSecId + '/kitcqct/' + kitCQctSecId + '/edit', {trigger: true});
        },

        onGridLoaded: function() {
            var kitCSecId = this.data.model.get('secId');
            var data = {
                url: App.request('kitcqct:get-collection-url', kitCSecId),
                rowCountUrl: App.request('kitcqct:get-count-url', kitCSecId)
            };

            this.view.displayGrid(data);
        },

        onDelete: function(kitSecId, secId){
            bootbox.confirm(_.i18n('kitcqct.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('kitcqct:delete', kitSecId, secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = SuperAdminKitCQctListController;
});
