define( 'entities/caccounts/share',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * REST API
   */
  var API = {
    getShare: function(data) {
      var defer = $.Deferred(),
        url = Settings.url(
          'compuzz',
          'v2/caccountshare/' + data.cAccountId + '/' + data.share
        );

      $.ajax({
        url: url,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    postShare: function(data) {
      var url = Settings.url('compuzz', 'v2/caccountshare');

      return $.ajax({
        url: url,
        type: 'POST',
        data: JSON.stringify(data),
        contentType: 'application/json'
      });
    },

    deleteShare: function(data) {
      var url = Settings.url('compuzz', 'v2/caccountshare');

      return $.ajax({
        url: url,
        type: 'DELETE',
        data: JSON.stringify(data),
        contentType: 'application/json'
      });
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('caccounts:get-share', function(data) {
    return API.getShare(data);
  });

  App.reqres.setHandler('caccounts:post-share', function(data) {
    return API.postShare(data);
  });

  App.reqres.setHandler('caccounts:delete-share', function(data) {
    return API.deleteShare(data);
  });
});
