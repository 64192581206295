define('customerCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!customerCreateEditLayoutTpl',
  'imageUtils',
  'imageUploadBehavior',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'tokenField',
  'dropdownCompositeView'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  imageUtils,
  ImageUploadBehavior,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView,
  tokenField,
  DropdownCompositeView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      logo: '.js-logo-holder',
      logoDiv: 'div.js-logo-holder',
      imgBlock: 'div.profile-img-block',
      changeLogo: '.js-change-logo',
      removeLogo: '.js-remove-logo',
      input: '.js-info-input',
      endUserIdInput: '.js-end-user-id',
      vatArea: '.vat-area',
      subjectToVat: '#subjectToVat',
      vatInput: '#vat-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      langlabel: '.lang-label',
      subscribed: '#subscribed'
    },

    events: {
      'click @ui.removeLogo': 'onRemoveLogo',
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm',
      'change @ui.subjectToVat': 'onChangeSubjectToVat',
      'change @ui.subscribed' : 'onChangeSubscribed',
      'click .dropdown-menu-lang a': 'onChangeLanguage'
    },

    regions: {
      selectCountries: '.js-select-countries-region',
      dropdownPriceListRegion: '.pricelist-dropdown-region'
    },

    className: 'profile-create-edit',

    behaviors: {
      ImageUpload: {
        behaviorClass: ImageUploadBehavior
      },
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'name', type: 'required'},
      {name: 'email', type: 'multipleEmail'},
      {name: 'emailCustomerService', type: 'multipleEmail'}
    ],

    initialize: function(options) {
      this.model = options.customerModel.clone();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      var countriesView = new AutocompleteView(
        this._getCountriesAutocompleteOptions()
      );
      this.getRegion('selectCountries').show(countriesView);
      this.displayLogo(this.model.get('logoUid'), this.model.get('code'));

      this.ui.vatArea.toggleClass('disabled', this.model.get('subjectToVat'));

      var subjectToVat = this.model.get('subjectToVat') || false;
      this.ui.vatArea.toggleClass('hidden',!subjectToVat);
      this.ui.subjectToVat.prop('checked', subjectToVat);

      var newLang = this.model.get('locales') || 'None';
      this.ui.langlabel.text(newLang);

      var subscribed = this.model.get('subscribed') || true;
      this.ui.subscribed.prop('checked', subscribed);

      this.getRegion('dropdownPriceListRegion' ).show(new DropdownCompositeView(
          this._getPriceListAutoCompleteOptions()
      ));

      $.material.init();

      this.initTokenFields();
    },

    initTokenFields: function() {
      // these variables are used to fix email input field appearance
      var value = this.$('#emailInput').val(),
          customerValue = this.$('#CustomerEmailInput').val(),
          emailTokenCount = value ? value.split(';').length : 0,
          customerEmailTokenCount = customerValue ? customerValue.split(';').length : 0;

      if (emailTokenCount > 0) {
        this.$el.find('[data-field-name="email"] .floating-label').addClass('floating-label-focused');
      }

      if (customerEmailTokenCount > 0) {
        this.$el.find('[data-field-name="emailCustomerService"] .floating-label').addClass('floating-label-focused');
      }

      this.$('#emailInput').tokenfield({
            createTokensOnBlur: true,
            inputType: 'email',
            delimiter: [';', ',', ' ']
        }).on('tokenfield:createdtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
            emailTokenCount++;
        }, this))
        .on('tokenfield:tokenEdited', _.bind(function(e){
            this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
            emailTokenCount--;
        }, this));

      // fix conflicts between materializecss and tokenfield plugin
      this.$('[data-field-name="email"]').off('focusin').focusin(function() {
        $(this).find('.floating-label').addClass('floating-label-focused selected');
      }).off('focusout').focusout(function() {
        if($(this).find('#emailInput-tokenfield').val() === '' && emailTokenCount === 0) {
          $(this).find('.floating-label').removeClass('floating-label-focused');
        }
        $(this).find('.floating-label').removeClass('selected');
      });
      
      this.$('#CustomerEmailInput').tokenfield({
            createTokensOnBlur: true,
            inputType: 'email',
            delimiter: [';', ',', ' ']
        }).on('tokenfield:createdtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
            customerEmailTokenCount++;
        }, this))
        .on('tokenfield:tokenEdited', _.bind(function(e){
            this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
            customerEmailTokenCount--;
        }, this));

      // fix conflicts between materializecss and tokenfield plugin  
      this.$('[data-field-name="emailCustomerService"]').off('focusin').focusin(function () {
        $(this).find('.floating-label').addClass('floating-label-focused selected');
      }).off('focusout').focusout(function () {
        if ($(this).find('#CustomerEmailInput-tokenfield').val() === '' && customerEmailTokenCount === 0) {
          $(this).find('.floating-label').removeClass('floating-label-focused');
        }
        $(this).find('.floating-label').removeClass('selected');
      });
    },

    onChangeLanguage: function(e){
      e.preventDefault();
      var newLang = $(e.target).text();
      this.model.set('locales', newLang);
      this.ui.langlabel.text(newLang);
      this.triggerMethod('enable:cancel:confirm');
    },

    onTokenFieldInputChange: function(e){
        this.onInputChange(e);
        this.triggerMethod('enable:cancel:confirm');
    },

    displayLogo: function(logo, code, remove) {
      var image = imageUtils.getLogo(logo, code);

      if (image) {
        this.ui.logo.removeClass('hidden');
        this.ui.imgBlock.removeClass('hidden');
        this.ui.logo.attr('src', image);
        if (!this.ui.logoDiv.hasClass('hidden')) {
          this.ui.logoDiv.addClass('hidden');
        }
      } else {
        if (remove) {
          this.ui.logo.attr('src', '');
        }
        this.ui.logo.attr('src', '').addClass('hidden');
        this.ui.imgBlock.addClass('hidden');
        this.ui.logoDiv.removeClass('hidden');
      }

      if (!this.model.get('logoUid')) {
        this.ui.removeLogo.hide();
      } else {
        this.ui.removeLogo.show();
      }
    },

    onImageUploadConfirm: function(base64Value, fileExt) {
      this.model.set('encodedValue', base64Value);
      this.model.set('extension', fileExt);
      this.ui.logo.removeClass('hidden');
      this.ui.imgBlock.removeClass('hidden');

      if (!base64Value) {
        this.ui.logo.attr('src', imageUtils.getLogo(base64Value, this.model.get('code')));
        this.ui.removeLogo.hide();
        this.ui.logo.attr('src', '').addClass('hidden');
        this.ui.imgBlock.addClass('hidden');
        this.ui.logoDiv.removeClass('hidden');
      } else {
        this.ui.logo.attr('src', 'data:image/png;base64,' + base64Value);
        this.ui.removeLogo.show();
      }

      if (!this.ui.logoDiv.hasClass('hidden') && base64Value) {
        this.ui.logoDiv.addClass('hidden');
      }

      this.triggerMethod('enable:cancel:confirm');
    },

    onRemoveLogo: function() {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.ui.changeLogo.val('');
          this.model.set('logoUid', null);
          this.onImageUploadConfirm(null, null);
        }
      }, this));
    },

    onConfirm: function() {
      if(this.options.newItem === true){
          this.fieldsToValidate.push(
              {name: 'endUserId', type: 'required'}
          );
      }

      if(this.validate(this.fieldsToValidate)) {
        this.model.save();
      }
    },

    onChangeSubscribed: function() {
      this.model.set('subscribed',this.ui.subscribed.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeSubjectToVat: function() {
      this.model.set('subjectToVat',this.ui.subjectToVat.is(':checked'));
      this.ui.vatArea.toggleClass('hidden',!(this.ui.subjectToVat.is(':checked')));
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangePriceList: function(model) {
      this.model.set('priceListCode',model.get('code'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();

      this.options.customerModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('customer:header:rerender', modelJSON);
      App.navigate('customers/' + model.get('secId'), {trigger: true});
      this.hide();
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=endUserId').addClass('has-error');
    },

    _getCountriesAutocompleteOptions: function() {
      var countryCode, countryValue, country;

      countryCode = this.model.get('countryCode') || '';
      country = _.findWhere(this.options.countries, {code: countryCode});
      countryValue = country ? country.name : null;

      return {
        data: this.options.countries,
        valueKey: 'name',
        apiKey: 'id',
        limit: this.options.countries.length,
        placeholder: _.i18n('common.countryCode'),
        value: countryValue,
        name: 'countryCode',
        callback: _.bind(this.onChangeCountry, this)
      };
    },

    _getPriceListAutoCompleteOptions: function() {
      return {
        modelProperty: 'code',
        modelDisplayProperty: 'name',
        modelSelectValue: this.model.get('priceListCode'),
        collection: new Backbone.Collection(this.options.priceLists),
        onItemSelected: _.bind(this.onChangePriceList, this)
      };
    },

    onChangeCountry: function(fieldName, model) {
      var code = model ? model.get('code') : '';

      this.onChange(fieldName, code);
      this.triggerMethod('enable:cancel:confirm');
    },

    onBeforeDestroy: function() {
      this.$('#emailInput').tokenfield( 'destroy' );
      this.$('#CustomerEmailInput').tokenfield( 'destroy' );
    }
  });

  module.exports = CreateEditLayoutView;
});
