define('runTableTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'runTableTabView',
    'jquery',
    'emptyView',
    'bootbox'
], function(
    module,
    App,
    _,
    Backbone,
    TableTabView,
    $,
    EmptyView,
    bootbox
) {
    'use strict';

    var TableTabController = {
        showTableTab: function(region, runModel) {
            this.region = region;
            this.model = runModel;
            this.view = new TableTabView({
                model: runModel
            });
            this.view.listenTo(this.view, 'well:filteredDetailRow', _.bind(this.filteredDetailRow, this));
            this.view.listenTo(this.view, 'well:filteredDetailColumn', _.bind(this.filteredDetailColumn, this));
            this.view.listenTo(this.view, 'well:filteredDetailItem', _.bind(this.filteredDetailItem, this));
            this.view.listenTo(this.view, 'run:importXml', _.bind(this.onImportXml, this));
            this.view.listenTo(this.view, 'run:refresh', _.bind(this.onRefresh, this));


            region.show(this.view);
        },

        onRefresh: function() {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);

            this.view.model.refreshData().done(_.bind(function(model) {
                this.emptyView.destroy();
                this.showTableTab(this.region, model);
            }, this)).fail(_.bind(function(response) {
                this.emptyView.destroy();
                this.showTableTab(this.region, this.model);
                if (response.status === 409) {
                 bootbox.alert(_.i18n(response.responseText));
                }
            }, this));
        },

        onImportXml: function(fileInfo) {
            this.view.destroy();
            this.emptyView = new EmptyView();
            this.region.show(this.emptyView);

            $.when(this.view.model.updateWithXmlFile(fileInfo)).done(_.bind(function(model) {
                this.emptyView.destroy();
                this.showTableTab(this.region, model);
            }, this));
        },

        filteredDetailRow: function(row) {
            App.navigate('runs/'+this.view.model.get('secId')+'/details/row/' + row, {trigger: true});
        },

        filteredDetailColumn: function(column) {
            if (column) {
                App.navigate('runs/'+this.view.model.get('secId')+'/details/column/' + column, {trigger: true});
            } else {
                App.navigate('runs/'+this.view.model.get('secId')+'/details', {trigger: true});
            }
        },

        filteredDetailItem: function(row, column) {
            App.navigate('runs/'+this.view.model.get('secId')+'/details/row/'+row+'/column/' + column, {trigger: true});
        }
    };

    module.exports = TableTabController;
});
