// /**
//  * Created by RKL on 18/08/2015.
//  */
// define([
//   'module',
//   'marionette',
//   'app',
//   'settings',
//   'customerProdTemplatesItemView',
//   'customerProdTemplatesNewItemView',
//   'template!customerProdTemplatesTpl'
// ], function (
//   module,
//   Marionette,
//   App,
//   Settings,
//   ItemView,
//   NewItemView,
//   templatesTpl
// ) {
//   'use strict';

//   var CustomersProductTemplatesTabCollectionView = Marionette.CompositeView
//     .extend({
//       template: templatesTpl,
//       childViewContainer: '.templates-container',

//       triggers: {
//         'click @ui.returnButton' : 'onPressReturn'
//       },

//       ui: {
//         returnButton: '.js-return-button'
//       },

//       getChildView: function(item) {
//         if (item.get('id') !== 0) {
//           return ItemView;
//         } else {
//           return NewItemView;
//         }
//       }
//   });

//   module.exports = CustomersProductTemplatesTabCollectionView;
// });

/**
 * Created by RKL on 18/08/2015.
 */
define('customerProdTemplatesCollectionView',[
  'module',
  'elementsCollectionView',
  'customerProdTemplatesItemView',
  'customerProdTemplatesNewItemView',
  'template!elementsCollectionTpl'
], function (
  module,
  ElementsCollectionView,
  ItemView,
  NewItemView,
  templatesTpl
) {
  'use strict';

  var CollectionView = ElementsCollectionView.extend({
    template: templatesTpl,

    getChildView: function(item) {
      if (item.get('id') !== 0) {
        return ItemView;
      } else {
        return NewItemView;
      }
    }
  });

  module.exports = CollectionView;
});
