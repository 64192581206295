/**
 * Created by BFR on 02/05/2017.
 */
define('adminLisAserieController',[
    'module',
    'underscore',
    'app',
    'adminLisAserieView',
    'bootbox',
    'lisAserieCreateEditLayoutView',
    'settings',
    'entities/lisaseries'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    CreateEditLayoutView
    ) {
    'use strict';

    var SuperAdminLisAserieListController = {
        showLisAserieList: function(data) {
            this.data = data;
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'lisaserie:delete', _.bind(this.onDelete, this));
            view.listenTo(view, 'lisaserie:edit', _.bind(this.onEdit, this));
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('lisAserieListTab').show(view);
        },

        showLisAserieAdd: function(lisSecId) {
            this.showLisAserieAddEdit(App.request('lisaserie:model', {
                refLis: {
                    secId: lisSecId
                }
            }), true);
        },

        showLisAserieAddEdit: function(model, newItem) {
            this.createEditView = new CreateEditLayoutView({
                lisAserieModel: model,
                newItem: newItem
            });
            this.createEditView.show({
                title: _.i18n('lisaserie.new')
            });
        },

        showLisAserieEdit: function(lisSecId, lisAserieSecId) {
            var that = this;
            var model = App.request('lisaserie:model', {
                refLis: {
                    secId: lisSecId
                },
                secId: lisAserieSecId
            });
            model.fetch().done(function() {
                that.showLisAserieAddEdit(model, false);
            });
        },

        onEdit: function(lisSecId, lisAserieSecId){
            App.navigate('admin/liss/' + lisSecId + '/lisaserie/' + lisAserieSecId + '/edit', {trigger: true});
        },

        onGridLoaded: function() {
            var lisSecId = this.data.model.get('refLisSecId')?
                                this.data.model.get('refLisSecId'):this.data.model.get('secId');
            var data = {
                url: App.request('lisaserie:get-collection-url', lisSecId),
                rowCountUrl: App.request('lisaserie:get-count-url', lisSecId)
            };

            this.view.displayGrid(data);
        },

        onDelete: function(lisSecId, secId){
            bootbox.confirm(_.i18n('lisaserie.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('lisaserie:delete', lisSecId, secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = SuperAdminLisAserieListController;
});
