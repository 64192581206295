/**
 * Created by GSP on 7/01/2016.
 */
define('itemPagesNameMixin',['module'], function(module) {
  'use strict';

  var ItemPagesNameMixin = {
    ACCOUNT: 'account',
    MY_ACCOUNT: 'account_account',
    GRAPHICAL_ID: 'account_graphicalid',

    // PRODUCTS
    PRODUCTS: 'products',
    MY_PRODUCTS: 'products_products',
    PRODUCTS_PRICELISTS: 'products_pricelists',
    PRODUCTS_CATALOGUES: 'products_catalogues',

    //CLIENTS
    CLIENTS: 'customers',
    MY_CLIENTS: 'customers_customers',
    //ORDERS
    ORDERS: 'orders',
    //SUPPLIERS
    SUPPLIERS: 'suppliers',
    SUPPLIERS_CATALOGUES: 'suppliers_catalogues',

    //GRAPHICAL REQUESTS
    GRAPHICALREQUESTS : 'graphicalRequests',
    GRAPHICALREQUESTS_ALL_IN_ONE : 'graphicalRequests_all_In_One',
    GRAPHICALREQUESTS_VECTORIZATION : 'graphicalRequests_vectorization',
    GRAPHICALREQUESTS_FILECONVERSION: 'graphicalRequests_fileConversion',
    GRAPHICALREQUESTS_REMOVEBACKGROUND : 'graphicalRequests_RemoveBackground',
      
    //ADMIN
    ADMIN: 'admin',
    ADMIN_TRANSLATIONS: 'admin_translations',
    ADMIN_TIMETRACKING: 'admin_timeTracking',
    ADMIN_KITS: 'admin_kits',
    ADMIN_LMBANALYZERS: 'admin_lmbAnalysers',
    ADMIN_LMBANAS: 'admin_lmbAnas',
    ADMIN_LMBSMPMATS: 'admin_lmbSmpMats',
    ADMIN_KITPROTS: 'admin_kitProts',
    ADMIN_LIS: 'admin_lis',
    ADMIN_LISANAS: 'admin_lisanas',
    ADMIN_SUBACCOUNTS:  'subAccountCreator',
    ADMIN_SETTING: 'admin_setting',
    ACCOUNTCREATOR:  'ACCOUNTCREATOR',
    ACCOUNTSHARECREATOR : 'ACCOUNTSHARECREATOR',
    TIMETRACKER: 'TIMETRACKER',
    SUPERADMIN_STATS: 'SUPERADMIN_STATS',
    COLLECTIONS : 'COLLECTIONS',

    //LAB
    RUN: 'run',
    RUNS: 'runs',
    EXTRACTRUNS: 'extractruns',
    RUNTEMPLATES: 'runTemplates',
    EXTRACTTEMPLATES: 'extractRunTemplates',
    SAMPLES: 'samples',

    //SUPER ADMIN
    SUPERADMIN: 'SUPERADMIN',
    SUPERADMIN_OVERVIEW: 'superAdmin_overview',
    SUPERADMIN_CACCOUNTS: 'superAdmin_cAccounts',
    SUPERADMIN_MBANAS: 'superAdmin_mbAnas',
    SUPERADMIN_MBSMPMATS: 'superAdmin_mbSmpMats',
    SUPERADMIN_MBANARESGRS: 'superAdmin_mbAnaResGrs',
    SUPERADMIN_MBAMODELS: 'superAdmin_mbAModels'
  };

  module.exports = ItemPagesNameMixin;
});
