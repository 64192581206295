define('timetrackerFromTicketView',[
  'module',
  'underscore',
  'backbone',
  'marionette',
  'app',
  'template!timetrackerFromTicketTpl',
  'imageUtils',
  'moment'
], function(
  module,
  _,
  Backbone,
  Marionette,
  App,
  viewTpl,
  imageUtils,
  moment
) {
  'use strict';

  var Timetracker =  Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
    },

    ui: {
      screenItem: '.json-calendar-image',
      trash: '.tt-delete-screen',
      screenBlock: '.timetracking-image-in-popup',
      backToTicket: '.back-to-ticket'
    },

    events: {
      'click @ui.screenItem' : 'onZoom',
      'click @ui.trash' : 'onDelete',
      'click @ui.backToTicket': 'onBackToTicket'
    },

    serializeData: function() {
      var templateData = {};

      templateData.logonId = this.options.logonId;
      templateData.screens = _.sortBy(this.model.toJSON(), function(item) {
        return item.end;
      });

      _.each(templateData.screens, function(screen) {
        _.each(screen.snaptshots, function(snapshot) {
          snapshot.fileUrl = imageUtils.getLogo(snapshot.fileUid);
          snapshot.dateTime = moment(snapshot.date).format('YYYY-MM-DD HH:mm:ss');
        });
      });

      return templateData;
    },

     onZoom: function(e) {
      var uid = $(e.currentTarget).attr('data-file-uid');

      this.trigger('timetracker:onZoom', uid);    
    },

    onDelete: function(e) {
      var secId = $(e.currentTarget).attr('data-secid'),
        uid = $(e.currentTarget).attr('data-uid'),
        screens = this.model.toJSON(),
        arr = [];

      var data = _.map(screens, function(item) {
          if (item.secId === secId) {
            arr = _.without(item.snaptshots, _.find(item.snaptshots, {fileUid: uid}));
          } else {
            arr = item.snaptshots;
          }
          return {
            end: item.end,
            secId: item.secId,
            snaptshots: arr,
            start: item.start,
            user: item.user,
            task: item.task
          };
      });
      this.model =  new Backbone.Model(data);
      this.trigger('timetracker:onDelete', secId, uid);
    },

    onBackToTicket: function() {
      App.navigate(this.gpaphicalService + '/' + this.secId, {trigger: true});
    }

  });

  module.exports = Timetracker;
});
