define('caccountProfileTabView',[
  'module',
  'caccountProfileEditPopupView',
  'underscore',
  'marionette',
  'imageUtils',
  'template!caccountProfileTabTpl'
], function (
  module,
  CaccountEditLayoutView,
  _,
  Marionette,
  imageUtils,
  viewTpl
) {
  'use strict';

  var CustomersInfoView = Marionette.ItemView.extend({
    template: viewTpl,

    modelEvents: {
      change: 'render'
    },

    ui: {
      logo: 'img.js-logo-holder',
      logoDiv: 'div.js-logo-holder',
      imgBlock: 'div.profile-img-block',
      vatArea: '#vat-area',
      subjectToVat : '#subject-to-vat'
    },

    events: {
      'click .js-edit': '_onEdit'
    },

    serializeData: function () {
      var templateData = this.model.toJSON(),
        secId = this.model.get('secId'),
        countryCode, country;

      templateData.viewLess = secId;
      templateData.newItem = this.options.isNew;
      templateData.vatNumber = this.model.get('vatNumber') || false;
      countryCode = this.model.get('country');
      country = _.findWhere(this.options.countries, {code: countryCode});
      templateData.country = country ? country.name : null;

      return templateData;
    },

    onRender: function() {
      this.displayLogo(this.model.get('logo'), this.model.get('code'));
      $.material.init();
    },

    onShow: function() {
      var vatNumber = this.model.get('vatNumber') || false; 
        
      this.ui.vatArea.toggleClass('hidden',!vatNumber);
      $.material.init();
    },

    displayLogo: function(logo, code, remove) {
      var image = imageUtils.getLogo(logo, code);

      if (image) {
        this.ui.logo.removeClass('hidden');
        this.ui.imgBlock.removeClass('hidden');
        this.ui.logo.attr('src', image);
        if (!this.ui.logoDiv.hasClass('hidden')) {
          this.ui.logoDiv.addClass('hidden');
        }
      } else {
        if (remove) {
          this.ui.logo.attr('src', '');
        }
        this.ui.logo.attr('src', '').addClass('hidden');
        this.ui.imgBlock.addClass('hidden');
        this.ui.logoDiv.removeClass('hidden');
      }
    },

    _onEdit: function() {
      var caccountEditView = new CaccountEditLayoutView({
        caccauntModel: this.model,
        countries: this.options.countries
      });
      caccountEditView.show({
        title: _.i18n('caccount.edit')
      });
      caccountEditView.listenTo(caccountEditView, 'onModelSaved', _.bind(this.onModelSaved, this));
    },

    onModelSaved: function(model) {
      this.trigger('onModelSaved', model);
    }

  });

  module.exports = CustomersInfoView;
});
