define('runTableTabView',[
    'module',
    'marionette',
    'template!runTableTabTpl',
    'underscore',
    'settings',
    'app',
    'envHandler',
    'backbone',
    'dateUtils',
    'wellView',
    'autocompleteView',
    'template!wellTooltipTpl',
    'runCreateTemplateLayoutView',
    'bootbox',
    'entities/wells'
], function(
    module,
    Marionette,
    runTableTabTpl,
    _,
    Settings,
    App,
    EnvironmentHandler,
    Backbone,
    DateUtil,
    WellView,
    AutocompleteView,
    wellTooltipTpl,
    CreateTemplateLayoutView,
    bootbox
) {
    'use strict';

    var TableTabView = Marionette.LayoutView.extend({
        template: runTableTabTpl,

        selectedWells: [],

        ui: {
            lineTitle: '.wellTableLineTitleItem',
            columnTitle: '.wellTableColumnTitleItem',
            importXmlBtn: '.js-update-runs',
            searchWellBtn: '.search-well-button',
            selectResult: '.select-result',
            selectSmpId: '.select-smpid',
            refreshBtn: '.refresh-button',
            newRunTemplateBtn: '.new-run-template-button',
            print: '.js-print'
        },

        events: {
            'click @ui.lineTitle': 'onLineTitleClick',
            'click @ui.columnTitle': 'onColumnTitleClick',
            'change @ui.importXmlBtn': 'onImportXmlClick',
            'click @ui.searchWellBtn': 'onSearchWellClick',
            'change @ui.selectResult': 'onResultChange',
            'change @ui.selectSmpId': 'onSmpIdChange',
            'click @ui.refreshBtn': 'refreshBtnClick',
            'click @ui.newRunTemplateBtn': 'newRunTemplate',
            'click .download-report-run-button': 'onDownloadReportClick',
            'click @ui.print': 'onPrint',
            'click .select-run-button': 'onSelectClick'
        },

        regions: {
            assaySearch: '.js-select-assay-region',
            mbAnaSearch: '.js-select-mbana-region',
            resultSearch: '.js-select-result-region',
            tblA01: '.tbl-A01',
            tblA02: '.tbl-A02',
            tblA03: '.tbl-A03',
            tblA04: '.tbl-A04',
            tblA05: '.tbl-A05',
            tblA06: '.tbl-A06',
            tblA07: '.tbl-A07',
            tblA08: '.tbl-A08',
            tblA09: '.tbl-A09',
            tblA10: '.tbl-A10',
            tblA11: '.tbl-A11',
            tblA12: '.tbl-A12',
            tblB01: '.tbl-B01',
            tblB02: '.tbl-B02',
            tblB03: '.tbl-B03',
            tblB04: '.tbl-B04',
            tblB05: '.tbl-B05',
            tblB06: '.tbl-B06',
            tblB07: '.tbl-B07',
            tblB08: '.tbl-B08',
            tblB09: '.tbl-B09',
            tblB10: '.tbl-B10',
            tblB11: '.tbl-B11',
            tblB12: '.tbl-B12',
            tblC01: '.tbl-C01',
            tblC02: '.tbl-C02',
            tblC03: '.tbl-C03',
            tblC04: '.tbl-C04',
            tblC05: '.tbl-C05',
            tblC06: '.tbl-C06',
            tblC07: '.tbl-C07',
            tblC08: '.tbl-C08',
            tblC09: '.tbl-C09',
            tblC10: '.tbl-C10',
            tblC11: '.tbl-C11',
            tblC12: '.tbl-C12',
            tblD01: '.tbl-D01',
            tblD02: '.tbl-D02',
            tblD03: '.tbl-D03',
            tblD04: '.tbl-D04',
            tblD05: '.tbl-D05',
            tblD06: '.tbl-D06',
            tblD07: '.tbl-D07',
            tblD08: '.tbl-D08',
            tblD09: '.tbl-D09',
            tblD10: '.tbl-D10',
            tblD11: '.tbl-D11',
            tblD12: '.tbl-D12',
            tblE01: '.tbl-E01',
            tblE02: '.tbl-E02',
            tblE03: '.tbl-E03',
            tblE04: '.tbl-E04',
            tblE05: '.tbl-E05',
            tblE06: '.tbl-E06',
            tblE07: '.tbl-E07',
            tblE08: '.tbl-E08',
            tblE09: '.tbl-E09',
            tblE10: '.tbl-E10',
            tblE11: '.tbl-E11',
            tblE12: '.tbl-E12',
            tblF01: '.tbl-F01',
            tblF02: '.tbl-F02',
            tblF03: '.tbl-F03',
            tblF04: '.tbl-F04',
            tblF05: '.tbl-F05',
            tblF06: '.tbl-F06',
            tblF07: '.tbl-F07',
            tblF08: '.tbl-F08',
            tblF09: '.tbl-F09',
            tblF10: '.tbl-F10',
            tblF11: '.tbl-F11',
            tblF12: '.tbl-F12',
            tblG01: '.tbl-G01',
            tblG02: '.tbl-G02',
            tblG03: '.tbl-G03',
            tblG04: '.tbl-G04',
            tblG05: '.tbl-G05',
            tblG06: '.tbl-G06',
            tblG07: '.tbl-G07',
            tblG08: '.tbl-G08',
            tblG09: '.tbl-G09',
            tblG10: '.tbl-G10',
            tblG11: '.tbl-G11',
            tblG12: '.tbl-G12',
            tblH01: '.tbl-H01',
            tblH02: '.tbl-H02',
            tblH03: '.tbl-H03',
            tblH04: '.tbl-H04',
            tblH05: '.tbl-H05',
            tblH06: '.tbl-H06',
            tblH07: '.tbl-H07',
            tblH08: '.tbl-H08',
            tblH09: '.tbl-H09',
            tblH10: '.tbl-H10',
            tblH11: '.tbl-H11',
            tblH12: '.tbl-H12'
        },

        onDownloadReportClick: function() {
            window.location = Settings.url('compuzz', 'v2/runs/' + this.model.get('secId') + '/report');
        },

        newRunTemplate: function() {
            this.createTemplate = new CreateTemplateLayoutView({
                runModel: this.model,
                newItem: true
            });
            this.createTemplate.show({
                title: _.i18n('run.newTemplate')
            });
        },

        refreshBtnClick: function() {
            this.trigger('run:refresh');
        },

        onImportXmlClick: function(e) {
            if (e && e.target && e.target.files && e.target.files[0]) {
                this.uploadXml(e.target.files[0]);
            }
        },

        uploadXml: function(fileInfo) {
            this.ui.importXmlBtn.val('');
            this.trigger('run:importXml', fileInfo);
        },

        onLineTitleClick: function(event) {
            this.trigger('well:filteredDetailRow', event.target.textContent);
        },

        onColumnTitleClick: function(event) {
            this.trigger('well:filteredDetailColumn', event.target.textContent);
        },

        serializeData: function() {
            var templateData = this.model.toJSON();
            templateData.createDate = DateUtil.toMDYHHMMFormat(this.model.get('createDate'));
            return templateData;
        },

        onShow: function() {
            this.showWell();

            this.selectedWells = [];

            var assayView = new AutocompleteView(
                this._getKitAutocompleteOptions('assaySecId', 'v2/assays', 'code', 'selectassay.placeholder')
            );
            this.getRegion('assaySearch').show(assayView);

            var mbAnaView = new AutocompleteView(
                this._getKitAutocompleteOptions('mbAnaSecId', 'v2/mbanas', 'code', 'selectmbana.placeholder')
            );
            this.getRegion('mbAnaSearch').show(mbAnaView);

            if (this.model.get('needRefresh')) {
                bootbox.confirm({
                    message: _.i18n('run.needRefresh'),
                    buttons: {
                        confirm: {
                            label: _.i18n('common.yes'),
                            className: 'btn-success'
                        },
                        cancel: {
                            label: _.i18n('common.no'),
                            className: 'btn-danger'
                        }
                    },
                    callback: _.bind(function (result) {
                        if (result) {
                            this.trigger('run:refresh');
                        }
                    }, this)
                });
            }
        },

        showImageZoomPopover: function(well, target) {
            well = App.request('well:model', well);

            if ((well.get('smpid') || well.get('smptype')) && well.get('refAssay')) {
              var templateData = well.toJSON();
              templateData.results = this.model.getResultForWell(well);

              templateData = _.extend(templateData, well.getErrorObject());

              var content = Marionette.Renderer.render(wellTooltipTpl, templateData);

              target.attr('data-content', content);
              target.popover({
                trigger: 'hover',
                placement: 'top',
                html: 'true',
                container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content')
              }).data('bs.popover').tip().addClass('jqgrid-popover');
            }
        },

        showWell: function() {
            _.each(this.model.get('wells'), _.bind(function(well) {
                var wellView = new WellView({
                    model: well,
                    modelRun: this.model
                });
                wellView.listenTo(wellView, 'well:filteredDetailItem', _.bind(this.filteredDetailItem, this));
                wellView.listenTo(wellView, 'well:selectedWell', _.bind(this.onWellSelected, this));
                this.getRegion('tbl' + well.pos).show(wellView);
                this.showImageZoomPopover(well, this.$('.tbl-' + well.pos));
            }, this));
        },

        onPrint: function() {
            var $print = $('.js-print-table')
                .clone()
                .addClass('print')
                .prependTo('#print-region');

            $('#print-region').find('.js-kitl-table-details-for-printing-container').remove();
            $('.js-kitl-table-details-for-printing-container').clone().prependTo('#print-region');
            $('html,body').css('overflow', 'visible');
            $('#main-region').hide();
            //window.print() stops JS execution
            window.print();

            //Remove div once printed
            $print.remove();
            $('html,body').css('overflow', 'hidden');
            $('#main-region').show();
        },

        onWellSelected: function(pos, select) {
            if (select) {
                this.selectedWells.push(pos);
            } else {
                var index = this.selectedWells.indexOf(pos);
                if (index > -1) {
                    this.selectedWells.splice(index, 1);
                }
            }
        },

        onSelectClick: function() {
            if (this.selectedWells) {
                var arj = this.selectedWells.join('-');
                App.navigate('/runs/' + this.model.get('secId') + '/details/select/' + arj, {trigger: true});
            }
        },

        _getKitAutocompleteOptions: function(property, service, valueKey, label) {
            return {
                type: 'remote',
                remote: Settings.url('compuzz', service),
                valueKey: valueKey,
                apiKey: 'secId',
                placeholder: _.i18n(label),
                value: null,
                name: property,
                onPressKey: true,
                lazyLoad: true,
                keys: {
                    query: 'code',
                    limit: 'pageSize'
                },
                callback: _.bind(this.onChange, this)
            };
        },

        onSearchWellClick: function() {
            App.navigate('/runs/' + this.model.get('secId') + '/details/filters/' + JSON.stringify(this.filters), {trigger: true});
        },

        onResultChange: function(e) {
            var $target = $(e.currentTarget),
                value = $target.val();

            if (!this.filters) {
                this.filters = {};
            }
            this.filters.result = value;
        },

        onSmpIdChange: function(e) {
            var $target = $(e.currentTarget),
                value = $target.val();

            if (!this.filters) {
                this.filters = {};
            }
            this.filters.smpid = value;
        },

        onChange: function(fieldName, model) {
            if (!this.filters) {
                this.filters = {};
            }
            this.filters[fieldName] = model.get('secId');
        },

        filteredDetailItem:function (pos) {
            this.trigger('well:filteredDetailItem', pos[0], pos.substring(1));
        }
    });

    module.exports = TableTabView;
});
