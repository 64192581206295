define('superAdminCAccountTabs',[
    'underscore',
    'rolesMixin'
], function (_,RolesMixin) {
    'use strict';

    var  cAccountSuperTabs = [
        {
            route: 'profile',
            name: 'profile',
            role : RolesMixin.DASHBOARD_SUPERADMIN_ACCOUNT_PROFILE,
            title: _.i18n('cAccountDetail.profile.tabTitle')
        },
        {
            route: 'credits',
            name: 'credits',
            role : RolesMixin.DASHBOARD_SUPERADMIN_ACCOUNT_CREDITS,
            title: _.i18n('cAccountDetail.credits.tabTitle')
        },
        {
          route: 'graphicalservice',
          name: 'graphicalservice',
          role : RolesMixin.DASHBOARD_SUPERADMIN_ACCOUNT_GRAPHICALREQUESTS,
          title: _.i18n('cAccountDetail.graphicalRequests.tabTitle')
        }
    ];

    return cAccountSuperTabs;
});
