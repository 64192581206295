define('anaAssayCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!anaAssayCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      lmbAnaSelect: '.js-select-lmbana-region',
      assaySelect: '.js-select-assay-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'refLmbAna', type: 'required'},
      {name: 'refAssay', type: 'required'}
    ],

    initialize: function(options) {
      this.model = options.anaAssayModel.clone();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      templateData.fromLmbAna = this.options.fromLmbAna;
      return templateData;
    },

    onShow: function() {
      $.material.init();
      if (this.options.fromLmbAna) {
        var assayView = new AutocompleteView(
          this._getAutocompleteOptions('refAssay', 'code', 'code',
            'v2/assays', 'selectassay.placeholder', 'code')
        );
        this.getRegion('assaySelect').show(assayView);
      } else {
        var lmbAnaView = new AutocompleteView(
          this._getAutocompleteOptions('refLmbAna', 'code', 'code',
            'v2/lmbanas', 'selectlmbana.placeholder', 'code')
        );
        this.getRegion('lmbAnaSelect').show(lmbAnaView);
      }
      this.triggerMethod('enable:cancel:confirm');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.model.save();
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.options.anaAssayModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('anaassays:header:rerender', modelJSON);
      this.hide();
    },

    hide: function() {
      var assaySecId = this.model.get('refAssay')?this.model.get('refAssay').secId:'';
      var lmbAnaSecId = this.model.get('refLmbAna')?this.model.get('refLmbAna').secId:'';
      var secId = this.model.get('secId');
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'admin/assays/'+assaySecId+'/anaassay/new' ||
          App.getCurrentRoute() ===  'admin/assays/'+assaySecId+'/anaassay/'+secId+'/edit') {
        App.navigate('admin/assays/'+assaySecId+'/anaassay', {trigger: true});
      } else if (App.getCurrentRoute() ===  'admin/lmbanas/'+lmbAnaSecId+'/anaassay/new' ||
        App.getCurrentRoute() ===  'admin/lmbanas/'+lmbAnaSecId+'/anaassay/'+secId+'/edit') {
        App.navigate('admin/lmbanas/'+lmbAnaSecId+'/anaassay', {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
