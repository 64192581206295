define('entities/restrans',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var ResTrans = Backbone.Model.extend({
    fetch: function() {      
      this.url = Settings.url('compuzz', 'v2/lmbanas/undifined/restrans/' + this.get('refLmbAna').secId);
      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      res: '',
      name: '',
      comment: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (lmbAnaSecId) {
      return Settings.url('compuzz', 'v2/lmbanas/' + lmbAnaSecId + '/restrans');
    },

    save: function() {
      this.url = this.postUrl(this.get('refLmbAna').secId);
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var ResTransCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/lmbanas/');
    },
    model: ResTrans
  });

  var API = {

    getResTransCollectionUrl: function(lmbAnaSecId) {
      new ResTransCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/lmbanas/' + lmbAnaSecId + '/restrans', params);
    },

    getAllResTrans: function(lmbAnaSecId, params) {
      var url = Settings.url('compuzz', 'v2/lmbanas' + lmbAnaSecId + '/restrans', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getResTransCountUrl: function(lmbAnaSecId) {
      var params = '';
      return 'v2/lmbanas/'+lmbAnaSecId+'/restrans/rowCount' + params;
    },

    deleteResTrans: function(lmbAnaSecId, secId){
      var url = Settings.url('compuzz', 'v2/lmbanas/'+lmbAnaSecId+'/restrans/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('restrans:get-collection-url', function(lmbAnaSecId) {
      return API.getResTransCollectionUrl(lmbAnaSecId);
  });

  App.reqres.setHandler('restrans:get-collection-to-page', function(lmbAnaSecId, params) {
      return API.getAllResTrans(lmbAnaSecId, params);
  });

  App.reqres.setHandler('restrans:get-count-url', function(lmbAnaSecId) {
    return API.getResTransCountUrl(lmbAnaSecId);
  });

  App.reqres.setHandler('restrans:model', function(itemJSON) {
    return new ResTrans(itemJSON);
  });

  App.reqres.setHandler('restrans:delete', function(lmbAnaSecId, secId) {
    return API.deleteResTrans(lmbAnaSecId, secId);
  });
});
