/**
 * Created by RKL on 18/08/2015.
 */
define('customerTemplatesMetaInfoView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'template!customerTemplatesMetaInfoTpl',
  'entities/caccounts/templates'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  viewTpl
) {
  'use strict';

  var MetaInfoView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      toolTip: '.tooltipped-color'
    },

    events: {
    },

    serializeData: function() {
      return {
        metaInfo : this.getMetaInfo()
      };
    },

    getMetaInfo: function() {
      var metaXml = this.model.get('metaXml') || {
          hasRasterImages: false
        },
        name = this.model.get('name'),
        variationsNumber = this.model.get('variationsNumber'),
        standardSizeCode = this.model.get('standardSizeCode'),
        colors = metaXml.colors || '',
        splittedColors = colors ? colors.split(';') : [],
        parsedColors = [],
        length = splittedColors.length < 4 ? splittedColors.length : 4,
        metaInfo;

      for(var index = 0; index < length; index++) {
        var color = splittedColors[index].split(':');
        parsedColors.push({
          name: color[0], 
          hexColor: color[1] || color[0]
        });
      }
      metaInfo = {
        variationsNumber: variationsNumber || 0,
        hasRasterImages: metaXml.hasRasterImages,
        colors: parsedColors,
        name: name,
        standardSizeCode: standardSizeCode || ''
      };
      return metaInfo;
    },

    onShow: function() {
      //it doesn't work because exist conflict between bootstrap and jquery ui
      // this.ui.toolTip.tooltip({
      //     position: { my: "top+15 center", at: "right center" }
      //   }).tooltip();
      this.ui.toolTip.tooltip();
    }

  });

  module.exports = MetaInfoView;
});
