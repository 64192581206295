define('adminAnaAssayView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!adminAnaAssayListTpl'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl
    ) {
    'use strict';

    var AnaAssayListView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'anaAssayListFilters',

        triggers: {
        },

        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteAnaAssay: '.delete-anaassay-link'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click @ui.deleteAnaAssay': 'onDeleteAnaAssay'
            }, this.gridEvents);
        },

        serializeData: function() {
            var templateData = {
                type: 'anaassay',
                data: this.options.data,
                currentCAccountSecId: Settings.get('currentCAccount').secId
            };
            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: Settings.get('perPage15'),
                currentPage: 1
            };
        },

        onDeleteAnaAssay: function(event){
            this.trigger('anaassay:delete', this.options.data.model.get('secId'), $(event.currentTarget).find('span').attr('data-row-id'));
        },

        gridOptions: function(data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('anaassay.refLmbAna'),
                        name: 'refLmbAna.code',
                        classes: 'anaassay-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'refLmbAna.code',
                        width: 40
                    }, {
                        label: _.i18n('anaassay.nbRepetition'),
                        name: 'nbRepetition',
                        classes: 'anaassay-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'nbRepetition',
                        width: 40
                    }, {
                        label: _.i18n('common.comment'),
                        name: 'comment',
                        classes: 'anaassay-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'comment'
                    }
                ],

                onSelectRow: function(rowId, bool, e) {
                    if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
                        if (e.type === 'click') {
                            App.navigate('/admin/anaassays/' + rowId, {trigger: true});
                        }
                    }
                }
            };

            if (Settings.get('currentCAccount').secId === this.options.data.model.get('refKit').refCAccount.secId) {
                options.colModel.push({
                    label: ' ',
                    name: 'delete',
                    search: false,
                    classes: 'delete-anaassay-link delete-link',
                    formatter: this.deleteIconFormatter,
                    width: 20
                });
            }

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        }
    });

    module.exports = AnaAssayListView;
});
