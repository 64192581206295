/**
 * Created by RKL on 15/09/2015.
 */
define('customerTemplatesElController',[
  'module',
  'backbone',
  'underscore',
  'app',
  'bootbox',
  'settings',
  'emptyView',
  'galleryView',
  'customerTemplatesPopupVariationsView',
  'customerTemplatesCollectionView',
  'customerTemplatesFiltersView',
  'entities/caccounts',
  'entities/caccounts/templates'
], function(
  module,
  Backbone,
  _,
  App,
  bootbox,
  Settings,
  EmptyView,
  GalleryView,
  PopupVariations,
  ElementsView,
  FiltersView
) {
  'use strict';

  /**
   * This controller is extended from ElementsController
   */
  var TemplatesController = {
    //Variable is used for picture lazy loading functionality.
    elCollectionViewed: undefined,
    subDomain: undefined,

    requests: {
      modelRequest: 'caccount:template-model',
      countRequest: 'caccount:template-count',
      collectionRequest: 'caccount:templates',
      groupRequest: 'caccount:template-group',
      createRequest: 'caccount:create-template',
      createFromEmpty: 'caccount:create-from-empty',
      createFromAttach: 'caccount:create-from-attach'
    },

    displayElements: function(groupModel, region, customerSecId, varSecId) {
      this.groupModel = groupModel;
      this.region = region;
      this.customerSecId = customerSecId;
      this.varSecId = varSecId;
      this.fetchElements(groupModel, region);
    },
    displayFilters: function(groupModel, region) {
      var that = this,
          params = {
            secId: groupModel.get('secId'),
            endUserId: groupModel.get('endUserId')
          };

      this.filtersRegion = region;
      App.request('caccount:template-count', params).done(function(count){
        that.getStandardSizes(count, params, region);
      });
    },

    getStandardSizes: function(count, params, region) {
      var that = this,
          filtersView;
      $.when(App.request('caccount:templates-standard-sizes', params)).done(function(standardSizes) {
          filtersView = new FiltersView({
            filters: standardSizes, 
            count: count
          });
          region.show(filtersView);

          App.off('templates-filters:filtersChanged')
            .on('templates-filters:filtersChanged', _.bind(that.fetchCollectionByFilters, that));
        });
    },

    fetchElements: function(groupModel, region) {
      var emptyView = new EmptyView(), params;

      params = {
        secId: groupModel.get('secId'),
        endUserId: groupModel.get('endUserId'),
        first: 0
      };

      region.show(emptyView);

      this.fetchCollection(params);

      $(window).on('resize', _.bind(this.checkResize, this));
    },

    fetchCollectionByFilters: function(filters) {
      var params,
          groupModel = this.groupModel;

      groupModel.set({elCollection: null});
      this.elCollectionViewed = null;

      params = {
        filters: filters,
        secId: groupModel.get('secId'),
        endUserId: groupModel.get('endUserId'),
        first: 0
      };
      this.fetchCollection(params);
    },

    /*
     Create fake template at the first position that will be rendered by a "+" icon
     and that contain the reference of the parent of which we can create a variation
     */
    createFakeAddTemplate: function() {
      var newTemplate = App.request('caccount:template-model');
      newTemplate.set('endUserId', this.groupModel.get('endUserId'));
      newTemplate.set('refTemplateGroup', this.group);
      newTemplate.set('isFakeAddTemplate', true);
      return newTemplate;
    },

    fetchCollection: function(params) {
      var that = this,
        newTemplate = this.createFakeAddTemplate();

      if (this.groupModel.get('elCollection') &&
          !this.templatesShouldBeRefreshed) {
        this.preShowElements();
      } else {
        $.when(App.request('caccount:templates', params)).done(function(templates) {
          that.groupModel.set({elCollection: templates});
          that.onCollectionFetched(newTemplate);
        });
        this.templatesShouldBeRefreshed = false;
      }
    },

    onCollectionFetched: function(newTemplate, groupModel) {
      var that = this,
        group = App.request('caccount:template-group', this.groupModel.get('secId'));

      if (groupModel) {
        this.group = groupModel.toJSON();
        this.addNewTemplate(newTemplate, groupModel);
      } else {
        group.fetch().done(function() {
          that.group = group.toJSON();
          that.addNewTemplate(newTemplate);
        });
      }
    },

    addNewTemplate: function(newTemplate) {
      this.groupModel.get('elCollection').add(newTemplate, {at: 0});
      this.beforeShowTemplates();
    },

    getEndUserId: function() {
      return this.data.type === 'customer' ? this.data.model.get('endUserId') : null;
    },

    beforeShowTemplates: function() {
      var that = this,
        fetchCountries, fetchLangs;

      if (!Settings.get('countries') || !Settings.get('languages')) {
        fetchCountries = App.request('caccount:countries');
        fetchLangs = App.request('caccount:languages');
        
        $.when(fetchCountries, fetchLangs).done(function(countries, langs) {
          Settings.set('countries', countries.toJSON());
          Settings.set('languages', langs.toJSON());
          that.preShowElements();
        });
      } else {
        this.preShowElements();
      }
    },

    preShowElements: function() {
      if (this.active) {
        this.showElements();
      }
    },

    subscribeToEvents: function () {
      App.off('template:create-from-upload');
      App.on(
        'template:create-from-upload',
        _.bind(this.onCreateFromUpload, this)
      );

      App.off('template:create-from-empty');
      App.on(
        'template:create-from-empty',
        _.bind(this.onCreateFromEmpty, this)
      );

      App.off('template:create-from-attach');
      App.on(
        'template:create-from-attach',
        _.bind(this.onCreateFromAttach, this)
      );

      App.off('caccount-template:modify-template');
      App.on(
        'caccount-template:modify-template',
        _.bind(this.onModifyTemplate, this)
      );

      App.off('caccount-template:remove-template');
      App.on(
        'caccount-template:remove-template',
        _.bind(this.onRemoveTemplate, this)
      );
      App.off('caccount:templates-zoom');
      App.on(
        'caccount:templates-zoom',
        _.bind(this.onZoom, this)
      );
      App.off('caccount:templates-displayVariations');
      App.on(
        'caccount:templates-displayVariations',
        _.bind(this.onDisplayTemplateVariations, this)
      );
      App.off('save:from:template');
      App.on('save:from:template', _.bind(this.onSave, this));

      App.off('templates:itemsWerePasted');
      App.on('templates:itemsWerePasted', _.bind(this.itemsWerePasted, this));
    },

    getId: function (item) {
      return item.get('id');
    },

    getLink: function (item, size) {
      size = size === 'b' ? 1000 : 100;

      return Settings.url(
        'x2png',
        'template/' + item.getImageId() + '/preview',
        {minWidth: size, minHeight: size, db: true}
      );
    },

    getFileName: function (item) {
      return item.get('secId');
    },

    onModifyTemplate: function (templateId) {
      var urlPart;

      urlPart = '/' + this.groupModel.get('endUserId') +
        '/' + this.customerSecId;

      if (Settings.get('lastVariationsParent')) {
        urlPart += '/' + Settings.get('lastVariationsParent');
        Settings.set('lastVariationsParent', null);
      }

      App.navigate('template/templates/' + this.groupModel.get('secId') + '/' +
        templateId + '/' + Settings.get('currentCAccount').secId + urlPart, {trigger: true});


      if (this.popupVariations) {
        this.popupVariations.hide();
        this.popupVariations = undefined;
      }
    },

    onRemoveTemplate: function(notVariation) {
      if (notVariation) {
        this.groupModel.set('count', this.groupModel.get('count') - 1);
      }
      // refresh filters 
      this.displayFilters(this.groupModel, this.filtersRegion);
      this.fetchCollectionByFilters({});
    },

    itemsWerePasted: function (templates, listToRefresh, moved) {
      this.onSave(templates, listToRefresh, moved);
      if (listToRefresh && listToRefresh.length > 0) {
        App.trigger('elements-controller:setTo', listToRefresh,
          {templatesShouldBeRefreshed: true});
      }
    },

    onCreateFromUpload: function(params, importIntoPdfImage, viewCallback) {
      var paramsInner = _.extend(params, {
          secId: this.groupModel.get('secId'),
          endUserId: this.groupModel.get('endUserId'),
          importIntoPdfImage: importIntoPdfImage
        }),
        createRequest = App.request(this.requests.createRequest, paramsInner);

      this.showLoadingMessage();
      $.when(createRequest).done(_.bind(function(templates) {
        this.onSave(templates);
        if(viewCallback) {
          viewCallback();
        }
      }, this)).fail(_.bind(function(error) {
        if (error.responseJSON) {
          bootbox.dialog({
            message: error.responseJSON.label?error.responseJSON.label:'Error',
            title: _.i18n('common.request.error')
          });
        } else {
          this.showErrorMessage();
        }
        this.elementsView.triggerMethod('hideMessage');
      }, this));
    },

    onCreateFromEmpty: function(model) {
      var createRequest;

      model.set('refTemplateGroup', {secId: this.groupId});
      createRequest = App.request(
        'caccount:create-from-empty',
        model.toJSON(),
        this.endUserId
      );

      $.when(createRequest).done(function(resp) {
        App.trigger('caccount-template:modify-template', resp.get('secId'));
      });
    },

    onCreateFromAttach: function(params, viewCallback) {
      var that = this,
          paramsInner = _.extend(params, {
            secId: this.groupModel.get('secId'),
            endUserId: this.groupModel.get('endUserId')
          }),
          request = App.request(this.requests.createFromAttach, paramsInner);

      this.showLoadingMessage();
      $.when(request).done(function(template) {
        template.refTemplateGroup = that.group;
        that.onSave(template);
        if(viewCallback) {
          viewCallback();
        }
      }).fail(function() {
        that.showErrorMessage();
      });
    },

    showElements: function() {
      var that = this,
        view;

      App.request('my-website:get-data').done(function(resp) {
        that.elCollectionViewed = App.request('caccount:templates-collection');
        that.elCollectionViewed.lastElIndex = 0;
        that.subDomain = resp.get('subdomain');

        view = new ElementsView({
          collection: that.elCollectionViewed,
          customerSecId: that.customerSecId,
          groupModel: that.groupModel,
          showFromProduct: true,
          subdomain: that.subDomain
        });
        that.elementsView = view;

        view.listenTo(view, 'onScroll', _.bind(that.checkLoadMore, that));
        view.listenTo(view, 'showView', _.bind(that.onShowView, that));

        that.region.show(view);
        that.subscribeToEvents();

        if (that.varSecId) {
          App.trigger('caccount:templates-displayVariations', that.varSecId, null);
          that.varSecId = false;
        }
      });
    },

    setValues: function(data) {
      this.groupId = data.secId;
      this.data = data;
      this.region = data.elRegion;
      this.first = 0;
    },

    onSave: function (models , listToRefresh, moved) {
      if (!this.isVariation(models)) {
        this.elementsView.triggerMethod('hideMessage');
        App.trigger('variant-progress-bar:hide');
        this.elCollectionViewed.add(models);
        this.groupModel.get('elCollection').add(models);
        this.setGroupModelCount(models, listToRefresh, moved);
      } else if (this.popupVariations) {
        this.variationCollection.add(models);
        this.popupVariations.onSave(models);
        this.elementsView.triggerMethod('hideMessage');
        this.popupVariations.triggerMethod('hideProgressBar');
      } else {
        this.setGroupModelCount(models, listToRefresh, moved);
      }
      // refresh filters
      this.displayFilters(this.groupModel, this.filtersRegion);
      this.fetchCollectionByFilters({});
    },

    setGroupModelCount: function(models, listToRefresh, moved) {
      // if pasted templates with variations change group model count
        var length,
            variantions = _.filter(models, function(model){
              return model.refParent;
            });
        if (variantions) {
          length = models.length - variantions.length;
        } else {
          length = models.length;
        }
        if (moved) {
          length = listToRefresh ? length : 0;
        } else {
          length = length ? length : 1;
        }
        this.groupModel.set({count: this.groupModel.get('count') + length});
    },

    isVariation: function (models) {
      //if variant we have refParent, also models for 'from upload' will be -> array but 'from template' -> model
      return ((models.length && models[0].refParent) || (!models.length && models.get('refParent')));
    },

    onPressReturn: function() {
      App.trigger('folder-groups:press-return');
    },

    getGalleryCollectionToParse: function() {
      if(this.variationCollection && this.variationCollection.length) {
        return this.variationCollection;
      }
      return this.elCollectionViewed;
    },

    parseGalleryCollection: function() {
      var that = this;
      var collectionViewed =   this.getGalleryCollectionToParse();
      var images = collectionViewed.filter(function(item) {
            if (item.get('secId') !== 0 && item.get('secId') !== null) {
              return item;
            }
          }),
        data = [];

      _.each(images, function(item){
        var model = new Backbone.Model({
          id: item.getImageId(),
          downloadLink: that.getDownloadLink(item),
          fileLink: that.getFileLink(item),
          fileName: that.getFileName(item),
          width: item.get('imageWidth'),
          height: item.get('imageHeight')
        });
        data.push(model);
      });

      return data;
    },

    onZoom: function(id) {
      var that = this,
        data = this.parseGalleryCollection(),
        galleryView = new GalleryView({
          id: id,
          data: data,
          hasSize: true,
          onLoadEnd: function() {
            that.galleryLoading = false;
          }
        });

      if (!this.galleryLoading) {
        this.galleryLoading = true;
        App.regions.getRegion('main').currentView.getRegion('zoom').show(galleryView);
      }
    },

    getDownloadLink: function (item) {
      return this.getLink(item, 'b');
    },

    getFileLink: function (item) {
      return this.getLink(item, 't');
    },

    getWidth: function (item) {
      return item.get('width');
    },

    getHeight: function (item) {
      return item.get('height');
    },

    hasSize: function () {
      return true;
    },

    onShowView: function() {
      var windowHeight = $(window).height(),
        headerHeight = $('header').height() || 75,
        detailsHeaderHeight = $('.caccount-details-header').height(),
        height = windowHeight - headerHeight - detailsHeaderHeight - 65;

      this.elementsView.ui.scrollContainer.height(height);
      this.checkLoadMore();
    },

    loadMoreElements: function() {
      var that = this,
        start = this.elCollectionViewed.lastElIndex,
        end = this.elCollectionViewed.lastElIndex + 10;
      _.each(this.groupModel.get('elCollection').slice(start, end), function(template) {
        that.elCollectionViewed.add(template);
      });
      this.elCollectionViewed.trigger('change');
      this.elCollectionViewed.lastElIndex += 10;
      setTimeout(_.bind(this.checkLoadMore, this), 500);
    },

    checkResize: function() {
      if (this.elementsView && typeof this.elementsView.ui.scrollContainer !== 'string' &&
          this.elementsView.ui.scrollContainer.is(':visible')) {
        this.onShowView();
      }
    },

    checkLoaderIsVisible: function() {
      var scrollContainer = this.elementsView.ui.scrollContainer,
        scrollBlock = this.elementsView.ui.scrollBlock,
        height = scrollContainer.height() || 75,
        top = scrollBlock.offset().top - scrollContainer.offset().top;

      if (top < height + 120) {
        this.loadMoreElements();
      }
    },

    checkLoadMore: function() {
      var scrollBlock = this.elementsView.ui.scrollBlock,
        count = this.groupModel.get('elCollection').length;

      if (typeof scrollBlock !== 'string' && scrollBlock.is(':visible') &&
          this.groupModel.length !== this.elCollectionViewed.length &&
          this.elCollectionViewed.length < count) {

        this.checkLoaderIsVisible();
      } else if (this.elCollectionViewed.length >= count ||
          this.elCollectionViewed.length === 1) {
        scrollBlock.hide();
      }
    },

    showLoadingMessage: function() {
      this.elementsView.triggerMethod(
        'showMessage',
        'primary',
        _.i18n('common.loading')
      );
      if (this.popupVariations &&
          this.popupVariations.$el.is(':visible')) {
        this.popupVariations.triggerMethod('showProgressBar');
      }
    },

    showErrorMessage: function() {
      this.elementsView.triggerMethod(
          'errorMessage',
          _.i18n('warning.systemError')
        );
    },

    onDisplayTemplateVariations: function (templateParentSecId,parentModel, callback) {
      App.request('caccount:template-variations', templateParentSecId).done(_.bind(function (variations) {

        /*
         Create a fake template at the first position that will be rendered by a "+" icon
         and that contain the reference of the parent of which we can create a variation
         */
        var newTemplate = this.createFakeAddTemplate();
        newTemplate.set('refParent', {secId: templateParentSecId});

        variations.add(newTemplate, {at: 0});

        this.variationCollection = variations;
        this.popupVariations = new PopupVariations({
          variations: variations,
          templateParentSecId: templateParentSecId,
          customerSecId: this.customerSecId,
          groupModel: this.groupModel,
          templateParent : parentModel,

          //We cannot create a variation from a product because
          //it launches OrderProcess and we cannot know which template we come from
          showFromProduct: false,
          subdomain: this.subDomain
        });

        this.popupVariations.show({title: _.i18n('template.popup.templateVariations')});
        $.material.init();
        if (typeof callback === 'function') {
          callback();
        }
      }, this));
    }


  };

  module.exports = TemplatesController;
});
