/**
 * Created by RKL on 18/08/2015.
 */
define('customerLogosImagesCollectionView',[
  'module',
  'elementsCollectionView',
  'customerLogosImagesItemView',
  'customerLogosImagesNewItemView',
  'template!elementsCollectionTpl'
], function (
  module,
  ElementsCollectionView,
  ItemView,
  NewItemView,
  templatesTpl
) {
  'use strict';

  var CollectionView = ElementsCollectionView.extend({
    template: templatesTpl,

    getChildView: function(item) {
      if (item.get('secId') !== null) {
        return ItemView;
      } else {
        return NewItemView;
      }
    }
  });

  module.exports = CollectionView;
});
