/**
 * Created by RKL on 18/08/2015.
 */
define('customerTemplatesTabController',[
  'module',
  'underscore',
  'backbone',
  'app',
  'customerTemplatesElController',
  'customerTemplatesCollectionView',
  'emptyView',
  'galleryView',
  'settings',
  'customerTemplatesGroupsLayoutView',
  'customerTemplatesGroupsCollectionView',
  'entities/caccounts',
  'entities/caccounts/templates'
], function(
  module,
  _,
  Backbone,
  App,
  ElementsController,
  TemplatesView,
  EmptyView,
  GalleryView,
  Settings,
  LayoutView,
  GroupsView
) {
  'use strict';

  var CustomersTemplatesTabController = {

    showTab: function(data) {
      if (!data.tab) {
        data.tab = 'templates';
      }

      this.region = 'customerTemplatesTab';

      this.data = data;
      this.elControllers = {};
      this.subscribeToEvents();
      this.showLayoutView();
    },

    showLayoutView: function() {
      var mainRegion = App.regions.getRegion('main');

      this.layoutView = new LayoutView();

      if (mainRegion) {
        mainRegion.currentView.getRegion('content').currentView.getRegion(this.region).show(this.layoutView);
      }
      this.filtersRegion = this.layoutView.getRegion('filtersColumn');
      this.elRegion = this.layoutView.getRegion('elementsColumn');

      this.fetchGroups();
    },

    fetchGroups: function() {
      var that = this;

      if (that.data.reload || (!this.groupsCollection || this.groupsCollection.length === 0) ||
          (this.data.type === 'customer' && this.endUserId !== this.data.id)) {
        this.groupsCollection = App.request('caccount:print-area:template-groups');
        this.groupsCollection.fetch({
          success: function() {
            that.groupsCollection.each(function(group) {
              group.set({endUserId: that.data.model.get('endUserId')}, {silent: true});
            });
            that.showGroups();
          }
        });
      } else {
        this.showGroups();
      }
    },

    showGroups: function(changeCount) {
      var view = new GroupsView({
          collection: this.groupsCollection
        }),
        secId;

      if (this.data.secId) {
        secId = this.data.secId;
        this._deactivateGroups();
        this.groupsCollection.get(secId).set({active: true});
      } else {
        secId = this.groupsCollection.at(0).get('secId');
        this.data.secId = secId;
        this.groupsCollection.at(0).set({active: true});
      }

      this.layoutView.getRegion('groupsColumn').show(view);
      if (!changeCount) {
        this.displayElements(secId);
      }
    },

    _deactivateGroups: function() {
      var active = this.groupsCollection.find(function(group) {
          return group.get('active');
        });
      if(active) {
        active.set({active: false});
      }
    },

    displayElements: function(groupId) {
      var currentGroupModel;
      if (!this.elControllers[groupId]) {
        this.elControllers[groupId] = _.extend({id: groupId}, ElementsController);
      }

      if (!this.elControllers[groupId].active) {
        _.each(this.elControllers, function (controller) {
          controller.active = false;
        });
        this.elControllers[groupId].active = true;
        currentGroupModel = this.groupsCollection.get(groupId);
        this.elControllers[groupId].displayElements(currentGroupModel, this.elRegion, this.data.id, this.data.varSecId);
        this.data.varSecId = null;
        // this show filters v
        this.elControllers[groupId].displayFilters(currentGroupModel, this.filtersRegion);

        Settings.set('currentGroupModel', currentGroupModel);
      }
    },

    subscribeToEvents: function() {
      App.off('folder-groups:show-group');
      App.on('folder-groups:show-group', _.bind(this.onShowGroup, this));
      App.off('elements-controller:setTo');
      App.on('elements-controller:setTo', _.bind(this.setToElementsController, this));
    },

    setToElementsController: function(listToRefresh, params) {
      // refresh templates in category after cut/paste
      _.each(listToRefresh, _.bind(function(groupModel){
        var elementsController = this.elControllers[groupModel.id];
        if (elementsController) {
          _.extend(elementsController, params);
        }
      }, this));
    },

    onShowGroup: function(model) {
      var data = this.data,
        secId = model.get('secId'),
        link = 'customers/';

      this.data.secId = secId;
      App.navigate(link + data.id + '/' + data.tab.name + '/' + secId);
      this.showGroups();
    },

    validate: function() {
      var fieldsToValidate = [
        {name: 'name', type: 'required'},
        {name: 'code', type: 'required'}
      ];

      return this.popupView.validate(fieldsToValidate);
    }
  };

  module.exports = CustomersTemplatesTabController;
});
