/**
 * Created by OLD on 15/09/2015.
 */
define('caccountDetailsTabController',[
  'module',
  'underscore',
  'app',
  'backbone',
  'caccountDetailsTabView',
  'caccountDetailsTabMonthView',
  'caccountDetailsTabOrdersView',
  'caccountDetailsTabGraphicalRequest',
  'rolesMixin',
  'settings',
  'entities/statistics',
  'entities/tickets'
], function(
  module,
  _,
  App,
  Backbone,
  View,
  CAccountDetailsTabMonthView,
  CAccountDetailsTabOrdersView,
  CAccountDetailsTabGraphicalRequest,
  RolesMixin,
  Settings
) {
  'use strict';

  var CAccountsDetailsTabController = {
    showTab: function() {
      var customersStat = App.request('customers:get-statistic'),
        vectorStat = App.request('vectors:get-statistic'),
        creditStat = App.request('credits:get-statistic'),
        ordersStat = App.request('orders:get-statistic'),
        monthStat = App.request('month:get-statistic'),
        productStat = App.request('products:get-statistic'),
        model = new Backbone.Model(),
        view,
        that = this;

      $.when(customersStat,
        vectorStat,
        creditStat,
        ordersStat,
        monthStat,
        productStat
      ).done(function(
        customers,
        vectors,
        credits,
        orders,
        month,
        products
      ) {
        model.set('customers', customers);
        model.set('vectors', vectors);
        model.set('credits', credits);
        model.set('orders', orders);
        model.set('month', month);
        model.set('products', products);
        
        view = new View();
        view.model = model;
        App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('caccountDetailsTab').show(view);

        if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS]) {
          view.getRegion('canvasOrders').show(
              new CAccountDetailsTabOrdersView({
                type: 'line',
                model:  model
              })
          );
          view.getRegion('canvasMonth').show(
              new CAccountDetailsTabMonthView({
                type: 'Doughnut',
                model:  model
              })
          );
        }

        that.requestsView = new CAccountDetailsTabGraphicalRequest();
        that.requestsView.listenTo(that.requestsView, 'onGridLoaded', _.bind(that.onGridLoaded, that));
        that.requestsView.listenTo(that.requestsView, 'tickets:show', _.bind(that.onTicketsShow, that));

        view.getRegion('canvasRequests').show(
            that.requestsView
        );
      });
    },

    onGridLoaded: function() {
      var data = {
            url: App.request('tickets:get-collection-url'),
            rowCountUrl: App.request('tickets:row-count-url'),
            displayRowToolTip: true
          };
      this.requestsView.displayGrid(data);
    },

    onTicketsShow: function(secId) {
      App.trigger('graphical_service_all_in_one:show', secId);
    }
  };

  module.exports = CAccountsDetailsTabController;
});
