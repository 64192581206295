/**
 * Created by OLD on 30/10/2015.
 */
define('smpResMainRouter',[
  'underscore',
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin',
  'smpResTabs',
  'sampleDetailsController'
], function(
    _,
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin,
  SmpResTabs,
  SmpResDetailsController
) {
  'use strict';

  var SmpResRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'smpres(/:secId)(/:tab)' : 'showSmpResDetails'
    }
  });

  var API = {
    checkContent: function() {
      var defer = $.Deferred();

      require([
        'contentShowController'
      ], function(ContentShowController) {
        ContentShowController.showContent().done(function() {
          defer.resolve();
        });
      });
      return defer.promise();
    },

    showSmpResDetailsNew: function() {
      this.showSmpResDetails(null, null, true);
    },

    showSmpResDetails: function(secId, tab) {
      this.checkContent().done(function() {
        require([
          'smpResDetailsController'
        ], function(SmpResDetailsController) {

          if (secId) {
            SmpResDetailsController.fetchSmpRes({
              secId: secId,
              tab: _.findWhere(SmpResTabs, {name: tab})
            });
          }

          App.execute('set:active:nav:item', ItemPagesNameMixin.SAMPLES);
        });
      });
    },

    showSmpResTabNew: function(secId, tab) {
      this.checkContent().done(function() {
        require([
          'smpResListTabController'
        ], function(SmpResController) {

          if (SmpResController.createEditView) {
            SmpResController.createEditView.hide(true);
          }

          if (secId) {
            SmpResDetailsController.fetchSmpRes({
              secId: secId,
              tab: _.findWhere(SmpResTabs, {name: tab})
            });
          }

          SmpResController.showSmpResAdd(secId);


          App.execute('set:active:nav:item', ItemPagesNameMixin.SAMPLES);
        });
      });
    }
  };

  App.on('sample:show', function() {
    App.navigate('sample');
  });

  App.on('before:start', function() {
    new SmpResRouter({
      controller: API
    });
  });

  module.exports = SmpResRouter;
});
