define('customersContactsTabDetailsLayoutView',[
  'module',
  'marionette',
  'app',
  'customerContactsDetailsData',
  'template!customersContactsTabDetailsLayoutTpl',
  'imageUtils',  
  'backToListBehavior'
], function(
  module,
  Marionette,
  App,
  tabsData,
  layoutTpl,
  imageUtils,
  BackToListBehavior
) {
  'use strict';

  var CustomerContactsDetailLayoutView = Marionette.LayoutView.extend({
    template: layoutTpl,
    tagName: 'div',

    ui: {
      logo: '.js-logo-holder',
      tabs: '.js-tab-layout-tab'
    },

    events: {
      'click .js-tab-layout-tab': '_navigate'
    },

    regions: {
      tabs: '.js-tabs-region'
    },

    behaviors: {
      BackToList: {
        behaviorClass: BackToListBehavior,
        redirectUrl: 'customers/' + App.getCurrentRoute().split('/')[1] + '/contacts'
      }
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      
      templateData.tabs = tabsData;
      return templateData;
    },

    onShowActive: function(tabName) {
      this.ui.tabs.each(function(index, tab) {
        var $tab = $(tab);
        if($tab.hasClass('js-' + tabName)) {
          $tab.addClass('active');
        } else {
          $tab.removeClass('active');
        }
      });
    }
  });

  module.exports = CustomerContactsDetailLayoutView;
});
