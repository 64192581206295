define('wellTemplateEditRefKitLView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!wellEditRefKitLTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'settings',
  'jquery'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView,
  Settings,
  $
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    models: [],

    ui: {
      input: '.js-info-input'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectKitL: '.js-select-kitl-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
    ],

    initialize: function(options) {
      this.models = [];
      this.kitSecId = options.kitSecId;
      _.each(options.models, _.bind(function(model) {
        this.models.push(model.clone());
      }, this));
    },

    serializeData: function() {
      var templateData = {};
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();

      var kitLView = new AutocompleteView(
          this._getKitLAutocompleteOptions('refKitL')
      );
      this.getRegion('selectKitL').show(kitLView);

      this.triggerMethod('enable:cancel:confirm');
    },

    _getKitLAutocompleteOptions: function(property) {
      var url = Settings.url('compuzz', 'v2/kitls');

      if (this.kitSecId) {
        url = Settings.url('compuzz', 'v2/kits/'+ this.kitSecId + '/kitls');
      }

      return {
        type: 'remote',
        remote: url,
        valueKey: 'lotNb',
        apiKey: 'secId',
        placeholder: _.i18n('selectkitl.placeholder'),
        value: null,
        name: property,
        onPressKey: true,
        lazyLoad: true,
        keys: {
          query: 'lotNb',
          limit: 'pageSize'
        },
        callback: _.bind(this.onChangeKitL, this)
      };
    },

    onChangeKitL: function(fieldName, model) {
      _.each(this.models, function(wellModel) {
        if (model) {
          model.unset('autocompleteValue', {silent: true});
          wellModel.set(fieldName, model.toJSON());
        } else {
          wellModel.set(fieldName, null);
        }
      });
      this.triggerMethod('enable:cancel:confirm');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        var list = [];
        _.each(this.models, _.bind(function(wellModel) {
          var modelToSave = wellModel.clone();
          modelToSave.set('amplCurve1', null);
          modelToSave.set('amplCurve2', null);
          modelToSave.set('amplCurve3', null);
          modelToSave.set('amplCurve4', null);
          modelToSave.set('amplCurve5', null);
          modelToSave.set('amplCurve6', null);
          modelToSave.set('meltCurve1', null);
          modelToSave.set('meltCurve2', null);
          modelToSave.set('meltCurve3', null);
          modelToSave.set('meltCurve4', null);
          modelToSave.set('meltCurve5', null);
          modelToSave.set('meltCurve6', null);
          list.push(modelToSave.save());
        }, this));

        $.when(list).done(_.bind(function (){
          this.triggerMethod('saved');
          this.hide();
          App.trigger('well:mass:edit', this.models);
        }, this));
      }
    },

    onSave: function() {

    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.triggerMethod('hide:label');
      }
    }
  });

  module.exports = CreateEditLayoutView;
});
