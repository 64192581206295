define('runCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!runCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'settings',
  'autocompleteView',
  'entities/runs'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  Settings,
  AutocompleteView
) {
  'use strict';

  /*var selectRunTemplateRegion = Marionette.Region.extend({
    el: '.js-select-run-template-region',
    allowMissingEl: true
  });*/

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      inputName: '.run-input-name',
      createRunRadio: '.create-run-radio',
      createKitFromTemplate: '.createRunFromTemplate',
      createKitFromEmpty: '.createRunFromEmpty'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm',
      'change @ui.createRunRadio': 'onCreateRunRadioChange'
    },

    regions: {
      selectLmbAnalyzer: '.js-select-lmbanalyzer-region',
      selectKitProt: '.js-select-kitprot-region',
      selectMbAModel: '.js-select-mbamodel-region',
      selectRunTemplate: '.js-select-run-template-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'name', type: 'required'}
    ],

    fromTemplate: true,

    initialize: function(options) {
      this.model = options.runModel.clone();
      this.fromTemplate = this.options.newItem;
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onCreateRunRadioChange: function() {
      if(this.ui.createRunRadio){
        this.fromTemplate = false;
        _.each(this.ui.createRunRadio, _.bind(function(radio) {
          switch(radio.value) {
            case 'fromTemplate':
              if (radio.checked) {
                this.fromTemplate = true;
              }
              break;
            case 'fromEmpty':
              if (radio.checked) {
                this.fromTemplate = false;
              }
              break;
          }
          this.ui.createKitFromTemplate.toggleClass('hidden', !this.fromTemplate);
          this.ui.createKitFromEmpty.toggleClass('hidden', this.fromTemplate);
        }, this));
      }
    },

    onShow: function() {
      $.material.init();
      this.lmbAnalyzerView = new AutocompleteView(
          this._getAutocompleteOptions('refLmbAnalyzer', 'code', 'code', 'v2/lmbanalyzers',
            'selectlmbanalyzer.placeholder', 'code')
      );
      this.getRegion('selectLmbAnalyzer').show(this.lmbAnalyzerView);

      var kitProtView = new AutocompleteView(
          this._getAutocompleteOptions('refKitProt', 'code', 'code', 'v2/kitprots/mineAndShared',
              'selectkitprot.placeholder', 'code', this.onKitProtChange)
      );
      this.getRegion('selectKitProt').show(kitProtView);

      this.mbAModelView = new AutocompleteView(
          this._getAutocompleteOptions('refMbAModel', 'code', 'code', 'v2/mbamodels',
              'selectkitmbamodel.placeholder', 'code', this.onMbAModelChange)
      );
      this.getRegion('selectMbAModel').show(this.mbAModelView);

      var runTempalteView = new AutocompleteView(
          this._getAutocompleteOptions('refRunTemplate', 'name', 'name', 'v2/runtemplates',
              'selectruntemplate.placeholder', 'name')
      );
      this.getRegion('selectRunTemplate').options.allowMissingEl = true;
      this.getRegion('selectRunTemplate').show(runTempalteView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onKitProtChange: function(fieldName, model) {
      if (model) {
        model.unset('autocompleteValue', {silent: true});
      }
      this.onChange(fieldName, model);
      if (model) {
        if (!this.ui.inputName.val()) {
          this.ui.inputName.val(model.get('runNamePattern'));
          this.ui.inputName.change();
        }

        if (this.mbAModelView) {
          this.mbAModelView.destroy();
        }
        this.mbAModelView = new AutocompleteView(
          this._getAutocompleteOptions('refLmbAnalyzer', 'code', 'code', 'v2/mbamodels?kitProtSecId=' + model.get('secId'),
            'selectkitmbamodel.placeholder', 'code')
        );
        this.getRegion('selectMbAModel').show(this.mbAModelView);
      }
    },

    onMbAModelChange: function(fieldName, model) {
      if (model) {
        model.unset('autocompleteValue', {silent: true});
        if (this.lmbAnalyzerView) {
          this.lmbAnalyzerView.destroy();
        }
        this.lmbAnalyzerView = new AutocompleteView(
            this._getAutocompleteOptions('refLmbAnalyzer', 'code', 'code', 'v2/lmbanalyzers?mbAModelSecId=' + model.get('secId'),
                'selectlmbanalyzer.placeholder', 'code')
        );
        this.getRegion('selectLmbAnalyzer').show(this.lmbAnalyzerView);
      }
      this.onChange(fieldName, model);
    },

    onConfirm: function() {
      if (this.fromTemplate) {
        if(this.validate([{name: 'refRunTemplate', type: 'required'}])) {
          App.request('run:from-template', this.model.get('refRunTemplate').get('secId')).done(_.bind(function(model) {
            App.navigate('runs/' + model.get('secId'), {trigger: true});
            this.hide();
          }, this));
        }
      } else {
        if(this.validate(this.fieldsToValidate)) {
          this.wells = this.model.get('wells');
          this.model.set('wells', null);
          this.model.set('refRunTemplate', null);
          this.model.save();
        }
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.model.set('wells', this.wells);
      this.options.runModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('run:header:rerender', modelJSON);
      App.navigate('runs/' + model.get('secId'), {trigger: true});
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'runs/new') {
        App.navigate('runs', {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
