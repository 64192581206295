define('wellCreateEditView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!wellCreateEditTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'settings'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'change .inputRes1Num': 'onInputRes1NumChange',
      'change .inputRes2Num': 'onInputRes2NumChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectKitL: '.js-select-kitl-region',
      selectAssay: '.js-select-assay-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [

    ],

    onInputRes1NumChange: function(e) {
      var $target = $(e.currentTarget),
          value = $target.val();

      var values = value.split(', ');
      switch(values.length) {
        case 3:
          this.model.set('res1Num3', values[2]);
          /* falls through */
        case 2:
          this.model.set('res1Num2', values[1]);
          /* falls through */
        case 1:
          this.model.set('res1Num1', values[0]);
          /* falls through */
          break;
      }
    },

    onInputRes2NumChange: function(e) {
      var $target = $(e.currentTarget),
          value = $target.val();

      var values = value.split(', ');
      switch(values.length) {
        case 3:
          this.model.set('res2Num3', values[2]);
          /* falls through */
        case 2:
          this.model.set('res2Num2', values[1]);
          /* falls through */
        case 1:
          this.model.set('res2Num1', values[0]);
          /* falls through */
          break;
      }
    },

    initialize: function(options) {
      this.model = options.wellModel.clone();
      this.modelRun = options.runModel;
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      templateData.target1 = this.modelRun.getTargetName(this.model.get('refAssay'), 1);
      templateData.target2 = this.modelRun.getTargetName(this.model.get('refAssay'), 2);
      templateData.target3 = this.modelRun.getTargetName(this.model.get('refAssay'), 3);
      templateData.target4 = this.modelRun.getTargetName(this.model.get('refAssay'), 4);
      templateData.target5 = this.modelRun.getTargetName(this.model.get('refAssay'), 5);
      templateData.target6 = this.modelRun.getTargetName(this.model.get('refAssay'), 6);
      templateData.res1Color = this.modelRun.getResultColor(this.model.get('refAssay'), this.model.get('res1Code'), 1);
      templateData.res2Color = this.modelRun.getResultColor(this.model.get('refAssay'), this.model.get('res2Code'), 2);
      templateData.res3Color = this.modelRun.getResultColor(this.model.get('refAssay'), this.model.get('res3Code'), 3);
      templateData.res4Color = this.modelRun.getResultColor(this.model.get('refAssay'), this.model.get('res4Code'), 4);
      templateData.res5Color = this.modelRun.getResultColor(this.model.get('refAssay'), this.model.get('res5Code'), 5);
      templateData.res6Color = this.modelRun.getResultColor(this.model.get('refAssay'), this.model.get('res6Code'), 6);
      templateData.res1Txt = this.modelRun.getResultText(this.model.get('refAssay'), this.model.get('res1Code'), 1);
      templateData.res2Txt = this.modelRun.getResultText(this.model.get('refAssay'), this.model.get('res2Code'), 2);
      templateData.res3Txt = this.modelRun.getResultText(this.model.get('refAssay'), this.model.get('res3Code'), 3);
      templateData.res4Txt = this.modelRun.getResultText(this.model.get('refAssay'), this.model.get('res4Code'), 4);
      templateData.res5Txt = this.modelRun.getResultText(this.model.get('refAssay'), this.model.get('res5Code'), 5);
      templateData.res6Txt = this.modelRun.getResultText(this.model.get('refAssay'), this.model.get('res6Code'), 6);
      return templateData;
    },

    onShow: function() {
      $.material.init();

      var kitLView = new AutocompleteView(
          this._getAutocompleteOptions('refKitL', 'lotNb', 'lotNb', 'v2/kitls',
              'selectkitl.placeholder', 'lotNb')
      );
      this.getRegion('selectKitL').show(kitLView);

      var assayView = new AutocompleteView(
          this._getAutocompleteOptions('refAssay', 'code', 'code', 'v2/assays',
              'selectassay.placeholder', 'code')
      );
      this.getRegion('selectAssay').show(assayView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.amplCurve1 = this.model.get('amplCurve1');
        this.amplCurve2 = this.model.get('amplCurve2');
        this.amplCurve3 = this.model.get('amplCurve3');
        this.amplCurve4 = this.model.get('amplCurve4');
        this.amplCurve5 = this.model.get('amplCurve5');
        this.amplCurve6 = this.model.get('amplCurve6');
        this.meltCurve1 = this.model.get('meltCurve1');
        this.meltCurve2 = this.model.get('meltCurve2');
        this.meltCurve3 = this.model.get('meltCurve3');
        this.meltCurve4 = this.model.get('meltCurve4');
        this.meltCurve5 = this.model.get('meltCurve5');
        this.meltCurve6 = this.model.get('meltCurve6');
        this.model.set('amplCurve1', null);
        this.model.set('amplCurve2', null);
        this.model.set('amplCurve3', null);
        this.model.set('amplCurve4', null);
        this.model.set('amplCurve5', null);
        this.model.set('amplCurve6', null);
        this.model.set('meltCurve1', null);
        this.model.set('meltCurve2', null);
        this.model.set('meltCurve3', null);
        this.model.set('meltCurve4', null);
        this.model.set('meltCurve5', null);
        this.model.set('meltCurve6', null);
        this.model.save();
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.model.set('amplCurve1', this.amplCurve1);
      this.model.set('amplCurve2', this.amplCurve2);
      this.model.set('amplCurve3', this.amplCurve3);
      this.model.set('amplCurve4', this.amplCurve4);
      this.model.set('amplCurve5', this.amplCurve5);
      this.model.set('amplCurve6', this.amplCurve6);
      this.model.set('meltCurve1', this.meltCurve1);
      this.model.set('meltCurve2', this.meltCurve2);
      this.model.set('meltCurve3', this.meltCurve3);
      this.model.set('meltCurve4', this.meltCurve4);
      this.model.set('meltCurve5', this.meltCurve5);
      this.model.set('meltCurve6', this.meltCurve6);
      this.options.wellModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('well:header:rerender', modelJSON);
      //App.navigate('runs/' + model.get('secId'), {trigger: true});
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'runs/new') {
        App.navigate('runs', {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.triggerMethod('hide:label');
      }
    }
  });

  module.exports = CreateEditLayoutView;
});
