
define('template!sampleCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group" data-field-name="sampleId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('sample.sampleId') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="sampleId" placeholder="' +
((__t = ( _.i18n('sample.sampleId') )) == null ? '' : __t) +
'" value="' +
((__t = ( sampleId )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('lmbana.refLmbSmpMat') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-lmbsmpmat-region"></div>\n        </div>\n      </div>\n      ';
 if (newItem) { ;
__p += '\n      <div class="form-group" data-field-name="sampleId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('sample.tests') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="tests" placeholder="' +
((__t = ( _.i18n('sample.tests') )) == null ? '' : __t) +
'" value="' +
((__t = ( tests )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      <div class="form-group" data-field-name="extractSt">\n        <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("sample.extractSt") )) == null ? '' : __t) +
':</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="extractSt" value="' +
((__t = ( extractSt )) == null ? '' : __t) +
'">\n            <option value="0" ';
  if (extractSt === '0') { ;
__p += ' selected ';
 } ;
__p += ' >0 - ' +
((__t = ( _.i18n("sample.extractSt.0") )) == null ? '' : __t) +
'</option>\n            <option value="1" ';
  if (extractSt === '1') { ;
__p += ' selected ';
 } ;
__p += ' >1 - ' +
((__t = ( _.i18n("sample.extractSt.1") )) == null ? '' : __t) +
'</option>\n            <option value="4" ';
  if (extractSt === '4') { ;
__p += ' selected ';
 } ;
__p += ' >4 - ' +
((__t = ( _.i18n("sample.extractSt.4") )) == null ? '' : __t) +
'</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="orderId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('sample.orderId') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="orderId" placeholder="' +
((__t = ( _.i18n('sample.orderId') )) == null ? '' : __t) +
'" value="' +
((__t = ( orderId )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="patId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('sample.patId') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="patId" placeholder="' +
((__t = ( _.i18n('sample.patId') )) == null ? '' : __t) +
'" value="' +
((__t = ( patId )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("sample.ordDt") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="datepicker" class="form-control date-ordDt js-info-input" data-field-name="ordDt" placeholder="' +
((__t = ( ordDt )) == null ? '' : __t) +
'" value="' +
((__t = ( ordDt )) == null ? '' : __t) +
'">\n          <span class="input-group-btn">\n              <span class="caret pull-right m-t-5"></span>\n          </span>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("sample.receptDt") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="datepicker" class="form-control date-receptDt js-info-input" data-field-name="receptDt" placeholder="' +
((__t = ( receptDt )) == null ? '' : __t) +
'" value="' +
((__t = ( receptDt )) == null ? '' : __t) +
'">\n          <span class="input-group-btn">\n              <span class="caret pull-right m-t-5"></span>\n          </span>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("sample.sampleDt") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="datepicker" class="form-control date-sampleDt js-info-input" data-field-name="sampleDt" placeholder="' +
((__t = ( sampleDt )) == null ? '' : __t) +
'" value="' +
((__t = ( sampleDt )) == null ? '' : __t) +
'">\n          <span class="input-group-btn">\n              <span class="caret pull-right m-t-5"></span>\n          </span>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("sample.datehh") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="datepicker" class="form-control date-datehh js-info-input" data-field-name="datehh" placeholder="' +
((__t = ( datehh )) == null ? '' : __t) +
'" value="' +
((__t = ( datehh )) == null ? '' : __t) +
'">\n          <span class="input-group-btn">\n              <span class="caret pull-right m-t-5"></span>\n          </span>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="description">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="description" placeholder="' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'">' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

