define('adminLmbSmpMatsView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!adminLmbSmpMatsListTpl'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl
    ) {
    'use strict';

    var LmbSmpMatListView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'lmbSmpMatsListFilters',

        triggers: {
        },

        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteLmbSmpMat: '.delete-lmbsmpmat-link'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click @ui.deleteLmbSmpMat': 'onDeleteLmbSmpMat'
            }, this.gridEvents);
        },

        serializeData: function() {
            var templateData = {type: 'lmbsmpmats'};
            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: Settings.get('perPage15'),
                currentPage: 1
            };
        },

        onDeleteLmbSmpMat: function(event){
            this.trigger('lmbsmpmat:delete', $(event.currentTarget).find('span').attr('data-row-id'));
        },

        gridOptions: function(data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('common.code'),
                        name: 'code',
                        classes: 'lmbsmpmat-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'code'
                    }, {
                        label: _.i18n('lmbsmpmat.refMbSmpMat'),
                        name: 'refMbSmpMat.code',
                        classes: 'lmbsmpmat-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'refMbSmpMat.code'
                    }, {
                        label: _.i18n('common.name'),
                        name: 'name',
                        classes: 'lmbsmpmat-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'name'
                    }, {
                        label: _.i18n('common.description'),
                        name: 'description',
                        classes: 'lmbsmpmat-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'description'
                    }, {
                        label: ' ',
                        name: 'delete',
                        search: false,
                        classes: 'delete-lmbsmpmat-link delete-link',
                        formatter: this.deleteIconFormatter,
                        width: 20
                    }
                ],

                onSelectRow: function(rowId, bool, e) {
                    if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
                        if (e.type === 'click') {
                            var route = App.getCurrentRoute() ===  'admin/lmbsmpmats' ?
                                App.getCurrentRoute() : 'admin/lmbsmpmats/' + App.getCurrentRoute();
                            App.navigate(route + '/' + rowId, {trigger: true});
                        }
                    }
                }
            };

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        }
    });

    module.exports = LmbSmpMatListView;
});
