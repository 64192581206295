define('customerDatasetsCollectionView',[
  'module',
  'app',
  'marionette',
  'underscore',
  'customerDatasetsItemView',  
  'template!customerDatasetsTableTpl'
], function(
  module,
  App,
  Marionette,
  _,
  ItemView,  
  TableTpl
) {
  'use strict';

  var DatasetTableView = Marionette.CompositeView.extend({
    template: TableTpl,
    childViewContainer: 'tbody',
    childView: ItemView,

    childViewOptions: function() {
      return {
        endUserId: this.options.endUserId
      };
    },

    childEvents: {
      saved: '_updateCollection'
    },

    _updateCollection: function() {
      this.collection.fetch();
    }
  });

  module.exports = DatasetTableView;
});
