/**
 * Created by RKL on 18/08/2015.
 */
define('customerLogosImagesItemView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'customerTemplatesMetaInfoView',
  'template!customerLogosImagesItemTpl',
  'entities/caccounts/templates'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  MetaInfoView,
  viewTpl
) {
  'use strict';

  var CustomersLogosImagesTabItemView = Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      imageHolder: '.js-image-holder',
      imageHolderContainer: '.js-image-holder-container',
      editor: '.js-template-item-editor',
      edit: '.js-edit-icon',
      remove: '.js-remove-icon',
      modify: '.js-modify-icon',
      zoom: '.js-zoom-icon',
      download: '.js-download-icon'
    },

    events: {
      'click @ui.edit': 'onEdit',
      'click @ui.remove': 'onRemove',
      'click @ui.modify': 'onModify',
      'click @ui.download': 'onDownload',
      'click @ui.zoom' : 'onZoom'
    },

    regions: {
      metaInfoRegion: '.template-meta-info-container'
    },

    tagName: 'div',

    className: 'original-img-wrapper zoom-img editor-holder template-item',

    serializeData: function () {
      var imageUrl = this.getImageUrl(this.model.get('fileUid'), 'b');

      return {
        downloadLink: imageUrl
      };
    },

    onRender: function() {
      var that = this,
        imageUrl = this.getImageUrl(this.model.get('fileUid'), 't');

      this.ui.imageHolder.attr('src', imageUrl).load(function() {
        that.model.set('height', this.height);
        that.model.set('width', this.width);
        var imgClass = (this.width / this.height > 160 / 120) ? 'wide' : 'tall';
        $(this).addClass(imgClass);
      });
      this.$('.template-item').fadeIn(500);

      this.getRegion('metaInfoRegion').show(new MetaInfoView({
        model: this.model
      }));
    },

    getImageUrl: function (fileUid, size) {
      var params = {
          action: 'res',
          params: size
        },
        imageUrl = Settings.url(
          'file',
          fileUid,
          params
        );

      return imageUrl;
    },

    onEdit: function() {
      App.trigger('elements:edit-item', this.model, this);
    },

    onRemove: function() {
      App.trigger('elements:remove-item', this.model);
    },

    onModify: function() {
      App.trigger('elements:modify-item', this.model.get('secId'));
    },

    onZoom: function() {
      App.trigger(
        'customers:design-el-zoom', 
        this.model.get('secId')
      );
    }

  });

  module.exports = CustomersLogosImagesTabItemView;
});
