/**
 * Created by RKL on 18/08/2015.
 */
define('customerTemplatesItemView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'customerTemplatesSelectionMix',
  'customerTemplatesPopupEditView',
  'customerTemplatesMetaInfoView',
  'template!customerTemplatesItemTpl',
  'entities/caccounts/templates'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  SelectionMix,
  PopupEditView,
  MetaInfoView,
  viewTpl
) {
  'use strict';

  var CAccountTemplatesTabItemView = Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      container: '.template-content-container',
      multiSelector: '.multi-selector',
      imageHolder: '.js-image-holder',
      imageHolderContainer: '.js-image-holder-container',
      editor: '.js-template-item-editor',
      edit: '.js-edit-icon',
      variations: '.js-variations-icon',
      remove: '.js-remove-icon',
      modify: '.js-modify-icon',
      zoom: '.js-zoom-icon',
      download: '.js-download-icon'
    },

    events: {
      'click @ui.container': 'onSelect',
      'click @ui.multiSelector': 'onMultiselect',
      'click @ui.edit': 'onEdit',
      'click @ui.variations': 'onVariations',
      'click @ui.remove': 'onRemove',
      'click @ui.modify': 'onModify',
      'click @ui.download': 'onDownload',
      'click @ui.zoom' : 'onZoom'
    },

    regions: {
      metaInfoRegion: '.template-meta-info-container'
    },

    tagName: 'div',

    className: 'original-img-wrapper zoom-img editor-holder template-item',

    initialize: function () {
      _.extend(this, SelectionMix);
    },

    serializeData: function() {
      var imageUrl = this.getDownloadUrl();

      return {
        downloadLink: imageUrl,
        showVariationsButton: this.options.showVariationsButton,
        name: this.model.get('name')
      };
    },

    onRender: function() {
      this.getRegion('metaInfoRegion').show(new MetaInfoView({
        model: this.model
      }));
    },

    onShow: function() {
      this.prepareSelection();
      this.showLogo();

      this.$('.template-item').fadeIn(500);
    },

    showLogo: function() {
      var that = this,
        imageUrl = this.getImageUrl(200, 200);
      that.ui.imageHolder.attr('src', imageUrl).load(function() {
        that.model.set('imageHeight', this.height);
        that.model.set('imageWidth', this.width);
      });
    },

    getImageUrl: function(width, height) {
      var templateId = this.model.get('secId').split('_')[0],
        params = {
          minWidth: width,
          minHeight: height,
          db: true
        },
        imageUrl = Settings.url(
          'x2png',
          'template/' + templateId + '/preview',
          params
        );

      return imageUrl;
    },

    getDownloadUrl: function() {
      return Settings.url('compuzz', 'v2/report', {
        id: this.model.get('secId').split('_')[0],
        type: 'template'
      });
    },

    onEdit: function () {
      this.ui.editor.hide();
      var that = this,
          showModel = App.request('caccount:template-by-secId', this.model.get('secId')),
          popupView;
      showModel.fetch({
        success: _.bind(function () {
          popupView = new PopupEditView({
            //We need to clone model because when we destroy the view model is destroyed too.
            model: App.request('caccount:template-model', {secId: that.model.get('secId')}),
            showModel: showModel,
            standardSizes: that.options.groupModel.get('standardSizes')
          });

          popupView.show({title: _.i18n('template.popup.editTemplate')});
          popupView.listenTo(popupView, 'edit-template-saved', _.bind(function (editedModel) {
            this.model = editedModel.clone();
            this.render();
            this.showLogo();
          }, this));
          $.material.init();
          this.ui.editor.show();
        }, this)
      });
    },


    onVariations: function () {
      //Caught by elements_controller
      this.ui.editor.hide();
      App.trigger('caccount:templates-displayVariations', this.model.get('secId'),this.model,
        _.bind(function(){
          this.ui.editor.show();
      } ,this));
    },

    onRemove: function() {
      var confirmMsg;
      if(this.model.get('variationsNumber') > 0){
          confirmMsg = _.i18n('common.confirmDeleteVariations');
      } else{
          confirmMsg = _.i18n('common.confirmDelete');
      }

      bootbox.confirm(confirmMsg, _.bind(function (result) {
        var notVariation = this.options.showVariationsButton;
        if (result) {
          this.model.url = this.model.deleteUrl();
          this.model.destroy().done(_.bind(function() {
            //change count only when removed parent template
            App.trigger('caccount-template:remove-template', notVariation);
          }, this));
        }
      }, this));
    },

    onModify: function() {
      App.trigger('caccount-template:modify-template', this.model.get('secId'));
    },

    onZoom: function() {
      App.trigger('caccount:templates-zoom', this.model.getImageId());
    }

  });

  module.exports = CAccountTemplatesTabItemView;
});
