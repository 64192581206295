/**
 * Created by OLD on 30/10/2015.
 */
define('smpResMain',[
  'module',
  'logger',
  'smpResMainRouter'
], function(
  module,
  Logger
) {
	'use strict';
	
  var SmpRes = {
    start: function() {
      Logger.log(module, 'starting SmpResModule');
    },

    stop: function() {
      Logger.log(module, 'stopping SmpResModule');
    }
  };

  module.exports = SmpRes;
});
