/**
 * Created by OBL on 14/01/2016.
 */
define('superAdminMbAnaResController',[
    'module',
    'underscore',
    'app',
    'superAdminMbAnaResView',
    'bootbox',
    'mbAnaResCreateEditLayoutView',
    'settings',
    'entities/mbanares'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    CreateEditLayoutView
    ) {
    'use strict';

    var SuperAdminMbAnaResListController = {
        showMbAnaResList: function(data) {
            this.data = data;
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'mbanares:delete', _.bind(this.onDelete, this));
            view.listenTo(view, 'mbanares:edit', _.bind(this.onEdit, this));
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('mbAnaResListTab').show(view);
        },

        showMbAnaResAdd: function(mbanaGrSecId) {
            this.createEditView = new CreateEditLayoutView({
                mbanaresModel: App.request('mbanares:model', {mbAnaResGrSecId: mbanaGrSecId}),
                newItem: true
            });
            this.createEditView.show({
                title: _.i18n('mbanares.new')
            });
        },

        showMbAnaResEdit: function(mbanaGrSecId, mbanaResSecId) {
            var model = App.request('mbanares:model', {
                refMbAnaResGr: {
                    secId: mbanaGrSecId
                },
                secId: mbanaResSecId
            });
            model.fetch().done(function() {
                this.createEditView = new CreateEditLayoutView({
                    mbanaresModel: model,
                    newItem: false
                });
                this.createEditView.show({
                    title: _.i18n('mbanares.edit')
                });
            });
        },

        onGridLoaded: function() {
            var data = {
                url: App.request('mbanares:get-collection-url', this.data.model.get('secId')),
                rowCountUrl: App.request('mbanares:get-count-url', this.data.model.get('secId'))
            };

            this.view.displayGrid(data);
        },

        onEdit: function(mbAnaResGrSecId, mbAnaResSecId){
            App.navigate('superAdmin/mbanaresgrs/' + mbAnaResGrSecId + '/mbanares/' + mbAnaResSecId + '/edit', {trigger: true});
        },

        onDelete: function(mbAnaResGrSecId, secId){
            bootbox.confirm(_.i18n('mbanares.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('mbanares:delete', mbAnaResGrSecId, secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = SuperAdminMbAnaResListController;
});
