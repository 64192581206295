/**
 * Created by GSP on 4/08/2015.
 */
define('customerTemplatesGroupsLayoutView',[
  'module',
  'underscore',
  'marionette',
  'app',
  'template!customerTemplatesGroupsLayoutTpl'
], function(
  module,
  _,
  Marionette,
  App,
  layoutTpl
) {
  'use strict';

  var ContentLayoutView =  Marionette.LayoutView.extend({
    template: layoutTpl,

    regions: {
      groupsColumn: '#groups-column',
      elementsColumn: '#elements-column',
      filtersColumn: '#filters-column'
    },

    ui: {
      groupsColumn: '.groups-column'
    },

    onShow: function() {
      $(window).on('resize.customers.templates', _.bind(this.calcColumnHeight, this));
      this.calcColumnHeight(true);
    },

    calcColumnHeight: function(calc) {
      var windowHeight, headerHeight, detailsHeaderHeight, height, a;

      if (calc === true || (typeof this.ui.groupsColumn !== 'string' &&
          this.ui.groupsColumn.is(':visible'))) {
        windowHeight = $(window).height();
        headerHeight = $('header').height() || 75;
        detailsHeaderHeight = $('.caccount-details-header').height();
        a = 65;
        height = windowHeight - headerHeight - detailsHeaderHeight - a;

        this.ui.groupsColumn.height(height);
      }
    },
    onDestroy: function () {
      $(window).off('resize:customers:templates');
    }
  });

  module.exports = ContentLayoutView;
});
