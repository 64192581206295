/**
 * Created by RKL on 15/09/2015.
 */
define('elementsController',[
  'module',
  'backbone',
  'underscore',
  'app',
  'bootbox',
  'settings',
  'emptyView',
  'galleryView'
], function(
  module,
  Backbone,
  _,
  App,
  bootbox,
  Settings,
  EmptyView,
  GalleryView
) {
  'use strict';

  var ElementsController = {

    fetchElements: function(data) {
      var emptyView = new EmptyView(),
        countRequest, params;

      this.setValues(data);
      this.endUserId = this.getEndUserId();
      params = this.getParams('0');

      data.elRegion.show(emptyView);

      if (this.groupModel.count === undefined) {
        countRequest = App.request(this.requests.countRequest, params);
        this.getGroupCount(countRequest);
      } else {
        this.fetchCollection();
      }

      $(window).on('resize', _.bind(this.checkResize, this));
    },

    fetchCollection: function() {
      var that = this,
        newModel = App.request(this.requests.modelRequest),
        params = this.getParams('0');
      if(this.data.model.get('endUserId')) {
        newModel.set('endUserId', this.data.model.get('endUserId'));
      }
      if (this.groupModel.elCollection) {
        this.collection = this.groupModel.elCollection;
        this.preShowElements();
      } else {
        this.collection = App.request(this.requests.collectionRequest, params);
        this.collection.fetch().done(function() {
          that.onCollectionFetched(newModel, that.groupModel);
        });
      }
    },

    showElements: function(ElementsView) {
      var view;

      view = new ElementsView({
        collection: this.collection,
        data: this.data
      });
      this.elementsView = view;

      view.listenTo(view, 'onPressReturn', _.bind(this.onPressReturn, this));
      view.listenTo(view, 'onScroll', _.bind(this.checkLoadMore, this));
      view.listenTo(view, 'showView', _.bind(this.onShowView, this));

      this.region.show(view);
      this.subscribeToEvents();
    },

    setValues: function(data) {
      this.groupId = data.secId;
      this.data = data;
      this.region = data.elRegion;
      this.first = 0;
    },

    onSave: function(model) {
      this.elementsView.triggerMethod('hideMessage');
      this.collection.add(model);
      this.groupModel.count += 1;
      this.data.onChangeCount();
    },

    getGroupCount: function(request) {
      var that = this;

      $.when(request).done(function(count) {
        that.groupModel.count = count;
        that.fetchCollection();
      });
    },

    onPressReturn: function() {
      App.trigger('folder-groups:press-return');
    },

    parseGalleryCollection: function() {
      var that = this,
        images = _.filter(
          this.collection.models,
          function(item) {
            if (item.id !== 0 && item.id !== null && 
                item.get('fileUid')) {
              return item;
            }
          }),
        data = [];

      _.each(images, function(item){
        var model = new Backbone.Model({
          id: that.getId(item),
          downloadLink: that.getDownloadLink(item),
          fileLink: that.getFileLink(item),
          fileName: that.getFileName(item),
          width: that.getWidth(item),
          height: that.getHeight(item)
        });
        data.push(model);
      });
      
      return data;
    },

    onZoom: function(id) {
      var that = this,
        data = this.parseGalleryCollection(),
        galleryView = new GalleryView({
          id: id,
          data: data,
          hasSize: this.hasSize(),
          onLoadEnd: function() {
            that.galleryLoading = false;
          }
        });

      if (!this.galleryLoading) {
        this.galleryLoading = true;
        App.regions.getRegion('main').currentView.getRegion('zoom').show(galleryView);
      }
    },

    getDownloadLink: function (item) {
      return this.getLink(item, 'b');
    },

    getFileLink: function (item) {
      return this.getLink(item, 't');
    },

    getLink: function (item, w, h) {
      return Settings.url(
        'x2png',
        'template/' + item.get('id') + '/preview',
        {minWidth: w, minHeight: h}
      );
    },

    getFileName: function (item) {
      return item.get('secId');
    },

    getWidth: function (item) {
      return item.get('width');
    },

    getHeight: function (item) {
      return item.get('height');
    },

    getId: function (item) {
      return item.get('secId');
    },

    hasSize: function () {
      return true;
    },

    onShowView: function() {
      var windowHeight = $(window).height(),
        headerHeight = $('header').height() || 75,
        detailsHeaderHeight = $('.caccount-details-header').height(),
        height = windowHeight - headerHeight - detailsHeaderHeight - 65;

      this.elementsView.ui.scrollContainer.height(height);
      this.checkLoadMore();
    },

    loadMoreElements: function() {
      var that = this,
        params = this.getParams(),
        request = App.request(this.requests.collectionRequest, params);

      this.first = this.collection.length - 1;
      request.fetch().done(function() {
        that.collection.add(request.models);
        that.checkLoadMore();
      });
    },

    checkResize: function() {
      if (this.elementsView && typeof this.elementsView.ui.scrollContainer !== 'string' &&
          this.elementsView.ui.scrollContainer.is(':visible')) {
        this.onShowView();
      }
    },

    checkLoaderIsVisible: function() {
      var scrollContainer = this.elementsView.ui.scrollContainer,
        scrollBlock = this.elementsView.ui.scrollBlock,
        height = scrollContainer.height() || 75,
        top = scrollBlock.offset().top - scrollContainer.offset().top;

      if (top < height + 120) {
        this.loadMoreElements();
      }
    },

    checkLoadMore: function() {
      var scrollBlock = this.elementsView.ui.scrollBlock,
        count = this.groupModel.count;

      if (typeof scrollBlock !== 'string' && scrollBlock.is(':visible') &&
          this.first !== this.collection.length - 1 &&
          this.collection.length <= count) {
        this.checkLoaderIsVisible();
      } else if (this.collection.length > count ||
          this.collection.length === 1) {
        scrollBlock.hide();
      }
    },

    showLoadingMessage: function() {
      this.elementsView.triggerMethod(
        'showMessage',
        'primary',
        _.i18n('common.loading')
      );
    },

    showErrorMessage: function() {
      this.elementsView.triggerMethod(
          'errorMessage',
          _.i18n('warning.systemError')
        );
    }
  };

  module.exports = ElementsController;
});
