/**
 * Created by RKL on 13/08/2015. 
 */
define('caccountSACreditsTabController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'caccountSACreditsTabView',
  'superAdminAddCreditsView',
  'entities/caccounts/credits',
  'settings'
], function(
  module,
  _,
  App,
  bootbox,
  View, SuperAdminAddPopupView, Credits,Settings
) {
  'use strict';

  var CAccountsCreditsTabController = {
    showTab: function(data) {
      this.cAccountSecId = data.secId;
      var view = new View(),balanceRequest = App.request('caccount-credits:get-balance', data.secId);

      view.listenTo(view, 'onAddCredits', _.bind(this.onAddCredits, this));

      this.view = view;
      $.when(balanceRequest).done(function(balance) {
        view.options.balance = balance;
        App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('caccountCreditsTab').show(view);
      });

      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));

      var currentRoute = App.getCurrentRoute();
      if(currentRoute.indexOf('payment') > 0){
           this.onPlacePayment(data);
      }
      App.off('vectorization:open-add-credit-popup');
      App.on('vectorization:open-add-credit-popup', _.bind(this.onAddCredits, this));
    },

    onAddCredits: function() {
        var that = this,
        model = App.request('caccount-credits:get-model');

        that.popupView = new SuperAdminAddPopupView({model: model});
        that.popupView.show({title: _.i18n('credit.popup.title'),
            className: 'add-credits-popup',
            buttons: {
                cancel: {
                    label: _.i18n('common.cancel'),
                    className: 'btn-default',
                    callback: function() { return true; }
                },
                main: {
                    label:  _.i18n('common.ok'),
                    className: 'btn-primary',
                    callback: function() {
                        that.onConfirmNewCredits(that.popupView.model);
                        return false;
                    }
                }
            }
        });
    },

    onConfirmNewCredits: function(model){
        var addCredits = App.request('caccount-credits:add-credits', model, this.cAccountSecId);
        $.when(addCredits).done(_.bind(function(){
            this.popupView.hide();
            bootbox.alert(_.i18n('credit.popup.creditsAdded'), _.bind(function(){
                this.view.updatePagination('reloadGrid');
                $.when(App.request('caccount-credits:get-balance', this.cAccountSecId)).done(_.bind(function(balance) {
                     this.view.updateBalance(balance);
                }, this));
            }, this));
        }, this));
    },


      refreshGrid: function(){
          var ca = Settings.get('currentCAccount');
          App.navigate('/caccount/'+ ca.secId+'/credits', {trigger: true});
      },

    onGridLoaded:function() {
      var data = {
          url: App.request('caccount-credits:get-url', this.cAccountSecId),
          rowCountUrl: App.request('caccount-credits:row-count-url', this.cAccountSecId)
        };
        
      this.view.displayGrid(data);
    }
  };

  module.exports = CAccountsCreditsTabController;
});
