define('runResultsTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'wellResultView',
    'wellDisplayGraphView',
    'entities/wells'
], function(
    module,
    App,
    _,
    Backbone,
    WellResultView,
    DisplayGraphLayoutView
) {
    'use strict';

    var DetailsTabController = {

        onShowWellTable: function(region, runModel, row, column, filters) {
            this.model = runModel;
            this.row = row;
            this.column = column;
            this.filters = filters;
            this.region = region;
            if (!this.sortOn) {
              this.sortOn = 'row';
            }

            if (this.filters && this.filters.targetSecId) {
              this.channelToDisplay = this.model.getChannelFromTarget(this.filters.targetSecId);
              this.runAt = this.model.getRunAtFromTarget(this.filters.targetSecId);
            } else {
              this.channelToDisplay = null;
              this.runAt = null;
            }

            this.view = new WellResultView({
                tabName: this.tabName
            });

            this.view.model = this.model;
            this.view.channelToDisplay = this.channelToDisplay;
            this.view.sortOn = this.sortOn;

            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.view.listenTo(this.view, 'well-table:sort-row', _.bind(this.onSortWellRow, this));
            this.view.listenTo(this.view, 'well-table:sort-column', _.bind(this.onSortWellColumn, this));
            this.view.listenTo(this.view, 'well-result:opengraphpopup', _.bind(this.onShowGraph, this));
            region.show(this.view);
        },

        onGridLoaded:function () {
            var data = {
                url: App.request('well:get-collection-result-url', this.model.get('secId')),
                rowCount: 9999
            };
            setTimeout(_.bind(function() {
              this.view.gridOptionsObj = this.view.gridOptions(data);
              this.view.onRowCountDone(data, 9999);
              this.view.triggerMethod('checkRowCount', 9999);
            }, this), 500);
        },

        onSortWellRow: function() {
          this.sortOn = 'row';
          this.onShowWellTable(this.region, this.model, this.row, this.column, this.filters);
        },

        onSortWellColumn: function() {
          this.sortOn = 'column';
          this.onShowWellTable(this.region, this.model, this.row, this.column, this.filters);
        },

        onShowGraph: function(rowObject) {
          if (rowObject.refAssay) {
            var well = _.findWhere(this.model.get('wells'), {secId: rowObject.wellSecId});
            this.displayGraphView = new DisplayGraphLayoutView({
              models: [App.request('well:model', well)],
              newItem: false,
              model: this.model.clone()
            });

            this.displayGraphView.channelToDisplay = rowObject.refKitP?this.model.getChannelFromTarget(rowObject.refKitP.secId):null;
            this.displayGraphView.graphToDisplay = this.model.getGraphToDisplay(this.view.model);
            this.displayGraphView.runAt = rowObject.refKitP?this.model.getRunAtFromTarget(rowObject.refKitP.secId):null;
            this.displayGraphView.show({
              title: _.i18n('well.graph')
            });
          }

        }
    };

    module.exports = DetailsTabController;
});
