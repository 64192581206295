/**
 * Created by RKL on 13/08/2015.
 */
define('runAssaysTargetsTabController',[
  'module',
  'underscore',
  'backbone',
  'app',
  'runAssaysTargetsTabView',
  'settings'
], function(
  module,
  _,
  Backbone,
  App,
  View
) {
  'use strict';

  var RunAssaysTargetsTabController = {
    showAssaysTargetsTab: function(region, model) {
      this.region = region;
      this.model = model;
      this.createView();
    },

    createView: function() {
      this.view = new View({
        model: this.model
      });
      this.view.listenTo(App, 'well:mass:edit', _.bind(this.onWellsChange, this));
      this.view.listenTo(App, 'runat:header:rerender', _.bind(this.onRunAtChange, this));
      this.region.show(this.view);
    },

    onRunAtChange: function(runAt) {
      var modelRunAt = _.findWhere(this.model.get('runAts'), { secId: runAt.secId });
      modelRunAt.baseline = runAt.baseline;
      modelRunAt.threshold = runAt.threshold;
      modelRunAt.override = runAt.override;
      this.view.refreshRunAt(runAt);
    },

    onWellsChange: function() {
      this.view.destroy();
      this.createView();
    }
  };

  module.exports = RunAssaysTargetsTabController;
});
