/**
 * Created by OBL on 14/01/2016.
 */
define('adminResTransController',[
    'module',
    'underscore',
    'app',
    'adminResTransView',
    'bootbox',
    'restransCreateEditLayoutView',
    'settings',
    'entities/restrans'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    CreateEditLayoutView
    ) {
    'use strict';

    var SuperAdminResTransListController = {
        showResTransList: function(data) {
            this.data = data;
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'restrans:delete', _.bind(this.onDelete, this));
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('lmbAnaResTransTab').show(view);
        },

        showResTransAdd: function(lmbAnaSecId) {
            this.showResTransAddEdit(App.request('restrans:model', {
                refLmbAna: {
                    secId: lmbAnaSecId
                }
            }), true);
        },

        showResTransAddEdit: function(model, newItem) {
            this.createEditView = new CreateEditLayoutView({
                restransModel: model,
                newItem: newItem
            });
            this.createEditView.show({
                title: _.i18n('restrans.new')
            });
        },

        showResTransEdit: function(lmbAnaSecId, restransSecId) {
            var that = this;
            var model = App.request('restrans:model', {
                refLmbAna: {
                    secId: lmbAnaSecId
                },
                secId: restransSecId
            });
            model.fetch().done(function() {
                that.showResTransAddEdit(model, false);
            });
        },

        onGridLoaded: function() {
            var kitSecId = this.data.model.get('refKitSecId')?
                this.data.model.get('refKitSecId'):this.data.model.get('secId');
            var data = {
                url: App.request('restrans:get-collection-url', kitSecId),
                rowCountUrl: App.request('restrans:get-count-url', kitSecId)
            };

            this.view.displayGrid(data);
        },

        onDelete: function(kitSecId, secId){
            bootbox.confirm(_.i18n('restrans.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('restrans:delete', kitSecId, secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = SuperAdminResTransListController;
});
