define('env',['module', 'browserUtils'
], function (module, BrowserUtils) {
  'use strict';
  
  var Env = {
    config: {
      defaults: {
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/logolacar.jpg',
        compuzzLogo: 'app/styles/css/images/logolacar.jpg',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/'
      },
      development: {
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/logolacar.jpg',
        compuzzLogo: 'app/styles/css/images/logolacar.jpg',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/'
      },
      minified: {
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'images/logolacar.jpg',
        compuzzLogo: 'images/logolacar.jpg',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/'
      },
      lacar: {
          filesPath: 'files/',
          serverUrl: 'https://genefox.lacar-mdx.com/',
          compuzzLogo: 'images/logolacar.jpg',
          defaultLogo: 'images/logolacar.jpg',
          notFoundImage: 'images/404.png',
          catalogueDefaultImage: 'images/book.png',
          logoToolSubDomain: '',
          editOrderUrl: 'https://genefox.lacar-mdx.com/htmlcompuzz/'
      },
      lacartest: {
          filesPath: 'files/',
          serverUrl: 'https://genefoxtest.lacar-mdx.com/',
          compuzzLogo: 'images/logolacar.jpg',
          defaultLogo: 'images/logolacar.jpg',
          notFoundImage: 'images/404.png',
          catalogueDefaultImage: 'images/book.png',
          logoToolSubDomain: '',
          editOrderUrl: 'https://genefoxtest.lacar-mdx.com/htmlcompuzz/'
      }
    },

    isProduction: function() {
      return this.getEnvironment() === 'production';
    },

    getEnvironment: function() {
      switch ( BrowserUtils.getHostName() ) {
        case 'localhost':
        case '127.0.0.1':
          return 'localhost';
        case 'betamobiolink.compuzz.com':
          return 'beta';
        case 'mobiolink.compuzz.com':
          return 'production';
      }
    }
  };
  module.exports = Env;
});

