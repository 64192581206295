define('adminKitpTabs',[
  'module',
  'underscore'
], function(
  module,
  _
) {
  'use strict';

  module.exports = [
    {
      name: 'detail',
      title: _.i18n('kitps.details.tabTitle'),
      isDefault: true
    }, {
      name: 'setting',
      title: _.i18n('kitps.setting.tabTitle'),
      isDefault: true
    }
  ];
});
