define('runDetailsTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'runDetailsTabView',
    'runDetailsGraphView',
    'emptyView',
    'wellTableView',
    'headerWellView',
    'wellEditRefAssayView',
    'wellEditRefKitLView',
    'wellEditSmpIdView',
    'wellDisplayGraphView',
    'entities/wells'
], function(
    module,
    App,
    _,
    Backbone,
    DetailsTabView,
    RunGraphView,
    EmptyView,
    WellTableView,
    HeaderWellView,
    CreateEditLayoutView,
    CreateEditRefKitLLayoutView,
    CreateEditSmpIdLayoutView,
    DisplayGraphLayoutView
) {
    'use strict';

    var DetailsTabController = {
        showDetailsTab: function(region, runModel, row, column, filters, selectedValues) {
            var that = this;
            this.model = runModel;
            this.view = new DetailsTabView({
            });

            this.row = row;
            this.column = column;
            this.filters = filters;
            this.selectedValues = selectedValues;
            this.sortOn = 'row';

            if (this.filters && this.filters.targetSecId) {
                this.target = this.model.getTarget(this.filters.targetSecId);
                this.channelToDisplay = this.target.channelNb;
                this.runAt = this.model.getRunAtFromTarget(this.filters.targetSecId);
            } else {
                this.target = null;
                this.channelToDisplay = null;
                this.runAt = null;
            }

            region.show(this.view);
            this.view.getRegion('wellTable').show(new EmptyView());
            that.onShowWellTable();
        },

        onShowWellTable: function() {
            this.wellView = new WellTableView({
                tabName: this.tabName
            });

            this.wellView.model = this.model;
            this.wellView.channelToDisplay = this.channelToDisplay;
            this.wellView.sortOn = this.sortOn;

            this.wellView.listenTo(this.wellView, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.wellView.listenTo(this.wellView, 'well-table:loaded', _.bind(this.onLoadSubViews, this));
            this.wellView.listenTo(this.wellView, 'well-table:edit-ref-assay', _.bind(this.onEditWellsRefAssay, this));
            this.wellView.listenTo(this.wellView, 'well-table:edit-ref-kitl', _.bind(this.onEditWellsRefKitL, this));
            this.wellView.listenTo(this.wellView, 'well-table:edit-smpid', _.bind(this.onEditWellsSmpId, this));
            this.wellView.listenTo(this.wellView, 'well-table:display-graph', _.bind(this.onDisplayGraph, this));
            this.wellView.listenTo(this.wellView, 'well-table:sort-row', _.bind(this.onSortWellRow, this));
            this.wellView.listenTo(this.wellView, 'well-table:sort-column', _.bind(this.onSortWellColumn, this));
            this.wellView.listenTo(App, 'well:header:rerender', _.bind(this.onWellChange, this));
            this.wellView.listenTo(App, 'well:mass:edit', _.bind(this.onWellsChange, this));
            this.view.getRegion('wellTable').show(this.wellView);
        },

        onGridLoaded:function () {
            var smpid = this.filters?this.filters.smpid:null,
                assaySecId = this.filters?this.filters.assaySecId:null,
                mbAnaSecId = this.filters?this.filters.mbAnaSecId:null,
                result = this.filters?this.filters.result:null,
                errorInd = this.filters?this.filters.errorInd:null,
                res1CodeErr = this.filters?this.filters.res1CodeErr:null,
                res2CodeErr = this.filters?this.filters.res2CodeErr:null,
                res3CodeErr = this.filters?this.filters.res3CodeErr:null,
                res4CodeErr = this.filters?this.filters.res4CodeErr:null,
                res5CodeErr = this.filters?this.filters.res5CodeErr:null,
                res6CodeErr = this.filters?this.filters.res6CodeErr:null,
                smptype = this.filters?this.filters.smptype:null;


            var data = {
                url: App.request('well:get-collection-url', this.model.get('secId'), this.row,
                    this.column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype, res1CodeErr,
                    res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr, res6CodeErr, this.selectedValues),
                rowCountUrl: App.request('well:get-count-url', this.model.get('secId'), this.row,
                    this.column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype, res1CodeErr,
                    res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr, res6CodeErr, this.selectedValues)
            };
            this.wellView.displayGrid(data);
        },

        onSortWellRow: function() {
          this.sortOn = 'row';
          this.onShowWellTable();
        },

        onSortWellColumn: function() {
          this.sortOn = 'column';
          this.onShowWellTable();
        },

        onDisplayGraph: function(items) {
            var models = this.getModels(items);

            this.displayGraphView = new DisplayGraphLayoutView({
                models: models,
                model: this.model.clone(),
                newItem: false
            });
            this.displayGraphView.channelToDisplay = this.channelToDisplay;
            this.displayGraphView.graphToDisplay = this.model.getGraphToDisplay(this.view.model);
            this.displayGraphView.runAt = this.runAt;

            this.displayGraphView.show({
                title: _.i18n('well.graph') +
                    (this.target?(' - ' + this.target.refAssay.code + ' - ' + this.target.refMbAna.code):'')
            });
        },

        onEditWellsSmpId:function(items) {
            var models = this.getModels(items);

            this.createEditSmpIdView = new CreateEditSmpIdLayoutView({
                models: models,
                newItem: false
            });
            this.createEditSmpIdView.show({
                title: _.i18n('well.edit')
            });
        },

        onEditWellsRefAssay: function(items) {
            var models = this.getModels(items);

            this.createEditView = new CreateEditLayoutView({
                models: models,
                runModel: this.model,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.edit')
            });
        },

        onEditWellsRefKitL: function(items) {
            var models = this.getModels(items);

            this.createEditView = new CreateEditRefKitLLayoutView({
                models: models,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.edit')
            });
        },

        getModels: function(items) {
            var models = [];
            _.each(items, _.bind(function (item) {
                var well = _.findWhere(this.model.get('wells'), {secId: item.secId});
                models.push(App.request('well:model', well));
            }, this));
            return models;
        },

        onWellsChange: function (wells) {
            _.each(wells, _.bind(function(well) {
                var wellJson = well.toJSON();
                for (var i = 0 ; i < this.model.get('wells').length ; i++) {
                    if (this.model.get('wells')[i].secId === wellJson.secId) {
                        this.model.get('wells')[i]= wellJson;
                    }
                }
            }, this));
            this.onShowWellTable();
        },

        onWellChange: function (wellJson) {
            for (var i = 0 ; i < this.model.get('wells').length ; i++) {
                if (this.model.get('wells')[i].secId === wellJson.secId) {
                    this.model.get('wells')[i]= wellJson;
                }
            }
            this.onShowWellTable();
        },

        onLoadSubViews: function(wellSecId) {
            var well = _.findWhere(this.model.get('wells'), {secId: wellSecId});
            this.onCreateRunGraphView(well);
        },

        onCreateRunGraphView: function(well) {
            var slopeAlgo;
            this.view.model = App.request('well:model', well);
            this.view.modelRun = this.model;
            if (this.headerWellView) {
                this.headerWellView.destroy();
            }
            this.headerWellView = new HeaderWellView();
            this.headerWellView.model = this.view.model;
            this.headerWellView.modelRun = this.model;
            this.headerWellView.channelToDisplay = this.channelToDisplay;
            var region = this.view.getRegion('headerWell');
            if (region){
                region.show(this.headerWellView);
            }

            var graphToDisplay = this.model.getGraphToDisplay(this.view.model);

            if (graphToDisplay.indexOf('M') !== -1) {
                this.view.showMelt();
                this.view.activeMelt();
                if (this.runGraphView) {
                    this.runGraphView.destroy();
                }
                this.runGraphView = new RunGraphView({type: 'line'});
                this.runGraphView.model = this.view.model;
                this.runGraphView.modelRun = this.model;
                this.runGraphView.slopeEnable = true;
                this.runGraphView.brutEnable = false;
                this.runGraphView.channelToDisplay = this.channelToDisplay;
                this.runGraphView.yLabel = _.i18n('well.graph.legend.temp');
                this.runGraphView.xLabel = _.i18n('well.graph.legend.1stDerivateFluo');
                region = this.view.getRegion('runGraph');
                if (region) {
                    region.show(this.runGraphView);
                }

                if (this.runGraphView1) {
                    this.runGraphView1.destroy();
                }
                this.runGraphView1 = new RunGraphView({type: 'line'});
                this.runGraphView1.model = this.view.model;
                this.runGraphView1.modelRun = this.model;
                this.runGraphView1.slopeEnable = true;
                this.runGraphView1.brutEnable = false;
                this.runGraphView1.deriv2Enable = true;
                this.runGraphView1.channelToDisplay = this.channelToDisplay;
                this.runGraphView1.yLabel = _.i18n('well.graph.legend.temp');
                this.runGraphView1.xLabel = _.i18n('well.graph.legend.2ndDerivateFluo');
                region = this.view.getRegion('runGraph1');
                if (region) {
                    region.show(this.runGraphView1);
                }

                if (this.runGraphView2) {
                    this.runGraphView2.destroy();
                }
                this.runGraphView2 = new RunGraphView({type: 'line'});
                this.runGraphView2.model = this.view.model;
                this.runGraphView2.modelRun = this.model;
                this.runGraphView2.slopeEnable = false;
                this.runGraphView2.brutEnable = true;
                this.runGraphView2.channelToDisplay = this.channelToDisplay;
                this.runGraphView2.yLabel = _.i18n('well.graph.legend.temp');
                this.runGraphView2.xLabel = _.i18n('well.graph.legend.fluo');
                region = this.view.getRegion('runGraph2');
                if (region) {
                    region.show(this.runGraphView2);
                }
            }

            if (graphToDisplay.indexOf('A') !== -1) {
                slopeAlgo = this.model.get('settings')['AC-SMOO'];
                this.view.showAmpl();
                if (graphToDisplay.indexOf('M') === -1) {
                    this.view.activeAmpl();
                }

                if (this.runMeltGraphView) {
                    this.runMeltGraphView.destroy();
                }
                this.runMeltGraphView = new RunGraphView({type: 'line'});
                this.runMeltGraphView.model = this.view.model;
                this.runMeltGraphView.modelRun = this.model;
                this.runMeltGraphView.slopeEnable = true;
                this.runMeltGraphView.brutEnable = false;
                this.runMeltGraphView.amplGraph = true;
                this.runMeltGraphView.channelToDisplay = this.channelToDisplay;
                this.runMeltGraphView.yLabel = _.i18n('well.graph.legend.cycle');
                this.runMeltGraphView.xLabel = _.i18n('well.graph.legend.1stDerivateFluo');
                region = this.view.getRegion('runAmplGraph');
                if (region) {
                    region.show(this.runMeltGraphView);
                }

                if (this.runMeltGraph1View) {
                    this.runMeltGraph1View.destroy();
                }
                this.runMeltGraph1View = new RunGraphView({type: 'line'});
                this.runMeltGraph1View.model = this.view.model;
                this.runMeltGraph1View.modelRun = this.model;
                this.runMeltGraph1View.slopeEnable = true;
                this.runMeltGraph1View.brutEnable = false;
                this.runMeltGraph1View.amplGraph = true;
                this.runMeltGraph1View.deriv2Enable = true;
                this.runMeltGraph1View.channelToDisplay = this.channelToDisplay;
                this.runMeltGraph1View.yLabel = _.i18n('well.graph.legend.cycle');
                this.runMeltGraph1View.xLabel = _.i18n('well.graph.legend.2ndDerivateFluo');
                region = this.view.getRegion('runAmplGraph1');
                if (region) {
                    region.show(this.runMeltGraph1View);
                }

                if (this.runMeltGraph2View) {
                    this.runMeltGraph2View.destroy();
                }
                this.runMeltGraph2View = new RunGraphView({type: 'line'});
                this.runMeltGraph2View.model = this.view.model;
                this.runMeltGraph2View.modelRun = this.model;
                this.runMeltGraph2View.slopeEnable = false;
                this.runMeltGraph2View.brutEnable = true;
                this.runMeltGraph2View.amplGraph = true;
                this.runMeltGraph2View.channelToDisplay = this.channelToDisplay;
                this.runMeltGraph2View.yLabel = _.i18n('well.graph.legend.cycle');
                this.runMeltGraph2View.xLabel = _.i18n('well.graph.legend.fluo');
                if (this.runAt) {
                  this.runMeltGraph2View.baseline = this.runAt.baseline;
                  this.runMeltGraph2View.threshold = this.runAt.threshold;
                }
                region = this.view.getRegion('runAmplGraph2');
                if (region) {
                    region.show(this.runMeltGraph2View);
                }
            }
        }
    };

    module.exports = DetailsTabController;
});
