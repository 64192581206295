define('mbAnaCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!mbAnaCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'settings',
  'autocompleteView'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  Settings,
  AutocompleteView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectMbAnaResGr: '.js-select-mbanaresgr-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'code', type: 'required'},
      {name: 'name', type: 'required'}
    ],

    initialize: function(options) {
      this.model = options.mbanaModel.clone();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();

      var mbAnaResGrView = new AutocompleteView(
          this._getMbAnaResGrsAutocompleteOptions('refMbAnaResGr')
      );
      this.getRegion('selectMbAnaResGr').show(mbAnaResGrView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.model.save();
      }
    },

    _getMbAnaResGrsAutocompleteOptions: function(property) {
      var mbAnaResGr = this.model.get(property);
      return {
        type: 'remote',
        remote: Settings.url('compuzz', 'v2/mbanaresgrs'),
        valueKey: 'code',
        apiKey: 'secId',
        placeholder: _.i18n('selectmbanaresgr.placeholder'),
        value: mbAnaResGr ? mbAnaResGr.code : null,
        name: property,
        onPressKey: true,
        lazyLoad: true,
        keys: {
          query: 'code',
          limit: 'pageSize'
        },
        callback: _.bind(this.onChangeMbAnaResGr, this)
      };
    },

    onChangeMbAnaResGr: function(fieldName, model) {
      if (model) {
        model.unset('autocompleteValue', {silent: true});
      }
      this.onChange(fieldName, model);
      this.triggerMethod('enable:cancel:confirm');
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.options.mbanaModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('mbana:header:rerender', modelJSON);
      App.navigate('superAdmin/mbanas/' + model.get('secId'), {trigger: true});
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'superAdmin/mbanas/new') {
        App.navigate('superAdmin/mbanas', {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
