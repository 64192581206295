define('adminLisSmpMatTabs',[
  'module',
  'underscore'
], function(
  module,
  _
) {
  'use strict';

  module.exports = [
    {
      name: 'detail',
      title: _.i18n('lissmpmats.details.tabTitle')
    }
  ];
});
