/**
 * Created by bfr on 30/01/2017.
 */
define('runTemplatesMainRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'contentShowController',
    'itemPagesNameMixin',
    'emptyView'
], function(
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    ContentShowController,
    ItemPagesNameMixin,
    EmptyView
) {
    'use strict';

    var RunTemplatesRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'runtemplates': 'showView',
            'runtemplates/empty': 'showEmpty',
            'runtemplates/new': 'showViewNewRunTemplate',
            'runtemplates/:runSecId': 'showRunTemplateTableTab',
            'runtemplates/:runSecId/detail': 'showRunTemplateDetailTab',
            'runtemplates/:runSecId/table': 'showRunTemplateTableTab',
            'runtemplates/:runSecId/details': 'showRunTemplateDetailsTab',
            'runtemplates/:runSecId/details/filters/:filters': 'showRunTemplateDetailsTabFilters',
            'runtemplates/:runSecId/details/row/:row': 'showRunTemplateDetailsTabRow',
            'runtemplates/:runSecId/details/column/:column': 'showRunTemplateDetailsTabColumn',
            'runtemplates/:runSecId/details/row/:row/column/:column': 'showRunTemplateDetailsTab'
        }
    });

    var API = {

        showEmpty: function() {

        },

        showViewNewRunTemplate: function() {
            this.showViewRunTemplate(true);
        },

        showViewRunTemplate: function(newItem) {
            require([
                'runTemplatesShowController'
            ], function(RunTemplatesShowController) {
                ContentShowController.showContent().done(function() {
                    App.startSubModule('runTemplatesMain');
                    RunTemplatesShowController.showRuns();
                    App.execute('set:active:nav:item', ItemPagesNameMixin.RUNTEMPLATES);

                    if (newItem) {
                        RunTemplatesShowController.showNewRun();
                    } else if (RunTemplatesShowController.createEditView) {
                        RunTemplatesShowController.createEditView.hide(true);
                    }
                });
            });
        },

        showView: function() {
            this.showViewRunTemplate(false);
        },

        showRunTemplateDetailTab: function(runSecId) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTemplateTabsLayoutController',
                    'runTemplateDetailTabController'
                ], function(RunTemplateTabsLayoutController, RunTemplateDetailTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    region.show(new EmptyView());
                    RunTemplateTabsLayoutController.showTabsRunTemplateLayout(region, 'detail', runSecId).done(function(region, runModel) {
                        RunTemplateDetailTabController.showDetailTab(region, runModel);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNTEMPLATES);
                    });
                });
            });
        },

        showRunTemplateTableTab: function(runSecId) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTemplateTabsLayoutController',
                    'runTemplateTableTabController'
                ], function(RunTemplateTabsLayoutController, RunTemplateTableTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    region.show(new EmptyView());
                    RunTemplateTabsLayoutController.showTabsRunTemplateLayout(region, 'table', runSecId).done(function(region, runModel) {
                        RunTemplateTableTabController.showTableTab(region, runModel);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNTEMPLATES);
                    });
                });
            });
        },

        showRunTemplateDetailsTab: function(runSecId, row, column) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTemplateTabsLayoutController',
                    'runTemplateDetailsTabController'
                ], function(RunTemplateTabsLayoutController, RunTemplateDetailsTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    RunTemplateTabsLayoutController.showTabsRunTemplateLayout(region, 'details', runSecId).done(function(region, runModel) {
                        RunTemplateDetailsTabController.showDetailsTab(region, runModel, row, column);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNTEMPLATES);
                    });
                });
            });
        },

        showRunTemplateDetailsTabFilters: function(runSecId, filters) {
            ContentShowController.showContent().done(function() {
                require([
                    'runTemplateTabsLayoutController',
                    'runTemplateDetailsTabController'
                ], function(RunTemplateTabsLayoutController, RunTemplateDetailsTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    RunTemplateTabsLayoutController.showTabsRunTemplateLayout(region, 'details', runSecId).done(function(region, runModel) {
                        var filtersJson = filters?JSON.parse(filters):null;
                        RunTemplateDetailsTabController.showDetailsTab(region, runModel, null, null, filtersJson);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RUNTEMPLATES);
                    });
                });
            });
        },

        showRunTemplateDetailsTabRow: function(runSecId, row) {
            this.showRunTemplateDetailsTab(runSecId, row);
        },

        showRunTemplateDetailsTabColumn: function(runSecId, column) {
            this.showRunTemplateDetailsTab(runSecId, null, column);
        }
    };

    App.on('runtemplates:show', function() {
        App.navigate('runtemplates');
        API.showView();
    });

    App.on('before:start', function() {
        new RunTemplatesRouter({
            controller: API
        });
    });

    module.exports = RunTemplatesRouter;
});
