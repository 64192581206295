
define('template!anaAssayDetailTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="well kit-detail">\n  <div class="detailed-info-form-container">\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('anaassay.refLmbAna') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( refLmbAna.code )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('anaassay.nbRepetition') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( nbRepetition )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( comment )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n  </div>\n  <div class="row">\n    <div class="pull-right p-r-15">\n      <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n    </div>\n  </div>\n</div>';

}
return __p
};});

