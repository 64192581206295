/**
 * Created by OLD on 04/09/2015.
 */
define('colorUtils',[
  'module'
], function(
  module
) {
  'use strict';

  var ColorConverter = {
    decimalToHexa: function (decimal) {
      var hexa = parseInt(decimal, 10).toString(16);

      while (hexa.length < 6) {
        hexa = '0' + hexa;
      }
      return '#' + hexa;
    },

    rgbToDecimal: function(r, g, b) {
      return ((r * 65536) + (g * 256) + b);
    }
  };

  module.exports = ColorConverter;
});

