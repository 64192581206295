define('indexController',[
  'module',
  'settings',
  'menuItemsUtils',
  'app',
  'underscore'
],
function(
  module,
  Settings,
  MenuItemUtils,
  App,
  _
) {
  'use strict';

  var IndexController = {
    show: function() {
      var menuItems = MenuItemUtils.getMenuItems();
      var cAccountRoles = Settings.get('cAccountRoles');
      var lastUrlPageCookie = Settings.get('lastUrlPageCookie');
      if(lastUrlPageCookie) {
        Settings.set('lastUrlPageCookie', null);
        App.navigate(lastUrlPageCookie, {trigger: true});
      } else if (menuItems) {
        var menuItem = _.find(menuItems, function(menuItem) {
          if(menuItem.role && typeof menuItem.role === 'string') {
            return cAccountRoles[menuItem.role] !== undefined;
          } else {
            return false;
          }
        });
        if ( menuItem ) {
          if ( _.isEmpty(menuItem.subMenu) === false) {
            menuItem = menuItem.subMenu[0];
          }
          if (menuItem.link) {
            App.navigate(menuItem.link(), {trigger: true});
          }
        }
      }
    }
  };

  module.exports = IndexController;
});
