/**
 * Created by OBL on 14-01-16.
 */
define('superAdminCAccountView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'imageUtils',
    'template!superAdminCAccountTpl',
    'superAdminCAccountTabs',
    'rolesMixin'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    Utils,
    viewTpl,
    SuperAdminCAccountTabs,
    RolesMixin
    ) {
    'use strict';

    var CaccountDetailsView =  Marionette.LayoutView.extend({
        template: viewTpl,

        triggers: {
            'click @ui.viewMore': 'onViewMore'
        },

        ui: {
            tab: '.caccount-tab',
            tabPane: '.tab-pane',
            logo: '.js-logo-holder',
            name: '.p-name',
            code: '.p-code',
            phone: '.p-phone',
            email: '.p-email'
        },

        events: {
            'click @ui.tab': 'onTabClick'
        },

        regions: {
            caccountProfileTab: '#profile-tab',
            caccountDetailsTab: '#detail-tab',
            caccountCreditsTab: '#credits-tab',
            caccountGraphicalRequestsTab: '#graphicalservice-tab'
        },

        serializeData: function() {
            var templateData = this.model.toJSON(),
                cAccountRoles = Settings.get('cAccountRoles');

            templateData.SuperAdminCAccountTabs = [];
            if(this.options.data.id === 'new') {
                var profileTab = _.findWhere( SuperAdminCAccountTabs, {role:  RolesMixin.DASHBOARD_SUPERADMIN_ACCOUNT_PROFILE});
                templateData.SuperAdminCAccountTabs.push(profileTab);
                return templateData;
            }
            if (cAccountRoles) {
                _.each( SuperAdminCAccountTabs, function (tab) {
                    if (cAccountRoles[tab.role] === true) {
                        templateData.SuperAdminCAccountTabs.push(tab);
                    }
                });
            }
            
            return templateData;
        },

        onRender: function() {
            this._setLogo(this.model.get('logo'), this.model.get('code'));

            if (this.options.data.tab) {
                this.ui.tab.removeClass('active');
                this.$el.find('.tab-pane').removeClass('active');
                this.$el
                    .find('.caccount-tab[data-name="' + this.options.data.tab.name + '"]')
                    .addClass('active');
                this.$el
                    .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
                    .addClass('active');
            }
        },

        onTabClick: function(e) {
            var $target = $(e.currentTarget),
                tab = {
                    route: $target.data('route'),
                    role: $target.data('role'),
                    name: $target.data('name')
                };
            this.ui.tab.removeClass('active');
            this.ui.tabPane.removeClass('active');
            $target.addClass('active');
            this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

            this.triggerMethod('click:tab', tab);
        },

        onHeaderChange: function(caccount) {
            this.ui.name.text(caccount.name);
            this.ui.code.text(caccount.code);
            this.ui.phone.text(caccount.phone);
            this.ui.email.text(caccount.contactEmail);
            this._setLogo(caccount.logo, caccount.code);
        },

        onSwitch: function () {
            var data = {
                    userId: Settings.get('currentUser').secId,
                    cAccountId: this.model.get('secId')
                },
                caccount = this.model.toJSON(),
                loginRequest = App.request('caccount:new-login', data);

            $.when(loginRequest).done(function() {
                App.trigger('switchCaccount', caccount);
            });
        },

        _setLogo: function(logo, code) {
            var logoUrl = Utils.getLogo(logo, code, {
                action: 'res',
                params: 't'
            });

            if(logoUrl) {
                this.ui.logo.css('background-image', 'url(' + logoUrl + ')');
            } else {
                this.ui.logo.addClass('default-upload-image');
            }
        }

    });

    module.exports = CaccountDetailsView;
});
