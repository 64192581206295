
define('template!caccountShareTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n\t<div class="well jq-grid-table-page">\n\t\t<div class="loading-block js-loading-block">\n\t\t\t<span class="loading-message js-loading-message label"></span>\n\t\t</div>\n\t\t<div class="vectorization-table">\n\t\t\t';
 if (data.type === 'caccounts' || data.type === 'subcaccounts') { ;
__p += '\n\t\t\t\t<div class="form-group add-new-block">\n\t\t\t\t\t<div class="btn js-add-share add-btn-text">\n\t\t\t\t\t\t<i class="mdi-content-add icon"></i>\n            <span>' +
((__t = ( _.i18n('share.new') )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t</div>\n\t\t\t\t</div> \n\t\t\t';
 } ;
__p += '\n\t\t\t<table id="jq-grid-share-' +
((__t = ( share )) == null ? '' : __t) +
'" role="grid"></table>\n\t\t\t<div id="jq-grid-pager-share-' +
((__t = ( share )) == null ? '' : __t) +
'"></div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

