define('adminKitCQctView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!adminKitCQctListTpl'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl
    ) {
    'use strict';

    var KitCQctListView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'kitcqctListFilters',

        triggers: {
        },

        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteKitCQct: '.delete-kitcqct-link',
                editKitCQct: '.edit-kitcqct-link'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click @ui.deleteKitCQct': 'onDeleteKitCQct',
                'click @ui.editKitCQct': 'onEditKitCQct'
            }, this.gridEvents);
        },

        serializeData: function() {
            var templateData = {
                type: 'kitcqct',
                data: this.options.data
            };
            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: Settings.get('perPage15'),
                currentPage: 1
            };
        },

        onDeleteKitCQct: function(event){
            this.trigger('kitcqct:delete', this.options.data.model.get('secId'), $(event.currentTarget).find('span').attr('data-row-id'));
        },

        onEditKitCQct: function(event) {
            this.trigger('kitcqct:edit', this.options.data.model.get('secId'), $(event.currentTarget).find('span').attr('data-row-id'));
        },

        gridOptions: function(data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('kitcqct.refKitP'),
                        name: 'refKitP.displayName',
                        classes: 'kitcqct-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'refKitP.displayName'
                    }, {
                        label: _.i18n('kitcqct.targetType'),
                        name: 'targetType',
                        classes: 'kitcqct-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'targetType'
                    }, {
                        label: _.i18n('kitcqct.algoCtrlType'),
                        name: 'algoCtrlType',
                        classes: 'kitcqct-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'algoCtrlType'
                    }, {
                        label: _.i18n('kitcqct.qcTargetValCode'),
                        name: 'qcTargetValCode',
                        classes: 'kitcqct-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'qcTargetValCode'
                    }, {
                        label: _.i18n('kitcqct.qcTargetValNum'),
                        name: 'qcTargetValNum',
                        classes: 'kitcqct-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'qcTargetValNum'
                    }, {
                        label: _.i18n('kitcqct.valueWarn'),
                        name: 'valueWarn',
                        classes: 'kitcqct-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'valueWarn'
                    }, {
                        label: _.i18n('kitcqct.valueErr'),
                        name: 'valueErr',
                        classes: 'kitcqct-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'valueErr'
                    }
                ]
            };

            if (Settings.get('currentCAccount').secId === this.options.data.model.get('refKit').refCAccount.secId) {
                options.colModel.push({
                    label: ' ',
                    name: 'edit',
                    search: false,
                    classes: 'edit-kitcqct-link edit-link',
                    formatter: this.editIconFormatter,
                    width: 20
                });
                options.colModel.push({
                    label: ' ',
                    name: 'delete',
                    search: false,
                    classes: 'delete-kitcqct-link delete-link',
                    formatter: this.deleteIconFormatter,
                    width: 20
                });
            }

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        }
    });

    module.exports = KitCQctListView;
});
