/**
 * Created by OLD on 28/09/2015.
 */
define('timetrackerController',[
  'module',
  'logger',
  'underscore',
  'app',
  'backbone',
  'timetrackerView',
  'timetrackerTableView',
  'timetrackerFiltersView',
  'timetrackerPopupView',
  'galleryView',
  'moment',
  'settings',
  'entities/timetracker'
], function(
  module,
  Logger,
  _,
  App,
  Backbone,
  View,
  TableView,
  FiltersView,
  PopupView,
  GalleryView,
  moment
) {
  'use strict';

  var Timetracker = {
    showTimetracker: function() {
      var that = this,
        view = new View(), 
        lastDay = moment().endOf('isoWeek').format('x'),
        firstDay = moment().startOf('isoWeek').format('x'),
        ttTimeRequest = App.request(
          'timetracker:get-time', 
          firstDay, 
          lastDay
        ),
        ttTimeUserList = App.request('timetracker:get-user');

      $.when(ttTimeRequest, ttTimeUserList)
        .done(function(data, userList) {
          that.data = new Backbone.Model(data);
          that.filters = {
            dateFrom: firstDay,
            dateTo: lastDay,
            logonId: null,
            type: 'Day'
          };
          that.options = {
            tplView: 'Day',
            userList: userList,
            daySpan: that.onCalculateDays(parseInt(firstDay, 10), parseInt(lastDay, 10)),
            filters: that.filters
          };
          App.regions.getRegion('main').currentView.getRegion('content').show(view);
          // create filterView
          that.filterView = new FiltersView ();
          that.filterView.model = that.data;
          that.filterView.options = that.options;
          view.getRegion('filtersTT').show(that.filterView);
          // create TableView
          that.tableView = new TableView ();
          that.tableView.model = that.data;
          that.tableView.options = that.options;
          view.getRegion('tableTT').show(that.tableView);

          that.tableView.listenTo(that.tableView, 'timetracking:show-screens', _.bind(that.onShowScreens, that));
          that.filterView.listenTo(that.filterView, 'timetracking:change-date', _.bind(that.onSendRequest, that));
        });
    },

    onSendRequest: function(filters) {
      var that = this,
        ttTimeRequest;

      this.filters = filters;
      ttTimeRequest = App.request('timetracker:get-time', 
        filters.dateFrom, filters.dateTo, filters.logonId, filters.type);
      $.when(ttTimeRequest)
        .done(function(data) {
          that.onRenderTableData(data);
        });
    },

    onShowScreens: function(target, timeF, timeT) {
      var that = this,
        logonId = target.attr('data-user'),
        ttDataRequest = App.request(
        'timetracker:get-data', 
        timeF, timeT, logonId 
      );

      $.when(ttDataRequest)
        .done(function(data) {
          var model = new Backbone.Model(data);
          that.popupView = new PopupView({
            model: model,
            logonId: logonId
          });
          that.popupView.show({
            title: _.i18n('timetracker.popup.title'),
            className: 'tt-popup'
          });

          that.popupView.listenTo(that.popupView, 'timetracker:onZoom', _.bind(that.onZoom, that));
          that.popupView.listenTo(that.popupView, 'timetracker:onDelete', _.bind(that.onDelete, that));
        });
    },

    onCalculateDays: function(v1, v2) {
      var d2 = new Date(v2);
      var d1 = new Date(v1);
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return parseInt((t2 - t1) / (24 * 3600 * 1000), 10);
    },

    onZoom: function(id) {
      var that = this, 
        data = this.parseZoomData();

        this.galleryView = new GalleryView({
          id: id,
          data: data,
          onLoadEnd: function() {
            that.galleryLoading = false;
          }
        });

      if (!this.galleryLoading) {
        this.galleryLoading = true;
        App.regions.getRegion('main').currentView.getRegion('zoom').show(this.galleryView);
      }
    },

    onRenderTableData: function(data) {
      this.tableView.model = new Backbone.Model(data);
      this.tableView.options.dateFrom = this.filters.dateFrom;
      this.tableView.options.dateTo = this.filters.dateTo;
      this.tableView.options.tplView = this.filters.type;
      this.tableView.options.daySpan = this.onCalculateDays(parseInt(this.filters.dateFrom, 10), parseInt(this.filters.dateTo, 10)) + 1;
      this.tableView.render();
    },

    onDelete: function(userSecId, screenSecId) {
      var that = this, 
        ttDeleteRequest = App.request('timetracker:delete-screen', userSecId, screenSecId);

      $.when(ttDeleteRequest).done(function() {
        that.popupView.render();
        that.onSendRequest(that.filters);
      });
    },

    parseZoomData: function() {
      var screens = [];

      _.map(this.popupView.model.toJSON(), function(item) {
        _.filter(item.snaptshots, function(snaptshots) {
          if (snaptshots.length !== 0) {
            var model = new Backbone.Model({
              downloadLink: snaptshots.fileUrl,
              fileLink: snaptshots.fileUrl,
              fileName: snaptshots.date,
              id: snaptshots.fileUid
            });
            screens.push(model);
          }
        });
      });
      return screens;
    }
  };

  module.exports = Timetracker;
});
