/**
 * Created by OLD on 30/10/2015.
 */
define('superAdminMainRouter',[
  'underscore',
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin',
  'superAdminCAccountTabs',
  'superAdminMbAnasTabs',
  'superAdminMbSmpMatsTabs',
  'superAdminMbAnaResGrsTabs',
  'superAdminMbAModelsTabs',
  'superAdminMbAnaResTabs',
  'superAdminMbAnaResController'
], function(
    _,
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin,
  SuperAdminCAccountTabs,
  SuperAdminMbAnasTabs,
  SuperAdminMbSmpMatsTabs,
  SuperAdminMbAnaResGrsTabs,
  SuperAdminMbAModelsTabs,
  SuperAdminMbAnaResTabs,
  SuperAdminMbAnaResController
) {
  'use strict';

  var SuperAdminRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'superAdmin': 'showSuperAdmin',
      'superAdmin/overview' : 'showOverviewTabSuperAdmin',
      'superAdmin/caccounts' : 'showCaccountsSuperadmin',
      'superAdmin/caccount/:secId(/:tab)': 'showCAccountDetails',
      'superAdmin/mbanas' : 'showMbAnasSuperadminList',
      'superAdmin/mbanas/new' : 'showMbAnasSuperadminNew',
      'superAdmin/mbanas/:secId(/:tab)' : 'showMbAnasSuperAdminDetails',

      'superAdmin/mbanares/:secId(/:tab)' : 'showMbAnaresSuperAdminDetails',

      'superAdmin/mbsmpmats' : 'showMbSmpMatsSuperadminList',
      'superAdmin/mbsmpmats/new' : 'showMbSmpMatsSuperadminNew',
      'superAdmin/mbsmpmats/:secId(/:tab)' : 'showMbSmpMatsSuperAdminDetails',
      'superAdmin/mbanaresgrs' : 'showMbAnaResGrsSuperadminList',
      'superAdmin/mbanaresgrs/new' : 'showMbAnaResGrsSuperadminNew',
      'superAdmin/mbanaresgrs/:secId(/:tab)' : 'showMbAnaResGrsSuperAdminDetails',
      'superAdmin/mbanaresgrs/:secId(/:tab)/new' : 'showMbAnaResGrsSuperAdminDetailsNew',
      'superAdmin/mbanaresgrs/:secId(/:tab)/:mbAnaSecId/edit' : 'showMbAnaResGrsSuperAdminDetails',
      'superAdmin/mbamodels' : 'showMbAModelsSuperadminList',
      'superAdmin/mbamodels/new' : 'showMbAModelsSuperadminNew',
      'superAdmin/mbamodels/:secId(/:tab)' : 'showMbAModelsSuperAdminDetails'
    }
  });

  var API = {
    checkContent: function() {
      var defer = $.Deferred();

      require([
        'contentShowController'
      ], function(ContentShowController) {
        ContentShowController.showContent().done(function() {
          defer.resolve();
        });
      });
      return defer.promise();
    },

    checkLayout: function(tab) {
      var defer = $.Deferred();

      require([
        'superAdminLayoutController'
      ], function(ShowController) {
        ShowController.showLayoutSuperAdmin(tab).done(function() {
          defer.resolve();
        });
      });
      return defer.promise();
    },

    showSuperAdmin: function() {
      App.navigate('superAdmin/overview');
      this.showOverviewTabSuperAdmin();
    },

    showOverviewTabSuperAdmin: function() {
      var that = this;

      this.checkContent().done(function() {
        that.checkLayout('overview').done(function() {
          require([
            'superAdminOverviewController'
          ], function(OverviewController) {
            OverviewController.showSuperAdminTab();
            App.startSubModule('superAdminMain');
            App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_OVERVIEW);
          });
        });
      });
    },

    showCaccountsSuperadmin: function() {
        var that = this;

        this.checkContent().done(function() {
            that.checkLayout('overview').done(function() {
              require([
                'superAdminCAccountsController'
              ], function(CaccountsController) {
                  CaccountsController.showCaccountList({
                      type: 'caccounts'
                  });
                App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_CACCOUNTS);
              });
            });
        });
    },

    showMbAnasSuperadminList: function() {
      this.showMbAnasSuperadmin(false);
    },

    showMbAnasSuperadminNew: function() {
      this.showMbAnasSuperadmin(true);
    },

    showMbAnasSuperadmin: function(openNewPopup) {
      var that = this;

      this.checkContent().done(function() {
        that.checkLayout('overview').done(function() {
          require([
            'superAdminMbAnasController'
          ], function(MbAnasController) {
            MbAnasController.showMbAnaList({
              type: 'mbanas'
            });
            App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_MBANAS);

            if (openNewPopup) {
              MbAnasController.showMbAnaAdd();
            } else {
              if (MbAnasController.createEditView) {
                MbAnasController.createEditView.hide(true);
              }
            }
          });
        });
      });
    },

    showMbAnasSuperAdminDetails: function(mbanaSecId, tab) {
      var that = this;
      if (!tab) {
        tab = 'details';
      }
      this.checkContent().done(_.bind(function() {
        that.checkLayout('overview').done(_.bind(function() {
          require([
            'superAdminMbAnaDetailsController'
          ], function(SuperAdminMbAnaDetailsController) {
            SuperAdminMbAnaDetailsController.fetchMbAna({
              secId: mbanaSecId,
              tab: _.findWhere(SuperAdminMbAnasTabs, {name: tab})
            });
            App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_MBANAS);
          });
        }, this));
      }, this));
    },

    showMbAnaresSuperAdminDetails: function(mbanaresSecId, tab) {
      this.checkContent().done(_.bind(function() {
        require([
          'superAdminMbAnaResDetailsController'
        ], function(superAdminMbAnaResDetailsController) {
          if (!tab) {
            tab = _.findWhere(SuperAdminMbAnaResTabs, {isDefault: true});
          } else {
            tab = _.findWhere(SuperAdminMbAnaResTabs, {name: tab});
          }
          superAdminMbAnaResDetailsController.fetchMbAnaRes({
            secId: mbanaresSecId,
            tab: tab
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_MBANARESGRS);
          if (SuperAdminMbAnaResController.createEditView) {
            SuperAdminMbAnaResController.createEditView.hide();
          }
        });
      }, this));
    },

    showMbSmpMatsSuperadminList: function() {
      this.showMbSmpMatsSuperadmin(false);
    },

    showMbSmpMatsSuperadminNew: function() {
      this.showMbSmpMatsSuperadmin(true);
    },

    showMbSmpMatsSuperadmin: function(openNewPopup) {
      var that = this;

      this.checkContent().done(function() {
        that.checkLayout('overview').done(function() {
          require([
            'superAdminMbSmpMatsController'
          ], function(MbSmpMatsController) {
            MbSmpMatsController.showMbSmpMatList({
              type: 'mbsmpmats'
            });
            App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_MBSMPMATS);

            if (openNewPopup) {
              MbSmpMatsController.showMbSmpMatAdd();
            } else {
              if (MbSmpMatsController.createEditView) {
                MbSmpMatsController.createEditView.hide(true);
              }
            }
          });
        });
      });
    },

    showMbSmpMatsSuperAdminDetails: function(mbsmpmatSecId, tab) {
      var that = this;
      if (!tab) {
        tab = 'details';
      }
      this.checkContent().done(_.bind(function() {
        that.checkLayout('overview').done(_.bind(function() {
          require([
            'superAdminMbSmpMatDetailsController'
          ], function(SuperAdminMbSmpMatDetailsController) {
            SuperAdminMbSmpMatDetailsController.fetchMbSmpMat({
              secId: mbsmpmatSecId,
              tab: _.findWhere(SuperAdminMbSmpMatsTabs, {name: tab})
            });
            App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_MBSMPMATS);
          });
        }, this));
      }, this));
    },

    showMbAnaResGrsSuperadminList: function() {
      this.showMbAnaResGrsSuperadmin(false);
    },

    showMbAnaResGrsSuperadminNew: function() {
      this.showMbAnaResGrsSuperadmin(true);
    },

    showMbAnaResGrsSuperadmin: function(openNewPopup) {
      var that = this;

      this.checkContent().done(function() {
        that.checkLayout('overview').done(function() {
          require([
            'superAdminMbAnaResGrsController'
          ], function(MbAnaResGrsController) {
            MbAnaResGrsController.showMbAnaResGrList({
              type: 'mbanaresgrs'
            });
            App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_MBANARESGRS);

            if (openNewPopup) {
              MbAnaResGrsController.showMbAnaResGrAdd();
            } else {
              if (MbAnaResGrsController.createEditView) {
                MbAnaResGrsController.createEditView.hide(true);
              }
            }
          });
        });
      });
    },

    showMbAnaResGrsSuperAdminDetailsNew: function(mbanaGrSecId, tab, mbAnaSecId) {
      this.showMbAnaResGrsSuperAdminDetailsParam(mbanaGrSecId, tab, true, mbAnaSecId);
    },

    showMbAnaResGrsSuperAdminDetails: function(mbanaGrSecId, tab, mbAnaSecId) {
      this.showMbAnaResGrsSuperAdminDetailsParam(mbanaGrSecId, tab, false, mbAnaSecId);
    },

    showMbAnaResGrsSuperAdminDetailsParam: function(mbanaGrSecId, tab, newItem, mbAnaSecId) {
      var that = this;
      this.checkContent().done(_.bind(function() {
        that.checkLayout('overview').done(_.bind(function() {
          require([
            'superAdminMbAnaResGrDetailsController'
          ], function(SuperAdminMbAnaResGrDetailsController) {
            if (!tab) {
              tab = _.findWhere(SuperAdminMbAnaResGrsTabs, {isDefault: true});
            } else {
              tab = _.findWhere(SuperAdminMbAnaResGrsTabs, {name: tab});
            }
            SuperAdminMbAnaResGrDetailsController.fetchMbAnaResGr({
              secId: mbanaGrSecId,
              tab: tab
            });
            App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_MBANARESGRS);
            if (newItem) {
              SuperAdminMbAnaResController.showMbAnaResAdd(mbanaGrSecId);
            } else if (mbAnaSecId) {
              SuperAdminMbAnaResController.showMbAnaResEdit(mbanaGrSecId, mbAnaSecId);
            }
          });
        }, this));
      }, this));
    },

    showMbAModelsSuperadminList: function() {
      this.showMbAModelsSuperadmin(false);
    },

    showMbAModelsSuperadminNew: function() {
      this.showMbAModelsSuperadmin(true);
    },

    showMbAModelsSuperadmin: function(openNewPopup) {
      var that = this;

      this.checkContent().done(function() {
        that.checkLayout('overview').done(function() {
          require([
            'superAdminMbAModelsController'
          ], function(MbAModelsController) {
            MbAModelsController.showMbAModelList({
              type: 'mbamodels'
            });
            App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_MBAMODELS);

            if (openNewPopup) {
              MbAModelsController.showMbAModelAdd();
            } else {
              if (MbAModelsController.createEditView) {
                MbAModelsController.createEditView.hide(true);
              }
            }
          });
        });
      });
    },

    showMbAModelsSuperAdminDetails: function(mbAModelSecId, tab) {
      var that = this;
      this.checkContent().done(_.bind(function() {
        that.checkLayout('overview').done(_.bind(function() {
          require([
            'superAdminMbAModelDetailsController'
          ], function(SuperAdminMbAModelDetailsController) {
            SuperAdminMbAModelDetailsController.fetchMbAModel({
              secId: mbAModelSecId,
              tab: _.findWhere(SuperAdminMbAModelsTabs, {name: tab})
            });
            App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_MBAMODELS);
          });
        }, this));
      }, this));
    },

    showCAccountDetails: function(secId, tab) {
        var that = this;
        this.checkContent().done(_.bind(function() {
            that.checkLayout('overview').done(_.bind(function() {
              require([
                'superAdminCAccountController'
              ], function(CaccountController) {
                  CaccountController.fetchCaccount({
                      secId: secId,
                      tab: _.findWhere(SuperAdminCAccountTabs, {name: tab})
                  });
                  App.execute('set:active:nav:item', ItemPagesNameMixin.SUPERADMIN_CACCOUNTS);
              });
            }, this));
        }, this));
    }
  };

  App.on('superAdmin:show:cAccountDetails', function(secId){
      App.navigate('superAdmin/caccount/' + secId);
      API.showCAccountDetails(secId);
  });

  App.on('superAdmin:show', function() {
    App.navigate('superAdmin');
    API.showSuperAdmin();
  });

  App.on('before:start', function() {
    new SuperAdminRouter({
      controller: API
    });
  });

  module.exports = SuperAdminRouter;
});
