/**
 * Created by bfr on 30/01/2017.
 */
define('runTemplatesMain',[
    'module',
    'logger',
    'runTemplatesMainRouter'
], function(
    module,
    Logger
) {
    'use strict';

    var RunTemplates = {
        start: function() {
            Logger.log(module, 'starting RunTemplatesModule');
        },

        stop: function() {
            Logger.log(module, 'stopping RunTemplatesModule');
        }
    };

    module.exports = RunTemplates;
});
