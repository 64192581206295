/**
 * Created by RKL on 18/08/2015.
 */
define('customerProdTemplatesGroupItemView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'template!customerProdTemplateGroupsTabTpl',
  'entities/caccounts/templates'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  viewTpl
) {
  'use strict';

  var CustomersProducTemplatesTabGroupsItemView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      count: '.templates-count',
      templateGroupItem: '.template-group-item'
    },

    events: {
      'click @ui.templateGroupItem': 'showTemplateGroup'
    },

    serializeData: function () {
      var models = this.model.attributes,
        templateData = models[0].toJSON();

      this.models = models;
      templateData.templatesCount = _.toArray(models).length;
      templateData.active = models[0].get('productCode') === this.options.data.secId;

      return templateData;
    },

    showTemplateGroup: function() {
      App.trigger(
        'customer-template:show-templates',
        this.models[0].get('productCode')
      );
    }
  });

  module.exports = CustomersProducTemplatesTabGroupsItemView;
});
