
define('template!kitProtDetailTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="well kit-detail">\n  <div class="detailed-info-form-container">\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( code ? code : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( name ? name : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( description ? description : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitprot.resGraph') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( resGraph ? resGraph : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitprot.channels') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( channels ? channels : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitprot.onlyExtractSamples') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">\n          <div class="checkbox checkbox-primary detail-checkbox">\n            <label>\n              <input disabled="disabled" type="checkbox" class="js-ticket-urgent" ' +
((__t = ( onlyExtractSamples ? 'checked' : '' )) == null ? '' : __t) +
' />\n            </label>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitprot.runNamePattern') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( runNamePattern ? runNamePattern : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitprot.defaultNbColumns') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( defaultNbColumns ? defaultNbColumns : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('kitprot.defaultNbLines') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( defaultNbLines ? defaultNbLines : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="pull-right p-r-15">\n        <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

