define('wellDisplayGraphView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!wellDisplayGraphTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'settings',
  'jquery',
  'wellsGraphView',
  'createEditRunAtLayoutView',
  'entities/runats'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView,
  Settings,
  $,
  WellsGraphView,
  CreateEditRunAtLayoutView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    models: [],

    ui: {
      cbxResult: '.cbx-result',
      cbxStatus: '.cbx-status',
      cbxCtrl: '.cbx-ctrl',
      legendRadio: '.legend-radio'
    },

    events: {
      'click .js-confirm': 'onConfirm',
      'change @ui.cbxResult' : 'onChangeCbx',
      'change @ui.cbxStatus' : 'onChangeCbx',
      'change @ui.cbxCtrl' : 'onChangeCbx',
      'click .btn-edit-runat': 'onClickBtnEditRunAt',
      'change @ui.legendRadio': 'onLegendRadioChange'
    },

    regions: {
      runGraph: '#run-graph-region',
      runGraph1: '#run-graph1-region',
      runGraph2: '#run-graph2-region',
      runAmplGraph: '#run-ampl-graph-region',
      runAmplGraph1: '#run-ampl-graph1-region',
      runAmplGraph2: '#run-ampl-graph2-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
    ],

    initialize: function(options) {
      this.models = options.models;
      this.currentModels = this.models;
      this.legend = null;
      this.listenTo(App, 'runat:header:rerender', _.bind(this.onRunAtChange, this));
    },

    serializeData: function() {
      var result,templateData = {}, status, ctrl;
      this.results = [];
      this.statuss = [];
      this.ctrls = [];

      _.each(this.models, _.bind(function(model) {
          result = model.get('res' + this.channelToDisplay + 'Code');
          status = model.get('res' + this.channelToDisplay + 'CodeErr');
          if (!status) {
              status = 'O';
          }
          if (model.get('smptype') === 'C') {
              ctrl = model.get('smpid');
              if (!this.ctrls.includes(ctrl)) {
                this.ctrls.push(ctrl);
              }
          }

          if (result && !this.results.includes(result)) {
              this.results.push(result);
          }
          if (!this.statuss.includes(status)) {
              this.statuss.push(status);
          }

      }, this));

      this.ctrls.unshift('SMP');

      templateData.newItem = this.options.newItem;
      templateData.displayAmpl = this.graphToDisplay.indexOf('A') !== -1;
      templateData.displayMelt = this.graphToDisplay.indexOf('M') !== -1;
      templateData.results = this.results;
      templateData.statuss = this.statuss;
      templateData.ctrls = this.ctrls;
      return templateData;
    },

    onLegendRadioChange: function() {
      if(this.ui.legendRadio) {
          _.each(this.ui.legendRadio, _.bind(function(radio) {
              if (radio.checked) {
                  this.legend = radio.value;
              }
          }, this));

          this.renderGraphics(this.currentModels);
      }
    },

    onRunAtChange: function(runAt) {
      this.runAt = runAt;
    },

    onChangeCbx: function() {
      var resultToDisplay = [], statusToDisplay = [], ctrlToDisplay = [], result, models = [], status;
      _.each(this.ui.cbxResult, _.bind(function(cbx) {
        cbx = $(cbx);
        result = cbx.data('field-name');
        if (cbx.is(':checked')) {
          resultToDisplay.push(result);
        }
      }, this));

      _.each(this.ui.cbxStatus, _.bind(function(cbx) {
          cbx = $(cbx);
          result = cbx.data('field-name');
          if (cbx.is(':checked')) {
              statusToDisplay.push(result);
          }
      }, this));

      _.each(this.ui.cbxCtrl, _.bind(function(cbx) {
          cbx = $(cbx);
          result = cbx.data('field-name');
          if (cbx.is(':checked')) {
              ctrlToDisplay.push(result);
          }
      }, this));

      _.each(this.models, _.bind(function(model) {
          status = model.get('res' + this.channelToDisplay + 'CodeErr');
          if (!status) {
            status = 'O';
          }
        if (resultToDisplay.includes(model.get('res' + this.channelToDisplay + 'Code')) &&
            statusToDisplay.includes(status) && ((ctrlToDisplay.includes('SMP') && model.get('smptype') !== 'C') ||
            (model.get('smptype') === 'C' && ctrlToDisplay.includes(model.get('smpid'))))) {
          models.push(model);
        }
      }, this));

      this.currentModels = models;

      this.renderGraphics(models);

    },

    onClickBtnEditRunAt: function() {
      this.createEditRunAtView = new CreateEditRunAtLayoutView({
          model: App.request('runat:model', this.runAt),
          modelRun: this.model,
          newItem: false
      });
      this.createEditRunAtView.show({
          title: _.i18n('runat.edit')
      });
    },

    onShow: function() {
      $.material.init();

      this.ui.cbxResult.prop('checked', true);
      this.ui.cbxStatus.prop('checked', true);
      this.ui.cbxCtrl.prop('checked', true);

      $('#main-region').addClass('hidden-print');
      this.renderGraphics(this.models);

      this.triggerMethod('enable:cancel:confirm');
    },

    renderGraphics: function(models) {
        var region;

        if (this.graphToDisplay.indexOf('M') !== -1) {
            this.graphView = new WellsGraphView({type: 'line'});
            this.graphView.models = models;
            this.graphView.modelRun = this.model;
            this.graphView.slopeEnable = true;
            this.graphView.brutEnable = false;
            this.graphView.channelToDisplay = this.channelToDisplay;
            this.graphView.legend = this.legend;

            region = this.getRegion('runGraph');
            if (region) {
                region.show(this.graphView);
            }

            this.graph1View = new WellsGraphView({type: 'line'});
            this.graph1View.models = models;
            this.graph1View.modelRun = this.model;
            this.graph1View.slopeEnable = true;
            this.graph1View.brutEnable = false;
            this.graph1View.deriv2Enable = true;
            this.graph1View.channelToDisplay = this.channelToDisplay;
            this.graph1View.legend = this.legend;

            region = this.getRegion('runGraph1');
            if (region) {
                region.show(this.graph1View);
            }

            this.graph2View = new WellsGraphView({type: 'line'});
            this.graph2View.models = models;
            this.graph2View.modelRun = this.model;
            this.graph2View.slopeEnable = false;
            this.graph2View.brutEnable = true;
            this.graph2View.channelToDisplay = this.channelToDisplay;
            this.graph2View.legend = this.legend;

            region = this.getRegion('runGraph2');
            if (region) {
                region.show(this.graph2View);
            }
        }

        if (this.graphToDisplay.indexOf('A') !== -1) {
            this.amplGraphView = new WellsGraphView({type: 'line'});
            this.amplGraphView.models = models;
            this.amplGraphView.modelRun = this.model;
            this.amplGraphView.slopeEnable = false;
            this.amplGraphView.brutEnable = true;
            this.amplGraphView.amplGraph = true;
            this.amplGraphView.channelToDisplay = this.channelToDisplay;
            this.amplGraphView.legend = this.legend;

            if (this.runAt) {
                this.amplGraphView.baseline = this.runAt.baseline;
                this.amplGraphView.threshold = this.runAt.threshold;
            }

            region = this.getRegion('runAmplGraph');
            if (region) {
                region.show(this.amplGraphView);
            }

            this.amplGraph1View = new WellsGraphView({type: 'line'});
            this.amplGraph1View.models = models;
            this.amplGraph1View.modelRun = this.model;
            this.amplGraph1View.slopeEnable = true;
            this.amplGraph1View.brutEnable = false;
            this.amplGraph1View.amplGraph = true;
            this.amplGraph1View.channelToDisplay = this.channelToDisplay;
            this.amplGraph1View.legend = this.legend;

            region = this.getRegion('runAmplGraph1');
            if (region) {
                region.show(this.amplGraph1View);
            }

            this.amplGraph2View = new WellsGraphView({type: 'line'});
            this.amplGraph2View.models = models;
            this.amplGraph2View.modelRun = this.model;
            this.amplGraph2View.slopeEnable = true;
            this.amplGraph2View.brutEnable = false;
            this.amplGraph2View.deriv2Enable = true;
            this.amplGraph2View.amplGraph = true;
            this.amplGraph2View.channelToDisplay = this.channelToDisplay;
            this.amplGraph2View.legend = this.legend;

            region = this.getRegion('runAmplGraph2');
            if (region) {
                region.show(this.amplGraph2View);
            }
        }
    },

    onConfirm: function() {
      this.triggerMethod('saved');
      this.hide();
    },

    onSave: function() {

    },

    hide: function() {
      $('#main-region').removeClass('hidden-print');
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.triggerMethod('hide:label');
      }
    }
  });

  module.exports = CreateEditLayoutView;
});
