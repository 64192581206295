define('adminKitTabs',[
  'module',
  'underscore'
], function(
  module,
  _
) {
  'use strict';

  module.exports = [
    {
      name: 'detail',
      title: _.i18n('kits.details.tabTitle')
    }, {
      name: 'assay',
      title: _.i18n('kits.assay.tabTitle'),
      isDefault: true
    }, {
      name: 'kitl',
      title: _.i18n('kits.kitl.tabTitle')
    }, {
      name: 'kitc',
      title: _.i18n('kits.kitc.tabTitle')
    }
  ];
});
