define('customersContactsInfoTabView',[
  'module',
  'marionette',
  'template!customersContactsInfoTabTpl'
], function(
  module,
  Marionette,
  infoTabTpl
) {
  'use strict';

  var CustomersContactsInfoTabView = Marionette.ItemView.extend({
    template: infoTabTpl,

    triggers: {
      'click @ui.editBtn' : '_onEditContactInfo'
    },

    ui: {
      editBtn: '.js-edit'
    },

    serializeData: function() {
      return this.model.toJSON();
    }
  });

  module.exports = CustomersContactsInfoTabView;
});
