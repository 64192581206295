
define('template!thankYouViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n  <div class="well login-form-container">\n    <form class="form-horizontal">\n      <fieldset>\n        <div class="top-login-part text-center">\n          <a class="login-view-link">\n            <div class="avatar-thank-you-link-img"></div>\n          </a>\n          <h3 class="thank-you-title">' +
((__t = ( _.i18n("pages.messages.thankyou") )) == null ? '' : __t) +
'</h3>\n        </div>\n        <div class="form-group message-container">\n          <div class="col-lg-12 text-center">\n            <p class="thank-you-message"></p>            \n          </div>\n        </div>\n        <div class="form-group login-footer">\n          <div class="col-lg-12 text-center">\n            <p>Genefox</p>\n            <p>&#169; 2018</p>\n          </div>\n        </div>\n      </fieldset>\n    </form>\n  </div>\n</div>';

}
return __p
};});

