
define('template!customerDatasetsTableTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<table class="table dataset-row-region">\n\t<thead>\n\t\t<tr>\n\t\t  <th>Name</th>\n\t\t  <th>Value</th>\n\t\t  <th></th>\n\t\t</tr>\n\t</thead>\n\t<tbody></tbody>\n</table>';

}
return __p
};});

