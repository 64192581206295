/**
 * Created by RKL on 29/09/2015.
 */
define('customerProdTemplatesElController',[
  'module',
  'backbone',
  'underscore',
  'app',
  'bootbox',
  'settings' ,
  'galleryView',
  'elementsController',
  'customerProdTemplatesCollectionView',
  'entities/customers/product_templates'
], function(
  module,
  Backbone,
  _,
  App,
  bootbox,
  Settings,
  GalleryView,
  ElementsController,
  ElementsView
) {
  'use strict';

  var DesignElementsController = _.defaults({

    requests: {
      modelRequest: 'customer:product-templates-model'
    },

    displayElements: function(data, collection) {
      var that = this,
        newModel = App.request(this.requests.modelRequest),
        selectedItems;

      this.setValues(data);

      if (data.groupModel.elCollection) {
        this.collection = data.groupModel.elCollection;
        this.showElements(ElementsView);
      } else {
        _.each(collection.models, function(model) {
          if (model.id === 0) { return; }
          if (model.attributes[0].get('productCode') === that.groupId) {
            selectedItems = model.attributes;
          }
        });
        this.collection = new Backbone.Collection(_.toArray(selectedItems));
        newModel.set('productCode', this.groupId);
        this.collection.add(newModel, {at: 0});

        this.showElements(ElementsView);
      }

      $(window).on('resize', _.bind(this.checkResize, this));
    },

    onShowView: function() {
      var windowHeight = $(window).height(),
        headerHeight = $('header').height(),
        detailsHeaderHeight = $('.caccount-details-header').height(),
        height = windowHeight - headerHeight - detailsHeaderHeight - 105;

      this.elementsView.ui.scrollContainer.height(height);
      this.elementsView.ui.scrollBlock.hide();
    },

    subscribeToEvents: function() {
      App.off('producttemplate:zoom');
      App.on(
        'producttemplate:zoom',
        _.bind(this.onZoom, this)
      );
    },

    getLink: function (item) {
      return Settings.url(
        'file',
        item.get('snapshotsUids')
      );
    },

    getFileName: function (item) {
      return item.get('name');
    },

    hasSize: function () {
      return false;
    },
    getId: function (item) {
      return item.get('id');
    }

  }, ElementsController);

  module.exports = DesignElementsController;
});
