define('smpResListTabView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!smpResListTabTpl'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl
    ) {
    'use strict';

    var SmpResListView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'smpresListFilters',

        triggers: {
        },

        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteSmpRes: '.delete-smpres-link',
                editSmpRes: '.edit-smpres-link'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click @ui.deleteSmpRes': 'onDeleteSmpRes',
                'click @ui.editSmpRes': 'onEditSmpRes'
            }, this.gridEvents);
        },

        serializeData: function() {
            var templateData = {
                type: 'smpres',
                data: this.options.data
            };
            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: Settings.get('perPage15'),
                currentPage: 1
            };
        },

        onDeleteSmpRes: function(event){
            this.trigger('smpres:delete', this.options.data.model.get('secId'), $(event.currentTarget).find('span').attr('data-row-id'));
        },

        onEditSmpRes: function(event) {
            this.trigger('smpres:edit', this.options.data.model.get('secId'), $(event.currentTarget).find('span').attr('data-row-id'));
        },

        gridOptions: function(data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('smpres.refLmbAna'),
                        name: 'refLmbAna.code',
                        classes: 'smpres-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'refLmbAna.code'
                    }, {
                        label: _.i18n('smpres.resSt'),
                        name: 'resSt',
                        classes: 'smpres-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'resSt'
                    }, {
                        label: _.i18n('smpres.orderedSt'),
                        name: 'orderedSt',
                        classes: 'smpres-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'orderedSt'
                    }, {
                        label: ' ',
                        name: 'delete',
                        search: false,
                        classes: 'delete-smpres-link delete-link',
                        formatter: this.deleteIconFormatter,
                        width: 20
                    }
                ],

                onSelectRow: function(rowId, bool, e) {
                    if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
                        if (e.type === 'click') {
                            App.navigate('smpres/' + rowId, {trigger: true});
                        }
                    }
                }
            };

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        }
    });

    module.exports = SmpResListView;
});
