
define('template!wellTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-show-result-cell wellSelectableItem" ' +
((__t = ( (displayResult && error)?'style="background-color:pink !important; width:100%; height:48px;"':'' )) == null ? '' : __t) +
'\n     ' +
((__t = ( (displayResult && warning)?'style="background-color:mistyrose !important; width:100%; height:48px;"':'' )) == null ? '' : __t) +
'>\n    <div class="well-result-icons p-l-0 p-r-0 col-xs-2 h-16"></div>\n    <span class="col-xs-10 ' +
((__t = ( well.smptype === 'C'? 'normall-bold-primary-compuzz' : 'small-regular-compuzz')) == null ? '' : __t) +
' p-l-2 p-r-0 h-16"\n    ' +
((__t = ( well.smptype === 'C'? 'style="background-color:grey !important; color:white;"':'')) == null ? '' : __t) +
'>' +
((__t = ( well.smpid )) == null ? '' : __t) +
'</span>\n    <div class="' +
((__t = ( (displayResult && ok)?'mdi-action-done':'')) == null ? '' : __t) +
' well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: green;"></div>\n    <span class="col-xs-10 small-regular-compuzz p-l-2 p-r-0 h-16">' +
((__t = ( well.refAssay?well.refAssay.code:'' )) == null ? '' : __t) +
'</span>\n    ';
 if (displayResult && error) { ;
__p += '\n        <div class="mdi-alert-error well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: red;"></div>\n    ';
 } else if (displayResult && warning) { ;
__p += '\n        <div class="mdi-alert-warning well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: orange;"></div>\n    ';
 } else { ;
__p += '\n        <div class="well-result-icons p-l-0 p-r-0 col-xs-2 h-16"></div>\n    ';
 } ;
__p += '\n    <div class="col-xs-10 container-fluid h-16">\n        <div class="row m-b-0">\n            ';
 if (displayResult) { ;
__p += '\n                <div class="col-xs-1 p-l-0 p-r-16-per tooltip1">\n                    ';
 if (color1) { ;
__p += '\n                    <div class="circleResult circleResult1" style="position: absolute; background-color: ' +
((__t = ( color1 )) == null ? '' : __t) +
' !important;"></div>\n                    ';
 } ;
__p += '\n                    ';
 if (well.res1CodeErr === 'E' || well.res1CodeErr === 'W') { ;
__p += '\n                        <div class="bigSquare" style="background-color: ' +
((__t = ( well.res1CodeErr==='E'?'#000000;':'orange' )) == null ? '' : __t) +
' !important;"></div>\n                        <span class="tooltiptext">' +
((__t = ( well.res1Comment )) == null ? '' : __t) +
'</span>\n                    ';
 } ;
__p += '\n                </div>\n                <div class="col-xs-1 p-l-0 p-r-16-per tooltip1">\n                    ';
 if (color2) { ;
__p += '\n                        <div class="circleResult circleResult2" style="position: absolute; background-color: ' +
((__t = ( color2 )) == null ? '' : __t) +
' !important;"></div>\n                    ';
 } ;
__p += '\n                    ';
 if (well.res2CodeErr === 'E' || well.res2CodeErr === 'W') { ;
__p += '\n                        <div class="bigSquare" style="background-color: ' +
((__t = ( well.res2CodeErr==='E'?'#000000;':'orange' )) == null ? '' : __t) +
' !important;"></div>\n                        <span class="tooltiptext">' +
((__t = ( well.res2Comment )) == null ? '' : __t) +
'</span>\n                    ';
 } ;
__p += '\n                </div>\n                <div class="col-xs-1 p-l-0 p-r-16-per tooltip1">\n                    ';
 if (color3) { ;
__p += '\n                        <div class="circleResult circleResult3" style="position: absolute; background-color: ' +
((__t = ( color3 )) == null ? '' : __t) +
' !important;"></div>\n                    ';
 } ;
__p += '\n                    ';
 if (well.res3CodeErr === 'E' || well.res3CodeErr === 'W') { ;
__p += '\n                        <div class="bigSquare" style="background-color: ' +
((__t = ( well.res3CodeErr==='E'?'#000000;':'orange' )) == null ? '' : __t) +
'!important;"></div>\n                        <span class="tooltiptext">' +
((__t = ( well.res3Comment )) == null ? '' : __t) +
'</span>\n                    ';
 } ;
__p += '\n                </div>\n                <div class="col-xs-1 p-l-0 p-r-16-per tooltip1">\n                    ';
 if (color4) { ;
__p += '\n                        <div class="circleResult circleResult4" style="position: absolute; background-color: ' +
((__t = ( color4 )) == null ? '' : __t) +
'!important;"></div>\n                    ';
 } ;
__p += '\n                    ';
 if (well.res4CodeErr === 'E' || well.res4CodeErr === 'W') { ;
__p += '\n                        <div class="bigSquare" style="background-color: ' +
((__t = ( well.res4CodeErr==='E'?'#000000;':'orange' )) == null ? '' : __t) +
'!important;"></div>\n                        <span class="tooltiptext">' +
((__t = ( well.res4Comment )) == null ? '' : __t) +
'</span>\n                    ';
 } ;
__p += '\n                </div>\n                <div class="col-xs-1 p-l-0 p-r-16-per tooltip1">\n                    ';
 if (color5) { ;
__p += '\n                        <div class="circleResult circleResult5" style="position: absolute; background-color: ' +
((__t = ( color5 )) == null ? '' : __t) +
'!important;"></div>\n                    ';
 } ;
__p += '\n                    ';
 if (well.res5CodeErr === 'E' || well.res5CodeErr === 'W') { ;
__p += '\n                        <div class="bigSquare" style="background-color: ' +
((__t = ( well.res5CodeErr==='E'?'#000000;':'orange' )) == null ? '' : __t) +
'!important;"></div>\n                        <span class="tooltiptext">' +
((__t = ( well.res5Comment )) == null ? '' : __t) +
'</span>\n                    ';
 } ;
__p += '\n                </div>\n                <div class="col-xs-1 p-l-0 p-r-16-per tooltip1">\n                    ';
 if (color6) { ;
__p += '\n                        <div class="circleResult circleResult6" style="position: absolute; background-color: ' +
((__t = ( color6 )) == null ? '' : __t) +
'!important"></div>\n                    ';
 } ;
__p += '\n                    ';
 if (well.res6CodeErr === 'E' || well.res6CodeErr === 'W') { ;
__p += '\n                        <div class="bigSquare" style="background-color: ' +
((__t = ( well.res6CodeErr==='E'?'#000000;':'orange' )) == null ? '' : __t) +
'!important;"></div>\n                        <span class="tooltiptext">' +
((__t = ( well.res6Comment )) == null ? '' : __t) +
'</span>\n                    ';
 } ;
__p += '\n                </div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n    <span class="empty-circle item-selector-circle multi-selector">\n      <i class="mdi-action-check-circle icon"></i>\n    </span>\n</div>';

}
return __p
};});

