/**
 * Created by RKL on 17/09/2015.
 */
define('customerLogosImagesExistPopupView',[
  'module',
  'underscore',
  'settings',
  'template!customerLogosImagesExistPopupTpl',
  'dialogView'
], function (
  module,
  _,
  Settings,
  viewTpl,
  DialogView
) {
  'use strict';

  var CustomersLogosImagesTabPopupViewFromExisting = DialogView.extend({
    template: viewTpl,

    ui: {
      attachment: '.js-attachment'
    },

    events: {
      'click @ui.attachment': 'onSelectAttachment'
    },

    serializeData: function() {
      var templateData = {},
        params = {
          action: 'res',
          params: 't'
        };

      templateData.models = this.collection.models;
      _.each(templateData.models, function (model) {
          model.set('fileLink', Settings.url('file', model.get('fileUid'), params));
      });
      return templateData;
    },

    onSelectAttachment: function (e) {
      var target = $(e.currentTarget),
        attachId = target.data('attachment-id');

      this.options.callback(attachId);
    }
  });

  module.exports = CustomersLogosImagesTabPopupViewFromExisting;
});
