define( 'entities/customers/product_templates',[
  'app',
  'underscore',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  _,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var Template = Backbone.Model.extend({
  });

  var ElementTemplate = Backbone.Model.extend({
    defaults: {
      id: 0,
      productCode: null
    },

    deleteUrl: function(poi) {
      return Settings.url('compuzz', 'commands.json/corporate/' + poi);
    }
  });

  /**
   * Template
   */
  var Templates = Backbone.Collection.extend({
    model: Template
  });

  var ElTemplates = Backbone.Collection.extend({
    model: ElementTemplate
  });

  /**
   * REST API
   */
  var API = {
    getCollection: function(endUserId) {
      var that = this,
        defer = $.Deferred(),
        collection = new ElTemplates(),
        params = {
          endUserId: endUserId,
          types: 'corporateTemplate',
          first: '0',
          pageSize: '100'
        },
        urlPart = 'commands.json/advancedSearch';

      collection.url = Settings.url('compuzz', urlPart, params);
      collection.fetch().done(function() {
        var promises = _.map(collection.models, function (model) {
          //For each corporate template, retreive compositions info
          return that.fetchCompositionData(model.get('purchaseOrderId'))
            .then(function (list) {
              model.set('compostionData', list);
            });
        });

        $.when.apply($.when, promises).then(function () {
          var grid = collection.groupBy(function(item) {
              return item.get('productCode');
            });

          collection = new Templates(_.toArray(grid));
          defer.resolve(collection);
        });
      });

      return defer.promise();
    },

    fetchCompositionData: function(poi) {
      var url = Settings.url('compuzz', 'commands.json/' + poi +
          '/compositions'),
        defer = $.Deferred();

      $.ajax({
        url: url,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getModel: function() {
      return new ElementTemplate();
    },

    countCorporateTemplates: function (endUserId, productCode) {
      var params = {
          endUserId: endUserId,
          productCode: productCode,
          types: 'corporateTemplate'
        },
        url = Settings.url(
          'compuzz',
          'commands.json/advancedSearch/rowCount',
          params
        ),
        defer = $.Deferred();

      $.ajax({
        url: url,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    deleteCorporateTemplate: function(poi) {
      var url = Settings.url('compuzz', 'commands.json/corporate/' + poi),
        defer = $.Deferred();

      $.ajax({
        url: url,
        type: 'DELETE',
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('customer:product-templates', function(endUserId) {
    return API.getCollection(endUserId);
  });

  App.reqres.setHandler('customer:product-templates-model', function() {
    return API.getModel();
  });

  App.reqres.setHandler('customer:count-templates', function(id, code) {
    return API.countCorporateTemplates(id, code);
  });

  App.reqres.setHandler('customer:product-templates-delete', function(poi) {
    return API.deleteCorporateTemplate(poi);
  });
});
