
define('template!customerTemplatesNewItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="main text-center">\n  <div class="image-holder js-image-holder pgallery_item">\n    <i class="mdi-action-perm-media icon"></i>\n    <i class="mdi-content-add abs-icon-addon"></i>\n  </div>\n</div>';

}
return __p
};});

