
define('template!customerProdTemplatesPopupAddNewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n\t<form>\n\t\t<div class="container-fluid">\n\t\t\t<div class="row form-group text-center">\n\t\t\t\t<div class="col-sm-12 select-product">\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</form>\n</div>';

}
return __p
};});

