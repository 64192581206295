/**
 * Created by RKL on 18/08/2015.
 */
define('customerTemplatesNewItemView',[
  'module',
  'marionette',
  'backbone',
  'underscore',
  'app',
  'settings',
  'customerTemplatesPopupAddNewView',
  'customerTemplatesPopupEditView',
  'customerTemplatesPopupAddEmptyView',
  'customerTemplatesPopupAttachView',
  'dialogView',
  'template!customerTemplatesNewItemTpl',
  'entities/caccounts/templates',
  'entities/caccounts/attachments'
], function (
  module,
  Marionette,
  Backbone,
  _,
  App,
  Settings,
  AddNewView,
  EditEmptyView,
  AddEmptyView,
  FromAttachView,
  DialogView,
  viewTpl
) {
  'use strict';

  var CAccountTemplatesTabNewItemView = Marionette.ItemView.extend({
    template: viewTpl,

    events: {
      'click': 'onAddNewTemplate'
    },

    tagName: 'div',

    className: 'original-img-wrapper zoom-img template-item template-new-item',

    onAddNewTemplate: function() {
      this.addNewView = new AddNewView({
        refTemplateGroup: this.model.get('refTemplateGroup'),
        endUserId: this.model.get('endUserId'),
        customerSecId: this.options.customerSecId,
        groupModel: this.options.groupModel,
        showFromProduct: this.options.showFromProduct,
        subdomain: this.options.subdomain,
        refParent: this.model.get('refParent'),
        templateParent: this.options.templateParent
      });
      this.addNewView.show({title: _.i18n('template.popup.addTemplate')});

      $('.modal-footer').css('text-align', 'center');
    },

    onActionSelected: function(action, formdata) {
      this.addNewView.hide();
      if (action === 'FromUpload') {
        App.trigger('template:create-from-upload', formdata);
      } else if (action === 'FromEmpty') {
        this.popupFromEmpty();
      } else if (action === 'FromAttach') {
        this.popupFromAttach();
      }
    },

    popupFromEmpty: function() {
      var newModel = this.model.clone();
      
      this.popupView = new AddEmptyView({
        model: newModel,
        templateParent: this.options.templateParent
      });

      this.popupView.show({title: _.i18n('template.popup.addFromEmpty')});
      $.material.init();
    },

    popupFromAttach: function() {
      var that = this,
        data = this.options.data,
        secId = data.type === 'customer' ? data.id : data.cAccountId;

      this.collection = App.request(
        'caccount:attachments',
        data.type,
        secId
      );

      this.collection.fetch().done(function() {
        that.popupView = new FromAttachView({
          collection: that.collection,
          callback: _.bind(that.onFromAttach, that)
        });

        that.popupView.show({
          title: _.i18n('template.popup.addFromAttachment')
        });
        $.material.init();
      });
    },

    onFromAttach: function(attachId) {
      this.popupView.hide();
      App.trigger('template:create-from-attach', attachId);
    }
  });

  module.exports = CAccountTemplatesTabNewItemView;
});
