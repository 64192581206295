
define('template!caccountAttachsCollectTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n\t<div class="row">\n\t\t<div class="loading-block js-loading-block">\n\t\t\t<span class="loading-message js-loading-message label"></span>\n\t\t</div>\n\t</div>\n\n\t<div class="scroll-container scrollable">\n\t\t<div class="attachments-container row"></div>\n\t\t<div class="lazy-load-block">\n\t\t\t<!-- loader block -->\n\t\t\t<div class="loader lazy-load-loader">\n\t\t\t<svg class="circular">\n\t\t\t\t<circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>\n\t\t\t</svg>\n\t\t  </div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

