
define('template!adminSettingListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="well jq-grid-table-page">\n        <h2>' +
((__t = ( _.i18n('setting.list') )) == null ? '' : __t) +
'</h2>\n        <div class="setting-table">\n            <div class="form-group add-new-block">\n                <a href="#admin/settings/new" class="btn new-setting-button add-btn-text">\n                    <i class="mdi-content-add"></i>\n                    <span>' +
((__t = ( _.i18n('setting.new') )) == null ? '' : __t) +
'</span>\n                </a>\n            </div>\n            <table id="jq-grid-list" class="settingTable" role="grid"></table>\n            <div id="jq-grid-pager-list"></div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

