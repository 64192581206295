/**
 * Created by RKL on 13/08/2015.
 */
define('customerInfoController',[
  'module',
  'underscore',
  'backbone',
  'app',
  'bootbox',
  'imageUtils',
  'customerInfoView',
  'emptyView',
  'settings',
  'entities/caccounts'
], function(
  module,
  _,
  Backbone,
  App,
  bootbox,
  imageUtils,
  View,
  EmptyView,
  Settings
) {
  'use strict';

  var CustomersInfoController = {
    showCustomerInfo: function(data) {
      var that = this,
        emptyView = new EmptyView(),
        fetchCountries, fetchLangs;

      this.model = data.model;
      this.tab = data.tab;

      App.regions.getRegion('main').currentView.getRegion('content').show(emptyView);

      this.data = data;
      if (!Settings.get('countries') || !Settings.get('languages')) {
        fetchCountries = App.request('caccount:countries');
        fetchLangs = App.request('caccount:languages');
        
        $.when(fetchCountries, fetchLangs).done(function(countries, langs) {
          Settings.set('countries', countries.toJSON());
          Settings.set('languages', langs.toJSON());
          that.createView();
        });
      } else {
        this.createView();
      }
      
    },

    createView: function() {
      var countries = Settings.get('countries'),
        view;

      view = new View({
        model: this.model,
        countries: countries,
        data: this.data
      });
      view.tempModel = new Backbone.Model(this.model.toJSON());
      this.view = view;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      view.listenTo(view, 'onViewLess', _.bind(this.onViewLess, this));
      view.listenTo(view, 'onChangeLogo', _.bind(this.onChangeLogo, this));
      view.listenTo(view, 'onRemoveLogo', _.bind(this.onRemoveLogo, this));
      view.listenTo(view, 'onCancel', _.bind(this.onCancel, this));
      view.listenTo(view, 'onConfirm', _.bind(this.onConfirm, this));
      view.listenTo(view, 'onDelete', _.bind(this.onDelete, this));
    },

    onViewLess: function() {
      App.trigger('customers:show', this.model.get('secId'), this.tab);
    },

    onCancel: function() {
      this.model.attributes = this.view.tempModel.toJSON();
      if (this.data.id === 'new') {
        App.trigger('customers:show');
      } else {
        App.trigger('customers:show', this.model.get('secId'), this.tab);
      }
    },

    onConfirm: function() {
      if (this.validate()) {
        this.onSave();
      }
    },

    validate: function() {
      var fieldsToValidate = [
        {name: 'companyName', type: 'required'},
        {name: 'code', type: 'required'},
        {name: 'email', type: 'email'},
        {name: 'emailCustomerService', type: 'email'}
      ];

      return this.view.validate(fieldsToValidate);
    },

    onSave: function() {
      var that = this,
        tempInfo;

      this.view.triggerMethod(
        'showMessage',
        'primary',
        _.i18n('common.saving')
      );
      this.model.url = this.model.postUrl();
      this.model.save().done(function() {
        tempInfo = that.model.toJSON();
        that.view.tempModel.attributes = tempInfo;
        App.trigger('customers:show', that.model.get('secId'), that.tab);
      }).fail(function() {
        that.view.triggerMethod('errorMessage', _.i18n('warning.systemError'));
      });
    },

    onDelete: function() {
      var that = this;

      bootbox.confirm(_.i18n('common.confirmDelete'), function (result) {
        if (result) {
          that.model.url = that.model.deleteUrl();
          that.model.destroy().done(function() {
            App.trigger('customers:show');
          });
        }
      });
    },

    onChangeLogo: function() {
      var target = this.view.ui.changeLogo[0],
        fileInfo = imageUtils.getFileInfo(target, 0);

      if (fileInfo) {
        this.model.set('extension', fileInfo.fileExt);
        imageUtils.encodeInputFileToBase64(
          target,
          0,
          _.bind(this.onImageEncoded, this)
        );
      }
    },

    onImageEncoded: function(base64Image) {
      this.view.model.set('encodedValue', base64Image);
      this.view.onUpdateLogo(base64Image);
    },

    onRemoveLogo: function() {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.model.set('extension', null);
          this.model.set('encodedValue', null);
          this.model.set('logo', null);

          this.onSave();
        }
      }, this));
    }
  };

  module.exports = CustomersInfoController;
});
