/**
 * Created by Matteo on 11/07/2015.
 */
define('headerMain',[
  'module',
  'logger',
  'app'
], function(
  module,
  Logger,
  App
) {
  'use strict';

  var Header = {
    start: function() {
      Logger.log(module, 'starting HeaderModule');
      require(['headerShowController'], function(ShowController){
        ShowController.showHeader();
      });
    },

    stop: function() {
      Logger.log(module, 'stopping HeaderModule');
    }
  };

  App.on('header:show', function() {
    Header.start();
  });

  module.exports = Header;
});
