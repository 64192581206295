
define('template!runDetailsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content info-page">\n    <div class="container-fluid">\n        <div class="col-md-5 left-area col-sm-12 well hidden-print">\n            <div class="well-table">\n\n            </div>\n        </div>\n        <div class="col-md-1 hidden-print" />\n        <div class="row col-md-6 col-sm-12">\n            <div id="right-graph" class="right-area col-sm-12 well well-left-border">\n                <div id="header-region"></div>\n                <ul class="nav nav-tabs row">\n                    <li class="nav-tab first-tab-melt tab-melt" style="display: none;">\n                        <a href="#meltCurve" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurve') )) == null ? '' : __t) +
'</a>\n                    </li>\n                    <li class="nav-tab tab-melt" style="display: none;"">\n                        <a href="#meltCurve1" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurveDeriv') )) == null ? '' : __t) +
'</a>\n                    </li>\n                    <li class="nav-tab tab-melt" style="display: none;">\n                        <a href="#meltCurve2" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurve1') )) == null ? '' : __t) +
'</a>\n                    </li>\n                    <li class="nav-tab first-tab-ampl tab-ampl" style="display: none;">\n                        <a href="#amplCurve" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurve1') )) == null ? '' : __t) +
'</a>\n                    </li>\n                    <li class="nav-tab tab-ampl" style="display: none;">\n                        <a href="#amplCurve1" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurveDeriv') )) == null ? '' : __t) +
'</a>\n                    </li>\n                    <li class="nav-tab tab-ampl" style="display: none;">\n                        <a href="#amplCurve2" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurve') )) == null ? '' : __t) +
'</a>\n                    </li>\n                </ul>\n                <div class="tab-content ">\n                    <div class="tab-pane first-tab-melt-content tab-melt-content" id="meltCurve">\n                        <div id="run-graph-region"></div>\n                    </div>\n                    <div class="tab-pane tab-melt-content" id="meltCurve1">\n                        <div id="run-graph1-region"></div>\n                    </div>\n                    <div class="tab-pane tab-melt-content" id="meltCurve2">\n                        <div id="run-graph2-region"></div>\n                    </div>\n                    <div class="tab-pane first-tab-ampl-content tab-ampl-content" id="amplCurve">\n                        <div id="run-ampl-graph-region"></div>\n                    </div>\n                    <div class="tab-pane tab-ampl-content" id="amplCurve1">\n                        <div id="run-ampl-graph1-region"></div>\n                    </div>\n                    <div class="tab-pane tab-ampl-content" id="amplCurve2">\n                        <div id="run-ampl-graph2-region"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

