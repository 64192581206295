
define('template!customerLogosImagesExistPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n\t<div class="container-fluid">\n\t\t<div class="panel panel-primary" data-id="0">\t\t\t\n\t\t\t<div class="panel-body">\n\t\t\t\t<div class="panel-content row">\n\t\t\t\t\t';
 _.each(models, function (attachment) { ;
__p += '\n\t\t\t\t\t\t<div class="img-container js-attachment m-b-10 m-r-5 " data-attachment-name="' +
((__t = ( attachment.fileName )) == null ? '' : __t) +
'" data-attachment-id="' +
((__t = ( attachment.get("secId") )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t<div class=\'original-img-wrapper\'>\n\t\t\t\t\t\t\t\t<div class="main text-center">\n\t\t\t\t\t\t\t\t\t<div class="image-texture-and-background">\n\t\t\t\t\t\t\t\t\t\t<img class="image-holder" src="' +
((__t = ( attachment.get("fileLink") )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

