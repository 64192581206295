define('entities/wells',[
  'app',
  'backbone',
  'settings'
], function(
  App,
  Backbone,
  Settings
) {
  'use strict';

  var Well = Backbone.Model.extend({
    fetch: function() {
      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      'code':  '',
      'smptype': 'U',
      'smpid': '',
      'assay': '',
      'pos': '',
      'refKitL': {}
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    getResFormated: function(index) {
      var num1 = this.get('res'+index+'Num1') || '',
        num2 = this.get('res'+index+'Num2') || '',
        num3 = this.get('res'+index+'Num3') || '';
      if (num1 || num2 || num3) {
        return '(' + num1 + ' ' + num2 + ' ' + num3 + ')';
      } else {
        return '';
      }
    },

    getErrorObject: function() {
      var errorObject = {
        error: false,
        warning: false,
        ok: false
      };

      switch(this.get('errorInd')) {
        case 'E': errorObject.error = true; break;
        case 'W': errorObject.warning = true; break;
      }

      if (!errorObject.warning && !errorObject.error && (this.get('res1CodeErr') === 'W' ||
        this.get('res2CodeErr') === 'W' || this.get('res3CodeErr') === 'W' ||
      this.get('res4CodeErr') === 'W' || this.get('res5CodeErr') === 'W' || this.get('res6CodeErr') === 'W')) {
        errorObject.warning = true;
      }

      if (!errorObject.error && (this.get('res1CodeErr') === 'E' ||
      this.get('res2CodeErr') === 'E' || this.get('res3CodeErr') === 'E' ||
      this.get('res4CodeErr') === 'E' || this.get('res5CodeErr') === 'E' || this.get('res6CodeErr') === 'E')) {
        errorObject.warning = false;
        errorObject.error = true;
      }

      if (!errorObject.error && !errorObject.warning) {
        errorObject.ok = true;
      }
      return errorObject;
    },

    postUrl: function (runSecId) {
      return Settings.url('compuzz', 'v2/runs/' + runSecId + '/wells');
    },

    save: function() {
      this.url = this.postUrl(this.get('run'));
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var WellCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/runs');
    },
    model: Well
  });

  var API = {

    getWellResultCollectionUrl: function(runSecId) {
      return Settings.url('compuzz', 'v2/runs/' + runSecId + '/results', {});
    },

    getWellCollectionUrl: function(runSecId, row, column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype,
                                   res1CodeErr, res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
                                   res6CodeErr, selectedValues) {
      new WellCollection();
      var params = {};
      if (row) {
        params.row = row;
      }
      if (column) {
        params.column = column;
      }
      if (assaySecId) {
        params.assaySecId = assaySecId;
      }
      if (mbAnaSecId) {
        params.mbAnaSecId = mbAnaSecId;
      }
      if (smpid) {
        params.smpid = smpid;
      }
      if (result) {
        params.result = result;
      }
      if (errorInd) {
        params.errorInd = errorInd;
      }
      if (smptype) {
        params.smptype = smptype;
      }
      if (res1CodeErr) {
        params.res1CodeErr = res1CodeErr;
      }
      if (res2CodeErr) {
        params.res2CodeErr = res2CodeErr;
      }
      if (res3CodeErr) {
        params.res3CodeErr = res3CodeErr;
      }
      if (res4CodeErr) {
        params.res4CodeErr = res4CodeErr;
      }
      if (res5CodeErr) {
        params.res5CodeErr = res5CodeErr;
      }
      if (res6CodeErr) {
        params.res6CodeErr = res6CodeErr;
      }
      if (selectedValues) {
        params.selectedValues = selectedValues;
      }
      return Settings.url('compuzz', 'v2/runs/' + runSecId + '/wells', params);
    },

    getWellsCountUrl: function(runSecId, row, column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype,
                               res1CodeErr, res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
                               res6CodeErr, selectedValues) {
      var params = '?';
      if (row) {
        params += 'row=' + row + '&';
      }
      if (column) {
        params += 'column=' + column + '&';
      }
      if (assaySecId) {
        params += 'assaySecId=' + assaySecId + '&';
      }
      if (mbAnaSecId) {
        params += 'mbAnaSecId=' + mbAnaSecId + '&';
      }
      if (smpid) {
        params += 'smpid=' + smpid + '&';
      }
      if (result) {
        params += 'result=' + result + '&';
      }
      if (errorInd) {
        params += 'errorInd=' + errorInd + '&';
      }
      if (smptype) {
        params += 'smptype=' + smptype + '&';
      }
      if (res1CodeErr) {
        params += 'res1CodeErr=' + res1CodeErr + '&';
      }
      if (res2CodeErr) {
        params += 'res2CodeErr=' + res2CodeErr + '&';
      }
      if (res3CodeErr) {
        params += 'res3CodeErr=' + res3CodeErr + '&';
      }
      if (res4CodeErr) {
        params += 'res4CodeErr=' + res4CodeErr + '&';
      }
      if (res5CodeErr) {
        params += 'res5CodeErr=' + res5CodeErr + '&';
      }
      if (res5CodeErr) {
        params += 'res6CodeErr=' + res6CodeErr + '&';
      }
      if (selectedValues) {
          params += 'selectedValues=' + selectedValues + '&';
      }
      return 'v2/runs/'+runSecId+'/wells/rowCount' + params;
    }
  };

  App.reqres.setHandler('well:get-collection-url', function(runSecId, row, column, assaySecId, mbAnaSecId, smpid,
                                                            result, errorInd, smptype, res1CodeErr,
                                                            res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
                                                            res6CodeErr, selectedValues) {
      return API.getWellCollectionUrl(runSecId, row, column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype,
                                      res1CodeErr,res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
                                      res6CodeErr, selectedValues);
  });

  App.reqres.setHandler('well:get-count-url', function(runSecId, row, column, assaySecId, mbAnaSecId, smpid,
                                                       result, errorInd, smptype, res1CodeErr,
                                                       res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr,
                                                       res6CodeErr, selectedValues) {
    return API.getWellsCountUrl(runSecId, row, column, assaySecId, mbAnaSecId, smpid, result, errorInd, smptype,
      res1CodeErr, res2CodeErr, res3CodeErr, res4CodeErr, res5CodeErr, res6CodeErr, selectedValues);
  });

  App.reqres.setHandler('well:get-collection-result-url', function(runSecId) {
    return API.getWellResultCollectionUrl(runSecId);
  });



  App.reqres.setHandler('well:model', function(runJSON) {
    return new Well(runJSON);
  });
});
