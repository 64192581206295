
define('template!contentLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div id="header-region"></div>\n\n<section  class="_compuzz-dashboard-body">\n  <section class="hbox stretch">\n    <!-- .aside -->\n    <aside id="sidebar-region" class="aside-md hidden-print hidden-xs"></aside>\n    <!-- /.aside -->\n    <section id="content"  class="_compuzz-dashboard-content">\n      <section class="vbox">\n        <div id="gallery-zoom"></div>\n        <section id="content-region" class="scrollable"></section>\n        <div id="content-blinder"></div>\n      </section>\n    </section>\n  </section>\n</section>';

}
return __p
};});

