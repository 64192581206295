/**
 * Created by RKL on 07/09/2015.
 */
define('customersShowView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'template!customersViewTpl',
  'customersTabs',
  'rolesMixin',
  'backToListBehavior',
  'customersCopyPasteMix'
], function (
  module,
  Marionette,
  _,
  Backbone,
  App,
  Settings,
  imageUtils,
  viewTpl,
  customersTabs,
  RolesMixin,
  BackToListBehavior,
  CopyPasteMix
) {
  'use strict';

  var CustomersDetailsView =  Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      tab: '.customer-tab',
      tabPane: '.tab-pane',
      logo: '.js-logo-holder',
      name: '.p-name',
      code: '.p-code',
      phone: '.p-phone',
      email: '.p-email',
      collectionToggle: '.js-collection-toggle',
      compuLogoToggle: '.js-compuLogo-toggle'
    },

    events: {
      'click @ui.tab': 'onTabClick',
      'change @ui.collectionToggle': 'onCollectionToggle',
      'change @ui.compuLogoToggle': 'onCompuLogoToggle'
    },

    regions: {
      customerLogosImagesTab: '#logosimages-tab',
      customerProfileTab: '#profile-tab',
      customerDesignElementsTab: '#designelements-tab',
      customerDatasetsTab: '#datasets-tab',
      customerProductTemplatesTab: '#producttemplates-tab',
      customerTemplatesTab: '#templates-tab',
      customerOrdersTab: '#orders-tab',
      customerAttachmentsTab: '#attachments-tab',
      customerFontsTab: '#fonts-tab',
      customerContactsTab: '#contacts-tab',
      customerActions: '.js-action-buttons-region'
    },

    behaviors: {
      BackToList: {
        behaviorClass: BackToListBehavior,
        redirectUrl: 'customers'
      }
    },

    initialize: function() {
      _.extend(this, CopyPasteMix);
    },

    serializeData: function() {
      var templateData = this.model.toJSON(),
        cAccountRoles = Settings.get('cAccountRoles');
      templateData.isNew = this.options.data.id === 'new';
      if(this.options.data.id === 'new') {
        templateData.customersTabs = [];
        var profileTab =  _.findWhere( customersTabs, {role:  RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE});
        templateData.customersTabs.push(profileTab);
      } else if (cAccountRoles) {
        templateData.customersTabs = [];
        _.each(customersTabs, function (tab) {
          if (cAccountRoles[tab.role] === true) {
            templateData.customersTabs.push(tab);
          }
        });
      } else {
        templateData.customersTabs = customersTabs;
      }
      templateData.isCollection = this.model.get('userType') === 'collection';
      templateData.compulogoCollection = this.model.get('compulogoCollection');

      return templateData;
    },

    onRender: function() {
      this._setLogo(this.model.get('logoUid'), this.model.get('code'));

      this.$el.find('.area-compuLogo').toggleClass('sr-only', this.model.get('userType') !== 'collection');
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.customer-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onShow: function() {
      this.prepareCopyPasteActionView('fromCategory');

      $.material.init();
    },

    onTabClick: function(e) {
      var $target = $(e.currentTarget),
        tab = {
          route: $target.data('route'),
          role: $target.data('role'),
          name: $target.data('name')
        };

      this.ui.tab.removeClass('active');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

      this.triggerMethod('click:tab', tab);
      this.checkIfCopyPasteAvailabe();
    },

    onCollectionToggle: function(e) {
      var $target = $(e.currentTarget);
      var compuLogo = this.$el.find('.area-compuLogo');
      compuLogo.toggleClass('sr-only', !$target.prop('checked'));
      this.model.set('compulogoCollection',compuLogo.prop('checked'));
      this.model.setAsCollection($target.prop('checked'), compuLogo.prop('checked'));

    },

    onCompuLogoToggle: function(e) {
      var $target = $(e.currentTarget);
      this.model.set('compulogoCollection',($target.prop('checked')));
      this.model.setAsCollection(true, $target.prop('checked'));
    },

    onHeaderChange: function(customer) {
      this.ui.name.text(customer.name);
      this.ui.phone.text(customer.tel ? customer.tel : '--');
      this.ui.email.text(customer.email ? customer.email : '--');
      this._setLogo(customer.logoUid, customer.code);
    },

    _setLogo: function(logoUid, code) {
      var logo = imageUtils.getLogo(logoUid, code);
      if(logo) {
        this.ui.logo.css('background-image', 'url(' + logo + ')');
      } else {
        this.ui.logo.addClass('default-upload-image');
      }
    }

  });

  module.exports = CustomersDetailsView;
});
