/**
 * Created by RKL on 13/08/2015.
 */
define('caccountShareView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'jqgridView',
  'stateMessages',
  'template!caccountShareTpl'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  JqGridView,
  StateMessages,
  viewTpl
) {
  'use strict';

  var CAccountShareTabView = JqGridView.extend({
    template: viewTpl,

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-share-' + this.options.share,
        jqGridPager: '#jq-grid-pager-share-' + this.options.share,
        addShare: '.js-add-share',
        deleteShare: '.js-delete-share',
        loader: '.js-loading'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click @ui.deleteShare': 'onDeleteShare',
        'click @ui.addShare': 'onAddShare'
      }, this.gridEvents);
    },

    serializeData: function () {
      return this.options;
    },

    gridOptions: function(data) {
      var defaultsOptions = this.gridInitOptions(data),
        type = this.options.data.type,
        check = type === 'caccounts' || type === 'subcaccounts',
        options = {
          datatype: 'local',
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: '#',
              name: 'number',
              classes: 'cell-link',
              formatter:  this.defaultFormatter,
              search: true,
              index: 'number',
              width: 50
            }, {
              label: _.i18n('common.code'),
              name: 'code',
              classes: 'cell-link',
              formatter:  this.defaultFormatter,
              search: true,
              index: 'code'
            }, {
              label: _.i18n('common.name'),
              name: 'name',
              classes: 'cell-link',
              formatter:  this.defaultFormatter,
              search: true,
              index: 'name'
            }
          ]
        };

      if (check) {
        options.colModel.push({
          label: '',
          name: '',
          classes: 'delete-link js-delete-share',
          formatter: this.deleteIconFormatter,
          search: false,
          width: 50
        });
      }

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    displayGrid: function(list, data) {
      var that = this,
        gridOptions;

      data.rowCount = 0;
      this.setFiltersName();
      gridOptions = this.gridOptions(data);

      that.ui.jqGrid.jqGrid(gridOptions);

      // activate the toolbar searching
      that.ui.jqGrid.jqGrid('filterToolbar');
      $(window).bind('resize', function() {
        that.ui.jqGrid.setGridWidth(that.$el.find('.jq-grid-table-page').width());
      });

      that.fetchGridData(list);
    },

    setFiltersName: function() {
      this.filtersName = 'caccountShare' + this.options.share + 'Filters';
    },

    fetchGridData: function(list) {
      var gridArrayData = [];
      var type = this.options.share === 'to' ? 'cAccountTo' : 'cAccountFrom';

      _.each(list, function(item, index) {
        gridArrayData.push({
          number: index + 1,
          code: item[type].code,
          name: item[type].name,
          secId: item[type].secId
        });
      });

      // set the new data
      this.ui.jqGrid.jqGrid('clearGridData');
      this.ui.jqGrid.jqGrid('setGridParam', {data: gridArrayData});

      // refresh the grid
      this.ui.jqGrid.trigger('reloadGrid');
    },

    updatePagination: function() {},

    onAddShare: function() {
      this.trigger('onAddShare', this.options.share);
    },

    onDeleteShare: function(e) {
      var target = $(e.currentTarget),
        shareId = target.find('.cell-delete').data('row-id');

      this.trigger('onDeleteShare', shareId, this.options.share);
    }
  });

  module.exports = CAccountShareTabView;
});
