define( 'entities/caccounts',[
  'app',
  'jquery',
  'underscore',
  'backbone',
  'settings'
], function(
  App,
  $,
  _,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var CAccount = Backbone.Model.extend({
    defaults: {
      vatNumber: undefined,
      name: undefined,
      zip: undefined,
      city: undefined,
      address: undefined,
      code: undefined,
      phone: undefined,
      contactEmail: undefined,
      forceInvoiceGeneration:false,
      country: undefined
    },

    url: function(secId) {
      return Settings.url('compuzz', 'v2/caccounts/' + secId);
    },

    postUrl: function () {
      return Settings.url('compuzz', 'v2/caccounts');
    },

    postCAccount: function() {
      var url = Settings.url('compuzz','sessions.json/token/byuser/' +
        this.get('secId'));

      return $.ajax({
        type: 'POST',
        url: url
      });
    },

    update: function() {
      this.url = this.postUrl();
      return this.save();
    },

    idAttribute: 'secId'
  } );

  /**
   * Collection
   */
  var CAccountCollection = Backbone.Collection.extend( {
    url: function() {
      return Settings.url('compuzz', 'v2/caccounts/');
    },
    model: CAccount,
    comparator: 'code'
  } );

  /**
   * REST API
   */
  var API = {
    getCAccountEntities: function() {
      var cAccounts = new CAccountCollection({}),
        defer = $.Deferred();

      cAccounts.url = Settings.url( 'compuzz', 'v2/caccounts/logged');
      cAccounts.fetch( {
        success: function(data) {
          defer.resolve(data);
        }
      } );

      return defer.promise();
    },

    getCAccountCollection: function() {
      var cAccounts = new CAccountCollection({});

      cAccounts.url = Settings.url('compuzz', 'v2/caccounts');

      return cAccounts;
    },

    getCAccountCollectionUrl: function() {
      return Settings.url('compuzz', 'v2/caccounts');
    },

    getRowCountUrl: function() {
      return 'v2/caccounts/rowCount';
    },

    getCAccountModel: function(cAccountId) {
      var cAccount = new CAccount({
        address: null,
        city: null,
        code: null,
        colorSettings: null,
        contactEmail: null,
        country: null,
        displayPublicPictos: false,
        displayPublicPictures: false,
        encodedValue: null,
        exportUrl: null,
        extension: null,
        htmlServerUrl: null,
        forceInvoiceGeneration: false,
        language: null,
        logo: null,
        mailExpert: null,
        mailFrom: null,
        name: null,
        phone: null,
        refMainCAccountJson: null,
        returnUrl: null,
        secId: null,
        serverUrl: null,
        subDomain: null,
        zip: null,
        vatNumber: null
      });

      cAccount.url = cAccount.url(cAccountId);
      

      return cAccount;
    },

    getCountries: function() {
      var defer = $.Deferred(),
        url = Settings.url( 'compuzz', 'v2/countries');

      $.ajax({
        url: url,
        success: function(data) {
          defer.resolve(new Backbone.Collection(data));
        }
      });

      return defer.promise();
    },

    _getLanguages: function() {
      var cAccounts = new CAccount(),
        defer = $.Deferred();

      cAccounts.url = Settings.url( 'compuzz', 'v2/languages');
      cAccounts.fetch({
        success: function(data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getAvailableCAccounts: function() {
      var availableCAccounts = new CAccount({});

      availableCAccounts.url = Settings.url('compuzz', 'sessions.json/token/byuser/caccounts');

      return availableCAccounts;
    },

    getMenuRoles: function() {
      var cAccounts = new CAccount(),
          defer = $.Deferred();

      cAccounts.url = Settings.url( 'compuzz', 'v2/caccounts/roles');
      cAccounts.fetch({
        success: function(data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    needToEncodeVat: function() {
      var url = Settings.url( 'compuzz', 'v2/caccounts/needToEncodeVat'),
          defer = $.Deferred();

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    postCAccount: function(secId) {
      var url = Settings.url('compuzz','sessions.json/token/byuser/' + secId),
        defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

     changeLanguageCAccount: function(secId, language) {
      var url = Settings.url('compuzz','v2/caccounts/' + secId + '/changeLanguage', {newLanguage : language}),
        defer = $.Deferred();

      $.ajax({
        type: 'PUT',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    loginCAccount: function(data) {
      var params = {
          userpidSecId: data.userId,
          cAccountSecId: data.cAccountId
        },
        url = Settings.url('compuzz','v2/navigations/autologin', params),
        defer = $.Deferred();

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getCAccountCount: function() {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz', 'sessions.json/token/byuser/caccounts/rowcount'),
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getCAccountWithDuplicateIP: function(secId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz', 'v2/caccounts/' + secId + '/duplicate/ip'),
        success: function(resp) {
          _.each(resp, function(cAccount) {
            cAccount.logo = cAccount.logo ? Settings.url('file', cAccount.logo) : Settings.get('notFoundImage');
          });
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }

  };

  /**
   * Request communications
   */
  App.reqres.setHandler('caccount:entities', function() {
    return API.getCAccountEntities();
  });

  App.reqres.setHandler('caccount:entity', function(cAccountId) {
    return API.getCAccountModel(cAccountId);
  });

  App.reqres.setHandler('caccount:countries', function() {
    return API.getCountries();
  });

  App.reqres.setHandler('caccount:languages', function() {
    return API._getLanguages();
  });

  App.reqres.setHandler('caccount:row-count-url', function() {
    return API.getRowCountUrl();
  });

  App.reqres.setHandler('caccount:get-collection', function() {
    return API.getCAccountCollection();
  });

  App.reqres.setHandler('caccount:get-collection-url', function() {
    return API.getCAccountCollectionUrl();
  });

  App.reqres.setHandler('caccount:get-available-caccounts', function() {
    return API.getAvailableCAccounts();
  });

  App.reqres.setHandler('caccount:get-menu-roles', function() {
    return API.getMenuRoles();
  });

  App.reqres.setHandler('caccount:need-to-encode-vat', function() {
    return API.needToEncodeVat();
  });

  App.reqres.setHandler('caccount:post-caccount', function(secId) {
    return API.postCAccount(secId);
  });

  App.reqres.setHandler('caccount:changeLanguage', function(secId, language) {
      return API.changeLanguageCAccount(secId, language);
  });

  App.reqres.setHandler('caccount:new-login', function(data) {
    return API.loginCAccount(data);
  });

  App.reqres.setHandler('caccount:count', function() {
    return API.getCAccountCount();
  });

  App.reqres.setHandler('caccount:model', function(caccountJSON) {
    return new CAccount(caccountJSON);
  });

  App.reqres.setHandler('caccount:duplicate:ip', function(secId) {
    return API.getCAccountWithDuplicateIP(secId);
  });
});
