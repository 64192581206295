define('customerTemplatesTabGeometryData',[
  'module',
  'underscore'
], function(
  module,
  _
) {
  'use strict';

  module.exports = [
    {
      id: 0,
      value: _.i18n('geometry.rect'),
      send: 'RECT'
    },
    {
      id: 1,
      value: _.i18n('geometry.round'),
      send: 'ROUND'
    }
  ];
});
