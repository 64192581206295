/**
 * Created by RKL on 21/08/2015.
 */
define('runsShowController',[
  'module',
  'logger',
  'underscore',
  'app',
  'runsShowView',
  'jquery',
  'bootbox',
  'emptyView',
  'runCreateEditLayoutView',
  'runCreateFromUploadView',
  'entities/runs'
], function(
  module,
  Logger,
  _,
  App,
  View,
  $,
  bootbox,
  EmptyView,
  CreateEditLayoutView,
  CreateFromUploadView
) {
  'use strict';

  var RunsController = {
    showRuns: function() {
      var view = new View();

      this.view = view;

      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      view.listenTo(view, 'onLoadSelectedRuns', _.bind(this.onLoadSelectedRuns, this));
      view.listenTo(view, 'run:import-xml', _.bind(this.onImportXml, this));
      view.listenTo(view, 'run:delete', _.bind(this.onDelete, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    },

    showNewRun: function() {
      this.createEditView = new CreateEditLayoutView({
        runModel: App.request('run:model'),
        newItem: true
      });
      this.createEditView.show({
        title: _.i18n('run.new')
      });
    },

    onGridLoaded: function() {
      var that = this, data;

      data = {
        url: App.request('run:get-collection-url'),
        rowCountUrl: App.request('run:get-count-url')
      };

      that.view.displayGrid(data);
    },

    onLoadSelectedRuns: function(first, last, params) {
      $.when(App.request('run:get-collection-to-page', params))
          .done(_.bind(function(runs) {
            this.view.triggerMethod('selectRuns', first, last, runs);
            $('#content-blinder').hide();
          }, this))
          .fail(function() {
            $('#content-blinder').hide();
          });
    },

    onImportXml: function(fileInfo) {
      var that = this;

        var createFromUploadView = new CreateFromUploadView();
        createFromUploadView.listenTo(createFromUploadView, 'popup-create:selectLmbAnalyser', _.bind(function(lmbAnalyser, kitProt) {
            this.view.onDestroy();
            this.emptyView = new EmptyView();
            App.regions.getRegion('main').currentView.getRegion('content').show(this.emptyView);

            $.when(App.request('run:upload-xml', fileInfo, lmbAnalyser, kitProt)).done(function() {
                that.emptyView.destroy();
                that.showRuns();
            }).fail(function() {
                that.emptyView.destroy();
                that.showRuns();
                bootbox.alert(_.i18n('common.errorOccuredWhileImporting'), function () {
                    return;
                });
            });
        }, this));
        createFromUploadView.show({
            title: _.i18n('run.create')
        });
    },

    onDelete: function(secId){
      bootbox.confirm(_.i18n('run.confirmDelete'), _.bind(function(result) {
        if(result){
          App.request('run:delete', secId)
              .done(_.bind(function(){
                this.view.updatePagination('reloadGrid');
              }, this));
        }
      }, this));
    }
  };

  module.exports = RunsController;
});
