
define('template!wellDisplayGraphTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container popup-graphs">\n      <div class="form-group">\n          <div class="row hidden-print">\n            <div class="col-xs-6">\n                <div class="row">\n                    <div class="col-xs-2 p-t-3 m-r-15" style="font-weight: bold;">\n                        ' +
((__t = ( _.i18n('graph.well') )) == null ? '' : __t) +
'\n                    </div>\n                    ';
 _.each(ctrls, function(ctrl) { var lstChar = (ctrl && ctrl.length > 3) ? ctrl.substr(ctrl.length - 3) : ctrl; ;
__p += '\n                    <div class="col-xs-2">\n                        <div class="row">\n                            <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0" style="font-weight: normal;">' +
((__t = ( lstChar )) == null ? '' : __t) +
'</label>\n                            <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                                <label>\n                                    <input type="checkbox" id="ctrl-' +
((__t = ( ctrl )) == null ? '' : __t) +
'" data-field-name="' +
((__t = ( ctrl )) == null ? '' : __t) +
'" class="cbx-ctrl js-keep-logged-in">\n                                </label>\n                            </div>\n                        </div>\n                    </div>\n                    ';
 }); ;
__p += '\n                </div>\n                <div class="row">\n                    <div class="col-xs-2 p-t-3" style="font-weight: bold;">\n                        ' +
((__t = ( _.i18n('graph.err') )) == null ? '' : __t) +
'\n                    </div>\n                    ';
 _.each(statuss, function(status) { ;
__p += '\n                    <div class="col-xs-2">\n                        <div class="row">\n                            <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0" style="font-weight: normal;">' +
((__t = ( status )) == null ? '' : __t) +
'</label>\n                            <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                                <label>\n                                    <input type="checkbox" id="status-' +
((__t = ( status )) == null ? '' : __t) +
'" data-field-name="' +
((__t = ( status )) == null ? '' : __t) +
'" class="cbx-status js-keep-logged-in">\n                                </label>\n                            </div>\n                        </div>\n                    </div>\n                    ';
 }); ;
__p += '\n                </div>\n            </div>\n            <div class="col-xs-6">\n                <div class="row">\n                    <div class="col-xs-2 p-t-3" style="font-weight: bold;">\n                        ' +
((__t = ( _.i18n('graph.res') )) == null ? '' : __t) +
'\n                    </div>\n                    ';
 _.each(results, function(result) { ;
__p += '\n                    <div class="col-xs-2">\n                        <div class="row">\n                            <label class="col-xs-6 control-label p-t-3 p-r-0 p-l-0" style="font-weight: normal;">' +
((__t = ( result )) == null ? '' : __t) +
'</label>\n                            <div class="col-xs-6 checkbox checkbox-primary p-l-3 p-r-0">\n                                <label>\n                                    <input type="checkbox" id="result-' +
((__t = ( result )) == null ? '' : __t) +
'" data-field-name="' +
((__t = ( result )) == null ? '' : __t) +
'" class="cbx-result js-keep-logged-in">\n                                </label>\n                            </div>\n                        </div>\n                    </div>\n                    ';
 }); ;
__p += '\n                </div>\n                <div class="row">\n                    <div class="col-xs-2 p-t-7" style="font-weight: bold;">\n                        ' +
((__t = ( _.i18n('graph.legend') )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="radio radio-primary col-xs-2 p-l-0">\n                        <label class="p-l-27">\n                            <input type="radio" class="legend-radio legend-radio-hide" name="legend-radio" value="hide" />\n                            ' +
((__t = ( _.i18n('graph.legend.hide') )) == null ? '' : __t) +
'\n                        </label>\n                    </div>\n                    <div class="radio radio-primary col-xs-2 p-l-0">\n                        <label class="p-l-27">\n                            <input type="radio" class="legend-radio legend-radio-well" name="legend-radio" value="well" />\n                            ' +
((__t = ( _.i18n('graph.legend.well') )) == null ? '' : __t) +
'\n                        </label>\n                    </div>\n                    <div class="radio radio-primary col-xs-2 p-l-0">\n                        <label class="p-l-27">\n                            <input type="radio" class="legend-radio legend-radio-smp" name="legend-radio" value="smp" />\n                            ' +
((__t = ( _.i18n('graph.legend.smp') )) == null ? '' : __t) +
'\n                        </label>\n                    </div>\n                    <div class="radio radio-primary col-xs-2 p-l-0">\n                        <label class="p-l-27">\n                            <input type="radio" class="legend-radio legend-radio-ct" name="legend-radio" value="ct" />\n                            ' +
((__t = ( _.i18n('graph.legend.res') )) == null ? '' : __t) +
'\n                        </label>\n                    </div>\n                </div>\n            </div>\n\n            <a href="javascript:window.print()" style="color: #000000;"><i class="mdi-action-print pull-right clickable p-r-20"></i></a>\n            <i class="btn-edit-runat mdi-image-flash-on pull-right clickable p-r-20"></i>\n            <i class="btn-uncheck-all mdi-toggle-check-box-outline-blank pull-right clickable p-r-20"></i>\n\n          </div>\n          <ul class="nav nav-tabs">\n            ';
 if(displayMelt) { ;
__p += '\n            <li class="nav-tab active">\n                <a href="#meltCurves" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurve') )) == null ? '' : __t) +
'</a>\n            </li>\n              <li class="nav-tab">\n                  <a href="#meltCurves1" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurveDeriv') )) == null ? '' : __t) +
'</a>\n              </li>\n              <li class="nav-tab">\n                  <a href="#meltCurves2" data-toggle="tab">' +
((__t = ( _.i18n('well.meltCurve1') )) == null ? '' : __t) +
'</a>\n              </li>\n            ';
 } ;
__p += '\n            ';
 if(displayAmpl) { ;
__p += '\n            <li class="nav-tab ' +
((__t = ( !displayMelt?'active':'' )) == null ? '' : __t) +
'">\n                <a href="#amplCurves" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurve') )) == null ? '' : __t) +
'</a>\n            </li>\n              <li class="nav-tab">\n                  <a href="#amplCurves1" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurve1') )) == null ? '' : __t) +
'</a>\n              </li>\n              <li class="nav-tab">\n                  <a href="#amplCurves2" data-toggle="tab">' +
((__t = ( _.i18n('well.amplCurveDeriv') )) == null ? '' : __t) +
'</a>\n              </li>\n            ';
 } ;
__p += '\n          </ul>\n          <div class="tab-content ">\n            ';
 if(displayMelt) { ;
__p += '\n            <div class="tab-pane active" id="meltCurves">\n              <div id="run-graph-region"></div>\n            </div>\n            <div class="tab-pane" id="meltCurves1">\n                <div id="run-graph1-region"></div>\n            </div>\n              <div class="tab-pane" id="meltCurves2">\n                  <div id="run-graph2-region"></div>\n              </div>\n            ';
 } ;
__p += '\n            ';
 if(displayAmpl) { ;
__p += '\n            <div class="tab-pane ' +
((__t = ( !displayMelt?'active':'' )) == null ? '' : __t) +
'" id="amplCurves">\n              <div id="run-ampl-graph-region"></div>\n            </div>\n            <div class="tab-pane" id="amplCurves1">\n                <div id="run-ampl-graph1-region"></div>\n            </div>\n              <div class="tab-pane" id="amplCurves2">\n                  <div id="run-ampl-graph2-region"></div>\n              </div>\n            ';
 } ;
__p += '\n          </div>\n      </div>\n      <div class="row hidden-print">\n        <div class="pull-right p-r-15">\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.ok') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

