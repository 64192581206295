/**
 * Created by OLD on 25/11/2015.
 */
define('wellTableView',[
  'module',  
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'jqgridView',
  'template!wellTableTpl',
  'entities/wells'
], function(
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  JqGridView,
  viewTpl
) {
  'use strict';

  var WellTableView = JqGridView.extend({
    filterEnable: false,
    GRID_MIN_WIDTH: 300,
    template: viewTpl,
    channelToDisplay: null,

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-well',
        jqgRow: 'tr.jqgrow',
        editRefAssayBtn: '.edit-refassay-button',
        editRefKitLBtn: '.edit-refkitl-button',
        editSmpIdBtn: '.edit-smpid-button',
        displayGraphBtn: '.display-graph-button',
        sortWellRadio: '.sort-well-radio'
      }, this.gridUi);
    },

    events: {
      'click @ui.editRefAssayBtn': 'onEditRefAssayBtnClick',
      'click @ui.editRefKitLBtn': 'onEditRefKitLBtnClick',
      'click @ui.editSmpIdBtn': 'onEditSmpIdBtnClick',
      'click @ui.displayGraphBtn': 'onDisplayGraphBtnClick',
      'change @ui.sortWellRadio': 'onSortWellRadioChange'
    },

    onShow: function() {
      $.material.init();
    },

    serializeData: function() {
      var templateData = {};
      templateData.editEnable = false;
      if (this.channelToDisplay) {
          templateData.editEnable = true;
      }
      templateData.sortOn = this.sortOn;
      return templateData;
    },

    onSortWellRadioChange: function() {
      if(this.ui.sortWellRadio){
        _.each(this.ui.sortWellRadio, _.bind(function(radio) {
          if (radio.checked) {
            switch(radio.value) {
              case 'sortRow':
                this.trigger('well-table:sort-row');
                break;
              case 'sortColumn':
                this.trigger('well-table:sort-column');
                break;
            }
          }
        }, this));
      }
    },

    onDisplayGraphBtnClick: function() {
      this.trigger('well-table:display-graph', this.ui.jqGrid.getRowData());
    },

    onEditRefAssayBtnClick: function() {
      this.trigger('well-table:edit-ref-assay', this.ui.jqGrid.getRowData());
    },

    onEditRefKitLBtnClick: function() {
      this.trigger('well-table:edit-ref-kitl', this.ui.jqGrid.getRowData());
    },

    onEditSmpIdBtnClick: function() {
      this.trigger('well-table:edit-smpid', this.ui.jqGrid.getRowData());
    },

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: 100,
        currentPage: 1
      };
    },

    gridOptions: function(data) {
       var defaultsOptions, options, 
        _colModel = [];

        _colModel.push({
            label: '',
            name: 'secId',
            key: true,
            hidden: true
        });

        _colModel.push({
            label: _.i18n('well.pos'),
            name: 'pos',
            classes: 'well-list-pos-label',
            formatter: this.defaultFormatter,
            search: false,
            sortable: false,
            index: 'pos',
            width: 50
        });

        _colModel.push({
            label: _.i18n('well.smpid'),
            name: 'smpid',
            classes: 'well-list-label',
            formatter: this.lastCharFormater,
            search: false,
            sortable: false,
            index: 'smpid',
            width: 120
        });

        if (this.channelToDisplay) {
            _colModel.push({
                label: _.i18n('well.target'),
                name: 'refAssay',
                classes: 'well-list-label',
                formatter: _.bind(this.displayTargetFormater, this),
                search: false,
                sortable: false,
                index: 'refAssay',
                width: 120
            });
        } else {
            _colModel.push({
                label: _.i18n('well.refAssay'),
                name: 'refAssay.code',
                classes: 'well-list-label',
                formatter: this.defaultFormatter,
                search: false,
                sortable: false,
                index: 'refAssay.code',
                width: 120
            });
        }


        _colModel.push({
            label: _.i18n('well.res1Code'),
            name: 'res1Code',
            classes: 'well-list-label',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code'
        });

      if (this.channelToDisplay) {
          _colModel.push({
              label: _.i18n('well.res'+this.channelToDisplay+'Num'),
              name: 'resNum',
              classes: 'well-list-label',
              formatter: _.bind(this.resNumFormatter, this),
              search: false,
              sortable: false,
              index: 'resNum'
          });
      }
      defaultsOptions = this.gridInitOptions(data);
      options = {
          colModel: _colModel,
          sortname: this.sortOn?this.sortOn:'row'
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    displayTargetFormater: function(cellValue) {
      var targetName = this.model.getTargetName(cellValue, this.channelToDisplay);
      return '<span class="cell-default">' + targetName + '</span>';
    },

    resNumFormatter: function(cellValue, call, object) {
        var model = App.request('well:model', object);
        return '<span class="cell-default">' + model.getResFormated(this.channelToDisplay) + '</span>';
    },

    squareIconFormatter: function(cellValue, call, object) {

      var color1, color2, color3, color4, color5, color6,
          start, end;

      start = '<div class="row m-b-0">';

      if (!this.channelToDisplay || this.channelToDisplay===1) {
          color1 = this.model.getResultColor(object.refAssay, object.res1Code, 1);
          start += this.generateResultHtml(object.res1Comment, object.res1CodeErr, color1);
      }
      if (!this.channelToDisplay || this.channelToDisplay===2) {
          color2 = this.model.getResultColor(object.refAssay, object.res2Code, 2);
          start += this.generateResultHtml(object.res2Comment, object.res2CodeErr, color2);
      }
      if (!this.channelToDisplay || this.channelToDisplay===3) {
          color3 = this.model.getResultColor(object.refAssay, object.res3Code, 3);
          start += this.generateResultHtml(object.res3Comment, object.res3CodeErr, color3);
      }
      if (!this.channelToDisplay || this.channelToDisplay===4) {
          color4 = this.model.getResultColor(object.refAssay, object.res4Code, 4);
          start += this.generateResultHtml(object.res4Comment, object.res4CodeErr, color4);
      }
      if (!this.channelToDisplay || this.channelToDisplay===5) {
          color5 = this.model.getResultColor(object.refAssay, object.res5Code, 5);
          start += this.generateResultHtml(object.res5Comment, object.res5CodeErr, color5);
      }
      if (!this.channelToDisplay || this.channelToDisplay===6) {
          color6 = this.model.getResultColor(object.refAssay, object.res6Code, 6);
          start += this.generateResultHtml(object.res6Comment, object.res6CodeErr, color6);
      }

      end = '</div>';

      return start + end;
    },

    generateResultHtml: function(comment, errorCode, color) {
        if (comment) {
            return '<div class="col-xs-1" data-toggle="tooltip" data-placement="right" title="'+comment+'">' +
                '<div class="' + (errorCode === 'E' ? 'well-square-icon-black' : 'well-square-icon-green') +
                ' '+ (errorCode === 'W' ? 'well-square-icon-orange' : '') +'">' +
                '<div class="well-circle-icon p-t-1" style="position:absolute; background: '+color+';"/>' +
                '</div>' +
                '</div>';
        } else {
            return '<div class="col-xs-1">' +
                '<div class="' + (errorCode === 'E' ? 'well-square-icon-black' : 'well-square-icon-green') +
                ' '+ (errorCode === 'W' ? 'well-square-icon-orange' : '') +'">' +
                '<div class="well-circle-icon p-t-1" style="position:absolute; background: '+color+';"/>' +
                '</div>' +
                '</div>';
        }

    },

    onLoadComplete: function(data) {
      this.codeTableData = data;
      if(data && data.length) {
        this.ui.jqGrid.setSelection(data[0].secId).click();
      }
    },

    onPreselectRow: function(rowId) {
      if (this.codeTableData) {
        this.ui.jqgRow.removeClass('btn-secondary');
        this.ui.jqGrid.find('tr#' + rowId).addClass('btn-secondary');
        this.trigger('well-table:loaded', rowId);
      } else {
        return;
      }
    },

    onCheckRowCount: function(rowCount) {
      if (rowCount === 0) {
        this.trigger('well:check-rowcount');
      }
    }
  });

  module.exports = WellTableView;
});
