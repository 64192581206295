define( 'entities/customers/customers',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var Customer = Backbone.Model.extend({
    defaults: {
      aProfile: null,
      cAccountId: null,
      city: null,
      code: null,
      comment: null,
      companyName: null,
      companyNumber: null,
      countryCode: null,
      email: null,
      emailCustomerService: null,
      encodedValue: null,
      endUserId: null,
      extension: null,
      fax: null,
      firstName: null,
      houseNumber: null,
      lastName: null,
      locales: null,
      login: null,
      logoUid: null,
      name: null,
      password: null,
      refAgent: null,
      secId: null,
      street: null,
      tel: null,
      userType: null,
      vatNumber: null,
      webSite: null,
      workName: null,
      zip: null,
      addressL1: null,
      addressL2: null,
      other: null,
      subjectToVat: false,
      priceListCode: null,
      subscribed: true
    },

    setAsCollection: function(isCollection, isCompuLogo) {
      var that = this,
        params = {
          value: isCollection ? 'true' : 'false',
          isCompuLogo: isCompuLogo ? 'true' : 'false'
        },
        url = Settings.url('compuzz', 'endusers.json/' + this.get('secId') + '/setascollection', params);

      $.ajax({
        url: url,
        method: 'PUT',
        success: function(resp) {
          that.set(resp);
          that.trigger('sync');
        },
        error: function(resp) {
          that.trigger('error', resp);
        }
      });
    },

    postUrl: function() {
      return Settings.url('compuzz', 'endusers.json');
    },

    deleteUrl: function() {
      return Settings.url('compuzz', 'endusers.json/' + this.get('secId'));
    },

    save: function() {
      this.url = this.postUrl();
      this.set('code', this.get('endUserId'));
      return Backbone.Model.prototype.save.call(this);
    },

    idAttribute: 'secId'
  });

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function() {
      return 'endusers.json/rowCount';
    },

    getCustomersUrl: function() {
      return Settings.url('compuzz', 'endusers.json/endusers');
    },

    getCustomerModel: function(id) {
      var customer = new Customer();

      customer.url = Settings.url('compuzz', 'endusers.json/' + id);

      return customer;
    },

    getCustomerModelByEndUserId: function(endUserId) {
      var customer = new Customer();

      customer.url = Settings.url('compuzz', 'endusers.json/code/' + endUserId);

      return customer;
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('customers:row-count-url', function() {
    return API.getRowCountUrl();
  });

  App.reqres.setHandler('customers:get-url', function() {
    return API.getCustomersUrl();
  });

  App.reqres.setHandler('customers:entity', function(id) {
    return API.getCustomerModel(id);
  });

  App.reqres.setHandler('customers:entity:end-user-id', function(endUserId) {
    return API.getCustomerModelByEndUserId(endUserId);
  });

  App.reqres.setHandler('customer:model', function(modelJSON) {
    return new Customer(modelJSON);
  });
});
