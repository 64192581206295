/**
 * Created by RKL on 13/08/2015.
 */
define('customerInfoView',[
  'module',
  'underscore',
  'app',
  'settings',
  'imageUtils',
  'formView',
  'autocompleteView',
  'stateMessages',
  'tokenField',
  'template!customerInfoTpl'
], function (
  module,
  _,
  App,
  Settings,
  imageUtils,
  FormView,
  AutocompleteView,
  StateMessages,
  tokenField,
  viewTpl
) {
  'use strict';

  var CustomersInfoView = FormView.extend({
    template: viewTpl,

    triggers: {
      'click @ui.viewLess': 'onViewLess',
      'click @ui.cancel': 'onCancel',
      'click @ui.confirm': 'onConfirm',
      'change @ui.changeLogo': 'onChangeLogo',
      'click @ui.removeLogo': 'onRemoveLogo',
      'click @ui.deleteBtn': 'onDelete'
    },

    ui: {
      autocomplete: 'input.autocomplete',
      logo: '.js-logo-holder',
      viewLess: '.js-view-less',
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      input: '.js-info-input',
      changeLogo: '.js-change-logo',
      removeLogo: '.js-remove-logo',
      deleteBtn: '.js-delete-button'
    },

    events: {
      'change @ui.input': 'onInputChange',
      'focus @ui.input' : 'onFocus'
    },

    regions: {
      customerCountries: '.select-countries'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    serializeData: function () {
      var templateData = this.model.toJSON(),
        id = this.options.data.id,
        secId = this.model.get('secId');

      templateData.viewLess = secId;
      templateData.newItem = id === 'new';

      return templateData;
    },

    onRender: function() {
      this.displayLogo(this.model.get('logoUid'), this.model.get('code'));
      $.material.init();
    },

    onShow: function() {
      var countriesView = new AutocompleteView(
        this.getCountriesAutocompleteOptions()
      );

      App.regions.addRegions(this.regions);
      App.regions.getRegion('customerCountries').show(countriesView);

      $.material.init();

       this.$('#emailInput').tokenfield({
            inputType: 'email',
            delimiter: [';', ',', ' ']
        }).on('tokenfield:createdtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:tokenEdited', _.bind(function(e){
            this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
        }, this));

        this.$('#CustomerEmailInput').tokenfield({
            inputType: 'email',
            delimiter: [';', ',', ' ']
        }).on('tokenfield:createdtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:tokenEdited', _.bind(function(e){
                this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
        }, this));
    },

    onTokenFieldInputChange: function(e){
        this.onInputChange(e);
        this.triggerMethod('enable:cancel:confirm');
    },

    getCountriesAutocompleteOptions: function() {
      var countryCode, countryValue, country;

      countryCode = this.model.get('countryCode');
      country = _.findWhere(this.options.countries, {code: countryCode});
      countryValue = country ? country.name : null;

      return {
        data: this.options.countries,
        limit: this.options.countries.length,
        valueKey: 'name',
        apiKey: 'id',
        placeholder: _.i18n('common.countryCode'),
        value: countryValue,
        name: 'countryCode',
        callback: _.bind(this.onChangeCountry, this)
      };
    },

    onUpdateLogo: function(logo) {
      this.ui.logo.css(
        'background-image',
        'url(data:image/png;base64,' + logo + ')'
      );
    },

    onRemoveLogo: function() {
      this.displayLogo(null, this.model.get('code'), true);
    },

    displayLogo: function(logo, code, remove) {
      var image = imageUtils.getLogo(logo, code);

      if (image) {
        this.ui.logo.css('background-image', 'url(' + image + ')');
      } else {
        if (remove) {
          this.ui.logo.css('background-image', 'none');
        }
        this.ui.logo.addClass('default-image');
      }
    },

    onChangeCountry: function(fieldName, model) {
      var code = model ? model.get('code') : '';

      this.onChange(fieldName, code);
    },

    onBeforeDestroy: function() {
      this.$('#emailInput').tokenfield( 'destroy' );
      this.$('#CustomerEmailInput').tokenfield( 'destroy' );
    }
  });

  module.exports = CustomersInfoView;
});
