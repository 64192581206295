/**
 * Created by RKL on 18/08/2015.
 */
define('customerProdTemplatesNewItemView',[
  'module',
  'marionette',
  'app',
  'template!customerProdTemplatesNewItemTpl'
], function (
  module,
  Marionette,
  App,
  viewTpl
) {
  'use strict';

  var CustomersProducTemplatesTabNewItemView = Marionette.ItemView.extend({
    template: viewTpl,

    events: {
      'click': 'onAddNewTemplate'
    },

    tagName: 'div',

    className: 'original-img-wrapper zoom-img template-item template-new-item',

    onAddNewTemplate: function() {
      App.trigger('producttemplate:create-new', this.model.get('productCode'));
    }
  });

  module.exports = CustomersProducTemplatesTabNewItemView;
});
