/**
 * Created by RKL on 18/08/2015.
 */
define('groupsNewItemView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'template!groupsNewItemTpl',
  'entities/caccounts/templates'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  viewTpl
) {
  'use strict';

  var GroupsNewItemView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      newGroup: '.template-group-item-new'
    },

    events: {
      'click @ui.newGroup' : 'onNewGroup'
    },

    onNewGroup: function() {
      App.trigger('folder-groups:add-group');
    }

  });

  module.exports = GroupsNewItemView;
});
