define('entities/lisanas',[
  'app',
  'backbone',
  'settings',
  'jquery'
], function(
  App,
  Backbone,
  Settings,
  $
) {
  'use strict';

  var LisAna = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/lisanas/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      code: '',
      smpSfx: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (lisSecId) {
      return Settings.url('compuzz', 'v2/lis/' + lisSecId + '/lisanas');
    },

    save: function() {
      this.url = this.postUrl(this.get('refLis').secId);
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var LisAnaCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/lisanas/');
    },
    model: LisAna
  });

  var API = {

    getLisAnaCollectionUrl: function(lisSecId) {
      new LisAnaCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/lis/' + lisSecId + '/lisanas', params);
    },

    getLisAnaCollectionLisAserieUrl: function(lisAserieSecId) {
      new LisAnaCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/lisaseries/' + lisAserieSecId + '/lisanas', params);
    },

    getLisAnaCountUrl: function(lisSecId) {
      var params = '';
      return 'v2/lis/' + lisSecId + '/lisanas/rowCount' + params;
    },

    getLisAnaCountLisAserieUrl: function(lisAserieSecId) {
      return 'v2/lisaseries/' + lisAserieSecId + '/lisanas/rowCount';
    },

    createLisAserieLisana: function(lisAserieSecId, lisAna) {
      var url = Settings.url('compuzz', 'v2/lisaseries/'+lisAserieSecId+ '/lisanas'),
        defer = $.Deferred();

      $.ajax({
        type: 'POST',
        data: JSON.stringify(lisAna),
        contentType: 'application/json',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    deleteLisAna: function(lisSecId, secId){
      var url = Settings.url('compuzz', 'v2/lis/'+lisSecId+'/lisanas/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    deleteLisAserieLisAna: function(lisAserieSecId, secId){
      var url = Settings.url('compuzz', 'v2/lisaseries/'+lisAserieSecId+ '/lisanas/'+secId),
        defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('lisana:get-collection-url', function(lisSecId) {
      return API.getLisAnaCollectionUrl(lisSecId);
  });

  App.reqres.setHandler('lisana:get-count-url', function(lisSecId) {
    return API.getLisAnaCountUrl(lisSecId);
  });

  App.reqres.setHandler('lisana:get-collection-lisaserie-url', function(lisAserieSecId) {
    return API.getLisAnaCollectionLisAserieUrl(lisAserieSecId);
  });

  App.reqres.setHandler('lisana:get-count-lisaserie-url', function(lisAserieSecId) {
    return API.getLisAnaCountLisAserieUrl(lisAserieSecId);
  });

  App.reqres.setHandler('lisana:create-lisaserie-lisana', function(lisAserieSecId, lisAna) {
    return API.createLisAserieLisana(lisAserieSecId, lisAna);
  });

  App.reqres.setHandler('lisana:model', function(itemJSON) {
    return new LisAna(itemJSON);
  });

  App.reqres.setHandler('lisana:delete', function(lisSecId, secId) {
    return API.deleteLisAna(lisSecId, secId);
  });

  App.reqres.setHandler('lisana:lisaserie-lisana-delete', function(lisAserieSecId, secId) {
    return API.deleteLisAserieLisAna(lisAserieSecId, secId);
  });
});
