define('caccountSAProfileEditPopupView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!caccountSAProfileEditPopupTpl',
  'imageUtils',
  'imageUploadBehavior',
  'savingBehavior',
  'stateMessages',
  'bootbox',
  'underscore',
  'app',
  'tokenField',
  'autocompleteView'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  imageUtils,
  ImageUploadBehavior,
  SavingBehavior,
  StateMessages,
  bootbox,
  _,
  App,
  tokenField,
  AutocompleteView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      logo: '.js-logo-holder',
      logoDiv: 'div.js-logo-holder',
      imgBlock: 'div.profile-img-block',
      changeLogo: '.js-change-logo',
      removeLogo: '.js-remove-logo',
      input: '.js-info-input',
      endUserIdInput: '.js-end-user-id',
      vatArea: '#vat-area',
      subjectToVat: '#subject-to-vat',
      vatInput: '#vat-input',
      forceInvoice: '#force-invoice',
      cancel: '.js-cancel',
      confirm: '.js-confirm'
    },

    events: {
      'click @ui.removeLogo': 'onRemoveLogo',
      'click @ui.cancel': 'hide',
      'change @ui.input': 'onInputChange',
      'focus @ui.input' : 'onFocus',
      'focus @ui.codeInput': 'onCodeFocus',
      'click @ui.confirm': 'onConfirm',
      'change @ui.subjectToVat': 'onChangeSubjectToVat',
      'change @ui.forceInvoice': 'onChangeForceInvoice'
    },

    regions: {
      accountCountries: '.select-countries',
      invoiceSample: '.billing-profile'
    },

    className: 'profile-create-edit',

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      },
      ImageUpload: {
        behaviorClass: ImageUploadBehavior
      },
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: function(){
        var fields = [
          {name: 'contactEmail', type: 'multipleEmail'},
          {name: 'name', type: 'required'},
          {name: 'code', type: 'required'},
          {name: 'city', type: 'required'},
          {name: 'address', type: 'required'},
          {name: 'zip', type: 'required'}
        ];
        var subjectToVat = this.model.get('subjectToVat') || false;
        if(subjectToVat){
          fields.push({name: 'vatNumber', type: 'required'});
        }
        return fields;
    },

    initialize: function(options) {
      //new Backbone.Model(options.caccauntModel.toJSON());
      this.model = options.caccauntModel.clone();
    },

    serializeData: function () {
      var templateData = this.model.toJSON(),
        type = this.options.type,
        id = this.options.accountId,
        secId = this.model.get('secId');
      templateData.sampleAllowed = this.options.invoiceAvailable;
      templateData.sampleUrl = this.options.sampleUrl;
      templateData.viewLess = !!secId;
      templateData.loginBtn = type === 'subcaccounts' && secId;
      templateData.switchBtn = type === 'caccounts' && secId;
      templateData.newAccount = id === 'new';

      return templateData;
    },

    onShow: function() {
      var countriesView = new AutocompleteView(
        this._getCountriesAutocompleteOptions()
      );
      this.displayLogo(this.model.get('logo'), this.model.get('code'));

      this.listenTo(countriesView, 'focus', this.removeCountriesError);

      this.getRegion('accountCountries').show(countriesView);

       this.ui.vatArea.toggleClass('disabled', this.model.get('subjectToVat'));

      var subjectToVat = this.model.get('subjectToVat') || false;
      this.ui.vatArea.toggleClass('hidden',!subjectToVat);
      this.ui.subjectToVat.prop('checked', subjectToVat);

      var forceInvoice = this.model.get('forceInvoiceGeneration') || false;
      this.ui.forceInvoice.prop('checked', forceInvoice);

      $.material.init();

      this.initTokenField();
    },

    initTokenField: function() {
      // these variables are used to fix email input field appearance
      var value = this.$('#contactEmailInput').val(),
          emailTokenCount = value ? value.split(';').length : 0;

      if (emailTokenCount > 0) {
        this.$el.find('[data-field-name="contactEmail"] .floating-label').addClass('floating-label-focused');
      }

      this.$('#contactEmailInput').tokenfield({
            createTokensOnBlur: true,
            inputType: 'email',
            delimiter: [';', ',', ' ']
        }).on('tokenfield:createdtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
            emailTokenCount++;
        }, this))
        .on('tokenfield:tokenEdited', _.bind(function(e){
           this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
            emailTokenCount--;
        }, this));

      // fix conflicts between materializecss and tokenfield plugin
      this.$('[data-field-name="contactEmail"]').off('focusin').focusin(function() {
        $(this).find('.floating-label').addClass('floating-label-focused selected');
      }).off('focusout').focusout(function() {
        if($(this).find('#contactEmailInput-tokenfield').val() === '' && emailTokenCount === 0) {
          $(this).find('.floating-label').removeClass('floating-label-focused');
        }
        $(this).find('.floating-label').removeClass('selected');
      });
    },

    onTokenFieldInputChange: function(e){
        this.onInputChange(e);
        this.triggerMethod('enable:cancel:confirm');
    },

    displayLogo: function(logo, code, remove) {
      var image = imageUtils.getLogo(logo, code);

      if (image) {
        this.ui.logo.removeClass('hidden');
        this.ui.imgBlock.removeClass('hidden');
        this.ui.logo.attr('src', image);
        if (!this.ui.logoDiv.hasClass('hidden')) {
          this.ui.logoDiv.addClass('hidden');
        }
      } else {
        if (remove) {
          this.ui.logo.attr('src', '');
        }
        this.ui.logo.attr('src', '').addClass('hidden');
        this.ui.imgBlock.addClass('hidden');
        this.ui.logoDiv.removeClass('hidden');
      }

      if (!this.model.get('logoUid')) {
        this.ui.removeLogo.hide();
      } else {
        this.ui.removeLogo.show();
      }
    },

    onImageUploadConfirm: function(base64Value, fileExt) {
      this.model.set('encodedValue', base64Value);
      this.model.set('extension', fileExt);
      this.ui.logo.removeClass('hidden');
      this.ui.imgBlock.removeClass('hidden');

      if (!base64Value) {
        this.ui.logo.attr('src', imageUtils.getLogo(base64Value, this.model.get('code')));
        this.ui.removeLogo.hide();
        this.ui.logo.attr('src', '').addClass('hidden');
        this.ui.imgBlock.addClass('hidden');
        this.ui.logoDiv.removeClass('hidden');
      } else {
        this.ui.logo.attr('src', 'data:image/png;base64,' + base64Value);
        this.ui.removeLogo.show();
      }

      if (!this.ui.logoDiv.hasClass('hidden') && base64Value) {
        this.ui.logoDiv.addClass('hidden');
      }

      this.triggerMethod('enable:cancel:confirm');
    },

    onRemoveLogo: function() {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.ui.changeLogo.val('');
          this.model.set('logoUid', null);
          this.onImageUploadConfirm(null, null);
        }
      }, this));
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate())) {
        this.model.url = this.model.postUrl();
        this.model.save();
      }
    },

    onChangeSubjectToVat: function() {
      this.model.set('subjectToVat',this.ui.subjectToVat.is(':checked'));
      this.ui.vatArea.toggleClass('hidden',!(this.ui.subjectToVat.is(':checked')));
      this.ui.cancel.prop('disabled', false);
      this.ui.confirm.prop('disabled', false);
    },

    onChangeForceInvoice: function() {
      this.model.set('forceInvoiceGeneration',this.ui.forceInvoice.is(':checked'));
      this.ui.cancel.prop('disabled', false);
      this.ui.confirm.prop('disabled', false);
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();

      this.options.caccauntModel.set(modelJSON);
      this.trigger('onModelSaved', this.model);
      this.hide();
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=endUserId').addClass('has-error');
    },

    _getCountriesAutocompleteOptions: function() {
      var countryCode, countryValue, country;

      countryCode = this.model.get('country') || '';
      country = _.findWhere(this.options.countries, {code: countryCode});
      countryValue = country ? country.name : null;

      return {
        data: this.options.countries,
        valueKey: 'name',
        apiKey: 'id',
        limit: this.options.countries.length,
        placeholder: _.i18n('common.country'),
        value: countryValue,
        name: 'country',
        callback: _.bind(this.onChangeCountry, this)
      };
    },

    onChangeCountry: function(fieldName, model) {
      var code = model ? model.get('code') : '';

      this.onChange(fieldName, code);
      this.triggerMethod('enable:cancel:confirm');
    },

    onBeforeDestroy: function() {
      this.$('#emailInput').tokenfield( 'destroy' );
      this.$('#contactEmailInput').tokenfield( 'destroy' );
    }
  });

  module.exports = CreateEditLayoutView;
});
