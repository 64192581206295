
define('template!customerProdTemplatesItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="main text-center">\n  <div class="image-holder-container transparent-bg pgallery_item js-image-holder-container">\n    <div class="image-holder js-image-holder"></div>\n  </div>\n</div>\n<div class="template-item-editor js-template-item-editor">\n  <!-- <i class="mdi-editor-mode-edit icon js-edit-icon"></i> -->\n  <i class="mdi-action-delete icon js-remove-icon"></i>\n  <i class="mdi-image-color-lens icon js-modify-icon"></i>\n  <!-- <i class="mdi-file-file-download icon js-download-icon"></i> -->\n</div>';

}
return __p
};});

