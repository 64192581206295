/**
 * Created by OLD on 12/10/2015.
 */
define('timetrackerTableView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'dateUtils',
  'moment',
  'template!timetrackerTableDayTpl',
  'template!timetrackerTableWeekTpl',
  'template!timetrackerTableMonthTpl',
  'settings',
  'jqueryui'
], function(
    module,
    Marionette,
    App,
    _,
    dateUtils,
    moment,
    viewDayTpl,
    viewWeekTpl,
    viewMonthTpl,
    settings
) {
  'use strict';

  var TimetrackerTable =  Marionette.ItemView.extend({
    templateDay: viewDayTpl,
    templateWeek: viewWeekTpl,
    templateMonth: viewMonthTpl,

    ui: {
      totalPerU: '.tt-time-val',
      timeItem: '.date-total-val'
    },

    events: {
      'click @ui.timeItem' : 'onShowScreens'
    },

    serializeData: function() {
      var templateData = {};

      templateData.times = this.model.toJSON();
      templateData.users = this.options.userList;
      templateData.calendar = this.calendar;
      templateData.dateFrom = moment(parseInt(this.options.filters.dateFrom, 10)).format('DD/MM/YYYY');
      templateData.dateTo = moment(parseInt(this.options.filters.dateTo, 10)).format('DD/MM/YYYY');
      templateData.totalPerUser = this.calculateTotalPerUser();

      return templateData;
    },

    getTemplate: function() {
      moment.locale(settings.get('lang'));

      if (this.options.tplView === 'Day') {
        this.calendar = this.renderDayCalendar();
        return this.templateDay;
      } else if (this.options.tplView === 'Week') {
        this.calendar = this.renderWeekCalendar();
        return this.templateWeek;
      } else if (this.options.tplView === 'Month') {
        this.calendar = this.renderMonthCalendar();
        return this.templateMonth;
      }
    },

    onRender: function() {
      $.material.init();
    },

    onShowScreens: function(e) {
      var target = $(e.currentTarget),
        weekNumber, df, dt;

      if (this.options.tplView === 'Day') {
        df = parseInt(moment(target.attr('data-day'), 'DD/MM/YYYY').startOf('day').format('x'), 10);
        dt = parseInt(moment(df).add(1, 'days').endOf('day').format('x'), 10);
      } else if (this.options.tplView === 'Week') {
        weekNumber= target.data('week-number');
        df = parseInt(moment(weekNumber, 'W').startOf('isoWeek').format('x'), 10);
        dt = parseInt(moment(df).endOf('isoWeek').format('x'), 10);
      } else if (this.options.tplView === 'Month') {
        df = parseInt(moment(target.attr('data-day'), 'DD/MM/YYYY').startOf('month').format('x'), 10);
        dt = parseInt(moment(df).endOf('month').format('x'), 10);
      }
      this.trigger('timetracking:show-screens', target, df, dt);
    },

    buildCalendarData: function() {
      var daysBack = 0,
          days = moment.weekdaysShort(),
          months = moment.monthsShort(),
          userDate = parseInt(this.options.filters.dateFrom, 10),
          dat = new Date(userDate);

      Date.prototype.addDays = function(days){
        var dat = new Date(this.valueOf());

        dat.setDate((dat.getDate() - daysBack) + days);
        return dat;
      };
      Date.prototype.getDayName = function() {
        return days[ this.getDay()];
      };
      Date.prototype.getMonthName = function() {
        return months[ this.getMonth() ];
      };

      return dat;
    },

    calculateTotalPerUser: function() {
      var that = this,
          users = this.model.toJSON(),
          dateFrom = parseInt(this.options.filters.dateFrom, 10),
          dateTo = parseInt(this.options.filters.dateTo, 10),
          newUser = [],
          userTotals, day, mF, mT, wF, wT,
          totalsPerUser;

      _.each(users, function(user) {
        totalsPerUser = [];
        _.each(user.totals, function(total) {
          day = parseInt(moment(total.day, 'DD/MM/YYYY').format('x'), 10);
          if (that.options.tplView === 'Day') {
            if (moment(day).isBetween(dateFrom, dateTo) ||
                moment(day).isSame(dateFrom) || moment(day).isSame(dateTo)) {
              totalsPerUser.push(total.miliseconds);
            }
          } else if (that.options.tplView === 'Month') {
            mF = parseInt(moment(dateFrom).startOf('month').format('x'), 10);
            mT = parseInt(moment(dateTo).endOf('month').format('x'), 10);
            if (moment(day).isBetween(mF, mT) ||
                moment(day).isSame(mF) ||
                moment(day).isSame(mT)) {
              totalsPerUser.push(total.miliseconds);
            }
          } else if (that.options.tplView === 'Week') {
            wF = parseInt(moment(dateFrom).startOf('isoWeek').format('x'), 10);
            wT = parseInt(moment(dateTo).endOf('isoWeek').format('x'), 10);
            if (moment(day).isBetween(wF, wT) ||
                moment(day).isSame(wF) ||
                moment(day).isSame(wT)) {
              totalsPerUser.push(total.miliseconds);
            }
          }
        });
        userTotals = _.reduce(totalsPerUser, function(a, b){
          return a + b;
        });
        newUser.push({
          user: user.user,
          totals: totalsPerUser,
          mlsec: dateUtils.toHHMM(userTotals),
          logonId: user.logonId
        });
      });
      return newUser;
    },

    renderMonthCalendar: function() {
      var data = this.model.toJSON(),
          mFrom = moment(parseInt(this.options.filters.dateFrom, 10)).month(),
          mTo = moment(parseInt(this.options.filters.dateTo, 10)).month(),
          calendar = _.range(mFrom, mTo + 1, 1);

      calendar = _.map(calendar, function(item) {
        return {
          name: moment().month(item).format('MMMM'),
          number: item
        };
      });
      _.each(data, function(item) {
        _.filter(calendar, function(month) {
          _.each(item.totals, function(total) {
            var day = parseInt(moment(total.day, 'DD/MM/YYYY').format('x'), 10);
            if (moment(day).get('month') === month.number) {
              month.date = total.day;
            }
          });
        });
      });
      return calendar;
    },

    renderWeekCalendar: function () {
      var users = this.model.toJSON(),
          weekCalendar = [];

      var wFrom = moment(parseInt(this.options.filters.dateFrom, 10)).isoWeek(),
          wTo = moment(parseInt(this.options.filters.dateTo, 10)).isoWeek(),
          displayedWeeks = _.range(wFrom, wTo + 1, 1);

      _.each(displayedWeeks, function (displayedWeek) {
        weekCalendar.push({
          weekNumber: displayedWeek,
          startWeek: moment().isoWeek(displayedWeek).startOf('isoWeek').format('DD/MM/YYYY'),
          endWeek: moment().isoWeek(displayedWeek).endOf('isoWeek').format('DD/MM/YYYY')
        });
      });

      _.each(users, function (user) {
        user.weeks = [];
        _.each(user.totals, function (total) {
          //Set the number of the week (used in the template)
          total.weekNumber = moment(total.day, 'DD/MM/YYYY').isoWeek();
        });
      });

      //Calculate the total per user per week
      var totalPerUserPerWeek = 0;
      _.each(weekCalendar, function (week) {
        _.each(users, function (user) {
          totalPerUserPerWeek = 0;
          _.each(user.totals, function (total) {
            if (total.weekNumber === week.weekNumber) {
              totalPerUserPerWeek += total.seconds;
            }
          });

          user.weeks.push({
            weekNumber: week.weekNumber,
            total: dateUtils.toHHMM(totalPerUserPerWeek, 'seconds')
          });

        });
      });

      return weekCalendar;
    },

    renderDayCalendar: function() {
      var dat = this.buildCalendarData(),
          daySpan = this.options.daySpan,
          timeJson = [];

      function pad(input) {
        var base = '00';

        return input ? base.substr(0, 2 - Math.ceil(input / 9)) + input : base;
      }

      for (var i = 0; i < daySpan;  i++) {
        var nextDate = dat.addDays(i),
            strDate = pad(nextDate.getDate()),
            strDay = nextDate.getDayName(),
            strMonth = pad(nextDate.getMonth() + 1),
            monthName = nextDate.getMonthName(strMonth),
            strYear = pad(nextDate.getFullYear());

        if (!_.isString(strDate) && strDate < 10) {
          strDate = '0' + strDate;
        }
        var fullDate = strDate + '/' + strMonth + '/' + strYear;
        var dayName = parseInt(moment(fullDate, 'DD/MM/YYYY').format('x'), 10);
        timeJson.push({
          day: strDay,
          date: strDate,
          month: monthName,
          dayName: dateUtils.getDayName(dayName),
          fullDate: fullDate
        });
      }
      return timeJson;
    }

  });

  module.exports = TimetrackerTable;
});
