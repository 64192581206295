
define('template!customerProdTemplateGroupsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n\t<div class="">\n\t\t<div class="panel panel-default template-group-item ';
 if (active) { ;
__p += 'active';
 } ;
__p += '">\n    \t<div class="icon-holder">\n    \t\t<i class="mdi-file-folder icon">\n\t\t\t\t<span class="templates-count text-in-icon">' +
((__t = ( templatesCount )) == null ? '' : __t) +
'</span>\n    \t\t</i>\n    \t\t\n    \t</div>\n    \t<div class="template-group-name">\n\t\t\t\t' +
((__t = ( productCode )) == null ? '' : __t) +
' - ' +
((__t = ( productName )) == null ? '' : __t) +
'\n    \t</div>\n    \t<div class="template-group-edit" style="display:none">\n\t\t\t<i class="mdi-editor-mode-edit icon js-edit-icon"></i>\n\t\t\t<i class="mdi-action-delete icon js-remove-icon"></i>\n    \t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

