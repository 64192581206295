/**
 * Created by RKL on 18/08/2015.
 */
define('customerProdTemplatesGroupView',[
  'module',
  'marionette',
  'customerProdTemplatesGroupItemView',
  'customerProdTemplatesGroupNewItemView'
], function (
  module,
  Marionette,
  ItemView,
  NewItemView
) {
  'use strict';

  var CustomersProducTemplatesTabGroupsCollectionView = Marionette
    .CollectionView.extend({
      getChildView: function(item) {
        if (item.get('id') !== 0) {
          return ItemView;
        } else {
          return NewItemView;
        }
      },

      childViewOptions: function() {
      return {
        data: this.options.data
      };
    }
  });

  module.exports = CustomersProducTemplatesTabGroupsCollectionView;
});
