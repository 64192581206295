
define('template!customerLogosImagesItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="template-content-container">\n  <div class="main text-center">\n    <div class="image-holder-container _keep-img-link _texture-bg js-image-holder-container">\n      <img class="image-holder js-image-holder transparent-bg">\n    </div>\n  </div>\n  <div class="template-item-editor js-template-item-editor">\n    <i class="mdi-editor-mode-edit icon js-edit-icon"></i>\n    <i class="mdi-image-color-lens icon js-modify-icon"></i>\n\n    <!--TODO - Display variation icon only if the logo has been created from a template-->\n    <!--<i class="mdi-file-folder icon js-variations-icon"></i>-->\n\n    <i class="mdi-action-delete icon js-remove-icon"></i>\n    <a id="download-attachment" mimetype="application/octet-stream" href="' +
((__t = ( downloadLink )) == null ? '' : __t) +
'" download>\n      <label for="download-attachment">\n        <i class="mdi-file-file-download icon js-download-icon"></i>\n      </label>\n    </a>\n    <i class="fa fa-search-plus icon js-zoom-icon" aria-hidden="true"></i>\n  </div>\n  <div class="template-meta-info-container"></div>\n</div>';

}
return __p
};});

