/**
 * Created by OLD on 28/01/2016.
 */
define('customerContactsDetailsController',[
  'module',
  'backbone',
  'app',
  'customersContactsTabDetailsLayoutView',
  'entities/customers/contacts'
], function(
  module,
  Backbone,
  App,
  DetailsLayoutView
) {
  'use strict';

  var CustomersContactsDetailController = {
    _layoutView: undefined,

    showTabsLayout: function(endUserId, region, tabName, contactsSecId) {
      var that = this,
        defer = $.Deferred(),
        itemModel = App.request('customers:contacts:get-model:item');

      if (this._layoutView && !this._layoutView.isDestroyed) {
        defer.resolve(this._layoutView.getRegion('tabs'), that._layoutView.model);
        this._layoutView.triggerMethod('show:active', tabName);

        return defer.promise();
      }

      itemModel._fetchModel(endUserId, contactsSecId).done(function() {
        that._layoutView = new DetailsLayoutView({
          model: itemModel
        });
        region.show(that._layoutView);
        that._layoutView.triggerMethod('show:active', tabName);

        defer.resolve(that._layoutView.getRegion('tabs'), that._layoutView.model);
      });

      return defer.promise();
    }
  };

  module.exports = CustomersContactsDetailController;
});
