define('adminKitpDetailsController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'adminKitpDetailsView',
  'emptyView',
  'kitpDetailTabController',
  'adminSettingKitpController',
  'rolesMixin',
  'settings',
  'entities/kitp'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  kitpDetailTabController,
  adminSettingKitpController
) {
  'use strict';

  var KitpsDetailsController = {
    fetchKitp: function(data) {
      var that = this,
          emptyView = new EmptyView(),
          contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.secId) {
        this.showKitp(data);
      } else {
        this.model = App.request('kitp:model', { secId: data.secId});
        this.model.fetch().done(function() {
          if (contentRegion) {
            that.showKitp(data);
          }
        });
      }
    },

    showKitp: function(data) {
      var view = new View({model: this.model, data: data});
      this.view = view;
      data.model = this.model;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      this.displayTabs(data);
      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
    },

    displayTabs: function(data) {
      for (var region in this.view.regions) {
        if (App.regions.getRegion('main') && App.regions.getRegion('main').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content') &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region)) {
          var currentViewRegion = App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region).currentView;
          if(currentViewRegion) {
            currentViewRegion.destroy();
          }
        }
      }

      if (data.tab) {
        switch (data.tab.name) {
          case 'setting':
              adminSettingKitpController.showSettingList(data);
              break;
          default:
            kitpDetailTabController.showTab(data);
            break;
        }
      } else {
        kitpDetailTabController.showTab(data);
      }

      data.model = this.model;
      this.tab = data.tab;
    },

    onTabClick: function(tab) {
        if (this.data.tab !== tab || this.data.secId) {
            this.data.tab = tab;
            this.data.secId = null;

            this.displayTabs(this.data);
            App.navigate('admin/kitp/' + this.model.get('secId') + '/' + tab.name);
        }
    }
  };

  module.exports = KitpsDetailsController;
});
