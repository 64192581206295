/**
 * Created by BFR on 10/07/2017.
 */
define('mobioChartView',[
  'module',
  'logger',
  'marionette',
  'underscore',
  'chartView'
], function(
    module,
    Logger,
    Marionette,
    _,
    ChartView
) {
  'use strict';

  var MobioChartView = ChartView.extend({

    min: 0,
    max: 0,
    slopeEnable: false,
    brutEnable: false,
    amplGraph: false,
    displayLegend: true,
    deriv2Enable: false,
    slopeAlgo: 'STD;5',

    slope: function(list, index, forceBrutSlope, forceSlope, slopeAglo) {
      var numberOfPoints, multi = [0.33, 0.66, 1, 0.66, 0.33], div = 3;

      numberOfPoints = parseInt(slopeAglo.split(';')[1], 10);

      if (forceSlope && numberOfPoints > 0) {
        var slopeValue = 0, start = -2, stop = 2, listdiff = list.length - index,
            j = 0;

        switch(numberOfPoints) {
            case 3:
                multi = [0.50, 1, 0.50];
                start = -1;
                stop = 1;
                if (index < 1) {
                    start = -index;
                }
                switch(listdiff) {
                    case 1: stop = -1; break;
                    case 2: stop = 0; break;
                    case 3: stop = 1; break;
                }
                break;
            case 5:
                multi = [0.33, 0.66, 1, 0.66, 0.33];
                start = -2;
                stop = 2;
                if (index < 2) {
                    start = -index;
                }
                switch(listdiff) {
                    case 1: stop = -1; break;
                    case 2: stop = 0; break;
                    case 3: stop = 1; break;
                    case 4: stop = 2; break;
                }
                break;
            case 7:
                multi = [0.25, 0.50, 0.75, 1, 0.75, 0.50, 0.25];
                start = -3;
                stop = 3;
                if (index < 3) {
                    start = -index;
                }
                switch(listdiff) {
                    case 0: stop = -2; break;
                    case 1: stop = -1; break;
                    case 2: stop = 0; break;
                    case 3: stop = 1; break;
                    case 4: stop = 2; break;
                }
                break;
            case 9:
                multi = [0.20, 0.40, 0.60, 0.80, 1, 0.80, 0.60, 0.40, 0.20];
                start = -4;
                stop = 4;
                if (index < 4) {
                    start = -index;
                }
                switch(listdiff) {
                    case 0: stop = -2; break;
                    case 1: stop = -1; break;
                    case 2: stop = 0; break;
                    case 3: stop = 1; break;
                    case 4: stop = 2; break;
                    case 5: stop = 3; break;
                }
                break;
            case 11:
                multi = [0.15, 0.30, 0.50, 0.70, 0.85, 1, 0.85, 0.70, 0.50, 0.30, 0.15];
                start = -5;
                stop = 5;
                if (index < 5) {
                    start = -index;
                }
                switch(listdiff) {
                    case 0: stop = -2; break;
                    case 1: stop = -1; break;
                    case 2: stop = 0; break;
                    case 3: stop = 1; break;
                    case 4: stop = 2; break;
                    case 5: stop = 3; break;
                    case 6: stop = 4; break;
                }
                break;
            case 13:
                multi = [0.10, 0.20, 0.35, 0.55, 0.75, 0.90, 1, 0.90, 0.75, 0.55, 0.35, 0.20, 0.10];
                start = -6;
                stop = 6;
                if (index < 6) {
                    start = -index;
                }
                switch(listdiff) {
                    case 0: stop = -2; break;
                    case 1: stop = -1; break;
                    case 2: stop = 0; break;
                    case 3: stop = 1; break;
                    case 4: stop = 2; break;
                    case 5: stop = 3; break;
                    case 6: stop = 4; break;
                    case 7: stop = 5; break;
                }
                break;
        }
        if (numberOfPoints > 0) {
            if (numberOfPoints % 2 === 0) {
                numberOfPoints--;
            }
            var half = ((numberOfPoints - 1) / 2);
            multi = [];
            stop = numberOfPoints - 1;
            start = -stop;
            if (index <= half) {
                start = -index;
                //j = stop / 2;
                stop = numberOfPoints - 1 + start;
            } else {
                start = -half;
                stop = half;
            }
            if (listdiff <= ((numberOfPoints +1) / 2)) {
                stop = listdiff - 2;
            }

            var val = 1 / (half + 1);
            for (var i = 0 ; i < half ; i++) {
                multi.push(val * (i + 1));
            }
            multi.push(1);
            for (var i = half - 1; i >= 0 ; i--) {
                multi.push(val * (i + 1));
            }
        }

        div = 0;
        for (var i = start ; i <= stop ; i++) {
          if (forceBrutSlope) {
              slopeValue += this.getFluor(list, index + i) * multi[j];
          } else {
              slopeValue += (this.getFluor(list, index + i) - this.getFluor(list, index + i + 1)) * multi[j];
          }
          div += multi[j];
          j++;
        }

        slopeValue /= div;

        if (forceBrutSlope) {
          return slopeValue;
        } else {
          return -slopeValue;
        }

      } else {
        if (this.brutEnable || forceBrutSlope) {
          return this.getFluor(list, index);
        } else {
          if (index === 0) {
            return -(this.getFluor(list, index) - this.getFluor(list, index + 1));
          } else {
            return -(this.getFluor(list, index - 1) - this.getFluor(list, index));
          }
        }
      }
    },

    getFluor: function(list, index) {
      return parseFloat(list[index].fluor);
    },

    generateLegend: function() {
      if (this.min === 0) {
        this.min = -0.6;
      }
      if (this.max === 0) {
        this.max = 2.6;
      }
      var xMax = 80, xMin = 30;
      if (this.amplGraph) {
        xMax = 45;
        xMin = 1;
      }
      var stepSize = parseFloat(((this.max - this.min) / 10).toFixed(1)); // this.max-this.min>1.2?0.2:0.1;
      if (!stepSize) {
        stepSize = 0.1;
      }

      var xAxe = {
          type: 'linear',
          position: 'bottom',
          ticks: {
              max: xMax,
              min: xMin,
              stepSize: 5
          }
      };

      if (this.xLabel) {
        xAxe.scaleLabel = {
          display: true,
          labelString: this.xLabel
        };
      }

        var yAxe;

      if (!this.brutEnable) {
        yAxe = {
          ticks: {
            max: this.max + (stepSize - (this.max % stepSize)),
            min: this.min - (stepSize + (this.min % stepSize)),
            stepSize: stepSize
          }
        };
      } else {
          yAxe = {
          };
      }

        if (this.yLabel) {
            yAxe.scaleLabel = {
                display: true,
                labelString: this.yLabel
            };
        }

        this.options.scales = {
            xAxes: [xAxe],
            yAxes: [yAxe]
        };


      this.options.legend = {
        labels: {
          boxWidth: 1
        },
        display: this.displayLegend
      };
    },

    getRandomColor: function () {
      var letters = '0123456789ABCDEF'.split('');
      var color = '#';
      for (var i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    },

    addData: function(curveData, r, g ,b, label, slopeAlgo) {
      var dataCreated = [],
          color,
          currentValue/*, maxValue = 0*/, multiplicateur = 1;

      if (r !== null && g !== null && b !== null) {
        color = 'rgba('+r+', '+g+', '+b+', 1)';
      } else {
        color = this.getRandomColor();
      }

      var curveDateSloped = [], newItem;
      _.each(curveData, _.bind(function(item, index) {
        newItem = _.extend({}, item);
        newItem.fluor = this.slope(curveData, index, true, this.slopeEnable, slopeAlgo);
        curveDateSloped.push(newItem);
      }, this));
      curveData = curveDateSloped;

      /*_.each(curveData, _.bind(function(item, index) {
        maxValue = Math.max(maxValue, this.slope(curveData, index));
      }, this));

      if (maxValue) {
        multiplicateur = 10 / maxValue;
      }*/

      if (this.deriv2Enable) {
        curveDateSloped = [];

        _.each(curveData, _.bind(function(item, index) {
            newItem = _.extend({}, item);
            newItem.fluor = this.slope(curveData, index, false, true, slopeAlgo) * multiplicateur;
            curveDateSloped.push(newItem);
        }, this));
        curveData = curveDateSloped;
      }

      _.each(curveData, _.bind(function(item, index) {

        if (this.amplGraph) {
          currentValue = index + 1;
        } else {
          currentValue = parseFloat(item.temp);
        }

        if ((index !== 0 || (this.amplGraph && this.brutEnable)) && ((currentValue > 30 && currentValue < 80) || this.amplGraph)) {
          var dataItem, slopeY;
          slopeY = this.slope(curveData, index, null, null, slopeAlgo) * (this.brutEnable||this.deriv2Enable?1:multiplicateur);

          this.min = Math.min(this.min, slopeY);
          this.max = Math.max(this.max, slopeY);

          dataItem = {
            x: currentValue,
            y: slopeY
          };

          dataCreated.push(dataItem);
        }
      }, this));

      _.sortBy(dataCreated, function(o) { return o.x; });

      return {
        label: label,
        borderColor : color,
        fill: false,
        pointRadius: 0,
        cubicInterpolationMode: 'monotone',
        data : dataCreated
      };
    },

    addLine: function(value, name, r, g, b) {
      var dataCreated = [], dataItem, color;

      if (r !== null && g !== null && b !== null) {
        color = 'rgba('+r+', '+g+', '+b+', 1)';
      } else {
        color = this.getRandomColor();
      }

      for (var i = 0 ; i < this.data.datasets[0].data.length ; i++) {
        dataItem = {
          x: this.data.datasets[0].data[i].x,
          y: parseFloat(value)
        };
        dataCreated.push(dataItem);
      }
      return {
        label: name,
        borderColor : color,
        fill: false,
        pointRadius: 0,
        cubicInterpolationMode: 'monotone',
        data : dataCreated
      };
    }
  });

  module.exports = MobioChartView;
});
