/**
 * Created by obl on 14-01-16.
 */
define('superAdminCAccountController',[
    'module',
    'underscore',
    'app',
    'superAdminCAccountView',
    'emptyView',
    'caccountSAProfileTabController',
    'caccountSACreditsTabController',
    'caccountSAGraphicalRequestsTabController',
    'rolesMixin',
    'settings',
    'superAdminCAccountTabs',
    'entities/caccounts'
], function(
    module,
    _,
    App,
    View,
    EmptyView,
    CaccountSAProfileTabController,
    CaccountSACreditsTabController,
    CaccountSAGraphicalRequestsTabController,
    RolesMixin,
    Settings,
    SuperAdminCAccountTabs
    ) {
    'use strict';

    var CaccountDetailsController = {
        fetchCaccount: function(data) {
            var that = this,
                emptyView = new EmptyView(),
                contentRegion =  App.regions.getRegion('main').currentView.getRegion('content');

            contentRegion.show(emptyView);

            this.data = data;
            this.model = App.request('caccount:entity', data.secId);
            data.model = this.model;
            if (data.cAccountId === 'new') {
                this.showCAccount(data);
            } else {
                this.model.fetch().done(function() {
                    if (contentRegion) {
                        that.showCAccount(data);
                    }
                });
            }
        },

        showCAccount: function(data) {
            var view = new View({model: this.model, data: data});

            this.view = view;
            App.regions.getRegion('main').currentView.getRegion('content').show(view);
            this.displayTabs(data);

            view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
            view.listenTo(App, 'caccount:header:rerender', _.bind(this.onHeaderChange, this));
        },

        onHeaderChange: function (caccount) {
            this.model.set({
                name: caccount.name,
                code: caccount.code,
                phone: caccount.phone,
                contactEmail: caccount.contactEmail,
                logo: caccount.logo
            });
            this.view.onHeaderChange(caccount);
        },

        displayTabs: function(data) {
            var roles = Settings.get('cAccountRoles' ),
                controllers = {};
            controllers[ RolesMixin.DASHBOARD_SUPERADMIN_ACCOUNT_PROFILE] = CaccountSAProfileTabController;
            controllers[ RolesMixin.DASHBOARD_SUPERADMIN_ACCOUNT_CREDITS] = CaccountSACreditsTabController;
            controllers[ RolesMixin.DASHBOARD_SUPERADMIN_ACCOUNT_GRAPHICALREQUESTS] = CaccountSAGraphicalRequestsTabController;
            data.model = this.model;

            if (data.tab) {
                controllers[data.tab.role].showTab(data);
            } else if (roles) {
                _.some(SuperAdminCAccountTabs, function (c, index) {
                    data.tab = SuperAdminCAccountTabs[index];
                    if (roles[data.tab.role] === true) {
                        controllers[data.tab.role].showTab(data);
                        return true;
                    }
                });
            }

            this.tab = data.tab;
        },

        onTabClick: function(tab) {
            if (this.data.tab !== tab || this.data.secId) {
                this.data.tab = tab;
                this.displayTabs(this.data);
                App.navigate('superAdmin/caccount/' + this.data.secId + '/' + tab.name);
            }
        }
    };

    module.exports = CaccountDetailsController;
});
