    /**
 * Created by OLD on 17/09/2015.
 */
define('galleryView',[
  'module',
  'logger',
  'app',
  'marionette',
  'underscore',
  'photoswipe',
  'photoswipeUi',
  'template!galleryViewTpl'
], function(
  module,
  Logger,
  App,
  Marionette,
  _,
  PhotoSwipe,
  PhotoSwipeUI,
  viewTpl
) {
  'use strict';

  var GalleryView = Marionette.ItemView.extend({

    template: viewTpl,

    ui: {
      psw: '.pswp'
    },

    setSizeParams: function (data) {
      var w, h,
          ratio = 0,
          maxWidth = $(document.body).width();

      if (data.width < maxWidth) {
        // get ratio for scaling image
        ratio = maxWidth / data.width;
        // Set new width
        w = maxWidth;
        // Scale height based on ratio
        h = data.height * ratio;
        // Reset height to match scaled image
        data.height = data.height * ratio;
        data.width = data.width * ratio;
      } else {
        h = data.height;
        w = data.width;
      }

      return {h: h, w: w};
    },

    createGallery: function() {
      var that = this,
        data = {},
        imageItem = [];

      _.each(this.images, function(item, index) {
        if(item.get('height') === undefined ||
           item.get('width') === undefined) {
          throw 'width or height is undefined for image ' + item.get('fileName') + '. Index: ' + index;
        }
        data.height = item.get('height');
        data.width = item.get('width');
        imageItem.push({
          w: that.setSizeParams(data).w,
          h: that.setSizeParams(data).h,
          src: item.get('zoomLink'),
          msrc: item.get('fileLink'),
          title: item.get('fileName'),
          index: index
        });
        that.setImageSize(imageItem);
        that.onLoadEnd();
      });
    },

    getSize: function () {
      var that = this,
        data = {},
        imageItems = [],
        galleryItems = [],
        imagesProcessed = 0;

      _.each(this.images, function(item, index) {
        var img = new Image();          
        
        img.onload = function () {
          data.height = img.height;
          data.width = img.width;
          
          var imageData = {
            w: that.setSizeParams(data).w,
            h: that.setSizeParams(data).h,
            src: item.get('zoomLink'),
            msrc: item.get('fileLink'),
            title: item.get('fileName'),
            index: index
          };

          if (index === that.indexOf) {
            galleryItems.push(imageData);
            that.parseCollection(galleryItems, 0);
          } else {
            imageItems.push(imageData);
          }

          if (++imagesProcessed === that.images.length &&
              !_.isEmpty(galleryItems)) {
            that.onAllLoaded(imageItems, galleryItems[0]);
          }
        };
        img.onerror = function() {
          if (++imagesProcessed === that.images.length) {
            that.onAllLoaded(imageItems, galleryItems[0]);
          }
        };
        // this must be done AFTER setting onload
        img.src = item.get('zoomLink');
      });
    },

    onAllLoaded: function(imageItems, current) {
      var that = this;

      imageItems = _.sortBy(imageItems, 'index');

      _.each(imageItems, function (image) {
        var currentIndex = _.indexOf(that.gallery.items, current);

        if (image.index > that.indexOf) {
          that.gallery.items.push(image);
        } else {
          that.gallery.items.splice(currentIndex, 0, image);
        }
      });

      if (!that.closed) {
        if (this.gallery !== undefined && this.gallery !== null) {
          this.gallery.updateSize(true);
          this.gallery.ui.update();
          this.gallery.goTo(this.indexOf);
        }
        this.onLoadEnd();
      }
    },

    setImageSize: function(images) {
      if (this.images.length === images.length) {
        this.parseCollection(
          _.sortBy(images, 'index'), 
          this.indexOf
        );
      }
    },

    parseCollection: function(items, indexOf) {
      var that = this,
        pswpElement = this.ui.psw[0];

      // define options (if needed)
      var options = {        
        index: indexOf,
        bgOpacity: 0.7,
        preload: [1,3],
        history: false,
        closeOnScroll: false
      };

      // Initializes and opens PhotoSwipe
      this.gallery = new PhotoSwipe(
        pswpElement, 
        PhotoSwipeUI, 
        items, 
        options
      );
      this.gallery.init();

      this.gallery.listen('close', function() {
        that.onLoadEnd();
        that.closed = true;
      });
    },

    onShow: function() {
      var that = this;

      this.images = this.options.data;
      _.each(this.images, function(item, index) {
        if (item.get('id') === that.options.id) {
          that.indexOf = index;
        }

        if (item.get('downloadLink')) {
          if (item.get('downloadLink').indexOf('?') !== -1) {
            item.set('zoomLink', item.get('downloadLink') + '&action=preview&params=m');
          } else {
            item.set('zoomLink', item.get('downloadLink') + '?action=preview&params=m');
          }
        }



      });
      if (!this.options.hasSize) {
        this.getSize();
      } else {
        this.createGallery();
      }
    },

    onLoadEnd: function() {
      if (_.isFunction(this.options.onLoadEnd)) {
          this.options.onLoadEnd();
        }
    },

    onDestroy : function() {
      if (this.instance) {
        this.instance.destroy();
        Logger.log(module, 'destroy');
      }
    }
  });

  module.exports = GalleryView;
});
