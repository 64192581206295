define( 'entities/caccounts/templates',[
  'app',
  'jquery',
  'backbone',
  'settings',
  'underscore'
], function(
  App,
  $,
  Backbone,
  Settings,
  _
) {
  'use strict';

  /**
   * Model
   */
  var Template = Backbone.Model.extend({
    defaults: {
      secId: null
    },

    getImageId: function() {
      return this.get('secId').split('_')[0];
    },

    getImageUrl: function(width, height) {
      var templateId = this.get('secId').split('_')[0],
        params = {
          minWidth: width,
          minHeight: height,
          db: true
        },
        imageUrl = Settings.url(
          'x2png',
          'template/' + templateId + '/preview',
          params
        );

      return imageUrl;
    },

    url: function() {
      return Settings.url('compuzz', 'v2/templates');
    },

    postUrl: function() {
      return Settings.url('compuzz', 'v2/templates');
    },

    deleteUrl: function() {
      return Settings.url('compuzz', 'v2/templates/' + this.get('secId'));
    },

    createFromTemplate: function(data) {
      var that = this,
          url, params = {
            templateGroupSecId: data.templateGroupSecId,
            endUserId: data.endUserId
          };

      if (data.refParent) {
        params.templateParentSecId = data.refParent.secId;
      }

      url = Settings.url('compuzz',
        'v2/templates/create/fromTemplate/' + data.templateSecId, params);

      this.trigger('request');
      $.ajax({
        url: url,
        type: 'POST',
        success: function(model) {
          that.set(model);
          that.trigger('sync', data.fromVariations);
        },
        error: function(resp) {
          that.trigger('error', resp);
        }
      });
    },

    idAttribute: 'secId'
  });

  /**
   * Collection
   */
  var Templates = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/templategroups');
    },

    fetchByStdSizeGeometryTplGroupSecId: function(params) {
      var that = this;

      if(!params.geometry || !params.endUserId ||
         !params.templateGroupSecId) {
        throw 'There are not all required params. Request should be.\n' +
        '/v2/templates?tokenId={token}&standardSizeSecId={SECID}&geometry={GEOMETRY}&endUserId={ENDUSERID}' +
        '&includeOthers=true&templateGroupSecId={templateGroupSecId}';
      }
      params.includeOthers = true;
      params.includeCollections = true;
      params.includeMetaXml = true;
      this.url = Settings.url('compuzz', 'v2/templates', params);

      this.fetch({
        success: function(collection, resp) {
          that.reset(resp);
        }
      });
    },
    model: Template
  });

  var TemplateGroup = Backbone.Model.extend({
    defaults: {
      active: false,
      count: undefined
    },
    idAttribute: 'secId',

    url: function() {
      return Settings.url('compuzz', 'v2/templategroups/' + this.get('secId'));
    },

    getCount: function() {
      var that = this,
        params = {
          templateGroupSecId: this.get('secId'),
          fromPublic: false,
          endUserId: this.get('endUserId')
        }, url;

      url = Settings.url('compuzz', 'v2/templates/count', params);

      $.ajax({
        url: url,
        success: function(count) {
          that.set({count: count});
        }
      });
    }
  });

  var TemplateGroups = Backbone.Collection.extend({
    model: TemplateGroup,

    url: function() {
      return Settings.url('compuzz', 'v2/templategroups');
    }
  });

  var PrintAreaTemplateGroups = Backbone.Collection.extend({
    model: TemplateGroup,

    url: function() {
      return Settings.url('compuzz', 'v2/templategroups/purpose/PrintArea');
    }
  });

  var DesignElementTemplateGroups = Backbone.Collection.extend({
    model: TemplateGroup,

    url: function() {
      return Settings.url('compuzz', 'v2/templategroups/purpose/DesignElement');
    }
  });

  /**
   * REST API
   */
  var API = {
    getTemplateGroup: function(secId) {
      var templateGroup = new Template();

      templateGroup.url = Settings.url('compuzz', 'v2/templategroups/' + secId);

      return templateGroup;
    },

    getTemplateModel: function(options) {
      var templateModel = new Template(options);

      return templateModel;
    },

    getTemplates: function(data) {
      var templates = new Templates(),
        params = {
          templateGroupSecId: data.secId,
          fromPublic: false,
          includeMetaXml: true
        },

        defer = $.Deferred();

      if (data.endUserId) {
        params.endUserId = data.endUserId;
      }

      if (data.filters) {
        _.each(data.filters, function(value, filter){
          params[filter] = value;
        });
      }

      $.ajax({
        method: 'get',
        url: Settings.url('compuzz', 'v2/templates', params),
        success: function(response) {
          _.each(response, function(template) {
            templates.add(new Template(template));
          });
          defer.resolve(templates);
        }
      });

      return defer.promise();
    },

    getTemplateVariations: function (parentTemplateSecId) {
      var templates = new Templates(),
          defer = $.Deferred(),
          params = {
            includeMetaXml: true
          };

      $.ajax({
        method: 'get',
        url: Settings.url('compuzz', 'v2/templates/' + parentTemplateSecId + '/variations', params),
        success: function (response) {
          _.each(response, function (template) {
            templates.add(new Template(template));
          });
          defer.resolve(templates);
        }
      });

      return defer.promise();
    },

    createTemplateFromUpload: function(data) {
      var defer = $.Deferred(),
        params = {
          templateGroupSecId: data.secId
        },
        url;

      if (data.endUserId) {
        params.endUserId = data.endUserId;
      }

      if (data.refParent) {
        params.templateParentSecId = data.refParent.secId;
      }

      if (data.importIntoPdfImage) {
        params.importIntoPdfImage = data.importIntoPdfImage;
      }

      url = Settings.url('compuzz', 'v2/templates/create/fromUpload', params);

      $.ajax({
        type: 'POST',
        url: url,
        data: data.formdata,
        processData: false,
        contentType: false,
        success: function(resp) {
          defer.resolve(resp);
        },
        error: function(resp) {
          defer.reject(resp);
        }
      });

      return defer.promise();
    },

    createTemplateFromAttach: function(data) {
      var defer = $.Deferred(),
        params = {
          templateGroupSecId: data.secId,
          importIntoPdfImage: data.importIntoPdfImage || false
        },
        url;

      if (data.endUserId) {
        params.endUserId = data.endUserId;
      }

      if (data.refParent) {
        params.templateParentSecId = data.refParent.secId;
      }

      url = Settings.url(
        'compuzz',
        'v2/templates/create/fromAttachment/' + data.attachId,
        params
      );

      $.ajax({
        type: 'POST',
        url: url,
        success: function(resp) {
          defer.resolve(new Template(resp));
        }
      });

      return defer.promise();
    },

    createTemplateFromEmpty: function(data, endUserId) {
      var defer = $.Deferred(),
        template = new Template(data),
        params = {};

      if (endUserId) {
        params.endUserId = endUserId;
      }

      template.url = Settings.url('compuzz', 'v2/templates', params);

      template.save({}, {
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getTemplatesCount: function(data) {
      var defer = $.Deferred(),
        params = {
          templateGroupSecId: data.secId,
          fromPublic: false
        },
        url;

      if (data.endUserId) {
        params.endUserId = data.endUserId;
      }

      url = Settings.url('compuzz', 'v2/templates/count', params);

      $.ajax({
        url: url,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getTemplateBySecId: function(secId) {
      var template = new Template();

      template.url = Settings.url('compuzz', 'v2/templates/' + secId);
      return template;
    },

    getStandartSizes: function(data) {
      var defer = $.Deferred(),
        params = {
          templateGroupSecId: data.secId,
          fromPublic: false
        },
        url;

      if (data.endUserId) {
        params.endUserId = data.endUserId;
      }

      url = Settings.url('compuzz', 'v2/templates/standardSizes', params);

      $.ajax({
        url: url,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    pasteTemplatesTo: function(params) {
      var defer = $.Deferred(),
        url;

      url = Settings.url(
        'compuzz',
        'v2/templates/paste'
      );
      params = _.extend({}, params);
      params.secIds = _.pluck(params.secIds, 'secId');

      $.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(params),
        processData: false,
        contentType: 'application/json',
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }

  };

  /**
   * Request communications
   */
  App.reqres.setHandler('caccount:template-groups', function() {
    return new TemplateGroups();
  });

  App.reqres.setHandler('caccount:print-area:template-groups', function() {
    return new PrintAreaTemplateGroups();
  });

  App.reqres.setHandler('caccount:design-elements:template-groups', function() {
    return new DesignElementTemplateGroups();
  });

  App.reqres.setHandler('caccount:template-group', function(secId) {
    return API.getTemplateGroup(secId);
  });

  App.reqres.setHandler('caccount:template-model', function(options) {
    return API.getTemplateModel(options);
  });

  App.reqres.setHandler('caccount:template-by-secId', function(secId) {
    return API.getTemplateBySecId(secId);
  });

  App.reqres.setHandler('caccount:templates', function(data) {
    return API.getTemplates(data);
  });

  App.reqres.setHandler('caccount:templates-collection', function(templatesJSON) {
    return new Templates(templatesJSON);
  });

  App.reqres.setHandler('caccount:templates-standard-sizes', function(data) {
    return API.getStandartSizes(data);
  });

  App.reqres.setHandler('caccount:template-count', function(data) {
    return API.getTemplatesCount(data);
  });

  App.reqres.setHandler('caccount:create-template', function(data) {
    return API.createTemplateFromUpload(data);
  });

  App.reqres.setHandler('caccount:create-from-attach', function(data) {
    return API.createTemplateFromAttach(data);
  });

  App.reqres.setHandler('caccount:create-from-empty', function(data, id) {
      return API.createTemplateFromEmpty(data, id);
    }
  );
  App.reqres.setHandler('caccount:template-variations', function(parentTemplateSecId) {
    return API.getTemplateVariations(parentTemplateSecId);
  });

  App.reqres.setHandler('caccount:template-group:model', function(params) {
    return new TemplateGroup(params);
  });

  App.reqres.setHandler('caccount:template-paste-to', function(params) {
    return API.pasteTemplatesTo(params);
  });
});
