/**
 * Created by OLD on 20/08/2015.
 */
define('caccountAddCreditsPopupView',[
  'module',
  'settings',
  'template!caccountAddCreditsChooseMethodPopupTpl',
  'template!caccountAddCreditsBankTransferPopupTpl',
  'template!caccountAddCreditsPopupTpl',
  'dialogFormView',
  'stateMessages'
], function(
  module,
  Settings,
  chooseMethodTpl,
  bankTransferTpl,
  viewTpl,
  DialogFormView,
  StateMessages
  ) {
  'use strict';

  var AddCreditPopupView = DialogFormView.extend({
    template: viewTpl,
    chooseMethodTpl: chooseMethodTpl,
    bankTransferTpl: bankTransferTpl,

    ui: {
      input: '.radio-chooser input',
      creditInput: '.input-number-quantity',
      payPalBtn: '.credit-paypal-btn',
      bankTransfertBtn: '.banktransfert-btn',
      modalFooterBtn: '.modal-footer button',
      needHelp: '.need-help-link',
      bankTransferApply: '.banktransfer-apply-b'
    },

    events: {
      'change @ui.input': 'onInputChange',
      'change @ui.creditInput' : 'onInputChange',
      'focus @ui.creditInput': 'onFocus',
      'click @ui.payPalBtn' : 'choosePayPalMethod',
      'click @ui.bankTransfertBtn' : 'chooseBankTransferMethod',
      'click @ui.bankTransferApply' : 'applyBankTransferBtn'
    },

    modelEvents: {
      change: 'onChangeModel'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    serializeData: function() {
      return {
        cAccountName: Settings.get('currentCAccount' ).code,
        quantity: this.model.get('quantity')
      };
    },

    onFocus: function(e) {
      var $target = $(e.currentTarget),
        type = $target.attr('type');

      if (type !== 'radio') {
        $('input[type="radio"]').prop('checked', false);
        $('input[type="radio"]').removeAttr('checked');
      }
    },

    onChangeModel: function() {
      this.ui.creditInput.val(this.model.get('quantity')).change();
    },

    choosePayPalMethod: function () {
      this.trigger('creditpopup:choose-paypal');
    },

    chooseBankTransferMethod: function() {
      this.trigger('creditpopup:choose-banktransfer');
    },

    applyBankTransferBtn: function() {
      this.trigger('creditpopup:apply-banktransfer');
    },

    onRender: function () {
      $.material.init();      
    },

    onDomRefresh: function () {
      if (this.options.paypal) {
        $('.add-credits-popup').find('.modal-footer').removeClass('hidden');
        this.ui.needHelp.attr('style', 'bottom:-1em;');
      } else {
        $('.add-credits-popup').find('.modal-footer').addClass('hidden');
      }
    },

    getTemplate: function() {
      if (this.options.paypal) {
        return this.template;
      } else if (this.options.bankTransfer) {
        return this.bankTransferTpl;
      } else {
        return this.chooseMethodTpl;
      }
    }
  });

  module.exports = AddCreditPopupView;
});
