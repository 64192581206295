/**
 * Created by OBL on 14/01/2016.
 */
define('adminKitcController',[
    'module',
    'underscore',
    'app',
    'adminKitcView',
    'bootbox',
    'kitcCreateEditLayoutView',
    'settings',
    'entities/kitc'
], function(
    module,
    _,
    App,
    View,
    bootbox,
    CreateEditLayoutView
    ) {
    'use strict';

    var SuperAdminKitcListController = {
        showKitcList: function(data) {
            this.data = data;
            var view = new View({data: data});
            this.view = view;
            view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            view.listenTo(view, 'kitc:delete', _.bind(this.onDelete, this));
            view.listenTo(view, 'kitc:edit', _.bind(this.onEdit, this));
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('kitcListTab').show(view);
        },

        showKitcAdd: function(mbKitSecId) {
            this.showKitcAddEdit(App.request('kitc:model', {
                refKit: {
                    secId: mbKitSecId
                }
            }), true);
        },

        showKitcAddEdit: function(model, newItem) {
            this.createEditView = new CreateEditLayoutView({
                kitcModel: model,
                newItem: newItem
            });
            this.createEditView.show({
                title: _.i18n('kitc.new')
            });
        },

        showKitcEdit: function(mbKitSecId, kitcSecId) {
            var that = this;
            var model = App.request('kitc:model', {
                refKit: {
                    secId: mbKitSecId
                },
                secId: kitcSecId
            });
            model.fetch().done(function() {
                that.showKitcAddEdit(model, false);
            });
        },

        onEdit: function(mbKitSecId, kitcSecId){
            App.navigate('admin/kits/' + mbKitSecId + '/kitc/' + kitcSecId + '/edit', {trigger: true});
        },

        onGridLoaded: function() {
            var kitSecId = this.data.model.get('refKitSecId')?
                this.data.model.get('refKitSecId'):this.data.model.get('secId');
            var data = {
                url: App.request('kitc:get-collection-url', kitSecId),
                rowCountUrl: App.request('kitc:get-count-url', kitSecId)
            };

            this.view.displayGrid(data);
        },

        onDelete: function(kitSecId, secId){
            bootbox.confirm(_.i18n('kitc.confirmDelete'), _.bind(function(result) {
                if(result){
                    App.request('kitc:delete', kitSecId, secId)
                        .done(_.bind(function(){
                            this.view.updatePagination('reloadGrid');
                        }, this));
                }
            }, this));
        }
    };

    module.exports = SuperAdminKitcListController;
});
