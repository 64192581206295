define('runTemplateDetailsTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'runTemplateDetailsTabView',
    'emptyView',
    'wellTemplateTableView',
    'headerWellTemplateView',
    'wellTemplateEditRefAssayView',
    'wellTemplateEditRefKitLView',
    'wellTemplateEditSmpIdView',
    'entities/welltemplates'
], function(
    module,
    App,
    _,
    Backbone,
    DetailsTabView,
    EmptyView,
    WellTableView,
    HeaderWellView,
    CreateEditLayoutView,
    CreateEditRefKitLLayoutView,
    CreateEditSmpIdLayoutView
) {
    'use strict';

    var DetailsTabController = {
        showDetailsTab: function(region, runModel, row, column, filters) {
            var that = this;
            this.model = runModel;
            this.view = new DetailsTabView({
            });

            this.row = row;
            this.column = column;
            this.filters = filters;

            region.show(this.view);
            this.view.getRegion('wellTable').show(new EmptyView());
            that.onShowWellTable();
        },

        onShowWellTable: function() {
            this.wellView = new WellTableView({
                tabName: this.tabName
            });

            this.wellView.model = this.model;

            this.wellView.listenTo(this.wellView, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.wellView.listenTo(this.wellView, 'welltemplate-table:loaded', _.bind(this.onLoadSubViews, this));
            this.wellView.listenTo(this.wellView, 'welltemplate-table:edit-ref-assay', _.bind(this.onEditWellsRefAssay, this));
            this.wellView.listenTo(this.wellView, 'welltemplate-table:edit-ref-kitl', _.bind(this.onEditWellsRefKitL, this));
            this.wellView.listenTo(this.wellView, 'welltemplate-table:edit-smpid', _.bind(this.onEditWellsSmpId, this));
            this.wellView.listenTo(App, 'well:header:rerender', _.bind(this.onWellChange, this));
            this.wellView.listenTo(App, 'well:mass:edit', _.bind(this.onWellsChange, this));
            this.view.getRegion('wellTable').show(this.wellView);
        },

        onGridLoaded:function () {
            var smpid = this.filters?this.filters.smpid:null;
            var assaySecId = this.filters?this.filters.assaySecId:null;
            var data = {
                url: App.request('welltemplate:get-collection-url', this.model.get('secId'), this.row,
                    this.column, assaySecId, smpid),
                rowCountUrl: App.request('welltemplate:get-count-url', this.model.get('secId'), this.row,
                    this.column, assaySecId, smpid)
            };
            this.wellView.displayGrid(data);
        },

        onEditWellsSmpId:function(items) {
            var models = this.getModels(items);

            this.createEditSmpIdView = new CreateEditSmpIdLayoutView({
                models: models,
                newItem: false
            });
            this.createEditSmpIdView.show({
                title: _.i18n('welltemplate.edit')
            });
        },

        onEditWellsRefAssay: function(items) {
            var models = this.getModels(items);

            this.createEditView = new CreateEditLayoutView({
                models: models,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('welltemplate.edit')
            });
        },

        onEditWellsRefKitL: function(items) {
            var models = this.getModels(items);

            this.createEditView = new CreateEditRefKitLLayoutView({
                models: models,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('welltemplate.edit')
            });
        },

        getModels: function(items) {
            var models = [];
            _.each(items, _.bind(function (item) {
                var well = _.findWhere(this.model.get('wells'), {secId: item.secId});
                models.push(App.request('welltemplate:model', well));
            }, this));
            return models;
        },

        onWellsChange: function (wells) {
            _.each(wells, _.bind(function(well) {
                var wellJson = well.toJSON();
                for (var i = 0 ; i < this.model.get('wells').length ; i++) {
                    if (this.model.get('wells')[i].secId === wellJson.secId) {
                        this.model.get('wells')[i]= wellJson;
                    }
                }
            }, this));
            this.onShowWellTable();
        },

        onWellChange: function (wellJson) {
            for (var i = 0 ; i < this.model.get('wells').length ; i++) {
                if (this.model.get('wells')[i].secId === wellJson.secId) {
                    this.model.get('wells')[i]= wellJson;
                }
            }
            this.onShowWellTable();
        },

        onLoadSubViews: function(wellSecId) {
            var well = _.findWhere(this.model.get('wells'), {secId: wellSecId});

            this.view.model = App.request('welltemplate:model', well);
            this.view.modelRun = this.model;
            if (this.headerWellView) {
                this.headerWellView.destroy();
            }
            this.headerWellView = new HeaderWellView();
            this.headerWellView.model = this.view.model;
            this.headerWellView.modelRun = this.model;
            var region = this.view.getRegion('headerWell');
            if (region){
                region.show(this.headerWellView);
            }
        }
    };

    module.exports = DetailsTabController;
});
