
define('template!mbAnaResCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container create-edit-anares-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group" data-field-name="code">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input js-code"\n                 data-field-name="code" placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'"\n                 value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('mbanares.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                 data-placement="top">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="name">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="txt">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.txt') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="txt" placeholder="' +
((__t = ( _.i18n('common.txt') )) == null ? '' : __t) +
'" value="' +
((__t = ( txt )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group set-colors" data-field-name="colorHexa">\n        <div class="color-input-item">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('mbanares.color') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9 input-group mbanares-color">\n            <span class="hidden"></span>\n            <label class="btn btn-default btn-flat btn-color colorpick" data-key="colorHexa">\n            <span class="btn-group">\n                <div class="color-block add-on" style="background-color:#' +
((__t = ( colorHexa )) == null ? '' : __t) +
';"></div>\n            </span>\n              <input value="#' +
((__t = ( colorHexa )) == null ? '' : __t) +
'">\n            </label>\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

