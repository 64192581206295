define('datePickerPopoverMixin',[
  'module',
  'jquery',
  'underscore',
  'datePickerPopoverView',
  'moment',
  'dateUtils'
], function(
  module,
  $,
  _,
  DatePickerPopoverView,
  moment,
  dateUtils
) {
  'use strict';

  var datePickerHelper = {
    jqGridDataInit: function (element) {
      function updateInput(timePeriod) {
        if (timePeriod.dateFrom) {
          $element.val(timePeriod.dateFrom + '-' + timePeriod.dateTo).change();
        } else {
          $element.val('');
        }
        $element.popover('hide');
      }

      var $element = $(element),
        datePickerPopoverView = new DatePickerPopoverView({
          datePeriod: $element.val()
        });
      $element.attr('type', 'period-picker-popover');
      $element.on('clear', function() {
        datePickerPopoverView.triggerMethod('should:clear');
      });
      datePickerPopoverView.render();

      $element.popover({
        html: true,
        content: datePickerPopoverView.$el,
        placement: 'bottom',
        trigger: 'manual',
        container: '.ui-jqgrid-view'
      });

      $element.click(function () {
        if(!$element.hasClass('popover-shown')) {
          $('input.popover-shown[type="period-picker-popover"]').popover('toggle');
        }
        $element.popover('toggle');
      });
      $element.on('shown.bs.popover', function () {
        $.material.init();
        datePickerPopoverView.triggerMethod('clear:inputs');
        $element.addClass('popover-shown');
      });

      $element.on('hidden.bs.popover', function() {
        $element.removeClass('popover-shown');
      });
      datePickerPopoverView.listenTo(datePickerPopoverView, 'apply', updateInput);
      datePickerPopoverView.listenTo(datePickerPopoverView, 'close:popover', function() {
        $element.popover('hide');
      });
    },

    parseDateFrom: function(value) {
      return datePickerHelper._parseDate(/(\d+\/\d+\/\d+)-\d+\/\d+\/\d+/, value);
    },

    parseDateTo: function(value) {
      return datePickerHelper._parseDate(/\d+\/\d+\/\d+-(\d+\/\d+\/\d+)/, value);
    },

    _parseDate: function(regex, value) {
      var regexResult = regex.exec(value),
        date = moment.utc(new Date(dateUtils.toMDYFormat(regexResult[1]))).toDate();

      return date.getTime();
    }

  };

  module.exports = datePickerHelper;
});
