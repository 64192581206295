
define('template!wellCreateEditTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="p-t-8">\n            ' +
((__t = ( pos ? pos : '-' )) == null ? '' : __t) +
'\n          </div>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="plateId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('well.smpid') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input js-smpid"\n                 data-field-name="smpid" placeholder="' +
((__t = ( _.i18n('well.smpid') )) == null ? '' : __t) +
'"\n                 value="' +
((__t = ( smpid )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                 data-placement="top">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('well.smptype') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="smptype" value="">\n            <option value="" ';
  if (!smptype) { ;
__p += ' selected ';
 } ;
__p += ' ></option>\n            <option value="E" ';
  if (smptype === 'E') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.e') )) == null ? '' : __t) +
'</option>\n            <option value="C" ';
  if (smptype === 'C') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.c') )) == null ? '' : __t) +
'</option>\n            <option value="U" ';
  if (smptype === 'U') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.u') )) == null ? '' : __t) +
'</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('well.refAssay') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-assay-region"></div>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('well.refKitL') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-kitl-region"></div>\n        </div>\n      </div>\n      <div class="row">\n        <div class=""></div>\n        <label class="col-xs-offset-1 col-xs-1">' +
((__t = ( _.i18n('well.target') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-1">' +
((__t = ( _.i18n('well.res') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-3">' +
((__t = ( _.i18n('well.values') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-3">' +
((__t = ( _.i18n('well.resText') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-2">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n      </div>\n      ';
 if (meltCurve1 || amplCurve1) { ;
__p += '\n      <div class="row">\n        <label class="col-xs-offset-1 col-xs-1">' +
((__t = ( target1 )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-1">\n          ';
 if (res1Color) { ;
__p += '\n          <div class="well-square-icon-green">\n            <div class="well-circle-icon" style="background: ' +
((__t = ( res1Color )) == null ? '' : __t) +
';"/>\n          </div>\n          ';
 } ;
__p += '\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label inputRes1Num" data-field-name="res1Num1"\n                 placeholder="" value="' +
((__t = ( (res1Num1?res1Num1:'') + (res1Num2?', '+res1Num2:'') + (res1Num3?', '+res1Num3:'') )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res1Txt"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res1Txt )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-2 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res1Comment"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res1Comment )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      ';
 if (meltCurve2 || amplCurve2) { ;
__p += '\n      <div class="row">\n        <label class="col-xs-offset-1 col-xs-1">' +
((__t = ( target2 )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-1">\n          ';
 if (res2Color) { ;
__p += '\n          <div class="well-square-icon-green">\n            <div class="well-circle-icon" style="background: ' +
((__t = ( res2Color )) == null ? '' : __t) +
';"/>\n          </div>\n          ';
 } ;
__p += '\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label inputRes2Num" data-field-name="res2Num1"\n                 placeholder="" value="' +
((__t = ( (res2Num1?res2Num1:'') + (res2Num2?', '+res2Num2:'') + (res2Num3?', '+res2Num3:'') )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res2Txt"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res2Txt )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-2 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res2Comment"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res2Comment )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      ';
 if (meltCurve3 || amplCurve3) { ;
__p += '\n      <div class="row">\n        <label class="col-xs-offset-1 col-xs-1">' +
((__t = ( target3 )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-1">\n          ';
 if (res3Color) { ;
__p += '\n          <div class="well-square-icon-green">\n            <div class="well-circle-icon" style="background: ' +
((__t = ( res3Color )) == null ? '' : __t) +
';"/>\n          </div>\n          ';
 } ;
__p += '\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label inputRes3Num" data-field-name="res3Num1"\n                 placeholder="" value="' +
((__t = ( (res3Num1?res3Num1:'') + (res3Num2?', '+res3Num2:'') + (res3Num3?', '+res3Num3:'') )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res3Txt"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res3Txt )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-2 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res3Comment"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res3Comment )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      ';
 if (meltCurve4 || amplCurve4) { ;
__p += '\n      <div class="row">\n        <label class="col-xs-offset-1 col-xs-1">' +
((__t = ( target4 )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-1">\n          ';
 if (res4Color) { ;
__p += '\n          <div class="well-square-icon-green">\n            <div class="well-circle-icon" style="background: ' +
((__t = ( res4Color )) == null ? '' : __t) +
';"/>\n          </div>\n          ';
 } ;
__p += '\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label inputRes4Num" data-field-name="res4Num1"\n                 placeholder="" value="' +
((__t = ( (res4Num1?res4Num1:'') + (res4Num2?', '+res4Num2:'') + (res4Num3?', '+res4Num3:'') )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res4Txt"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res4Txt )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-2 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res4Comment"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res4Comment )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      ';
 if (meltCurve5 || amplCurve5) { ;
__p += '\n      <div class="row">\n        <label class="col-xs-offset-1 col-xs-1">' +
((__t = ( target5 )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-1">\n          ';
 if (res5Color) { ;
__p += '\n          <div class="well-square-icon-green">\n            <div class="well-circle-icon" style="background: ' +
((__t = ( res5Color )) == null ? '' : __t) +
';"/>\n          </div>\n          ';
 } ;
__p += '\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label inputRes5Num" data-field-name="res5Num1"\n                 placeholder="" value="' +
((__t = ( (res5Num1?res5Num1:'') + (res5Num2?', '+res5Num2:'') + (res5Num3?', '+res5Num3:'') )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res5Txt"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res5Txt )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-2 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res5Comment"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res5Comment )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      ';
 if (meltCurve6 || amplCurve6) { ;
__p += '\n      <div class="row">\n        <label class="col-xs-offset-1 col-xs-1">' +
((__t = ( target6 )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-1">\n          ';
 if (res6Color) { ;
__p += '\n          <div class="well-square-icon-green">\n            <div class="well-circle-icon" style="background: ' +
((__t = ( res6Color )) == null ? '' : __t) +
';"/>\n          </div>\n          ';
 } ;
__p += '\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label inputRes6Num" data-field-name="res6Num1"\n                 placeholder="" value="' +
((__t = ( (res6Num1?res6Num1:'') + (res6Num2?', '+res6Num2:'') + (res6Num3?', '+res6Num3:'') )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res6Txt"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res6Txt )) == null ? '' : __t) +
'">\n        </div>\n        <div class="form-group col-xs-2 m-b-0 m-r-0 m-l-0">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="res6Comment"\n                 maxlength="5" placeholder="" value="' +
((__t = ( res6Comment )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

