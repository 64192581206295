/**
 * Created by RKL on 18/08/2015.
 */
define('customerProdTemplatesGroupNewItemView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'customerProdTemplatesPopupView',
  'template!customerProdTemplateGroupsNewItemTpl',
  'entities/caccounts/templates'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  PopupView,
  viewTpl
) {
  'use strict';

  var CustomersProducTemplatesTabGroupsNewItemView = Marionette.ItemView
    .extend({
      template: viewTpl,

      ui: {
        newGroup: '.template-group-item-new'
      },

      events: {
        'click @ui.newGroup' : 'onNewGroup'
      },

      onNewGroup: function() {
        this.popupView = new PopupView();

        this.popupView.show({title: _.i18n('popup.corporateTemplates.add')});
        $.material.init();
      }
  });

  module.exports = CustomersProducTemplatesTabGroupsNewItemView;
});
