define('adminLisAnaView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'jqgridView',
  'template!adminLisAnaListTpl'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  JqGridView,
  viewTpl
) {
  'use strict';

  var LisAnaListView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'lisAnaListFilters',

    triggers: {
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        deleteLisAna: '.delete-lisana-link'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click @ui.deleteLisAna': 'onDeleteLisAna'
      }, this.gridEvents);
    },

    serializeData: function() {
      var templateData = {
        type: 'lisana',
        data: this.options.data
      };
      return templateData;
    },

    pagination: function() {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage15'),
        currentPage: 1
      };
    },

    onDeleteLisAna: function(event){
      this.trigger('lisana:delete', this.options.data.model.get('secId'), $(event.currentTarget).find('span').attr('data-row-id'));
    },

    gridOptions: function(data) {
      var options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: _.i18n('lisana.refLmbAna'),
            name: 'refLmbAna.code',
            classes: 'lisana-link',
            formatter:  this.defaultFormatter,
            search: true,
            index: 'lmbAnaCode',
            width: 30
          }, {
            label: _.i18n('common.code'),
            name: 'code',
            classes: 'lisana-link',
            formatter:  this.defaultFormatter,
            search: true,
            index: 'code',
            width: 30
          }, {
            label: _.i18n('common.name'),
            name: 'name',
            classes: 'lisana-link',
            formatter:  this.defaultFormatter,
            search: true,
            index: 'name'
          }, {
            label: ' ',
            name: 'delete',
            search: false,
            classes: 'delete-lisana-link delete-link',
            formatter: this.deleteIconFormatter,
            width: 20
          }
        ],

        onSelectRow: function(rowId, bool, e) {
          if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
            if (e.type === 'click') {
              App.navigate('admin/lisanas/' + rowId, {trigger: true});
            }
          }
        }
      };

      options = _.defaults(options, this.gridInitOptions(data));

      return options;
    }
  });

  module.exports = LisAnaListView;
});
