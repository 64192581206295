/**
 * Created by RKL on 07/09/2015.
 */
define('superAdminMbAnaResGrDetailsController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'superAdminMbAnaResGrDetailsView',
  'emptyView',
  'mbAnaResGrDetailTabController',
  'superAdminMbAnaResController',
  'rolesMixin',
  'settings',
  'superAdminMbAnaResGrsTabs',
  'entities/mbanaresgrs'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  mbAnaResGrDetailTabController,
  superAdminMbAnaResController
) {
  'use strict';

  var MbAnaResGrsDetailsController = {
    fetchMbAnaResGr: function(data) {
      var that = this,
          emptyView = new EmptyView(),
          contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.secId) {
        this.showMbAnaResGr(data);
      } else {
        this.model = App.request('mbanaresgr:model', {secId: data.secId});
        this.model.fetch().done(function() {
          if (contentRegion) {
            that.showMbAnaResGr(data);
          }
        });
      }
    },

    showMbAnaResGr: function(data) {
      var view = new View({model: this.model, data: data});

      this.view = view;
      data.model = this.model;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      this.displayTabs(data);

      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
      view.listenTo(App, 'mbanaresgr:header:rerender', _.bind(this.onHeaderChange, this));
    },

    onHeaderChange: function (mbanaresgr) {
      this.view.onHeaderChange(mbanaresgr);
    },

    displayTabs: function(data) {
      mbAnaResGrDetailTabController.showTab(data);
      superAdminMbAnaResController.showMbAnaResList(data);
      data.model = this.model;
      this.tab = data.tab;
    },

    onTabClick: function(tab) {
      if (this.data.tab !== tab || this.data.secId) {
        this.data.tab = tab;
        this.data.secId = null;

        this.displayTabs(this.data);
        App.navigate('superAdmin/mbanaresgrs/' + this.model.get('secId') + '/' + tab.name);
      }
    }
  };

  module.exports = MbAnaResGrsDetailsController;
});
