define('adminAssayView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!adminAssayListTpl'
], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl
    ) {
    'use strict';

    var AssayListView = JqGridView.extend({
        template: viewTpl,
        filtersName: 'assayListFilters',

        triggers: {
        },

        ui: function() {
            return _.extend({
                jqGrid: '#jq-grid-list',
                jqGridPager: '#jq-grid-pager-list',
                deleteAssay: '.delete-assay-link'
            }, this.gridUi);
        },

        events: function() {
            return _.extend({
                'click @ui.deleteAssay': 'onDeleteAssay'
            }, this.gridEvents);
        },

        serializeData: function() {
            var templateData = {
                type: 'assay',
                data: this.options.data
            };
            return templateData;
        },

        pagination: function() {
            return {
                first: 0,
                rowCount: 0,
                pageSize: Settings.get('perPage15'),
                currentPage: 1
            };
        },

        onDeleteAssay: function(event){
            this.trigger('assay:delete', this.options.data.model.get('secId'), $(event.currentTarget).find('span').attr('data-row-id'));
        },

        gridOptions: function(data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('assay.assay'),
                        name: 'code',
                        classes: 'assay-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'code',
                        width: 30
                    }, {
                        label: _.i18n('common.description'),
                        name: 'description',
                        classes: 'assay-link',
                        formatter:  this.defaultFormatter,
                        search: false,
                        index: 'description'
                    }
                ],

                onSelectRow: function(rowId, bool, e) {
                    if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
                        if (e.type === 'click') {
                            App.navigate('admin/assays' + '/' + rowId, {trigger: true});
                        }
                    }
                }
            };

            if (!this.options.data.model.get('refKitSecId')) {
                options.colModel.push({
                    label: ' ',
                    name: 'delete',
                    search: false,
                    classes: 'delete-assay-link delete-link',
                    formatter: this.deleteIconFormatter,
                    width: 20
                });
            }

            options = _.defaults(options, this.gridInitOptions(data));

            return options;
        }
    });

    module.exports = AssayListView;
});
