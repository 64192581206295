/**
 * Created by GSP on 15/09/2015.
 */
/**
 * Created by RKL on 10/09/2015.
 */
define('chartView',[
  'module',
  'logger',
  'marionette',
  'underscore',
  'chart',
  'template!chartViewTpl'
], function(
    module,
    Logger,
    Marionette,
    _,
    Chart,
    viewTpl
) {
  'use strict';

  var ChartView = Marionette.ItemView.extend({

    template: viewTpl,

    ui: {
      chart: '.chart-canvas'
    },

    initOptions: function() {
      return {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.2
      };
    },
    getOptions: function(options) {
      return _.extend(options || {}, this.initOptions());
    },

    onShow: function() {
      this.type = this.options.type;
      if ( !_.isUndefined(this.data) && !_.isEmpty(this.data)) {
        this.instance = new Chart(this.ui.chart[0].getContext('2d'), {
          type: this.type,
          data: this.data,
          options: this.getOptions(this.options)
        });

        if (this.options.legend) {
          this.$el.prepend(this.options.legend);
        }
      }
    },

    onDestroy : function() {
      if (this.instance) {
        this.instance.destroy();
        Logger.log(module, 'destroy');
      }
    }
  });

  module.exports = ChartView;
});
