define('kitCQctCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!kitCQctCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'entities/kitp'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      kitpSelect: '.js-select-kitp-region',
      mbAnaResSelect: '.js-select-mbanares-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
    ],

    initialize: function(options) {
      this.model = options.kitCQctModel.clone();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();

      var kitPView = new AutocompleteView(
          this._getAutocompleteOptions('refKitP', 'displayName', 'displayName',
              'v2/kitcs/'+this.model.get('refKitC').secId+'/kitps',
              'selectkitp.placeholder', 'displayName', this.onKitPChange)
      );
      this.getRegion('kitpSelect').show(kitPView);

      if (this.model.get('refKitP')){
        this.mbAnaResView = new AutocompleteView(
            this._getAutocompleteOptions('qcTargetValCode', 'code', 'code',
                'v2/mbanaresgrs/'+this.model.get('refKitP').refMbAna.refMbAnaResGr.secId+'/mbanares?sidx=code&sord=DESC',
                'selectmbanares.placeholder', 'code', this.onMbAnaResChange)
        );
        this.getRegion('mbAnaResSelect').show(this.mbAnaResView);
      }

      this.triggerMethod('enable:cancel:confirm');
    },

    onKitPChange: function(fieldName, model) {
      if (model) {
        model.unset('autocompleteValue', {silent: true});
      }
      this.onChange(fieldName, model);

      if (model) {
        if (this.mbAnaResView) {
          this.mbAnaResView.destroy();
        }


        this.mbAnaResView = new AutocompleteView(
            this._getAutocompleteOptions('qcTargetValCode', 'code', 'code',
                'v2/mbanaresgrs/'+model.get('refMbAna').refMbAnaResGr.secId+'/mbanares?sidx=code&sord=DESC',
                'selectmbanares.placeholder', 'code', this.onMbAnaResChange)
        );
        this.getRegion('mbAnaResSelect').show(this.mbAnaResView);
      }

      this.triggerMethod('enable:cancel:confirm');
    },

    onMbAnaResChange: function(fieldName, model) {
      if (model) {
        model.unset('autocompleteValue', {silent: true});
        this.onChange(fieldName, model.get('code'));
      } else {
        this.onChange(fieldName, null);
      }
      this.triggerMethod('enable:cancel:confirm');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.model.save();
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.options.kitCQctModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('kitcqcts:header:rerender', modelJSON);
      App.navigate('admin/kitcs/' + model.get('refKitC').secId + '/kitcqct', {trigger: true});
      this.hide();
    },

    hide: function() {
      var kitCSecId = this.model.get('refKitC')?this.model.get('refKitC').secId:'';
      var secId = this.model.get('secId');
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'admin/kitcs/'+kitCSecId+'/kitcqct/new' ||
          App.getCurrentRoute() ===  'admin/kitcs/'+kitCSecId+'/kitcqct/'+secId+'/edit') {
        App.navigate('admin/kitcs/'+kitCSecId+'/kitcqct', {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
