/**
 * Created by BFR on 21/02/2016.
 */
define('mbAModelDetailView',[
  'module',
  'mbAModelCreateEditLayoutView',
  'underscore',
  'marionette',
  'template!mbAModelDetailTabTpl',
  'superAdminMbAModelsTabs'
], function (
  module,
  CreateEditLayoutView,
  _,
  Marionette,
  viewTpl
) {
  'use strict';

  var MbAModelDetailView = Marionette.ItemView.extend({
    template: viewTpl,

    modelEvents: {
      change: 'render'
    },

    events: {
      'click .js-edit': '_onEdit'
    },

    onShow: function() {
      $.material.init();
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.viewLess = this.model.get('secId');
      return templateData;
    },

    _onEdit: function() {
      var createEditView = new CreateEditLayoutView({
        mbamodelModel: this.model
      });
      createEditView.show({
        title: _.i18n('customers.edit')
      });
    }

  });

  module.exports = MbAModelDetailView;
});
