/**
 * Created by RKL on 19/09/2015.
 */
define('customerDatasetsTabView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'jqgridView',
  'template!customerDatasetsTabTpl'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  JqGridView,
  layoutTpl
) {
  'use strict';

  var CustomersDatasetsTabView = Marionette.LayoutView.extend({
    template: layoutTpl,

    regions: {
      datasetRow: '.dataset-row-region'
    }
  });

  module.exports = CustomersDatasetsTabView;
});
