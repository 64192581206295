define('wellView',[
    'module',
    'marionette',
    'template!wellTpl',
    'underscore'
], function(
    module,
    Marionette,
    wellTpl,
    _
) {
    'use strict';

    var WellView = Marionette.ItemView.extend({
        template: wellTpl,

        ui: {
            elementRight: '.elementRight',
            circleResult1: '.circleResult1',
            circleResult2: '.circleResult2',
            circleResult3: '.circleResult3',
            circleResult4: '.circleResult4',
            circleResult5: '.circleResult5',
            circleResult6: '.circleResult6',
            selector: '.item-selector-circle',
            selectableItem: '.wellSelectableItem'
        },

        events: {
            'click': 'onItemClick',
            'click @ui.selector': 'onSelectClick'
        },

        onSelectClick: function(e) {
            e.preventDefault();
            e.stopPropagation();

            var $template = this.ui.selectableItem,
                selected = $template.hasClass('selected') ? true : false;

            if (selected) {
                $template.removeClass('selected');
            } else {
                $template.addClass('selected');
            }

            this.trigger('well:selectedWell', this.model.pos, !selected);
        },

        onItemClick:function() {
            this.trigger('well:filteredDetailItem', this.model.pos);
        },

        serializeData: function() {
            var templateData = {
            well: _.clone(this.model),
            color1: this.options.modelRun.getResultColor(this.model.refAssay, this.model.res1Code, 1),
            color2: this.options.modelRun.getResultColor(this.model.refAssay, this.model.res2Code, 2),
            color3: this.options.modelRun.getResultColor(this.model.refAssay, this.model.res3Code, 3),
            color4: this.options.modelRun.getResultColor(this.model.refAssay, this.model.res4Code, 4),
            color5: this.options.modelRun.getResultColor(this.model.refAssay, this.model.res5Code, 5),
            color6: this.options.modelRun.getResultColor(this.model.refAssay, this.model.res6Code, 6)
          };
          if (templateData.well.smpid && templateData.well.smpid.length > 10) {
            templateData.well.smpid = templateData.well.smpid.substr(templateData.well.smpid.length - 10);
          }
          templateData.displayResult = '';
          if (this.model.smpid && this.model.refAssay) {
              templateData.displayResult = true;
          }

          templateData.error = false;
          templateData.warning = false;
          templateData.ok = false;

          switch(templateData.well.errorInd) {
            case 'E': templateData.error = true; break;
            case 'W': templateData.warning = true; break;
          }

          if (!templateData.warning && !templateData.error && (this.model.res1CodeErr === 'W' ||
            this.model.res2CodeErr === 'W' || this.model.res3CodeErr === 'W' ||
            this.model.res4CodeErr === 'W' || this.model.res5CodeErr === 'W' || this.model.res6CodeErr === 'W')) {
            templateData.warning = true;
          }

          if (!templateData.error && (this.model.res1CodeErr === 'E' ||
            this.model.res2CodeErr === 'E' || this.model.res3CodeErr === 'E' ||
            this.model.res4CodeErr === 'E' || this.model.res5CodeErr === 'E' || this.model.res6CodeErr === 'E')) {
            templateData.warning = false;
            templateData.error = true;
          }

          if (!templateData.error && !templateData.warning) {
            templateData.ok = true;
          }

          return templateData;
        }
    });

    module.exports = WellView;
});
