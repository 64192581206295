/**
 * Created by RKL on 20/08/2015.
 */
define('customerTemplatesPopupEditView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'template!customerTemplatesPopupEditTpl',
  'dialogFormView',
  'autocompleteView'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  viewTpl,
  DialogFormView,
  AutocompleteView
) {
  'use strict';

  var CAccountTemplatesTabPopupViewEdit = DialogFormView.extend({
    template: viewTpl,
    ui: {
      formInput: '.js-form-input',
      save: '.js-save-template'
    },

    events: {
      'change @ui.formInput': 'onInputChange',
      'focus @ui.formInput' : 'onFocus',
      'click @ui.save': 'onSave'
    },

    regions: {
      templateSizes: '.select-standardsizes',
      templateCountries: '.select-countries',
      templateLanguages: '.select-languages'
    },

    modelEvents: {
      sync: 'onModelSaved'
    },

    fieldsToValidate: [
      {name: 'height', type: 'number'},
      {name: 'height', type: 'required'},
      {name: 'width', type: 'number'},
      {name: 'width', type: 'required'}
    ],

    serializeData: function() {
      this.prepareModelValues();
      return {
        displayOrder: this.options.showModel.get('displayOrder'),
        name: this.options.showModel.get('name'),
        originalFileName: this.options.showModel.get('originalFileName')
      };
    },

    // set received values to model
    prepareModelValues: function() {
      var that = this,
          preselectedFields = {
            'country': 'refCountry',
            'displayOrder': 'displayOrder',
            'language': 'refLanguage',
            'standardSize': 'refStandardSize'
          };
      _.each(preselectedFields, function(refField,field){
        var value = that.options.showModel.get(field),
            refValue;
            if (value) {
              refValue = value.secId ? {
                secId: value.secId
              } : value;
              that.model.set(refField, refValue);
            }
      });
    },

    onShow: function() {
      var countriesView, langsView, sizesView;

      sizesView = new AutocompleteView(
        this.getSizesAutocompleteOptions()
      );

      countriesView = new AutocompleteView(
        this.getCountriesAutocompleteOptions()
      );
      langsView = new AutocompleteView(
        this.getLangsAutocompleteOptions()
      );

      this.getRegion('templateCountries').show(countriesView);
      this.getRegion('templateLanguages').show(langsView);
      this.getRegion('templateSizes').show(sizesView);
    },

    getSizesAutocompleteOptions: function() {
      var sizeValue = this.options.showModel.get('standardSize') ?
        this.options.showModel.get('standardSize').nameTranslated : '';
      return {
        data: this.filterSizesAutocompleteOptions(),
        valueKey: 'searchValue',
        apiKey: 'secId',
        callback: _.bind(this.onChangeStandardSizes, this),
        value: sizeValue,
        inputType: 'dropdown'
      };
    },

    filterSizesAutocompleteOptions: function() {
      return _.map(this.options.standardSizes, function(item) {
        return {
          code: item.code,
          height: item.height,
          isAbsolute: item.isAbsolute,
          nameTranslated: item.nameTranslated,
          secId: item.secId,
          unit: item.unit,
          width: item.width,
          searchValue: item.code + ' - ' + item.nameTranslated
        };
      });
    },

    onChangeStandardSizes: function(fieldName, model) {
      if (!model) {
        this.onChange('refStandardSize', null);
      } else {
        this.onChange('refStandardSize', {secId: model.get('secId')});
      }
    },

    getCountriesAutocompleteOptions: function() {
      var countryValue = this.options.showModel.get('country') ?
        this.options.showModel.get('country').name : '';
      return {
        data: Settings.get('countries'),
        valueKey: 'name',
        apiKey: 'id',
        placeholder: _.i18n('template.country'),
        name: 'refCountry',
        value: countryValue,
        inputType: 'dropdown',
        callback: _.bind(this.onChangeModel, this)
      };
    },

    getLangsAutocompleteOptions: function() {
      var languageValue = this.options.showModel.get('language') ?
        this.options.showModel.get('language').name : '';
      return {
        data: Settings.get('languages').languages,
        valueKey: 'name',
        apiKey: 'id',
        placeholder: _.i18n('template.language'),
        name: 'refLanguage',
        value: languageValue,
        inputType: 'dropdown',
        callback: _.bind(this.onChangeModel, this)
      };
    },

    onChangeModel: function(fieldName, model) {
      var value = model ? model.get('secId') : '';

      this.onChange(fieldName, {secId: value});
    },

    onSave: function() {
      if (!this.model.get('refStandardSize')) {
        this.onChangeStandardSizes();
      }
      this.model.save();
    },

    onModelSaved: function() {
      this.trigger('edit-template-saved', this.model);
      this.destroy();
      this.hide();
    }

  });

  module.exports = CAccountTemplatesTabPopupViewEdit;
});
