/**
 * Created by GSP on 4/08/2015.
 */
define('groupsLayoutView',[
  'module',
  'underscore',
  'marionette',
  'app',
  'template!groupsLayoutTpl'
], function(
  module,
  _,
  Marionette,
  App,
  layoutTpl
) {
  'use strict';

  var ContentLayoutView =  Marionette.LayoutView.extend({
    template: layoutTpl,

    regions: {
      groupsColumn: '#groups-column',
      elementsColumn: '#elements-column'
    },

    ui: {
      groupsColumn: '.groups-column',
      newGroup: '.js-add-new'
    },

    events: {
      'click @ui.newGroup' : 'onNewGroup'
    },

    serializeData: function() {
      var tabsWithAddButton = ['producttemplates'],
        check = _.indexOf(tabsWithAddButton, this.options.data.tab) > -1,
        showAddButton = check ? true : false;
      
      return {showAddButton: showAddButton};
    },

    onNewGroup: function() {
      App.trigger('folder-groups:add-group');
    }
  });

  module.exports = ContentLayoutView;
});
