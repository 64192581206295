/**
 * Created by GSP on 4/08/2015.
 */
define('contentShowController',[
  'module',
  'jquery',
  'underscore',
  'app',
  'settings',
  'contentShowView',
  'notificationCenter',
  'caccountCreditsAccountFormPopupView',
  'entities/caccounts'
], function(
  module,
  $,
  _,
  App,
  Settings,
  View,
  NotificationCenter,
  AccountFormPopupView
) {
  'use strict';

  var ContentLayoutController = {
    showContent: function() {
      var that = this,
        defer = $.Deferred(),
        view, fetchRoles, currentCAccount = Settings.get('currentCAccount'),
          needToEncodeVat, currentUser = Settings.get('currentUser' );

      if (App.regions &&
          _.isUndefined(App.regions.getRegion('main').currentView) ||
          (App.regions.getRegion('main').currentView &&
          _.isUndefined(App.regions.getRegion('main').currentView.getRegion)) ||
          _.isUndefined(App.regions.getRegion('main').currentView.getRegion('content')) ||
          this.switchCaccount || App.getCurrentRoute() === '') {
        
        this.switchCaccount = false;
        if (currentCAccount && currentUser) {
          view = new View();
          fetchRoles = App.request('caccount:get-menu-roles');
          $.when(fetchRoles).done(_.bind(function(resp) {

            if (resp && _.isFunction(resp.toJSON)) {
              Settings.set('cAccountRoles', that.parseRoles(resp.toJSON()));
            }

            NotificationCenter.getInstance().subscribe(currentCAccount.secId);
            App.regions.getRegion('main').show(view);
            App.trigger('header:show');
            App.trigger('sidebar:show');

            App.off('sidebar:resize');
            App.on('sidebar:resize', view.onResizeSidebar, view);
            App.off('sidebar:switch');
            App.on('sidebar:switch', _.bind(view.onSidebarShow, view));
            App.off('switchCaccount');
            App.on('switchCaccount', _.bind(that.onSwitchCAccount, that));
            $(window).off('resize');
            $(window).on('resize', _.bind(view.onResizeWindow, view));

            defer.resolve();
          }, this));
        } else {
          Settings.set('lastUrlPageCookie', App.getCurrentRoute());
          App.clearAuthCookieValues();
          App.navigate('login', {trigger: true});
        }
      } else {
        App.regions.getRegion('main').currentView.triggerMethod('before:render');
        defer.resolve();
      }

      return defer.promise();
    },

    displayPopupCAccountInfo : function(defer, currentCAccount){
      var accountFormPopupView = new AccountFormPopupView({
        model: App.request('caccount:model', currentCAccount),
        countries: Settings.get('countries'),
        first: true
      });

      accountFormPopupView.listenTo(accountFormPopupView, 'saved', _.bind(this.onAccountSaved, this));
      accountFormPopupView.listenTo(accountFormPopupView, 'error', _.bind(this.onAccountSaveError, this));
      accountFormPopupView.listenTo(accountFormPopupView, 'firstRequest:start',_.bind(this.onFirstRequestStart, this));
      accountFormPopupView.show({
        title: _.i18n('account.profile.billingInfo'),
        className: 'billingConfirm'});

      this.accountFormPopupView = accountFormPopupView;
      defer.resolve();
    },

    onFirstRequestStart: function() {
      App.navigate('/graphical_service_all_in_one/new', {trigger: true});
      this.accountFormPopupView.hide();
    },

    onAccountSaved: function(model) {
      var account = model.toJSON();
      Settings.set('currentCAccountCookie', JSON.stringify(account));
      Settings.set('currentCAccount', account);
      App.trigger('header:update-caccount');
      App.trigger('caccount:header:rerender', account);
      App.trigger('caccount:tab-profile:update-caccount',{cAccountId :  model.get('secId')});
    },

    onAccountSaveError: function(error) {
      if(error.status === 406 && error.responseJSON){
        this.accountFormPopupView.onSaveError(error.responseJSON);
      }
    },

    parseRoles: function(cAccountRoles) {
      var roles = {};
      if (cAccountRoles) {
        _.each(cAccountRoles, function (role) {
          if (role && _.isFunction(role.toLowerCase) ) {
              roles[role] = true;
          }
        });
      }
      return roles;
    },

    onSwitchCAccount: function(caccount) {
      var that = this,
        postCaccount = App.request('caccount:post-caccount', caccount.secId);

      $.when(postCaccount).done(function (data) {
        Settings.clearMemory();
        Settings.set('tokenId', data.tokenId);
        var caccountTemp = _.extend(caccount);
        caccountTemp.refMainCAccountJson = null;
        Settings.set('currentCAccountCookie', JSON.stringify(caccountTemp));
        Settings.set('currentCAccount', caccount);
        that.switchCaccount = true;
        App.navigate('', {trigger: true});
      });
    }
  };

  module.exports = ContentLayoutController;
});
