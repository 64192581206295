/**
 * Created by RKL on 07/09/2015.
 */
define('adminLisAserieDetailsView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'template!adminLisAserieDetailsTpl',
  'adminLisAserieTabs',
  'rolesMixin',
  'backToListBehavior'
], function (
  module,
  Marionette,
  _,
  Backbone,
  App,
  Settings,
  imageUtils,
  viewTpl,
  lisAseriesTabs,
  RolesMixin,
  BackToListBehavior
) {
  'use strict';

  var LisAseriesDetailsView =  Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      tab: '.lisaserie-tab',
      tabPane: '.tab-pane',
      logo: '.js-logo-holder',
      name: '.p-name',
      code: '.p-code'
    },

    events: {
      'click @ui.tab': 'onTabClick',
      'change @ui.collectionToggle': 'onCollectionToggle'
    },

    regions: {
      lisAserieDetailTab: '#detail-tab',
      lisAnaListTab: '#lisana-tab'
    },

    behaviors: function() {
      var redirectUrl = 'admin/lis/'+this.options.model.get('refLis').secId+'/lisaserie';
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: redirectUrl
        }
      };
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.lisAseriesTabs = lisAseriesTabs;
      templateData.compulogoCollection = this.model.get('compulogoCollection');
      return templateData;
    },

    onRender: function() {
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.lisaserie-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onShow: function() {
      $.material.init();
    },

    onTabClick: function(e) {
      var $target = $(e.currentTarget),
        tab = {
          route: $target.data('route'),
          role: $target.data('role'),
          name: $target.data('name')
        };

      this.ui.tab.removeClass('active');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

      this.triggerMethod('click:tab', tab);
    },

    onHeaderChange: function(lisAserie) {
      this.ui.name.text(lisAserie.name);
      this.ui.code.text(lisAserie.code);
    }
  });

  module.exports = LisAseriesDetailsView;
});
