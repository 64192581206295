
define('template!messagesAllTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="loading-block js-loading-block">\n\t<span class="loading-message js-loading-message label"></span>\n</div>\n<div class="row tickets-message-container">\n\t';
 if (direction === 'from') { ;
__p += '\n\t\t<div class="col-xs-3 col-sm-1 avatar-section p-r-0">\n\t\t\t';
 if (fileUrl) { ;
__p += '\n\t\t\t\t<div class="avatar-in-msg" style="background-image: url(' +
((__t = ( fileUrl )) == null ? '' : __t) +
')"></div>\n\t\t\t';
 } else { ;
__p += '\n\t\t\t\t<i class="mdi-action-account-circle avatar-messages-block"></i>\n\t\t\t';
 } ;
__p += '\n\t\t\t';
 if (userPid && userPid.logonId) { ;
__p += '\n\t\t\t\t<p class="first-name hidden-xs" title="' +
((__t = ( userPid.logonId )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="right">\n\t\t\t\t\t' +
((__t = ( userPid.logonId )) == null ? '' : __t) +
'\n\t\t\t\t</p>\n\t\t\t';
 } ;
__p += '\n\t\t</div>\n\t\t<div class="col-xs-8 col-sm-7 message-section p-l-0">\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-12 date-container">\n\t\t\t\t\t<i class="mdi-action-alarm"></i>\n\t\t\t\t\t<span class="m-time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t<i class="mdi-action-today"></i>\n\t\t\t\t\t<span class="m-day">' +
((__t = ( day )) == null ? '' : __t) +
'</span>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t<div class="well message-container">\n                    ';
 if (vectorizator && content) { ;
__p += '\n                      <a  tabindex="0" role="button" class="btn pull-right btn-xs btn-flat translation" data-to-translate="' +
__e( content ) +
'"><i class="mdi-action-translate"></i></a>\n                        ';
 } ;
__p += '\n                      <p>' +
__e( content.replace(/<[^>]br>|<br[^>]*>/g, '\n') ) +
'</p>\n\t\t\t\t\t\t<div class="row">\n\t\t\t\t\t\t\t';
 _.each(attachements, function(item, index) { ;
__p += '\n\t\t\t\t\t\t\t<div class="attach-block">\n\t\t\t\t\t\t\t\t<div class="attachment-container">\n\t\t\t\t\t\t\t\t\t<div class="attached-image-container">\n\t\t\t\t\t\t\t\t\t\t<div data-name="' +
((__t = ( item.fileName )) == null ? '' : __t) +
'" class="js-image-cell js-' +
((__t = ( index )) == null ? '' : __t) +
' attachment-in-m attach-' +
((__t = ( index )) == null ? '' : __t) +
'"></div>\n\t\t\t\t\t\t\t\t\t</div>\n                  <div class="attachment-file-link js-attachment-file js-' +
((__t = ( index )) == null ? '' : __t) +
'">\n                    <a href="' +
((__t = ( item.downloadLink)) == null ? '' : __t) +
'">' +
((__t = ( item.fileName )) == null ? '' : __t) +
'</a>\n                  </div>\n\t\t\t\t\t\t\t\t\t<a class="btn btn-primary download-btn-text btn-sm  attach-' +
((__t = ( index )) == null ? '' : __t) +
'" href="' +
((__t = ( item.downloadLink)) == null ? '' : __t) +
'">\n                    <i class="mdi-action-get-app"></i>\n                    <span>' +
((__t = ( _.i18n('common.download') )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t';
 if (vectorizator) { ;
__p += '\n\t\t\t\t\t\t\t\t\t<!-- Upload btn -->\n\t\t\t\t\t\t\t\t\t\t<a href="' +
((__t = ( item.vectorizedLink )) == null ? '' : __t) +
'" class="btn add-btn-text file-btn btn-sm load-vectorized-pdf" target="_blank">\n\t\t\t\t\t\t\t\t\t\t\t<i class="mdi-file-file-upload icon"></i>\n\t\t\t\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t\t\t\t';
 } ;
__p += '\n\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t';
 } else { ;
__p += '\n\t\t<div class="col-xs-offset-1 col-sm-offset-4 col-xs-8 col-sm-7 message-section p-r-0">\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-12 date-container">\n\t\t\t\t\t<i class="mdi-action-alarm"></i>\n\t\t\t\t\t<span class="m-time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t<i class="mdi-action-today"></i>\n\t\t\t\t\t<span class="m-day">' +
((__t = ( day )) == null ? '' : __t) +
'</span>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t<div class="well message-container">\n                      <p>' +
__e( content.replace(/<[^>]br>|<br[^>]*>/g, '\n') ) +
'</p>\n\t\t\t\t\t\t<div class="row">\n\t\t\t\t\t\t\t';
 _.each(attachements, function(item, index) { ;
__p += '\n\t\t\t\t\t\t\t<div class="attach-block">\n\t\t\t\t\t\t\t\t<div class="attachment-container">\n\t\t\t\t\t\t\t\t\t<div class="attached-image-container">\n\t\t\t\t\t\t\t\t\t\t<div data-name="' +
((__t = ( item.fileName )) == null ? '' : __t) +
'" class="js-image-cell js-' +
((__t = ( index )) == null ? '' : __t) +
' attachment-in-m attach-' +
((__t = ( index )) == null ? '' : __t) +
'"></div>\n\t\t\t\t\t\t\t\t\t</div>\n                  <div class="attachment-file-link js-attachment-file js-' +
((__t = ( index )) == null ? '' : __t) +
'">\n                    <a href="' +
((__t = ( item.downloadLink)) == null ? '' : __t) +
'">' +
((__t = ( item.fileName )) == null ? '' : __t) +
'</a>\n                  </div>\n\t\t\t\t\t\t\t\t\t<a class="btn btn-primary download-btn-text btn-sm attach-' +
((__t = ( index )) == null ? '' : __t) +
'" href="' +
((__t = ( item.downloadLink)) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<i class="mdi-action-get-app"></i>\n                    <span>' +
((__t = ( _.i18n('common.download') )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t\t\t\t</a>\n\n\t\t\t\t\t\t\t\t\t';
 if (vectorizator) { ;
__p += '\n\t\t\t\t\t\t\t\t\t\t<!-- Upload btn -->\n\t\t\t\t\t\t\t\t\t\t<a href="' +
((__t = ( item.vectorizedLink )) == null ? '' : __t) +
'" class="btn add-btn-text file-btn btn-sm load-vectorized-pdf" target="_blank">\n\t\t\t\t\t\t\t\t\t\t\t<i class="mdi-file-file-upload icon"></i>\n\t\t\t\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t\t\t\t';
 } ;
__p += '\n\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="col-xs-3 avatar-section p-l-0 col-sm-1">\n\t\t\t';
 if (fileUrl) { ;
__p += '\n\t\t\t\t<div class="avatar-in-msg" style="background-image: url(' +
((__t = ( fileUrl )) == null ? '' : __t) +
')"></div>\n\t\t\t';
 } else { ;
__p += '\n\t\t\t\t<i class="mdi-action-account-circle avatar-messages-block"></i>\n\t\t\t';
 } ;
__p += '\n\t\t\t';
 if (userPid && userPid.logonId) { ;
__p += '\n\t\t\t\t<p class="first-name hidden-xs" title="' +
((__t = ( userPid.logonId )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="right">\n\t\t\t\t\t' +
((__t = ( userPid.logonId )) == null ? '' : __t) +
'\n\t\t\t\t</p>\n\t\t\t';
 } ;
__p += '\n\t\t</div>\n\t';
 } ;
__p += '\n</div>';

}
return __p
};});

