/**
 * Created by RKL on 13/08/2015.
 */
define('caccountAdminsTabController',[
  'module',
  'underscore',
  'backbone',
  'app',
  'bootbox',
  'caccountAdminsTabView',
  'caccountAdminsAddView',
  'entities/caccounts/userpids'
], function(
  module,
  _,
  Backbone,
  App,
  bootbox,
  View,
  PopupView
) {
  'use strict';

  var CAccountsAdminsTabController = {
    showTab: function() {
      var view = new View();

      this.view = view;
      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      view.listenTo(view, 'onCreateItem', _.bind(this.onCreateItem, this));
      view.listenTo(view, 'onDeleteItem', _.bind(this.onDeleteItem, this));
      view.listenTo(view, 'onEditItem', _.bind(this.onEditItem, this));

      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('caccountAdministratorsTab').show(view);
    },

    onGridLoaded:function() {
      var data = {
          url: App.request('caccount-admins:get-url'),
          rowCountUrl: App.request('caccount-admins:row-count-url')
        };
        
      this.view.displayGrid(data);
    },

    onCreateItem: function() {
      var model = App.request('caccount-admins:get-model');

      this.onDisplayPopup(model);
    },

    onEditItem: function(secId) {
      var that = this,
        pageSize = this.view.pagination.pageSize,
        first = ((this.view.pagination.currentPage - 1) * pageSize).toString(),
        params = {
          first: first,
          pageSize: pageSize
        },
        getItemRequest,
        model, item;

      getItemRequest = App.request('caccount-admins:get-admins', params);
      $.when(getItemRequest).done(function(list) {
        item = _.findWhere(list, {'secId': secId});
        model = new Backbone.Model(item);
        that.onDisplayPopup(model);
      });
    },

    onDisplayPopup: function(model) {
      var that = this;

      this.popupView = new PopupView({model: model});

      this.popupView.show({title: _.i18n('administratorDetail.title'),
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default btn-flat',
            callback: function() { return true; }
          },
          success: {
            label: _.i18n('common.confirm'),
            className: 'btn-primary',
            callback: function() {
              return that.onConfirmPopup(that.popupView.model);
            }
          }
        }
      });
    },

    onConfirmPopup: function(model) {
      var that = this,
        data,
        postRequest;

      if (this.validate()) {
        data = new FormData();
        data.append( 'secId', model.get( 'secId' ) );
        data.append( 'firstName', model.get( 'firstName' ) );
        data.append( 'lastName', model.get( 'lastName' ) );
        data.append( 'email', model.get( 'email').toLowerCase() );
        // maybe use the same request like in editUser?
        postRequest = App.request( 'caccount-admins:post-item', data );
        $.when( postRequest ).done( function () {
          that.view.updatePagination();
        } );
      } else {
        return false;
      }
    },

    validate: function() {
      var fieldsToValidate = [
        {name: 'firstName', type: 'required'},
        {name: 'lastName', type: 'required'},
        {name: 'email', type: 'email'}
      ];

      return this.popupView.validate(fieldsToValidate);
    },

    onDeleteItem: function(itemId) {
      var that = this,
        deleteRequest;

      bootbox.confirm(_.i18n('common.confirmDelete'), function (result) {
        if (result) {
          deleteRequest = App.request('caccount-admins:delete-item', itemId);
          $.when(deleteRequest).done(function() {
            that.view.updatePagination();
          });
        }
      });
    }
  };

  module.exports = CAccountsAdminsTabController;
});
