define('headerWellTemplateView',[
    'module',
    'marionette',
    'template!headerWellTemplateTpl',
    'wellCreateEditView',
    'underscore',
    'settings',
    'app',
    'envHandler',
    'backbone'
], function(
    module,
    Marionette,
    headerWellTpl,
    CreateEditLayoutView,
    _
) {
    'use strict';

    var HeaderWellTemplateView = Marionette.LayoutView.extend({
        template: headerWellTpl,

        ui: {
            btnEditWell: '.btn-edit-well'
        },

        events: {
            'click @ui.btnEditWell': 'onEditWell'
        },

        serializeData: function() {
            var templateData = this.model.toJSON();
            return templateData;
        },

        onEditWell: function() {
            this.createEditView = new CreateEditLayoutView({
                wellModel: this.model,
                runTemplateModel: this.modelRunTemplate,
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.edit')
            });
        }
    });

    module.exports = HeaderWellTemplateView;
});
