/**
 * Created by RKL on 18/08/2015.
 */
define('groupsCollectionView',[
  'module',
  'marionette',
  'app',
  'settings',
  'groupsItemView',
  'groupsNewItemView'
], function (
  module,
  Marionette,
  App,
  Settings,
  ItemView,
  NewItemView
) {
  'use strict';

  var GroupsCollectionView = Marionette.CollectionView.extend({
    getChildView: function(item) {
      if (item.get('id') !== 0) {
        return ItemView;
      } else {
        return NewItemView;
      }
    },

    childViewOptions: function() {
      return {
        data: this.options.data
      };
    }
  });

  module.exports = GroupsCollectionView;
});
