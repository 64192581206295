
define('template!adminLisSmpMatListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="well jq-grid-table-page">\n        <div class="lissmpmat-table">\n            <div class="form-group add-new-block">\n                <a href="#admin/lis/' +
((__t = ( data.model.get('secId') )) == null ? '' : __t) +
'/lissmpmat/new" class="btn new-lissmpmat-button add-btn-text">\n                    <i class="mdi-content-add"></i>\n                    <span>' +
((__t = ( _.i18n('lissmpmat.new') )) == null ? '' : __t) +
'</span>\n                </a>\n            </div>\n            <table id="jq-grid-list" class="lissmpmatTable" role="grid"></table>\n            <div id="jq-grid-pager-list"></div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

