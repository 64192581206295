
define('template!customerTemplatesItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="template-content-container">\n  <div class="main text-center">\n    <div class="image-holder-container _texture-bg js-image-holder-container">\n      <img class="image-holder js-image-holder transparent-bg" src=""/>\n    </div>\n    <span class="empty-circle item-selector-circle multi-selector">\n      <i class="mdi-action-check-circle icon"></i>\n    </span>\n  </div>\n  <div class="template-item-editor js-template-item-editor">\n    <i class="mdi-editor-mode-edit icon js-edit-icon"></i>\n    <i class="mdi-image-color-lens icon js-modify-icon"></i>\n\n    ';
 if(showVariationsButton) { ;
__p += '\n      <i class="mdi-file-folder icon js-variations-icon"></i>\n    ';
 } ;
__p += '\n\n    <i class="mdi-action-delete icon js-remove-icon"></i>\n    <a id="download-attachment" href="' +
((__t = ( downloadLink )) == null ? '' : __t) +
'" download>\n      <label for="download-attachment">\n        <i class="mdi-file-file-download icon js-download-icon"></i>\n      </label>\n    </a>\n    <i class="fa fa-search-plus icon js-zoom-icon" aria-hidden="true"></i>\n  </div>\n  <div class="template-meta-info-container"></div>\n</div>\n';

}
return __p
};});

