/**
 * Created by BFR on 23/05/2017.
 */
define('adminResTransDetailsController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'adminResTransDetailsView',
  'emptyView',
  'restransDetailTabController',
  'rolesMixin',
  'settings',
  'entities/restrans'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  restransDetailTabController
) {
  'use strict';

  var ResTransDetailsController = {
    fetchResTrans: function(data) {
      var that = this,
          emptyView = new EmptyView(),
          contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.secId) {
        this.showResTrans(data);
      } else {
        this.model = App.request('restrans:model', {refLmbAna: {secId: data.secId}});
        this.model.fetch().done(function() {
          if (contentRegion) {
            that.showResTrans(data);
          }
        });
      }
    },

    showResTrans: function(data) {
      var view = new View({model: this.model, data: data});
      this.view = view;
      data.model = this.model;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);
      this.displayTabs(data);
    },

    displayTabs: function(data) {
      for (var region in this.view.regions) {
        if (App.regions.getRegion('main') && App.regions.getRegion('main').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content') &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region)) {
          var currentViewRegion = App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region).currentView;
          if(currentViewRegion) {
            currentViewRegion.destroy();
          }
        }
      }

      if (data.tab) {
        switch (data.tab.name) {          
          default:
            restransDetailTabController.showTab(data);
            break;
        }
      } else {
        restransDetailTabController.showTab(data);
      }

      data.model = this.model;
      this.tab = data.tab;
    }    
  };

  module.exports = ResTransDetailsController;
});
