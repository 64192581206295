
define('template!mbAnaDetailTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="well mbana-detail">\n  <div class="detailed-info-form-container">\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( code ? code : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( name ? name : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('mbana.refMbAnaResGr') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( refMbAnaResGr ? (refMbAnaResGr.code + ' - ' + refMbAnaResGr.name): '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('mbana.typeTarget') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( typeTarget ? _.i18n('mbana.typeTarget.' + typeTarget.toLowerCase()) : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('mbana.infectionType') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( infectionType ? _.i18n('mbana.infectionType.' + infectionType.toLowerCase()) : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( description ? description : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="pull-right p-r-15">\n        <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

