define('entities/lmbsmpmats',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var LmbSmpMat = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/lmbsmpmats/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      code: '',
      serialNb: '',
      location: '',
      description: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function () {
      return Settings.url('compuzz', 'v2/lmbsmpmats');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var LmbSmpMatCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/lmbsmpmats');
    },
    model: LmbSmpMat
  });

  var API = {

    getLmbSmpMatCollectionUrl: function() {
      new LmbSmpMatCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/lmbsmpmats', params);
    },

    getAllLmbSmpMats: function(params) {
      var url = Settings.url('compuzz', 'v2/lmbsmpmats', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getLmbSmpMatCountUrl: function() {
      var params = '';
      return 'v2/lmbsmpmats/rowCount' + params;
    },

    deleteLmbSmpMat: function(secId){
      var url = Settings.url('compuzz', 'v2/lmbsmpmats/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('lmbsmpmat:get-collection-url', function() {
      return API.getLmbSmpMatCollectionUrl();
  });

  App.reqres.setHandler('lmbsmpmat:get-collection-to-page', function(params) {
      return API.getAllLmbSmpMats(params);
  });

  App.reqres.setHandler('lmbsmpmat:get-count-url', function() {
    return API.getLmbSmpMatCountUrl();
  });

  App.reqres.setHandler('lmbsmpmat:model', function(itemJSON) {
    return new LmbSmpMat(itemJSON);
  });

  App.reqres.setHandler('lmbsmpmat:delete', function(secId) {
    return API.deleteLmbSmpMat(secId);
  });
});
