/**
 * Created by GSP on 2/10/2015.
 */
define( 'menuItemsUtils',[
  'module',
  'settings',
  'underscore',
  'menuItems'
], function(
  module,
  Settings,
  _,
  MenuItems
) {
  'use strict';

  var MenuItemsUtils = {
    getMenuItems: function() {
      var cAccountRoles = Settings.get('cAccountRoles'),
        menuConfig = {}, subMenus, cMenuItem;

      if (cAccountRoles) {
        menuConfig.menuItems = [];
        _.each(MenuItems, function (menuItem) {
          subMenus = [];
          cMenuItem = _.clone(menuItem);
          if (cAccountRoles[cMenuItem.role] === true) {
            //main menu
            if ( _.isEmpty(cMenuItem.subMenu)) {
              menuConfig.menuItems.push(cMenuItem);
            } else {
              //subMenus
              _.each(cMenuItem.subMenu, function (subMenu) {
                if (cAccountRoles[subMenu.role] === true) {
                  subMenus.push(subMenu);
                }
              });
              cMenuItem.subMenu = subMenus;
              menuConfig.menuItems.push(cMenuItem);
            }
          }
        });
      } else {
        menuConfig.menuItems = MenuItems;
      }

      return   menuConfig.menuItems;
    }
  };
  module.exports = MenuItemsUtils;
});
