
define('template!customerDatasetsPopupCreateNewModelTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="my-dataset-popup-container">\n    <form class="form-horizontal">\n      <fieldset>\n          <div class="col-xs-12">\n            <label class="control-label col-xs-4 text-left">' +
((__t = ( objectName.name )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-8 objectnames-container">\n              <div class="form-control">\n                <div class="input-group floating-label col-xs-12 show-on-hover">\n                  <input type="text" class="form-control js-popup-input" \n                  data-field-name="' +
((__t = ( objectName.name )) == null ? '' : __t) +
'" \n                  data-secid=""\n                  data-objname-secid="' +
((__t = ( objectName.secId )) == null ? '' : __t) +
'"\n                  placeholder=""\n                  value="">\n                </div>\n              </div>\n            </div>\n          </div>\n      </fieldset>\n    </form>\n  </div>\n</div>';

}
return __p
};});

