/**
 * Created by RKL on 13/08/2015.
 */
define('lmbSmpMatDetailView',[
  'module',
  'lmbSmpMatCreateEditLayoutView',
  'underscore',
  'marionette',
  'template!lmbSmpMatDetailTabTpl'
], function (
  module,
  CreateEditLayoutView,
  _,
  Marionette,
  viewTpl
) {
  'use strict';

  var LmbSmpMatDetailView = Marionette.ItemView.extend({
    template: viewTpl,

    modelEvents: {
      change: 'render'
    },

    events: {
      'click .js-edit': '_onEdit'
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.viewLess = this.model.get('secId');
      return templateData;
    },

    _onEdit: function() {
      var createEditView = new CreateEditLayoutView({
        lmbSmpMatModel: this.model
      });
      createEditView.show({
        title: _.i18n('lmbanalyser.edit')
      });
    }

  });

  module.exports = LmbSmpMatDetailView;
});
