/**
 * Created by RKL on 15/09/2015.
 */
define('customerLogosImagesItemsController',[
  'module',
  'backbone',
  'underscore',
  'app',
  'bootbox',
  'settings' ,
  'galleryView',
  'elementsController',
  'customerLogosImagesCollectionView',
  'customerLogosImagesAddPopupView',
  'customerLogosImagesExistPopupView',
  'customerCommonPopupFromTemplateView',
  'entities/customers/design_elements',
  'entities/caccounts/templates',
  'entities/caccounts/objectnames'
], function(
  module,
  Backbone,
  _,
  App,
  bootbox,
  Settings,
  GalleryView,
  ElementsController,
  ElementsView,
  AddNewPopupView,
  FromExistingPopupView,
  FromTemplatePopupView
) {
  'use strict';

  /**
   * This controller is extended from ElementsController
   */
  var LogosImagesController = _.defaults({

    requests: {
      modelRequest: 'customers:objectname-model',
      countRequest: 'customers:elements-count',
      collectionRequest: 'customers:design-elements',
      existingRequest: 'customers:objectname-existing',
      duplicateRequest: 'customers:objectname-duplicate',
      templateGroupRequest: 'caccount:templates',
      fromTemplateRequest: 'customers:objectname-from-template'
    },

    displayElements: function(data, groupId) {
      this.groupModel = data.groupsCollection.get(groupId);
      this.fetchElements(data);
    },

    onCollectionFetched: function(newModel) {
      this.collection.add(newModel, {at: 0});
      this.groupModel.elCollection = this.collection;
      this.preShowElements();
    },

    preShowElements: function() {
      this.showElements(ElementsView);
    },

    getEndUserId: function() {
      return this.data.model.get('endUserId');
    },

    getParams: function(first, attachId) {
      return {
        secId: this.groupId,
        endUserId: this.data.model.get('endUserId'),
        first: first || this.collection.length - 1,
        attachId: attachId
      };
    },

    subscribeToEvents: function() {
      App.off('elements:remove-item');
      App.on('elements:remove-item',  _.bind(this.onRemoveItem, this));

      App.off('elements:edit-item');
      App.on('elements:edit-item',  _.bind(this.onEditItem, this));

      App.off('elements:modify-item');
      App.on('elements:modify-item',  _.bind(this.onModifyItem, this));

      App.off('elements:add-item');
      App.on('elements:add-item',  _.bind(this.onAddItem, this));
      
      App.off('customers:design-el-zoom');
      App.on('customers:design-el-zoom', _.bind(this.onZoom, this));

      App.off('save:from:template');
      App.on('save:from:template', _.bind(this.onSave, this));
    },

    onRemoveItem: function(model) {
      var that = this;

      bootbox.confirm(_.i18n('common.confirmDelete'), function(result) {
        if (result) {
          model.url = model.deleteUrl();
          model.destroy().done(function() {
            that.groupModel.count -= 1;
            that.data.onChangeCount();
          });
        }
      });
    },

    onEditItem: function(model, view) {
      bootbox.prompt({
        title: _.i18n('popup.designElement.edit'),
        value: model.get('name'),
        callback: _.bind(function(result) {
          if (result !== null) {
            model.set('name', result);
            model.url = model.postUrl(this.data.model.get('endUserId'));
            model.save().done(function() {
              view.render();
            });
          }
        }, this)
      });
    },

    onModifyItem: function(secId) {
      var endUserId = this.data.model.get('secId') + '/',
        endUserCode = this.data.model.get('code') + '/',
        aProfileId = this.data.model.get('aProfile').secId + '/',
        groupId = this.groupId + '/',
        imageId = secId + '/',
        path = 'customers' + '/',
        tab = 'logosimages';

      App.navigate('editImage/' + endUserId + aProfileId + endUserCode +
        path + imageId + groupId + tab, {trigger: true});
    },

    onAddItem: function() {
      this.addNewView = new AddNewPopupView({
        data: this.data,
        callback: _.bind(this.onActionSelected, this)
      });

      this.addNewView.show({title: _.i18n('popup.designElement.add')});

      $('.modal-footer').css('text-align', 'center');
    },

    onActionSelected: function(action, imageData) {
      this.addNewView.hide();
      if (action === 'FromUpload') {
        this.onCreateFromUpload(imageData);
      } else if (action === 'FromEmpty') {
        this.onModifyItem('new');
      } else if (action === 'FromExisting') {
        this.popupFromExisting();
      } else if (action === 'FromTemplates') {
        this.popupFromTemplates();
      }
    },

    onCreateFromUpload: function(imageData) {
      var that = this,
        newModel = App.request(this.requests.modelRequest);

      newModel.set('encodedValue', imageData.base64Value);
      newModel.set('extension', imageData.fileExt);
      newModel.set('fileName', imageData.fileName);
      newModel.set('objectName', {secId: this.groupId});
      newModel.url = newModel.postUrl(this.data.model.get('endUserId'));

      this.showLoadingMessage();
      newModel.save().done(function() {
        that.onSave(newModel);
      }).fail(function() {
        that.showErrorMessage();
      });
    },

    popupFromExisting: function () {
      var that = this,
        params = this.getParams(),
        collection = App.request('logos-images:collection');

      $.when(collection.getLogosImages(params)).done(function(collection) {
        that.fromExistingPopup = new FromExistingPopupView({
          collection: collection,
          callback: _.bind(that.onFromAttach, that)
        });

        that.fromExistingPopup.show({
          title: _.i18n('popup.designElement.fromExisting'),
          className: 'fromExistingPopup'
        });
        $.material.init();
      });
    },

    onFromAttach: function(attachId) {
      var that = this,
        params = this.getParams(null, attachId),
        request = App.request(
          this.requests.duplicateRequest,
          params
        );

      this.fromExistingPopup.hide();
      this.showLoadingMessage();
      $.when(request).done(function(model) {
        that.onSave(model);
      }).fail(function() {
        that.showErrorMessage();
      });
    },

    popupFromTemplates: function() {
      var groupModel = new Backbone.Model({
          secId: this.groupModel.get('templateGroupSecId'),
          endUserId: this.getEndUserId()
        }),
        newModel = App.request('caccount-objnames:get-model', {
          endUserId: this.getEndUserId(),
          objectNameSecId: this.groupModel.get('secId')
        }),
        templateGroup = App.request('caccount:template-group:model', {
          secId: this.groupModel.get('templateGroupSecId')
        });
      if(!this.groupModel.get('templateGroupSecId')) {
        bootbox.alert('No template group set for ObjectName.');
        return;
      }
      templateGroup.fetch({success: function(model) {
        var fromTemplateView = new FromTemplatePopupView({
          standardSizes: model.get('standardSizes'),
          groupModel: groupModel,
          templatesCollection: App.request('caccount:templates-collection'),
          newModel: newModel,
          isGroupHidden: true
        });
        fromTemplateView.show({
          title: _.i18n('template.popup.addFromTemplate')
        });
        $.material.init();
      }});
    },

    onFromTemplate: function(templateId) {
      var that = this,
        params = this.getParams(null, templateId),
        request = App.request(
          this.requests.fromTemplateRequest,
          params
        );

      this.fromTemplatePopupView.hide();
      this.showLoadingMessage();
      $.when(request).done(function(model) {
        that.onSave(model);
      }).fail(function() {
        that.showErrorMessage();
      });
    },

    getLink: function(item, size) {
      return Settings.url(
        'file',
        item.get('fileUid'),
        {action: 'res', params: size}
      );
    },

    getFileName: function(item) {
      return item.get('fileName');
    },

    hasSize: function() {
      return true;
    }

  }, ElementsController);

  module.exports = LogosImagesController;
});
