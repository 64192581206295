
define('template!customerProdTemplateGroupsNewItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n\t<div class="col-xs-6 col-sm-4 col-lg-3">\n\t\t<div class="panel panel-default template-group-item template-group-item-new">\n\t\t<div class="icon-holder">\n\t\t\t<i class="mdi-file-folder icon">\n\t\t\t\t<span class="add-new text-in-icon">+</span>\n\t\t\t</i>\n\t\t\t\n\t\t</div>\n\t\t<div class="template-group-name">\n\t\t\t' +
((__t = ( name )) == null ? '' : __t) +
'\n\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

