define( 'entities/customers/contacts',[
  'app',
  'underscore',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  _,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var CustomerContacts = Backbone.Model.extend({    
    defaults: {
      addressL1: '',
      addressL2: '',
      city: '',
      comment: '',
      countryCode: '',
      fax: '',
      firstName: '',
      lastName: '',
      name: '',
      tel: '',
      zip: '',
      email: '',
      secId: ''
    },

    _saveModel: function(endUserId) {
      var model = new Backbone.Model({
        refAddress: {
          addressL1: this.get('addressL1'),
          addressL2: this.get('addressL2'),
          city: this.get('city'),
          comment: this.get('comment'),
          countryCode: this.get('countryCode'),
          fax: this.get('fax'),
          firstName: this.get('firstName'),
          lastName: this.get('lastName'),
          name: this.get('name'),
          tel: this.get('tel'),
          zip: this.get('zip')
        },
        email: this.get('email'),
        secId: this.get('secId') ? this.get('secId') : null
      });
      model.listenTo(model, 'sync', _.bind(this._modelSaved, this));
      model.url = Settings.url('compuzz', 'v2/endusercontact/enduser/' + endUserId);
      model.save();
    },

    _modelSaved: function() {
      this.trigger('save:event:done');
    },

    idAttribute: 'secId'
  });
  
  var ContactItemModel = Backbone.Model.extend({
    defaults: {
    },

    _fetchModel: function(endUserId, contactSecId) {
      var defer = $.Deferred();

      this.contactSecId = contactSecId;
      this.url = Settings.url('compuzz', 'v2/endusercontact/enduser/' + endUserId, {
        secId: contactSecId,
        first: 0,
        pageSize: 15
      });
      this.fetch().done(function(){
        defer.resolve();
      });

      return defer.promise();
    },

    parse: function(models) {
      var model;

      if (_.isArray(models)) {
        model = _.findWhere(models, {secId: this.contactSecId});
      } else {
        model = models;
      }
      return {
        addressL1: model.refAddress.addressL1,
        addressL2: model.refAddress.addressL2,
        city: model.refAddress.city,
        comment: model.refAddress.comment,
        countryCode: model.refAddress.countryCode,
        fax: model.refAddress.fax,
        firstName: model.refAddress.firstName,
        lastName: model.refAddress.lastName,
        name: model.refAddress.name,
        tel: model.refAddress.tel,
        zip: model.refAddress.zip,
        email: model.email,
        secId: model.secId ? model.secId : null
      };
    },

    _convertToCustom: function() {
      this.set('refAddress', {
          addressL1: this.get('addressL1'),
          addressL2: this.get('addressL2'),
          city: this.get('city'),
          comment: this.get('comment'),
          countryCode: this.get('countryCode'),
          fax: this.get('fax'),
          firstName: this.get('firstName'),
          lastName: this.get('lastName'),
          name: this.get('name'),
          tel: this.get('tel'),
          zip: this.get('zip')
        });
      this.set('email', this.get('email'));
      this.set('secId', this.get('secId'));
    },

    _updateModel: function() {
      this._convertToCustom();
      this.url = Settings.url('compuzz', 'v2/endusercontact');
      this.save();
    },

    idAttribute: 'secId'
  });
  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function(endUserId) {
      return 'v2/endusercontact/enduser/' + endUserId + '/rowCount?';
    },

    getCustomersContactsUrl: function(endUserId) {
      return Settings.url('compuzz', 'v2/endusercontact/enduser/' + endUserId);
    },

    deleteContactsItem: function(contactSecId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'v2/endusercontact/' + contactSecId);

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function() {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    deleteUser: function(secId){
        var url = Settings.url('compuzz', 'endusers.json/'+secId),
            defer = $.Deferred();

        $.ajax({
            type: 'DELETE',
            url: url,
            success: function (data) {
                defer.resolve(data);
            }
        });

        return defer.promise();
    },

    importExcelFile: function(fileInfo) {
          var defer = $.Deferred(),
              formdata =  new FormData(),
              url = Settings.url('compuzz', 'endusers.json/excel');

          formdata.append('file', fileInfo.base64Value);
          formdata.append('filename', fileInfo.fileName);

          $.ajax({
              type: 'POST',
              url: url,
              data: formdata,
              processData: false,
              contentType: false,
              success: function(resp) {
                  defer.resolve(resp);
              }
          });

          return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('customers:contacts:row-count-url', function(endUserId) {
      return API.getRowCountUrl(endUserId);
  });

  App.reqres.setHandler('customers:contacts:get-url', function(endUserId) {
    return API.getCustomersContactsUrl(endUserId);
  });

  App.reqres.setHandler('customers:contacts:get-model:item', function() {
    return new ContactItemModel();
  });

  App.reqres.setHandler('customer:contacts:new-model', function() {
    return new CustomerContacts();
  });
  App.reqres.setHandler('customer:delete', function(secId) {
      return API.deleteUser(secId);
  });

  App.reqres.setHandler('customers:upload-excel', function(fileInfo) {
      return API.importExcelFile(fileInfo);
  });

  App.reqres.setHandler('customers:contacts:delete-contact', function(contactSecId) {
    return API.deleteContactsItem(contactSecId);
  });
});
