define('mbAnaResDetailView',[
  'module',
  'mbAnaResCreateEditLayoutView',
  'underscore',
  'marionette',
  'template!mbAnaResDetailTabTpl',
  'settings',
  'colorUtils'
], function (
  module,
  CreateEditLayoutView,
  _,
  Marionette,
  viewTpl,
  Settings,
  ColorUtils
) {
  'use strict';

  var MbAnaResDetailView = Marionette.ItemView.extend({
    template: viewTpl,

    modelEvents: {
      change: 'render'
    },

    events: {
      'click .js-edit': '_onEdit'
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.colorHexa = ColorUtils
          .decimalToHexa(this.model.get('color'))
          .split('#')[1];
      return templateData;
    },

    _onEdit: function() {
      var createEditView = new CreateEditLayoutView({
        mbanaresModel: this.model
      });
      createEditView.show({
        title: _.i18n('mbanares.edit')
      });
    }

  });

  module.exports = MbAnaResDetailView;
});
