define('superAdminCreditGraphView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'moment',
  'chartView'
], function (
  module,
  Marionette,
  _,
  App,
  moment,
  ChartView
) {
  'use strict';

  var SuperAdminGraphView =  ChartView.extend({
    serializeData: function() {
      var that = this, 
        tplData = this.model.toJSON(),
        labels = [],
        dataBuy = [],
        dataSpent = [];
     
      if ( !_.isUndefined(tplData) && !_.isEmpty(tplData)) {
        _.each(tplData, function(item) {
          if (that.options.filters.type === 'Month') {
            labels.push(moment(item.date).format('MMMM'));
          } else if (that.options.filters.type === 'Day') {
            labels.push(moment(item.date).format('DD/MM/YYYY'));
          } else if (that.options.filters.type === 'Week') {
            labels.push(moment(item.date).format('W'));
          } else if (that.options.filters.type === 'Year') {
            labels.push(moment(item.date).format('YYYY'));
          }
          dataBuy.push(item.buy);
          dataSpent.push(item.spent);
        });

        this.data = {
          labels : labels,
          datasets : [
            {
              label: _.i18n('superAdmin.credits.spent'),
              backgroundColor : 'rgba(0, 190, 164, 0.10)',
              borderColor : 'rgba(0, 190, 164, 1)',
              pointColor : 'rgba(0, 190, 164, 1)',
              pointStrokeColor : '#fff',
              data : dataSpent
            },
            {
              label: _.i18n('superAdmin.credits.bought'),
              backgroundColor : 'rgba(226, 147, 239, 0.10)',
              borderColor : 'rgba(226, 147, 239, 1)',
              pointColor : 'rgba(226, 147, 239, 1)',
              pointStrokeColor : '#fff',
              data : dataBuy
            }
          ]
        };
      }
      this.generateLegend();
      return this.data;
    },

    generateLegend: function() {
      this.options.legend = '<ul class="legend-chart-view">' +
        '<li><span>' + _.i18n('superAdmin.period') + ': '  + this.options.filters.type + '</span></li></ul>';
    }
  });

  module.exports = SuperAdminGraphView;
});
